import React from 'react'
import { Icon } from '@components/common'
// import File from '@images/teachV2/home.svg'
// import Trash from '@images/teachV2/trash.svg'
import Edit from '@images/teachV2/edit.svg'
import imgOlder from '@images/teachV2/img-older.png';
import { useHistory } from "react-router-dom";

const ItemInteractive = (props) => {
  const { caseData } = props;
  const history = useHistory();
  return (
    <div className="interactiveItem">
      <div className="interactiveItem__info">
        <div className='interactiveItem__info-wrap'>
          <img src={caseData?.Thumbnail || imgOlder} alt="older" />
          <div className="info-wrap">
            <p className="info-title">{caseData?.Title} | #{caseData?.Id}</p>
            <span className="info-status">{caseData?.Status}</span>
          </div>
        </div>
        <div className="interactiveItem__info-action">
          <div onClick={() => history.push(`/teach/module/storyboard/${caseData?.Id}`)}>
            <Icon src={Edit} width={20} height={20} stroke="#98A2B3"/>
          </div>
          {/* <div>
            <Icon src={Trash} width={20} height={20} stroke="#D92D20"/>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ItemInteractive
