import React from 'react'
import styled from 'styled-components'
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import { TitleNoteCommon } from '../../../../home/generate';
import { Controller } from "react-hook-form";
import { Input } from 'reactstrap';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

const WrapListConsuming = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 700px;
  .wrap-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .wrap__data {
    display: flex;
    flex-direction: column;
    &-title {
      color: #101828;
      font-size: 14px;
      font-weight: 600;
    }
    &-name {
      color: #101828;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 0;
    }
  }
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const Consuming = (props) => {
  const { control, errors, watch, handleBackStep } = props;

  // Retrieve pagination settings dynamically
  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Complete Your Assignment Setup"
          subtitle="Review your assignment details and provide a name before finalizing. To make changes to the series, period, or cohort, please return to the corresponding previous steps."
        />
        <WrapListConsuming>
          <div className='wrap-item'>
            <TitleNoteCommon
              dataFor={'assignmentName'} 
              title="Assignment Name" 
              content=""
              required
            />
            <Controller
              name="assignment_name"
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <>
                  <Input
                    type='text'
                    name='assignment_name'
                    placeholder="Example: Emergency Medicine Simulation – 2025 Cohort"
                    {...field}
                  />
                  {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                </>
              )}
            />
          </div>
          <div className='wrap__data'>
            <p className='wrap__data-title'>Series</p>
            <p className='wrap__data-name'>{watch('series_selected')[0]?.Name}</p>
          </div>
          <div className='wrap__data'>
            <p className='wrap__data-title'>Period</p>
            <p className='wrap__data-name'>{watch('period_selected')[0]?.Name}</p>
          </div>
          <div className='wrap__data'>
            <p className='wrap__data-title'>Cohort</p>
            <p className='wrap__data-name'>{watch('cohort_selected')[0]?.Name}</p>
          </div>
        </WrapListConsuming>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          {...props} 
          dataPass={!watch('assignment_name')}
          handleBack={handleBackStep}
          isGenerate={true}
        />
      </div>     
    </WrapCommon>
  )
}

export default Consuming
