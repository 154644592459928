import React from 'react'
import styled from 'styled-components'
import ScoreComparision from '../../detailPerformance/ScoreComparision';

const WrapDetailPerformance = styled.div`
  /* padding: 20px;
  border-radius: 16px;
  border: 1px solid #E5E5E5; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  .performance-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #D0D5DD;
    .performance-header__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #101828;
      }
    }
  }
`

const DetailPerformance = (props) => {

  return (
    <WrapDetailPerformance>
      <div className="performance-header">
        <div className="performance-header__title">
          <h1>DETAILED PERFORMANCE</h1>
        </div>
      </div>
      <ScoreComparision {...props}/>
    </WrapDetailPerformance>
  )
}

export default DetailPerformance
