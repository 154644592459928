import React from 'react';
import styled from 'styled-components';
import { Iconsax } from "@components-common";
import { useSelector } from "react-redux";

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
`;

function ButtonNextQuestion(props) {
    const { handleNextQuestion, statusAudio } = props || {};

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const CaseTask = InterfaceTemplateDetails?.CaseTask;

    // Get the question data and loading state from the Redux store
    const questionData = useSelector((state) => state.question || {});
    const isQuestionLoading = questionData?.isLoading;
    return (
        <ButtonContainer>
            <button
                type="button"
                className="btn-send w-100 justify-content-center"
                disabled={isQuestionLoading || statusAudio}
                onClick={handleNextQuestion}
                style={{maxWidth: 300}}
            >
                {/* Use dangerouslySetInnerHTML to render HTML content */}
                <span dangerouslySetInnerHTML={{ __html: CaseTask?.button_next_question?.Text || "I got it!" }} className="mr-2" />
                {isQuestionLoading ? <span className="spinner-border spinner-border-sm"></span> : <Iconsax iconName="arrow-right" fill="#fff" />}
            </button>
        </ButtonContainer>
    );
}

export default ButtonNextQuestion;