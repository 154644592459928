import React, { useState } from "react";
import { FormUpload } from '@ui-partents/Form/FormUpload';

//partents
import { FormModal } from "@ui-partents/Modal";
import { useDispatch } from "react-redux";
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

const ModalUploadTimepointImg = (props) => {
  const { toggle, isOpen, id, timepointData } = props && props;
  const timePointId = timepointData?.Id;
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async () => {
      let formData = new FormData();
      formData.append("PatientProfileImage", imageSrc);
      formData.append("Action", "Update");
      formData.append("Target", "TimePoint");
      formData.append("TimePointId", timePointId);
  
      // Call Api
      dispatch({ type: storyboardConstants.UPLOAD_EXAM_PROFILE_REQUEST, isLoading: true });
      setIsLoading(true);
      apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
        if (res?.status === 200) {
          const data = res?.data;
          dispatch({
            type: storyboardConstants.UPLOAD_EXAM_PROFILE_SUCCESS,
            payload: data
          });
          toggle();
          setImageSrc();
          setIsLoading(false);
        } else {
          dispatch({
            type: storyboardConstants.UPLOAD_EXAM_PROFILE_FAILURE,
            error: 'error'
          });
          console.error("Error uploading:");
          setIsLoading(false);
        }
      });
    };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Upload Image`}
      hiddenIcon={true}
      actionText={`Upload`}
      maxWidth={`600px`}
      onAction={handleUpload}
      actionDisabled={isLoading || !imageSrc}
    >
      <FormUpload
        dataFileImported={imageSrc || null}
        setDataFileImported={setImageSrc}
        typeUpload="JPG, JPEG, PNG. Max: 10 MB."
        typeFile={['image/jpeg', 'image/png']}
        errorFile="Only JPG, JPEG, PNG image files are allowed."
      />
    </FormModal>
  );
};

export default ModalUploadTimepointImg;