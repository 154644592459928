/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ListQuestionForTask from "./ListQuestionForTask";

const SelectedTasksList = (props) => {
  const { handleUpdateTaskActive, dataType, setTabActive } = props;
  
  const [filteredTasks, setFilteredTasks] = useState(dataType?.type === "patient" ? [
    {
      Label: "Demographics",
      Status: "Active",
      ViewClass: "demographics"
    },
    {
      Label: "Appearance",
      Status: "Active",
      ViewClass: "appearance"
    },
    {
      Label: "Dialog Settings",
      Status: "Active",
      ViewClass: "dialog_settings"
    }] : [
      {
        Label: "Description",
        Status: "Active",
        ViewClass: "description"
      },
      {
        Label: "Character",
        Status: "Active",
        ViewClass: "character"
      },
      {
        Label: "Dialog Settings",
        Status: "Active",
        ViewClass: "",
        Children: [
          {
            Label: "Narrative",
            Status: "Active",
            Parent: "Dialog Settings",
            ViewClass: "narrative",
          },
          {
            Label: "Advance / Finish",
            Status: "Active",
            Parent: "Dialog Settings",
            ViewClass: "advance_setting",
          },
        ]
      }
    ])

  useEffect(() => {
    setTabActive(filteredTasks[0])
  }, [filteredTasks])

  return (
    <ul className="selected-tasks-list">
      {filteredTasks.map((selectedTask, index) => (
        <ListQuestionForTask
          {...props}
          key={index} // Ideally, use a unique identifier here if available
          indexItem={index}
          task={selectedTask}
          handleUpdateTaskActive={handleUpdateTaskActive}
          setFilteredTasks={setFilteredTasks}
        />
      ))}
    </ul>
  );
};

export default SelectedTasksList;