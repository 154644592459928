import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from "styled-components";

// Icons
import IconMicro from '@images/chatvoice/micro-icon.svg';
import IconPause from '@images/chatvoice/pause-icon.svg';

const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #2268F0;
  }
  50% {
    border: solid #2268F0;
    opacity: 0.8;
  }
  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #2268F0;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #2268F0;
  }
`;

// Keyframes for shake animation
const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-bottom: 80px;
  .object {
    display: flex;
    flex: 0 1 100%;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    position: relative;
  }
  .outline {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 40px solid #2268F0;
    position: absolute;
    ${({ isRecording }) =>
      isRecording &&
      css`
        animation: ${pulse} 2s ease-out infinite;
      `}
  }
  .button {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    background: #2268F0;
    opacity: 0.34;
    cursor: pointer;
    transition: transform 0.2s;
    &:active {
      transform: scale(0.95);
      animation: ${shake} 0.3s linear;
    }
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    max-width: 100%;
    max-height: 100%;
  }
`;

const FrameChatFullBox = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

const Text = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
`;

export default function RealtimeAI() {
  const [isRecording, setIsRecording] = useState(false);
  const websocketRef = useRef(null);
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const inputRef = useRef(null);
  const globalStreamRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);

  const initWebSocket = () => {
    const protocol = window.location.protocol === "https:" ? "wss://" : "wss://";
    websocketRef.current = new WebSocket(`${protocol}voice-chat-socket.med2lab.com/media-stream?token=168bc5ab490b80f9bbaf54a8197587a947766e08&topic_id=102`);
    websocketRef.current.binaryType = "arraybuffer";

    websocketRef.current.onopen = () => {
      console.log("WebSocket connection opened");
    };

    websocketRef.current.onmessage = (event) => {
      if (typeof event.data !== "string") {
        audioQueueRef.current.push(event.data);
        if (!isPlayingRef.current) {
          playAudioQueue();
        }
      }
    };

    websocketRef.current.onclose = (event) => {
      console.error("WebSocket connection closed:", event);
    };

    websocketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };

  const initAudio = async () => {
    try {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) throw new Error("AudioContext not supported");

      audioContextRef.current = new AudioContext();

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: false,
      });

      globalStreamRef.current = stream;
      inputRef.current = audioContextRef.current.createMediaStreamSource(stream);

      processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);
      processorRef.current.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0);
        const downsampledBuffer = downsampleBuffer(inputData, audioContextRef.current.sampleRate, 16000);
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN && downsampledBuffer) {
          websocketRef.current.send(downsampledBuffer);
        }
      };

      inputRef.current.connect(processorRef.current);
      processorRef.current.connect(audioContextRef.current.destination);
    } catch (error) {
      console.error("Error initializing audio: ", error);
      stopRecording();
    }
  };

  const downsampleBuffer = (buffer, sampleRate, outSampleRate) => {
    if (outSampleRate >= sampleRate) {
      console.warn("Downsampling rate must be lower than original sample rate");
      return null;
    }
    const sampleRateRatio = sampleRate / outSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;

    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0,
        count = 0;
      for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }
      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }

    return result.buffer;
  };

  const playAudioQueue = async () => {
    if (isPlayingRef.current) return;
    isPlayingRef.current = true;
    try {
      while (audioQueueRef.current.length > 0) {
        const arrayBuffer = audioQueueRef.current.shift();
        const audioBuffer = new Int16Array(arrayBuffer);
        const float32Buffer = new Float32Array(audioBuffer.length);

        for (let i = 0; i < audioBuffer.length; i++) {
          float32Buffer[i] = audioBuffer[i] / 0x7fff;
        }

        const buffer = audioContextRef.current.createBuffer(1, float32Buffer.length, 24000);
        buffer.getChannelData(0).set(float32Buffer);

        const source = audioContextRef.current.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContextRef.current.destination);

        await new Promise((resolve) => {
          source.onended = resolve;
          source.start();
        });
      }
    } catch (error) {
      console.error("Error during audio playback:", error);
    } finally {
      isPlayingRef.current = false;
    }
  };

  const startRecording = () => {
    if (isRecording) return;
    setIsRecording(true);
    initWebSocket();
    initAudio();
  };

  const stopRecording = () => {
    if (!isRecording) return;
    setIsRecording(false);

    if (processorRef.current) processorRef.current.disconnect();
    if (inputRef.current) inputRef.current.disconnect();
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }

    if (websocketRef.current) {
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.close();
      }
      websocketRef.current = null;
    }

    if (globalStreamRef.current) {
      globalStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    audioQueueRef.current = [];
    isPlayingRef.current = false;
  };

  return (
    <FrameChatFullBox className="frameChat__fullBox my-5">
      <div className="h-100 w-100 text-center d-flex flex-column justify-content-between">
        {isRecording ? (
          <Box isRecording={isRecording} onClick={stopRecording}>
            <div className="object">
              <div className="outline" />
              <div className="button" />
              <div className="icon-wrapper">
                <img src={IconPause} alt="Stop Recording" className="icon" />
              </div>
            </div>
            <Text>Stop talking</Text>
          </Box>
        ) : (
          <Box isRecording={isRecording} onClick={startRecording}>
            <div className="object">
              <div className="outline" />
              <div className="button" />
              <div className="icon-wrapper">
                <img src={IconMicro} alt="Start Recording" className="icon" />
              </div>
            </div>
            <Text>Press to Talk</Text>
          </Box>
        )}
      </div>
    </FrameChatFullBox>
  );
}