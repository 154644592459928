import React from 'react';
import { FormModal } from '@ui-partents/Modal';
import editModal from '@images/teachV2/edit-modal.svg'
import styled from 'styled-components';
import { FormGroup, Input, Label } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiCaller } from "@utils";
import { atcLoadLaunchPlans } from "@actions";

const WrapForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const WrapFormCol = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Draft', label: 'Draft' },
]

const ModalEditAssignment = ({ isOpen, toggle, item }) => {
  const dispatch = useDispatch();
  const cohortStore = useSelector((state) => state?.listCohort || {});
  const periodsStore = useSelector((state) => state?.loadPeriods || {});
  const instructorData = useSelector((state) => state.instructor || {});

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: item?.Name,
      cohort: item?.Cohort?.CohortId,
      series: item?.Series?.SeriesId,
      status: item?.Status,
      period: item?.Period?.PeriodId,
    }
  })

  const onSubmit = async (data) => {
    try {
      const params = {
        Name: data.name,
        SeriesId: data.series,
        CohortId: data.cohort,
        PeriodId: data.period,
        Status: data.status,
        Action: "Update",
        LaunchPlanId: item?.LaunchPlanId,
      };
      const res = await apiCaller(`/api/teach/launchplan/`, "POST", params);
      if (res?.status === 200) {
        toast.success(`Data updated successfully !`);
        dispatch(atcLoadLaunchPlans());
        toggle();
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title="Edit Assignment Settings"
      actionText={"Save"}
      maxWidth="950px"
      iconTitle={editModal}
      onAction={handleSubmit(onSubmit)}
			// hiddenButtonCancel={true}
    >
      <form className="form-platform">
        <WrapForm>
          <WrapFormCol>
            <FormGroup className='w-100 mb-0'>
              <Label for="nameInput">Assignment Name</Label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => (
                  <>
                    <Input
                      type='text'
                      name='name'
                      placeholder="Name"
                      {...field}
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </>
                )}
              />
            </FormGroup>
            <FormGroup className='w-100 mb-0'>
              <Label for="cohort">Cohort </Label>
              <Controller
                control={control}
                name="cohort"
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select cohort"
                      value={cohortStore?.data.find((c) => c.value === watch("cohort"))}
                      options={cohortStore?.data}
                      onChange={val => {
                        setValue("cohort", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </FormGroup>
          </WrapFormCol>
          <WrapFormCol>
            <FormGroup className='w-100 mb-0'>
              <Label for="series">Series </Label>
              <Controller
                control={control}
                name="series"
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select series"
                      value={instructorData?.data?.Series.find((c) => c.value === watch("series"))}
                      options={instructorData?.data?.Series}
                      onChange={val => {
                        setValue("series", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup className='w-100 mb-0'>
              <Label for="status">Status</Label>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select status"
                      value={statusOptions.find((c) => c.value === watch("status"))}
                      options={statusOptions}
                      onChange={val => {
                        setValue("status", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </FormGroup>
          </WrapFormCol>
          <WrapFormCol>
            <FormGroup className='w-100 mb-0'>
              <Label for="period">Period</Label>
              <Controller
                control={control}
                name="period"
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select period"
                      value={periodsStore?.data.find((c) => c.value === watch("period"))}
                      options={periodsStore?.data}
                      onChange={val => {
                        setValue("period", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup className='w-100 mb-0'>
            </FormGroup>
          </WrapFormCol>
        </WrapForm>
      </form>
    </FormModal>
  );
};

export default ModalEditAssignment;