import React, { useState, useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";

//common
import { Iconsax } from "@components-common";

//constants
import { teachConstants } from "@constants";

//components common
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";
import { ModalDeleteCommon } from "@ui-partents/Modal";

//call Action
import {
  actQuestionOrder,
  atcloadQuestionsFromTemplates,
} from "@actions";

//liblary
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//common
import { MessageEmtyData } from "@ui-partents/Message";

//test
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import DomainList from "./DomainList";
import QuestionListItem from "./QuestionListItem";
import styled from "styled-components";
import { Icon } from '@components/common'
import question from "@images/common/question-circle.svg";

const WrapItemDrag = styled.div`
  display: flex;
  align-items: center;
  justify-content:flex-end;
  gap: 4px;
  .drag-question-icon {
    visibility: hidden;
  }
  &:hover {
    background-color: #F7F8F9;
    .drag-question-icon {
      visibility: visible;
    } 
  }
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #D0D5DD;
  .question__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-header {
      display: flex;
      align-items: center;
      gap: 12px;
      &--id {
        padding: 2px 6px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        border: 1px solid #ABEFC6;
        background-color: #ECFDF3;
        .id-text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #17B26A;
        }
      }
    }
    &-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #1D2939;
    }
  }
  .question__action {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const WrapListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

function DragQuestion(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { data, disableSort, timePointId, task, handleEditQuestion, type } = props && props;

  const [itemSort, setItemSort] = useState(
    (data && data) || []
  );
  const [isSort, setIsSort] = useState(false);
  const [stateModal, setModal] = useState({
    deleteQuestion: false,
  });
  const [itemActive, setItemActive] = useState({});
  const [isLoading, setLoading] = useState({
    addTemplate: false,
    cloneQuestion: false,
    deleteQuestion: false
  });

  //Update Array Store
  useEffect(() => {
    setItemSort((data && data) || []);
    ReactTooltip.rebuild();
  }, [data]);

  //Load Question Blank
  useEffect(() => {
    const params = {
      Action: "Load",
      Target: "QuestionBank",
      Source: "Template",
    };
    dispatch(atcloadQuestionsFromTemplates(id, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SortableItem = sortableElement(({ item }) => {
    const QuestionId = item?.QuestionId;
    return (
      <WrapItemDrag data-id={QuestionId}>
        {!disableSort &&
          <div className="drag-question-icon">
            <Iconsax iconName={`drag-drop`} fill="#939393" />
          </div>
        } 
        <WrapContent>
          <div className="question__content">
            <div className="question__content-header">
              <div className="question__content-header--id">
                <Icon src={question} color="#079455" size={14} />
                <span className="id-text">#{QuestionId}</span>
              </div>
              <QuestionListItem item={item} />
            </div>
            <DomainList item={item} />
            <div className="question__content-desc"
              dangerouslySetInnerHTML={{
                __html: item.QuestionContent,
              }}
            ></div>
          </div>
          <div className="question__action">
            <BtnIconEditV2 handleEditButtonClick={() => handleEditQuestion("editQuestion", item)} colorIcon={`#16B364`} />
            <BtnIconDeleteV2 handleDelete={() => toggle("deleteQuestion", item)} />
          </div>
        </WrapContent>
      </WrapItemDrag>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="lsQuestion">{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItemSort(arrayMove(itemSort, oldIndex, newIndex));
    setIsSort(true);
  };

  useEffect(() => {
    const requestApiSort = (dataSort) => {
      const params = {
        Action: "Update",
        Target: "Questions_Order",
        TimePointId: timePointId,
        Task: task,
        QuestionsOrderByIds: dataSort,
      };

      dispatch(actQuestionOrder(id, params));

      //Done
      setIsSort(false);
    };

    if (itemSort && isSort) {
      var result = _.map(itemSort, "QuestionId");
      const newArray = result.map((item) => {
        return item;
      });
      requestApiSort(newArray);
    }
  }, [itemSort, isSort, dispatch, id, timePointId, task]);

  // Handle Modal
  const toggle = (name, item) => {
    setModal((prevState) => ({ ...prevState, [name]: !stateModal[name] }));
    setItemActive(item);
  };

  // Handle Remove Question
  const handleRemoveQuestion = () => {
    const params = {
      Action: "Delete",
      Target: "Question",
      TimePointId: timePointId,
      Task: type,
      QuestionId: itemActive?.QuestionId,
    };

    const actionList = {
      resquest: teachConstants?.DELETE_QUESTION_REQUEST,
      success: teachConstants?.DELETE_QUESTION_SUCCESS,
      failure: teachConstants?.DELETE_QUESTION_FAILURE,
    }
    sendApiByType(id, params, actionList, 'deleteQuestion');
  }

  //send Api with action
  const sendApiByType = (id, params, actionList, type) => {
    setLoading((prevState) => ({ ...prevState, [type]: true }));
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      dispatch({ type: actionList?.resquest, isLoading: true });
      if (res?.status === 200) {
        const data = res?.data;
        //Set Loading And Close
        setModal((prevState) => ({ ...prevState, [type]: false }));
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.success, payload: data });
      } else {
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.failure, isLoading: false });
        setModal((prevState) => ({ ...prevState, [type]: false }));
      }
    });
  }

  return (
    <React.Fragment>
      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis="y"
        helperClass="question-task"
        distance={1}
      >
        <WrapListItem>
          {itemSort.map((value, index) => (
            <SortableItem
              key={`item-${value.QuestionId}`}
              index={index}
              item={value}
              disabled={disableSort}
            />
          ))}
        </WrapListItem>
      </SortableContainer>
      {/* /Modal Clone */}
      {itemSort.length === 0 && (
        <MessageEmtyData
          title="No Available Questions"
          text="There are no questions yet. Please get started by creating a new question."
        />
      )}
      <ModalDeleteCommon
        title={`Are you certain that you would like to delete this question?`}
        sub={` This action is not reversible.<br />All data related to this question will also be deleted.`}
        isOpen={stateModal.deleteQuestion}
        toggle={() => toggle('deleteQuestion', {})}
        handleRemove={handleRemoveQuestion}
        isLoading={isLoading?.deleteQuestion}
      />
    </React.Fragment>
  );
}

export default DragQuestion;