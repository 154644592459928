import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ProgressStep } from "@ui-partents/ProgressStep";
import dataWizard from './default.json'
import { ModuleSeries } from "./ListStepSetup/ModuleSeries";
import { AvailabilityPeriod } from "./ListStepSetup/AvailabilityPeriod";
import { LearnerCohort } from "./ListStepSetup/LearnerCohort";
import { Consuming } from "./ListStepSetup/Consuming";
import { apiCaller } from "@utils";
import styled from "styled-components";
import Success from '@images/login/success.svg'
import { Icon } from '@components/common'
import { ButtonComponent } from "@components/patterns/elements/Button";
import { useHistory } from "react-router-dom";

const WrapResult = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 60px;
  .result__noti {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &-title {
      color: #101828;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
    }
    &-desc {
      color: #344054;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

const WizardAssignmentSetup = (props) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [loadingResult, setLoadingResult] = useState(false)
  const [dataResult, setDataResult] = useState();

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const handleBackStep = () => {
    setStep((prev) => prev - 1);
  };

  const { 
    register, 
    handleSubmit, 
    setValue, 
    watch, 
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      series: "create_series",
      period: "create_period",
      cohort: "create_cohort",
    }
  });

  const onSubmit = async (data) => {
    setLoadingResult(true);
    try {
      const params = {
        Name: data.assignment_name,
        SeriesId: data.series_selected[0]?.Id,
        CohortId: data.cohort_selected[0]?.CohortId,
        PeriodId: data.period_selected[0]?.PeriodId,
        Status: "Active",
        Action: "Create",
        InterfaceTemplateId: 0
      };
      const res = await apiCaller(`/api/teach/launchplan/`, "POST", params);
      if (res?.status === 200) {
        setDataResult(res?.data);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setLoadingResult(false);
    }
  } 

  const selectStep = (step, contents) => {
    switch (step) {
      case 1:
        return (
          <ModuleSeries 
            step={step}
            setStep={setStep}
            register={register}
            handleNextStep={handleNextStep}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            getValues={getValues}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            errors={errors}
            control={control}
            {...props}
          />
        )
      case 2:
        return (
          <AvailabilityPeriod 
            step={step}
            setStep={setStep}
            register={register}
            handleNextStep={handleNextStep}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            getValues={getValues}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            handleBackStep={handleBackStep}
            errors={errors}
            control={control}
            {...props}
          />
        )
      case 3:
        return (
          <LearnerCohort 
            step={step}
            setStep={setStep}
            register={register}
            handleNextStep={handleNextStep}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            getValues={getValues}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            handleBackStep={handleBackStep}
            errors={errors}
            control={control}
            {...props}
          />
        )
      case 4:
        return (
          <Consuming 
            step={step}
            setStep={setStep}
            register={register}
            handleNextStep={handleNextStep}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            getValues={getValues}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            handleBackStep={handleBackStep}
            errors={errors}
            control={control}
            {...props}
          />
        )
      default:
        break;
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <ProgressStep listStep={dataWizard?.Flow} step={step}/>
      {dataResult ? (
        <WrapResult>
          <Icon src={Success} alt="Success" />
          <div className="result__noti">
            <div className="result__noti-title">Assignment Successfully Created</div>
            <div className="result__noti-desc">{`Your assignment has been successfully created. You can now manage it in Assignments  Assignment Management > Assignments or use the button below to access it directly.`}</div>
            <ButtonComponent style={{ maxWidth: 'max-content' }} size="large" onClick={() => history.push('/teach/assignments-management')}>
              Go to Assigment Management
            </ButtonComponent>
          </div>
        </WrapResult>
      ) : (
        <form id="form-assignment" className="form-report" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-report__question">
            {dataWizard?.Flow?.map((flow, index) => (
              (index + 1) === step && selectStep(flow.StepId, flow?.Contents)
            ))}
          </div>
        </form>
      )}
    </div>
  );
};

export default WizardAssignmentSetup;
