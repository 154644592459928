import React, { useState } from "react";
import { FormModal } from '@ui-partents/Modal';
import styled from "styled-components";
import { ButtonComponent } from "@components/patterns/elements/Button";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from '@components/common'
import { moveTofolder } from "@actions";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import Folder from '@images/teachV2/folder.svg'

const WrapContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header-modal {
    h1 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #475467;
    }
  }
  .action-modal {
    padding-top: 24px;
    border-top: 1px solid #D0D5DD;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .current-folder {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 60%;
    h1 {
      color: #475467;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      white-space: nowrap;
    }
  }
`

const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ItemFolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  background-color: ${({ active }) => active ? '#F2F4F7' : '#FFF'};
  cursor: pointer;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #344054;
    width: 100%;
  }
`


function ModalMoveToFolder(props) {
  const { isOpen, toggle, dataEdit, activeFolder, getListOrgBank } = props && props;
  const dispatch = useDispatch();
  const dataAllFolders = useSelector((state) => state.getListFolders || []);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [folderCurrent, setFolderCurrent] = useState(activeFolder?.FolderId !== 0 ? activeFolder : dataAllFolders?.data[1]);

  const {
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingAdd(true)
    const dataShare = await dispatch(moveTofolder({
      Action: "MoveQuestionToFolder",
      FolderId: folderCurrent?.FolderId,
      QuestionItemId: dataEdit?.QuestionItemId
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Question moved successfully!', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      toggle()
      getListOrgBank()
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={''}
      maxWidth="800px"
      hiddenIcon={true}
      hiddenButtonCancel={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapContentModal>
          <div className="header-modal">
            <h1>Move Question to Another Folder</h1>
            <p>This question is currently saved in the folder below. Select a new folder from the list <br/> to move it.</p>
          </div>
          <div className="current-folder mb-3">
            <h1>Current Folder </h1>
            <ItemFolder>
              <Icon src={Folder}/>
              <p>{activeFolder?.FolderName}</p>
            </ItemFolder>
          </div>
          <WrapItems>
            {dataAllFolders?.data?.map((folder, index) => (
              index !== 0 &&
                <ItemFolder 
                  key={index} 
                  onClick={() => setFolderCurrent(folder)}
                  active={folderCurrent?.FolderId === folder?.FolderId}
                >
                  <Icon src={Folder}/>
                  <p>{folder?.FolderName}</p>
                </ItemFolder>
            ))}
          </WrapItems>
          <div className="action-modal">
            <ButtonComponent 
              color={"outline"}
              onClick={toggle}
              disabled={loadingAdd}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent 
              color="primary" 
              type="submit"
              disabled={loadingAdd}
            >
              Move
            </ButtonComponent>
          </div>
        </WrapContentModal>
      </form>
    </FormModal>
  );
}

export default ModalMoveToFolder;