import React from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for the green pulse effect
const pulseGreen = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
`;

const ReadyTargetWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: -4px;
    top: -12px;
    padding: 2px 8px 2px 6px;
    justify-content: center;
    gap: 6px;
    border-radius: 15px;
    border: 1px solid #A9EFC5;
    background: #ECFDF3;
    animation: ${pulseGreen} 2s infinite;
`;

const ReadyText = styled.span`
    color: #079455;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
`;

const PulseDot = styled.div`
    background: #17B26A;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    animation: ${pulseGreen} 2s infinite;
`;

const ReadyTarget = () => {
    return (
        <ReadyTargetWrapper>
            <PulseDot />
            <ReadyText>Ready</ReadyText>
        </ReadyTargetWrapper>
    );
};

export default ReadyTarget;