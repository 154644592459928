import React from 'react'
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { TitleNoteCommon } from '../../../home/generate';

const WrapFormTextarea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  textarea {
    padding: 12px;
    font-size: 16px;
    background: #F9FAFB;
    border: 1px solid #D6DBE3;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    &:hover {
      cursor: pointer;
      background: #f4f4f4;
    }
    &:focus {
      background: #fff;
      box-shadow: 0px 0px 0px 4px rgb(0 129 255 / 12%);
    }
  }
`

const FormTextareaCommon = (props) => {
  const { setValue, title, textPlaceholder, contentTooltip, dataFor } = props
  return (
    <WrapFormTextarea>
      <div className='w-100'>
        <TitleNoteCommon
          dataFor={dataFor}
          required={true} 
          title={title}
          content={contentTooltip || ''}
          {...props}
        />
      </div>
      <div className='w-100'>
        <Input
          type='textarea'
          name='prompt'
          placeholder={textPlaceholder}
          style={{ height: '200px' }}
          onChange={(e) => {
            setValue("prompt", e.target.value);
          }}
        />
      </div>
    </WrapFormTextarea>
  )
}

export default FormTextareaCommon
