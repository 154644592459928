import React, { useEffect, useState } from "react";
import ImageUser from '@images/common/no-avatar.jpg';

//partents
import { FormModal } from "@ui-partents/Modal";
import { Col, FormGroup, Input, Row } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getListCharacter } from "@actions";
import ClipLoader from "react-spinners/ClipLoader";
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";
import { toast } from "react-toastify";

const WrapItemCharacter = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border: ${(props) => (props.isActive ? "1px solid #0089C2" : "1px solid #D0D5DD")};
  box-shadow: ${(props) => (props.isActive ? "0px 0px 0px 4px #006E9B1F" : "none")};
  border-radius: 8px;
  margin-bottom: 32px;
  cursor: pointer;
  .character__check {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .character__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    color: #081B2A;
    &-img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    &-gender {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }
`

const WrapListCharacter = styled.div`
  max-height: 440px;
  padding: 0 16px;
  overflow-y: scroll;
`

const ColCustom = styled(Col)`
  flex: 0 0 20%; /* 100% / 5 = 20% */
  max-width: 20%;
  padding: 0 8px;
`

const ModalSelectCharacter = (props) => {
  const { toggle, isOpen, id } = props && props;
  const dispatch = useDispatch();
  const listCharacter = useSelector((state) => state?.getListCharacter || {});
  const [dataSelect, setDataSelect] = useState(null)
  const [loadingSelect, setLoadingSelect] = useState(false)

  const handleAction = async () => {
    setLoadingSelect(true)    
    const params = {
      Action: "Update",
      Target: "Character",
      Role: "Instructor",
      CharacterId: dataSelect?.CharacterId
    }
    try {
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({
            type: storyboardConstants.CHARACTER_SETTINGS_SUCCESS,
            payload: data,
          });
          toast.success(`Character selected successfully!`, {
            position: "top-right",
          });
          toggle()
          setLoadingSelect(false)    
        } else {
          dispatch({
            type: storyboardConstants.CHARACTER_SETTINGS_FAILURE,
            error: "error",
          });
        }
      });
    } catch (error) {
      console.error("Error fetching timeline:", error);
      setLoadingSelect(false)    
      // Xử lý lỗi nếu cần thiết
    }
  };

  useEffect(() => {
    dispatch(getListCharacter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Select Virtual Instructor Character`}
      describeContent="Choose a character from the list to be the Virtual Instructor for this module. Each character has a different background."
      hiddenIcon={true}
      actionText={`Select this Character`}
      maxWidth="1024px"
      onAction={handleAction}
      actionDisabled={!dataSelect || loadingSelect}
      classCustom="modal-px-2"
    >
      <WrapListCharacter>
        <Row>
          {listCharacter?.isLoading ? (
            <div className="d-flex aling-items-center justify-content-center w-100 py-3">
              <ClipLoader color="#0089c3" size={24} />
            </div>
          ) : (
            listCharacter?.data?.Characters?.map((item, index) => (
              <ColCustom>
                <WrapItemCharacter isActive={item.CharacterId === dataSelect?.CharacterId} key={index} onClick={() => setDataSelect(item)}>
                  <div className="character__check">
                    <FormGroup check className='form-common'>
                      <Input 
                        className="react-radio-common" 
                        type="radio" 
                        name="radio"
                        checked={item.CharacterId === dataSelect?.CharacterId} 
                      />
                    </FormGroup>
                  </div>
                  <div className="character__info">
                    <div className="character__info-img">
                      <img src={item?.StandardSquarePhoto || ImageUser} alt="" />
                    </div>
                    <p className="character__info-name">{item?.CharacterName}</p>
                    <p className="character__info-gender">{item?.Demographics?.Age || item?.Demographics?.age} years old / {item?.Demographics?.gender || item?.Demographics?.Gender}</p>
                  </div>
                </WrapItemCharacter>
              </ColCustom>
            ))
          )}
        </Row>
      </WrapListCharacter>
    </FormModal>
  );
};

export default ModalSelectCharacter;