import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import apiCaller from "@utils/apiCaller";

// Redux
import { useSelector } from "react-redux";

//redux
import { useDispatch } from "react-redux";

//actions
import {
    atcCallLockElementsChat
} from "@actions";

const TextToSpeech = ({ selectedSpeed, contentSpeechAudio, onAudioStart, onAudioEnd, onStatusChange, handleTTSStreamFinally }) => {
    const [audioObject, setAudioObject] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const audioRef = useRef(null);
    const isFirstRender = useRef(true); // Check lần render đầu tiên
    const history = useHistory();
    const dispatch = useDispatch();

    // Lấy dữ liệu từ Redux Store
    const caseData = useSelector((state) => state.cases || []);
    const InstructorCharacter = caseData?.data?.Case?.Characters?.Instructor?.Character;
    const AgeInteractingCharacter = InstructorCharacter?.Demographics?.age;
    const GenderInteractingCharacter = InstructorCharacter?.Demographics?.gender;

    // Cleanup khi điều hướng trang
    useEffect(() => {
        const unlisten = history.listen(() => stopAudio());

        return () => {
            unlisten();
            stopAudio();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    // Chỉ phát âm thanh khi `contentSpeechAudio` thực sự thay đổi và không bị reset khi mount
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // Lần đầu tiên không phát audio
            return;
        }

        if (contentSpeechAudio) {
            // Trước khi gọi API, khóa view
            dispatch(atcCallLockElementsChat(true));
            
            handleStreamAudio(contentSpeechAudio);
        }

        return stopAudio; // Cleanup khi unmount hoặc `contentSpeechAudio` thay đổi
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSpeechAudio]);

    //Generate Audio
    const handleStreamAudio = async (text) => {
        if (onStatusChange) onStatusChange('streaming');
        
        const params = {
            input: text,
            gender: GenderInteractingCharacter,
            age: parseInt(AgeInteractingCharacter) || 0,
            speed: selectedSpeed
        };
    
        try {
            const response = await apiCaller(
                '/api/binh/tts_streaming/',
                'POST',
                params,
                false,
                { responseType: 'arraybuffer' }
            );
    
            if (response?.status === 200 && response?.data) {
                if (!(response.data instanceof ArrayBuffer)) {
                    throw new Error("Invalid data format");
                }
    
                let newAudioContext = audioContext;
                if (!newAudioContext || newAudioContext.state === 'closed') {
                    newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
                    setAudioContext(newAudioContext);
                }
    
                await playAudio(newAudioContext, response.data);
            }
        } catch (error) {
            handleAudioError(error);
            handleTTSStreamFinally('error');
        } finally {
            handleTTSStreamFinally('completed');
        }
    };
    
    // Play Audio
    const playAudio = async (audioContext, audioData) => {
        try {
            const buffer = await audioContext.decodeAudioData(audioData);
            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
    
            if (onAudioStart) onAudioStart();
            source.start(0);
    
            setAudioObject(source);
            audioRef.current = source;
    
            if (onStatusChange) onStatusChange('playing');
            dispatch(atcCallLockElementsChat(false));

            source.onended = () => {
                handleAudioEnd();
                if (audioContext.state !== 'closed') {
                    audioContext.close();
                    setAudioContext(null);
                }
            };
        } catch (error) {
            console.error('Error decoding audio:', error);
            if (onStatusChange) onStatusChange('error');
            throw error;
        }
    };

    // Handle Audio Error
    const handleAudioError = (error) => {
        console.error('Error streaming audio:', error);
        if (onStatusChange) onStatusChange('error');
    };    

    // Hàm xử lý khi audio kết thúc
    const handleAudioEnd = () => {
        if (onAudioEnd) onAudioEnd();
        if (onStatusChange) onStatusChange('ended');

        if (audioObject) {
            audioObject.stop();
            setAudioObject(null);
        }
    };

    // Hàm dừng và reset tất cả trạng thái âm thanh
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.stop();
            audioRef.current.disconnect();
            audioRef.current = null;
        }
        if (audioContext) {
            audioContext?.close();
            setAudioContext(null);
        }
        setAudioObject(null);
    };

    return null; // Component này không cần render UI
};

export default TextToSpeech;