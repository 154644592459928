// components v1
import {
    TeachCasePage,
    AddCasePage,
    StoryboardPage,
    ReferencesPage,
    SchemaPage,
    SchemaV2Page,
    TestQuestionAIPage,
    InstructorPage
} from "@pages/med2lab/teach";

// components v2
import {
    TeachHome,
    TeachScript,
    // TeachMcq,
    StoryboardV2Page,
    SettingModulePage,
    TeachScriptLibrary,
    InteractiveModule,
    InteractiveBuilder,
    TeachMcqBank,
    InventoryPage,
    ListLearner,
    ReferenceLiblaryPage,
    PresentationPage,
    EngagementPage,
    AnalyzeFreeTextPage,
    ReferenceMediaPage,
    AnalysesDetail,
    CreateMcqMultiple,
    QuestionBankDetail,
    QuestionBankEdit,
    RealtimeAIBeta,
    ReportPage,
    ReportCohortSingle,
    ReportCohortMultiple,
    CreateAssignmentSetup,
    AssignmentManagement,
} from "@pages/teach-v2";


import {
    MgmtHomePage,
    MgmtSeriesPage,
    MgmtCohortsPage,
    MgmtLaunchPlansPage,
    MgmtPeriodsPage,
    MgmtTemplatePage
} from "@pages/teach-v2/Management";
//instructor-tool V2

const teachRoutes = [
    // teach v1
    {
        path: "/instructor-tool",
        exact: true,
        classPage: '',
        component: InstructorPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/case/:id",
        exact: true,
        classPage: '',
        component: TeachCasePage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/new/:slug",
        exact: true,
        classPage: '',
        component: AddCasePage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/storyboard/:id",
        exact: true,
        classPage: '',
        component: StoryboardPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/schema/v1/:id",
        exact: true,
        classPage: '',
        component: SchemaPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/schema/:id",
        exact: true,
        classPage: '',
        component: SchemaV2Page,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/test/:id",
        exact: true,
        classPage: '',
        component: TestQuestionAIPage,
        isLayout: true,
        navigator: true
    },
    {
        path: ["/teach/references/:id"],
        exact: true,
        classPage: '',
        component: ReferencesPage,
        isLayout: true,
        navigator: true
    },

    // Teach V2
    // ----------------------------------------------------------------------
    {
        path: "/teach/module/overview/:id",
        exact: true,
        classPage: '',
        component: TeachCasePage,
        isLayout: true,
        navigator: true
    },
    {
        path: ["/teach/module/references/:id"],
        exact: true,
        classPage: '',
        component: ReferencesPage,
        isLayout: true,
        navigator: true
    },
    {
        path: ["/teach/home"],
        exact: true,
        classPage: '',
        component: TeachHome,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/mcq",
        exact: true,
        classPage: '',
        component: CreateMcqMultiple,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/mcq/bank",
        exact: true,
        classPage: '',
        component: TeachMcqBank,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/script",
        exact: true,
        classPage: '',
        component: TeachScript,
        isLayout: true,
        navigator: false, // Menu Top Page
        isCase: true,
        isBgWhite: true,
    },
    {
        path: "/teach/script/library",
        exact: true,
        classPage: '',
        component: TeachScriptLibrary,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/script/:idScript",
        exact: true,
        classPage: '',
        component: TeachScript,
        isLayout: true,
        navigator: false, // Menu Top Page
        isCase: true,
        isBgWhite: true,
    },
    {
        path: "/teach/presentation",
        exact: true,
        classPage: '',
        component: PresentationPage,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/presentation/:idScript",
        exact: true,
        classPage: '',
        component: PresentationPage,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/engage",
        exact: true,
        classPage: '',
        component: EngagementPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/free-text",
        exact: true,
        classPage: '',
        component: AnalyzeFreeTextPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/free-text/:idQuestion",
        exact: true,
        classPage: '',
        component: AnalyzeFreeTextPage,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/question-bank/review",
        exact: true,
        classPage: '',
        component: QuestionBankDetail,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
        backButtonConfig: {
            showBackButton: true,
            urlBack: "/teach/inventory",
            state: {
                type: "review"
            }
        }
    },
    {
        path: "/teach/question-bank/edit",
        exact: true,
        classPage: '',
        component: QuestionBankEdit,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
        backButtonConfig: {
            showBackButton: true,
            urlBack: "/teach/inventory",
            state: {
                type: "edit"
            }
        }
    },
    {
        path: "/teach/analysis/:idQuestion/:idAnalyses",
        exact: true,
        classPage: '',
        component: AnalysesDetail,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/performance-report",
        exact: true,
        classPage: '',
        component: CreateMcqMultiple,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/realtime-api-beta",
        exact: false,
        classPage: '',
        component: RealtimeAIBeta,
        isLayout: false,
    },
    {
        path: ["/teach/inventory", "/teach/inventory/mcq", "/teach/inventory/script", "/teach/inventory/module", "/teach/inventory/slides", "/teach/inventory/engage"],
        exact: true,
        classPage: '',
        component: InventoryPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: ["/teach/reference-library", "/teach/reference-library/my-ref", "/teach/reference-library/org-ref"],
        exact: true,
        classPage: '',
        component: ReferenceLiblaryPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: ["/teach/reference-media"],
        exact: true,
        classPage: '',
        component: ReferenceMediaPage,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/assignments-setup",
        exact: true,
        classPage: '',
        component: CreateAssignmentSetup,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/assignments-management",
        exact: true,
        classPage: '',
        component: AssignmentManagement,
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: ["/teach/report-learner", "/teach/report-cohort"],
        exact: true,
        classPage: '',
        component: ReportPage,
        isLayout: true,
        navigator: false, // Menu Top Page
    },
    {
        path: "/teach/report-cohort-single",
        exact: true,
        classPage: '',
        component: ReportCohortSingle,
        isLayout: true,
        navigator: false, // Menu Top Page
    },
    {
        path: "/teach/report-cohort-multiple",
        exact: true,
        classPage: '',
        component: ReportCohortMultiple,
        isLayout: true,
        navigator: false, // Menu Top Page
    },
    {
        path: "/teach/module/builder",
        exact: true,
        classPage: '',
        component: InteractiveBuilder, //CreateCasePage
        isLayout: true,
        navigator: false, // Menu Top Page
        isBgWhite: true,
    },
    {
        path: "/teach/module/library",
        exact: true,
        classPage: '',
        component: InteractiveModule,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/module/library/case",
        exact: true,
        classPage: '',
        component: InteractiveModule,
        isLayout: true,
        navigator: false // Menu Top Page
    },
    {
        path: "/teach/module/storyboard/:id",
        exact: true,
        classPage: '',
        component: StoryboardV2Page,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/module/editor/:id",
        exact: true,
        classPage: '',
        component: SettingModulePage,
        isLayout: true,
        navigator: true,
        isShowEditor: true,
    },
    {
        path: "/teach/module/learners/:id",
        exact: true,
        classPage: '',
        component: ListLearner,
        isLayout: true,
        navigator: true
    },
    // Teach V2 - (instructor-tool?tabActive=1)
    // ----------------------------------------------------------------------
    {
        path: "/teach/manage/home",
        exact: true,
        classPage: '',
        component: MgmtHomePage,
        isLayout: true,
        navigator: false
    },
    {
        path: "/teach/manage/series",
        exact: true,
        classPage: '',
        component: MgmtSeriesPage,
        isLayout: true,
        navigator: false
    }, {
        path: "/teach/manage/templates",
        exact: true,
        classPage: '',
        component: MgmtTemplatePage,
        isLayout: true,
        navigator: false
    }, {
        path: "/teach/manage/periods",
        exact: true,
        classPage: '',
        component: MgmtPeriodsPage,
        isLayout: true,
        navigator: false
    },{
        path: "/teach/manage/cohorts",
        exact: true,
        classPage: '',
        component: MgmtCohortsPage,
        isLayout: true, // Layout has Sidebar
        navigator: false // Menu Top Page
    },{
        path: "/teach/manage/launch-plans",
        exact: true,
        classPage: '',
        component: MgmtLaunchPlansPage,
        isLayout: true,
        navigator: false
    }
];

export { teachRoutes };