import React from 'react';
import SidebarItem from '../SidebarItem';
import { useSelector } from 'react-redux';

const MainMenu = ({ isSidebarCollapsed, sidebarItems }) => {
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const AccountData = AccountInfoData?.Account;
    return (
        <ul className="sidebar-menu">
            {!isSidebarCollapsed && <p className="sidebar__title">MANAGEMENT</p>}
            {sidebarItems.map((item, index) => {
                if (item.name === "Performance Report" && (AccountData?.email === "ha@med2lab.com" || AccountData?.email === "kumiko@med2lab.com" || AccountData?.email === "bao@med2lab.com")) {
                    return (
                        <SidebarItem 
                            key={index} 
                            item={item} 
                            isSidebarCollapsed={isSidebarCollapsed} 
                        />
                    );
                } else if (!item.isHidden && item.name !== "Performance Report") {
                    return (
                        <SidebarItem 
                            key={index} 
                            item={item} 
                            isSidebarCollapsed={isSidebarCollapsed} 
                        />
                    );       
                } 
                return null
            })}
        </ul>
    );
};

export default MainMenu;