import React, { useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//components
import { ButtonNextTimepoint } from "@components/learner/StandardScreen/CaseTask"; //ProgressBarAnimation

//call Action
import { actFetchPutCaseRequest } from "@actions";

//redux
import { useSelector, useDispatch } from "react-redux";

function ProgressBarTimePoint(props) {
  //props
  const { isCurrentTask } = props && props;

  //variables
  let { id } = useParams();
  const dispatch = useDispatch();
  const CaseProgressReducer = useSelector((state) => (state?.caseTaskCurrent) || {});

  //previous Data
  // const previousData = CaseProgressReducer?.previousData;
  // const PercentCompletePrevious = previousData?.CaseProgress?.CurrentTimePointProgress?.PercentComplete;

  // New Data
  const details = CaseProgressReducer?.data;
  const CaseProgress = details?.CaseProgress;
  const CurrentTimePointProgress = CaseProgress?.CurrentTimePointProgress;
  // const PercentComplete = CurrentTimePointProgress?.PercentComplete;

  const DisplayName = CurrentTimePointProgress?.DisplayName;

  // Load Case Progress
  useEffect(() => {
    dispatch(actFetchPutCaseRequest(id, isCurrentTask));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])
  return (
    <div className="timePoint-progress">
      <div className="d-flex flex-column w-100">
        <p className="timePoint-progress__name text-left font-weight-500">
          {DisplayName}
        </p>
        {/* <ProgressBarAnimation start={PercentCompletePrevious || 0} end={PercentComplete} /> */}
      </div>
      <ButtonNextTimepoint {...props} />
    </div>
  );
}

export default ProgressBarTimePoint;