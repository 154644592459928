import styled, { keyframes } from "styled-components";
import { Label } from "reactstrap";

// Định nghĩa hiệu ứng fade-in
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Wrapper cho danh sách các lựa chọn (items)
export const AnswerItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

// Mỗi item lựa chọn với hiệu ứng fade-in
export const AnswerItem = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  justify-content: center;

  /* Áp dụng hiệu ứng fade-in */
  animation: ${fadeIn} 0.5s ease-out;

  /* Style cho trạng thái active (selected) */
  &.selected {
    border: 1px solid #0089c2;
    background: #204553;
  }
`;

// Label style
export const StyledLabel = styled(Label)`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;

  /* Active state for the label when selected */
  &.selected {
    color: #fff !important;
  }
`;

// Mỗi item lựa chọn radio với hiệu ứng fade-in
export const RadioItem = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #cdd5df;
  justify-content: center;

  /* Áp dụng hiệu ứng fade-in */
  animation: ${fadeIn} 0.5s ease-out;

  /* Style khi được chọn */
  &.selected {
    border-radius: 8px;
    border: 1px solid #0089C2;
    background: #204553;
    label {
      color: #0089c2 !important;
    }
  }
`;

// Label for radio items
export const RadioLabel = styled(Label)`
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
`;

// Span chứa text của lựa chọn radio
export const RadioText = styled.span`
  padding: 0;
`;
