import adapter from 'webrtc-adapter';
import { useState, useEffect } from 'react';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { useSelector } from "react-redux";

// API Caller
import { apiCaller } from "@utils";

// Constants
const AUDIO_TYPE = 'audio';
const TRANSCRIPTIONS_API_URL = '/api/binh/stt/';

const useWhisperSTT = () => {
  // State Management
  const [recorder, setRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [status, setStatus] = useState('stopped'); // 'stopped', 'recording', 'paused'
  const [error, setError] = useState(null); // Error state
  const [transcribedText, setTranscribedText] = useState(''); // Stores transcribed text

  // Redux Data
  const caseData = useSelector((state) => state.cases || []);
  const caseResponse = caseData?.data;
  const InstructorCharacters = caseResponse?.Case?.Characters?.Instructor?.Character;
  const IsoCode = InstructorCharacters?.Language?.IsoCode?.code || 'en';

  // Handle Errors
  const handleError = (error) => {
    console.error("Recording Error:", error.message);
    setError(error.message);
    resetRecordingState();
  };

  // Start Recording
  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioRecorder = new RecordRTCPromisesHandler(audioStream, { type: AUDIO_TYPE });

      await audioRecorder.startRecording();
      setStream(audioStream);
      setRecorder(audioRecorder);
      setStatus('recording');
      setTranscribedText(''); // Clear previous transcription
    } catch (error) {
      handleError(error);
    }
  };

  // Stop Recording & Transcribe (Now correctly returns transcribed text)
  const stopRecording = async () => {
    if (status !== 'recording' || !recorder) {
      handleError(new Error('Cannot stop: No recorder or not currently recording'));
      return ''; // Ensure a string is always returned
    }

    try {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();

      // Transcribe the audio and update state
      const transcribedText = await transcribe(blob, IsoCode);

      cleanupStream();
      console.log("Transcribed Text:", transcribedText);

      // Update state with transcribed text
      setTranscribedText(transcribedText);

      return transcribedText; // NOW correctly returning the transcribed text
    } catch (error) {
      handleError(error);
      return ''; // Always return a string on error
    }
  };

  // Transcribe Audio (Send to Backend API)
  const transcribe = async (audioBlob, language) => {
    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'audio.wav'); // Required API format
    formData.append('language', language);

    try {
      const response = await apiCaller(TRANSCRIPTIONS_API_URL, 'POST', formData);
      if (response?.status === 200) {
        const text = response?.data?.text || '';
        console.log(response, "response........");

        // Update state with transcribed text
        setTranscribedText(text);
        return text; // Ensure we return the transcribed text
      }
    } catch (error) {
      handleError(new Error('Error transcribing audio'));
      return ''; // Return empty text on error
    }
  };

  // Cleanup Stream
  const cleanupStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    resetRecordingState();
  };

  // Reset Recording State
  const resetRecordingState = () => {
    setRecorder(null);
    setStream(null);
    setStatus('stopped');
  };

  useEffect(() => {
    console.log(`Browser: ${adapter.browserDetails.browser}, Version: ${adapter.browserDetails.version}`);
  }, []);

  return {
    isRecording: status === 'recording',
    isStopped: status === 'stopped',
    isPaused: status === 'paused',
    error,
    transcribedText, // Expose transcribed text in the hook
    startRecording,
    stopRecording,
  };
};

export default useWhisperSTT;