import React, { useState, useEffect } from 'react';

//reactstrap
import { Label, Row, Col, FormGroup, Input } from "reactstrap";

//call Action
import { atcUpdateChatModeChat } from "@actions";

//redux
import { useDispatch } from "react-redux";

//react router
import { useParams } from "react-router-dom";

//components
import { NatureOfInteraction } from "../InteractiveChat";

const QuestionTypeComponent = (props) => {
    const dispatch = useDispatch();
    let { id } = useParams();
    const options = [
        {
            label: 'Learner to choose from a list of questions',
            value: 'click'
        },
        {
            label: 'Learner to enter question as free-text',
            value: 'freetext'
        },
        {
            label: 'Learner to interact by voice (mic required)',
            value: 'voice'
        },
        {
            label: 'Learner to interact by voice - STREAMING',
            value: 'streaming'
        },
        {
            label: 'Learner can switch between voice vs typing',
            value: 'switch_voice_text'
        }
    ]

    //props
    const { timepointData } = props && props;
    const timePointId = timepointData?.Id;
    const chatModeParams = timepointData?.ChatMode;

    //state
    const [stateChecked, setChecked] = useState(chatModeParams || '');

    //Load
    useEffect(() => {
        setChecked(chatModeParams || '');
    }, [chatModeParams]);

    //hanlde Change
    const handleChange = (type) => {
        const params = {
            "Action": "Update",
            "Target": "TimePoint",
            "TimePointId": timePointId,
            "ChatMode": type
        }
        setChecked(type);
        dispatch(atcUpdateChatModeChat(id, params));
    }

    return (
        <Row>
            <Col md={8}>
                <FormGroup tag="fieldset">
                    <Label className='labeForm --label-blue mb-2'>Choose chat interaction method:</Label>
                    {options?.map((item, index) =>
                        <FormGroup check key={index} className='mb-2'>
                            <Label check>
                                <Input
                                    type="radio"
                                    className='radio-base'
                                    id={`${item?.value}`}
                                    name="ChatMode"
                                    defaultValue={stateChecked}
                                    checked={stateChecked === item?.value}
                                    onChange={() => handleChange(`${item?.value}`)}
                                />{' '}
                                {item?.label}
                            </Label>
                        </FormGroup>
                    )}
                </FormGroup>
            </Col>
            <Col md={4}>
                <NatureOfInteraction {...props} />
            </Col>
        </Row>
    );
};

export default QuestionTypeComponent;