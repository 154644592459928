import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import { useSelector, useDispatch } from "react-redux";
import { MessageEmtyData } from "@ui-partents/Message";
import { getReferenceMedia } from "@actions";
import { wizardConstants } from "@constants";
import HeaderCommon from './HeaderCommon';
import { SearchCustomForTable } from "@ui-partents/Search";

// Import the pagination configuration
import {paginationOptions} from "@utils";

//partents
import { SortCaret } from "@ui-partents/Table";
import { FormGroup, Input } from 'reactstrap';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function SelectListCommonImage(props) {
  const { setValue, dataForm, watch, handleNext } = props;
  const dispatch = useDispatch();
  const initialSelectedBooks = watch(dataForm?.field) || [];
  const { data: documents } = useSelector((state) => state.getReferenceMedia) || {};
  const [filteredData, setFilteredData] = useState(documents?.Combined || []); // dữ liệu gốc
  const selectedImagesRef = useRef(initialSelectedBooks);

  useEffect(() => {
    dispatch(getReferenceMedia())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (documents?.Combined) {
      // setFilteredData(documents.Combined); // Cập nhật dữ liệu đã lọc khi có thay đổi dữ liệu gốc
      setFilteredData(
        documents.Combined.map(item => ({
          ...item,
          selected: selectedImagesRef.current.some(image => image.id === item.id),
        }))
      );
    }
  }, [documents]);

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
    setValue(dataForm?.field, null)
    selectedImagesRef.current = [];
    setFilteredData([]);
  }

  const handleNextSelect = () => {
    setValue(dataForm?.field, selectedImagesRef.current);
    handleNext();
  };

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: {
        width: "96%",
      },
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  // const onSelect = (row, isSelect) => {
  //   if (isSelect) {
  //     setSelectedBooks([row]);
  //     setValue(dataForm?.field, [row])
  //   } else {
  //     setSelectedBooks(selectedBooks.filter((book) => book.id !== row.id));
  //     setValue(dataForm?.field, selectedBooks.filter((book) => book.id !== row.id))
  //   }
  // }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    // hideSelectColumn: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [];
      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.id === row.id);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }
      
      selectedImagesRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.id === item.id),
      })));
      setValue(dataForm?.field, updatedSelectedBooks.map(book => book.id));
    },
    selected: selectedImagesRef.current.map(book => book.id),
    selectionRenderer: ({ mode, checked }) => (
      <FormGroup check className="form-common">
        <Input
          {...mode}  // This automatically handles checked state and onChange
          checked={checked} 
          type="radio"
          className="react-radio-common"
        />
      </FormGroup>
    ),
  };

  const handleClearSearch = () => {
    setFilteredData(
      documents?.Combined.map(item => ({
        ...item,
        selected: selectedImagesRef.current.some(book => book.id === item.id),
      })) || []
    );
    setValue(dataForm?.field, selectedImagesRef.current.map(book => book.id));
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={filteredData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                {/* <SearchBar 
                  {...toolkitprops.searchProps} 
                  onSearch={(searchText) => {
                    paginationProps.page = 1
                    toolkitprops.searchProps.onSearch(searchText);
                    setFilteredData(documents?.Combined.filter(doc => 
                      doc.title.toLowerCase().includes(searchText.toLowerCase())
                    ));
                  }} 
                /> */}
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder="Search"
                  onClear={handleClearSearch}
                />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-common"
                data={filteredData || []}
                bordered={false}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  // Calculate the total size based on documents
  const totalSize = filteredData.length || 0;

  // Retrieve pagination settings dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon {...props} />
        <div className="form-area__select">
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        </div>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          {...props} 
          dataPass={!selectedImagesRef.current.length}
          handleNext={handleNextSelect}
          handleBack={handleActionBack}
        />
      </div>     
    </WrapCommon>
  )
}
