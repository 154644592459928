// VoiceChatComponent.styles.js
import styled, { css } from "styled-components";

export const StyledImage = styled.img`
  width: 110px;
  height: 110px;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const PatientLabel = styled.div`
  color: #5ecdfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
`;

export const ImageLabelWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

export const ResponseText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  margin-top: 20px;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 90%;
  }
`;

export const BlinkingDiv = styled.div`
  position: relative;
  ${({ audioStarted }) =>
    audioStarted &&
    css`
      &:before,
      &:after {
        content: "";
        width: 120px;
        height: 120px;
        border: 14px solid rgba(20, 91, 173, 0.8);
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
      &:before {
        animation: pulse 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
      }
      &:after {
        animation: pulse 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        animation-delay: 0.375s;
      }

      @keyframes pulse {
        from {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.8;
        }
        to {
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.4;
        }
      }
    `}
`;

export const FooterChatVoice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;