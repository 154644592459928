import React from 'react'
import { Icon } from '@components/common'
// import File from '@images/teachV2/home.svg'
import Edit from '@images/teachV2/edit.svg'
import imgOlder from '@images/teachV2/img-older.png';
import { useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import { deleteCasefromSeries } from "@actions";
import { CloseIcon } from "@components/teachV2/Manage/Cohorts";

const ItemInteractive = (props) => {
  const { caseData, titleSeries, handelLoadAllCase, activeSeries } = props;
  const history = useHistory();

  const handleRemove = (caseId) => {
    const params = {
      Action: "RemoveCase",
      CaseId: caseId,
    };
    deleteCasefromSeries(activeSeries, params);

    handelLoadAllCase()
  };

  return (
    <div className="interactiveItem">
      <div className="interactiveItem__info">
        <div className='interactiveItem__info-wrap'>
          <img src={caseData?.Thumbnail || imgOlder} alt="older" />
          <div className="info-wrap">
            <p className="info-title">{caseData?.Title} | #{caseData?.Id}</p>
            <span className="info-status">{caseData?.Status}</span>
          </div>
        </div>
        <div className="interactiveItem__info-action">
          <div data-tip data-for={`edit-${caseData?.Id}`} onClick={() => history.push(`/teach/module/storyboard/${caseData?.Id}`)}>
            <Icon src={Edit} width={20} height={20} stroke="#98A2B3"/>
          </div>
          <ReactTooltip id={`edit-${caseData?.Id}`} place='top' effect='solid' className={`custom-tooltip`} >
            <span>Edit Module</span>
          </ReactTooltip>
          {activeSeries !== 'all_case' && (
            <WrapModalDeleteCommon
              title={`Remove Module`}
              sub={`Are you sure you want to remove this module from ${titleSeries}. The module will NOT be deleted. However, learners who are assigned this series will no longer have access to this module.`}
              handleRemove={handleRemove}
              idRemove={caseData?.Id}
            >
              {({onOpen}) => (
                <>
                  <div className="d-flex justify-content-center align-items-center" data-tip data-for={`remove-${caseData?.Id}`} onClick={onOpen}>
                    <CloseIcon />
                  </div>
                  <ReactTooltip id={`remove-${caseData?.Id}`} place='top' effect='solid' className={`custom-tooltip`} >
                    <span>Remove Module</span>
                  </ReactTooltip>
                </>
              )}
            </WrapModalDeleteCommon>
          )}
        </div>
      </div>
      {/* <div className="interactiveItem__note">
        <div className="interactiveItem__note-icon">
          <Icon src={File} height="8" width="8" />
        </div>
        <p>Note: 
          <span> Check something out test</span>
        </p>
      </div> */}
    </div>
  )
}

export default ItemInteractive
