import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoaderPage from '@utils/LoaderPage';
import { ModalSSOMessage } from "@components/auth/sso";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import axios from "axios";
import { API_URL, MAKANSAFE_API_URL } from '@constants/Config';

// utilities
import { checkAuth } from '@utils';

// alert
import { toast } from 'react-toastify';
//actions
import { actionLogin, actFetchThemeRequest } from "@actions";

const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const apiCallerSSO = (endpoint, method = "GET", body,access_token, useMakanSafeUrl = false) => {
    if (!endpoint) {
      return Promise.reject(new Error('Endpoint is undefined'));
    }
    
    const apiUrl = useMakanSafeUrl ? `${MAKANSAFE_API_URL || ''}${endpoint}` : `${API_URL || ''}${endpoint}`;
    return axios({
      method: method,
      url: apiUrl,
      data: body,
      headers: {
        "Authorization": "Bearer "+access_token
      }
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if (error.response && checkAuth()) {
          const message = error?.response?.data?.message;
          switch (error.response.status) {
            case 403:
              window.location.href = `/403-forbidden?message=${message}`;
              break;
            case 500:
              toast.error('Note: There might be something wrong. We will look into this.', {
                autoClose: 4000
              });
              break;
            default:
              break;
          }
        }
        return error;
      });
  };
  
const SsoPage = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const username = queryParams.get('user');
                const password = queryParams.get('pwd');

                if (!username || !password) {
                    history.push('/login');
                    return;
                }

                const formData = new FormData();
                formData.append('username', username);
                //formData.append('one_time_code', password);

                const response = await apiCallerSSO('/api/auth/login_sso/', 'POST', formData,password);
                const { data, status } = response;

                if (status === 200 && data.status !== "Log in attempt failed") {
                  handleLoginSuccess(data);
                } else {
                  setError("Log in attempt failed. Please check your credentials.");
                }
            } catch (error) {
                console.error("Error during login:", error);
                setError("An error occurred during login. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const handleLoginSuccess = async (data) => {
        // Dispatch login action
        await dispatch(actionLogin(data));

        // Fetch and apply theme
        await dispatch(actFetchThemeRequest());

        const { is_instructor: isInstructor } = data?.account || {};
        nextPage(isInstructor);
    };

    const nextPage = (isInstructor) => {
      const queryParams = new URLSearchParams(location.search);
      const paramsCaseid = queryParams.get('case_id');
      const paramsScriptId = queryParams.get('script_id');
      let redirectPath;
    
      if (paramsCaseid) {
        redirectPath = `/learn/case/${paramsCaseid}`;
      } else if (isInstructor === 'Yes' && paramsScriptId) {
        redirectPath = `/teach/script/${paramsScriptId}`;
      } else if (isInstructor === 'Yes') {
        redirectPath = "/teach/home";
      } else {
        redirectPath = "/learn/home";
      }
    
      history.push(redirectPath);
    };

    if (loading) {
        return <LoaderPage />;
    }

    if (error) {
        const dataMessage = {
            error: true,
            title: 'Log in attempt failed',
            message: 'Your login attempt has failed. Please check your credentials and try again.',
            buttons: [
                {
                    ButtonTitle: 'Back to Login',
                    ButtonStyle: 'primary',
                    URL: '/login',
                },
            ],
        };
        return (
            <ModalContainer>
                <ModalSSOMessage dataMessage={dataMessage} />
            </ModalContainer>
        );
    }

    return null;
};

export default SsoPage;
