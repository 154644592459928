import React, { useState } from 'react'
import styled from 'styled-components'
import { ChoiceCommon } from '../../StepCommon'
import FormCreatePeriod from './FormCreatePeriod'
import SelectSinglePeriod from './SelectSinglePeriod'

const WrapperModule = styled.div`
  width: 100%;
  height: 100%;
`

const AvailabilityPeriod = (props) => {
  const { handleNextStep, handleBackStep } = props;
  const [subStep, setSubStep] = useState();

  const handleSubStep = (subStep) => {
    setSubStep(subStep)
  }

  const selectTypeFlow = (contents) => {
    switch (contents?.view) {
      case "create_period":
        return (
          <FormCreatePeriod
            handleNext={handleNextStep}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      case "select_exist_period":
        return (
          <SelectSinglePeriod
            handleNext={handleNextStep}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      default:
        break;
    }
  }

  return (
    <WrapperModule>
      {subStep ? (
        subStep?.SubSteps?.map((step) => (
          selectTypeFlow(step.Contents)
        ))
      ) : (
        <ChoiceCommon
          {...props}
          handleBack={handleBackStep}
          handleSubStep={handleSubStep}
          type="period"
        />
      )}
    </WrapperModule>
  )
}

export default AvailabilityPeriod
