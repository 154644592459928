import React, { useState } from 'react'
import { WizardAssignmentSetup } from '@components/teachV2/AssignmentsPage/AssignmentSetup'
import styled from 'styled-components'
import { ButtonComponent } from "@components/patterns/elements/Button";

const WrapExplain = styled.div`
  min-height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .explain-wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 720px;
  }
  .explain-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    h1 {
      color: #101828;
      text-align: center;
      font-size: 36px;
      font-weight: 600;
    }
    p {
      color: #344054;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

const CreateAssignmentSetup = () => {
  const [showOptionSeries, setShowOptionSeries] = useState(false)
  
  return (
    <div className="wrapper -performancePage">
      {!showOptionSeries ? (
        <WrapExplain>
          <div className='explain-wr'>
            <div className='explain-content'>
              <h1>Assignment Setup</h1>
              <p>An assignment is a structured set of interactive modules assigned to a specific group of learners within a defined period. Click the button below to create a new assignment.</p>
            </div>
            <ButtonComponent 
              size="large" 
              style={{ fontSize: '18px', fontWeight: 500 }} 
              onClick={() => setShowOptionSeries(true)}
            >
              Create New Assigment
            </ButtonComponent>
          </div>
        </WrapExplain>
      ) : (
        <WizardAssignmentSetup setShowOptionSeries={setShowOptionSeries}/>
      )}
    </div>
  )
}

export default CreateAssignmentSetup
