/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { TitleNoteCommon } from "../../home/generate";

const WrapForm = styled.div`
  width: 100%;
  max-width: 420px;
`;

const WrapFormTextarea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

export default function Demographics(props) {
  const { timepointData, id } = props;
  const characters = timepointData?.Characters || {};
  const dispatch = useDispatch();

  const { watch, reset, setValue, register } = useForm();

  useEffect(() => {
    if (timepointData) {
      reset({
        name: characters?.Instructor?.DisplayName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timepointData]);

  const handleSubmit = useCallback(
    debounce((name) => {
      const params = {
        Action: "Update",
        Target: "Character",
        Role: "Instructor",
        DisplayName: name
      };
      dispatch({
        type: storyboardConstants.DESCRIPTION_SETTINGS_REQUEST,
        isLoading: true,
      });
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({
            type: storyboardConstants.DESCRIPTION_SETTINGS_SUCCESS,
            payload: data,
          });
          toast.success(`Data updated successfully !`, {
            position: "top-right",
          });
        } else {
          dispatch({
            type: storyboardConstants.DESCRIPTION_SETTINGS_FAILURE,
            error: "error",
          });
        }
      });
    }, 500),
    []
  );

  return (
    <WrapForm>
      <form className="form-platform">
        <WrapFormTextarea>
          <div className="w-100">
            <TitleNoteCommon
              dataFor="display_name"
              title="Display Name "
              content="The title of the persona being shown to the learner"
              {...props}
            />
          </div>
          <div className="w-100">
            <Input
              id="ageInput"
              name="name"
              placeholder="Example: Instructor / Preceptor / Mrs. Smith etc."
              {...register("name")}
              min={0}
              type="text"
              defaultValue={watch("name")}
              onChange={(e) => setValue("name", e.target.value)}
              onBlur={() =>
                handleSubmit(watch("name"))
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </WrapFormTextarea>
      </form>
    </WrapForm>
  );
}
