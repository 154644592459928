import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { atcLoadLaunchPlans, atcDeleteLaunchPlan, actLoadAllPeriods, atcLoadAllCohorts, actFetchTeachPortfolioRequest } from "@actions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { MessageEmtyData } from "@ui-partents/Message";
import { LoaderText } from "@utils";
import { paginationOptions } from "@utils";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import styled from 'styled-components';
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import ReactTooltip from 'react-tooltip';
import eyeOn from "@images/auth/eye.svg";
import fileDownload from "@images/teachV2/file-download.svg";
import editIcon from '@images/common/edit-action.svg'
import trashIcon from '@images/teachV2/trash.svg'
import { ButtonComponent } from "@components/patterns/elements/Button";
import ModalAssignmentDetail from './ModalAssignmentDetail';
import Plus from '@images/common/plus-single.svg'
import _ from 'lodash';
import Select from 'react-select';
import { apiCaller } from "@utils";
import ModalEditAssignment from './ModalEditAssignment';
import { useHistory } from 'react-router-dom';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .title-name {
    color: #081B2A;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .custom-tooltip {
    width: 340px;
  }
`

const WrapStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #ABEFC6;
  background: #ECFDF3;
  border-radius: 9999px;
  padding: 2px 6px;
  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #17B26A;
  }
  p {
    color: #067647;
    font-size: 12px;
    font-weight: 500;
  }
  &.type-draft {
    border: 1px solid #F2F4F7 !important;
    background: #F7F8FA !important;
    span {
      background: #667085 !important;
    }
    p {
      color: #344054 !important;
    }
  }
  &.type-expired {
    border: 1px solid #FECDCA !important;
    background: #FEF3F2 !important;
    span {
      background: #F04438 !important;
    }
    p {
      color: #B42318 !important;
    }
  }
`

const WrapAction = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .item-action {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

const WrapFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .select-status {
    width: 150px;
  }
  .btn-add-new {
    font-weight: 500;
    gap: 4px;
    span {
      white-space: nowrap;
    }
  }
`

const WrapLoader = styled.div`
  height: 200px;
`

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Draft', label: 'Draft' },
  { value: 'Expired', label: 'Expired' },
]

const AssignmentTab = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeLaunchPlans = useSelector((state) => state.loadAllLaunchPlans || []);
  const dataLaunchPlans = storeLaunchPlans?.data || [];
  const [stateData, setStateData] = useState([]);
  const [rowRecord, setRowRecord] = useState({});
  const [isStatus, setStatus] = useState('');
  const [modal, setModal] = useState({
    details: false, 
    edit: false,
  });
  
  const toggle = (type, row) => {
    setRowRecord(row);
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const styleStatus = (status) => {
    switch (status) {
      case "Active":
      case "Start":
        return "";
      case "Draft":
      case "Not Started":
        return "type-draft";
      case "Expired":
        return "type-expired";
      default:
        return "";
    }
  }
  
  //Load Launch Plans
  useEffect(() => {
    dispatch(atcLoadLaunchPlans());
    dispatch(actLoadAllPeriods({ Action: "Load" }));
    dispatch(atcLoadAllCohorts());
    dispatch(actFetchTeachPortfolioRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Load Data
  useEffect(() => {
    if (isStatus === '') {
      setStateData(dataLaunchPlans || []);
    } else {
      const dataDefault = _.filter(dataLaunchPlans, { 'Status': "Active" });
      setStateData((dataDefault && dataDefault) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeLaunchPlans])

  const handleExportCsv = (items) => {
    const params = {
      "Action": "ExportRawData",
      "LaunchPlanId": items?.LaunchPlanId,
      "ExcelName": items?.Name,
      "DataIncluded": ["Chat", "Questions"]
    };

    apiCaller(`/api/teach/launchplan/`, 'POST', params)
      .then(res => {
        const processedName = params?.ExcelName?.replace(/\s/g, '_');
        const fileName = `${processedName}.xlsx`;
        const excelUrl = res?.data?.excel_url;

        if (res?.status === 200) {
          fetch(excelUrl)
            .then(response => response.blob())
            .then(blob => {
              const link = document.createElement('a');
              const url = window.URL.createObjectURL(new Blob([blob]));

              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);

              link.click();

              // Remove the a element after the download
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading file:', error);
            });
        }
      })
      .catch(error => {
        console.error('Error exporting CSV:', error);
      });
    };

  const columns = [
    {
      dataField: "Name",
      text: "Assignment Name",
      headerClasses: "text-nowrap font-weight-500",
    },
    {
      dataField: "LaunchPlanId",
      text: "Series",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (cell, row) => {
        return (
          <p>{row?.Series?.Name}</p>
        );
      },
    },
    {
      dataField: "LaunchPlanId",
      text: "Cohort",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (cell, row) => {
        return (
          <p>{row?.Cohort?.CohortName}</p>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Period",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (cell, row) => {
        return (
          <p>{row?.Period?.PeriodName}</p>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (cell, row) => {
        return (
          <WrapStatus className={`d-flex align-items-center question-type ${styleStatus(cell)}`}>
            <span></span>
            <p>
              {cell}
            </p>
          </WrapStatus>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-nowrap font-weight-500 text-center",
      headerStyle: { width: "72px" },
      formatter: (cell, row) => {
        return (
          <WrapAction>
            <div className='item-action' data-tip data-for={`view-${cell?.topic_id}`} onClick={() => toggle('details', row)}>
              <Icon src={eyeOn} width={20} height={20} stroke="#475467"/>
            </div>
            <ReactTooltip id={`view-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
              <span>View Progress</span>
            </ReactTooltip>
            <div className='item-action' data-tip data-for={`export-${cell?.topic_id}`} onClick={() => handleExportCsv(row)}>
              <Icon src={fileDownload} width={20} height={20} stroke="#475467"/>
            </div>
            <ReactTooltip id={`export-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
              <span>Export Data</span>
            </ReactTooltip>
            <div className='item-action' data-tip data-for={`edit-${cell?.topic_id}`} onClick={() => toggle('edit', row)}>
              <Icon src={editIcon} width={20} height={20} stroke="#475467"/>
            </div>
            <ReactTooltip id={`edit-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
              <span>Edit Settings</span>
            </ReactTooltip>
            <WrapModalDeleteCommon
              title={`Delete Assignments `}
              sub={`Are you certain that you would like to delete this Assignments? Learner’s performances will not be removed. However, all analyzed data regarding this trial period will be removed.`}
              handleRemove={handleRemove}
              idRemove={row?.LaunchPlanId}
            >
              {({onOpen}) => (
                <>
                  <div className='item-action' data-tip data-for={`remove-${cell?.topic_id}`} onClick={() => {
                    onOpen();
                    setRowRecord(row);
                  }}>
                    <Icon src={trashIcon} width={20} height={20} stroke="#475467"/>
                  </div>
                  <ReactTooltip id={`remove-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
                    <span>Delete Assignment</span>
                  </ReactTooltip>
                </>
              )}
            </WrapModalDeleteCommon>
          </WrapAction>
        );
      },
    }
  ];

  const handleRemove = (idRemove) => {
    console.log(idRemove);
    const params = {
      Action: "Delete",
      LaunchPlanId: idRemove,
    };
    dispatch(atcDeleteLaunchPlan(params));
    setRowRecord();
  };

  //handle Change Status
  const handleChange = (value) => {
    if (dataLaunchPlans && value) {
      const newData = _.filter(dataLaunchPlans, { 'Status': value });
      setStatus(value);
      setStateData((newData && newData) || []);
    } else {
      setStateData((dataLaunchPlans && dataLaunchPlans) || []);
      setStatus(value);
    }
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="LaunchPlanId"
        data={stateData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-library__filter d-flex align-items-center justify-content-between">
              <WrapNote>
                <p className="title-name">Assignments</p>
                <div data-tip data-for="note-tile">
                  <Icon src={info} width={20} height={20}/>
                </div>
                <ReactTooltip id="note-tile" place='top' effect='solid' className={`custom-tooltip`} >
                  <span>An assignment is a structured set of interactive modules assigned to a specific group of learners within a defined period.</span>
                </ReactTooltip>
              </WrapNote>
              <WrapFilter>
                <Select
                  className={`react-select-common select-status`}
                  placeholder="Select item"
                  options={statusOptions}
                  value={statusOptions.find(option => option.value === isStatus)}
                  classNamePrefix="react-select-custom"
                  onChange={(e) => handleChange(e.value)}
                />
                <ButtonComponent 
                  size="small"
                  className="d-flex align-items-center btn-add-new"
                  onClick={() => history.push("/teach/assignments-setup")}
                >
                  <Icon src={Plus} stroke="#fff" width={20} height={20}/>
                  <span>Create New Assignment</span>
                </ButtonComponent>
              </WrapFilter>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={stateData || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = stateData?.length || 0; 
  const paginationConfig = paginationOptions({ totalSize });
  
  return (
    <div className="position-relative">
      {storeLaunchPlans?.isLoading ? (
        <WrapLoader>
          <LoaderText />
        </WrapLoader>
      ) : (
        <PaginationProvider pagination={paginationFactory(paginationOptions(paginationConfig))}>
          {contentTable}
        </PaginationProvider>
      )}
      {modal?.details && (
        <ModalAssignmentDetail 
          isOpen={modal?.details}
          toggle={() => toggle("details", null)}
          item={rowRecord}
          styleStatus={styleStatus}
        />
      )}
      {modal?.edit && (
        <ModalEditAssignment
          isOpen={modal?.edit}
          toggle={() => toggle("edit", null)}
          item={rowRecord}
        />
      )}
    </div>
  )
}

export default AssignmentTab
