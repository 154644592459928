import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useScreenSize } from "@context/ScreenSizeContext";
import styled from "styled-components";
import {
  MicRecord,
  ButtonSend,
  TextArea,
  CustomIcon,
  AudioWaveform,
} from "../../ChatFooter";

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "0" : "8px")};
  padding: ${({ isMobile }) => (isMobile ? "0" : "16px 20px")};
  border-radius: ${({ isMobile, typeQuestion }) =>
    typeQuestion ? "none" : isMobile ? "8px 8px 0 0" : "8px"};
  border: ${({ isMobile, typeQuestion }) =>
    typeQuestion ? "none" : isMobile ? "none" : "1px solid #e3e8ef"};
  background: #fff;
  box-shadow: ${({ isMobile }) =>
    isMobile ? "none" : "0 1px 2px rgba(16, 24, 40, 0.05)"};
  flex: ${({ isMobile }) => (isMobile ? "1 0 0" : "unset")};
  position: ${({ isMobile }) => (isMobile ? "relative" : "unset")};
  bottom: ${({ isMobile }) => (isMobile ? "10px" : "unset")};
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledAudioWaveform = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ isMobile }) => (isMobile ? "12px" : "0")};
`;

function InputFreeText({
  handleSubmitQuestion,
  IsloadingSubmitAnswer,
  valueOptions,
  disabled = false,
  textareaRef,
  typeQuestion,
  ...props
}) {
  const isLock = useSelector((state) => state?.isChatPending?.isLock);
  const [valueFreeText, setValueFreeText] = useState("");
  const { isMobile } = useScreenSize();
  const [recording, setRecording] = useState(false);
  let isMutipleChoice = typeQuestion === "multiple_choice";
  let isFreeText = typeQuestion === "free_text";

  // Waveform
  const [audioStream, setAudioStream] = useState(null); // Lưu trữ stream từ MicRecord

  const handleFormSubmit = async () => {
    let submissionValue = "";
    console.log(valueOptions, "valueOptions...");
    if (isMutipleChoice) {
      submissionValue = valueOptions;
    } else {
      submissionValue = valueFreeText;
    }
    try {
      const dataResponse = await handleSubmitQuestion(submissionValue);
      if (dataResponse) {
        setValueFreeText(""); // Reset ô nhập nếu API trả về kết quả
      }
    } catch (error) {
      console.error("Lỗi khi gửi câu trả lời:", error); // Xử lý lỗi từ API
    }
  };

  const handleChange = (e) => setValueFreeText(e.target.value);

  const handleStreamUpdate = (stream) => {
    setAudioStream(stream); // Cập nhật stream khi có thay đổi
  };

  // Handle key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Ngăn xuống dòng
      handleFormSubmit(); // Gọi hàm submit
    }
    // Nếu Shift + Enter thì hành vi xuống dòng sẽ được giữ nguyên
  };

  // Handle Submit From STT
  const handleSubmitFromSTT = async (stringSTT) => {
    try {
      const dataResponse = await handleSubmitQuestion(stringSTT);
      if (dataResponse) {
        setValueFreeText(""); // Reset ô nhập nếu API trả về kết quả
      }
    } catch (error) {
      console.error("Lỗi khi gửi câu trả lời:", error); // Xử lý lỗi từ API
    }
  };

  // Hàm nhận trạng thái ghi âm từ MicRecord
  const handleRecordingStatusChange = (status) => {
    setRecording(status); // Cập nhật trạng thái ghi âm
    console.log("Recording Status:", status ? "Started" : "Stopped");
  };

  const isIOSOrMacOSSafari = () => {
    const userAgent = navigator.userAgent;

    // Kiểm tra iOS
    const isIOS = /iPhone|iPad|iPod/.test(userAgent);

    // Kiểm tra Safari trên macOS
    const isSafariOnMac =
      /Macintosh/.test(userAgent) &&
      /Safari/.test(userAgent) &&
      !/Chrome/.test(userAgent);

    return isIOS || isSafariOnMac;
  };

  return (
    <React.Fragment>
      <StyledInputContainer isMobile={isMobile} typeQuestion={isMutipleChoice}>
        <StyledTextAreaWrapper>
          <TextArea
            {...props}
            value={valueOptions || valueFreeText}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            isMobile={isMobile}
            disabled={disabled || IsloadingSubmitAnswer || isLock || recording}
            textareaRef={textareaRef}
            placeholder={
              audioStream ? "" : "Enter your answer or ask me for help here..."
            }
            name="answer"
          />
          {audioStream && (
            <StyledAudioWaveform>
              <AudioWaveform stream={audioStream} />
            </StyledAudioWaveform>
          )}
        </StyledTextAreaWrapper>
        <ButtonGroup isMobile={isMobile}>
          {isFreeText && !isIOSOrMacOSSafari() && (
            <MicRecord
              handleSubmitFromSTT={handleSubmitFromSTT}
              onStreamUpdate={handleStreamUpdate}
              onRecordingStatusChange={handleRecordingStatusChange}
            />
          )}
          {isMobile ? (
            <CustomIcon
              {...props}
              onSubmitWIcon={() => {
                handleFormSubmit();
              }}
            />
          ) : (
            !isMobile &&
            !recording && (
              <ButtonSend
                {...props}
                handleSubmit={handleFormSubmit}
                disabled={IsloadingSubmitAnswer || isLock || recording}
                IsloadingSubmitAnswer={IsloadingSubmitAnswer}
              />
            )
          )}
        </ButtonGroup>
      </StyledInputContainer>
    </React.Fragment>
  );
}

export default InputFreeText;
