import React from "react";
import styled from "styled-components";
import { ContinueText } from "./WebSocketAudioHandler.styles";

const Tooltip = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  width: 270px;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

const StyledContinueText = styled(ContinueText)`
  display: block;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  position: relative;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NextQuestionButton = ({
  showNextQuestion,
  isLoadingNextQuestion,
  handleNextQuestionClick,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <>
      <StyledContinueText
        className={showNextQuestion ? "show" : ""}
        onClick={() => {
          if (isLoadingNextQuestion) return;
          handleNextQuestionClick(isLoadingNextQuestion);
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {isLoadingNextQuestion && (
          <span className="spinner-border spinner-border-sm mr-2"></span>
        )}
        Continue
        <Tooltip show={showTooltip}>
          Click here to proceed. Once clicked, you will NOT be able to
          return to this question.
        </Tooltip>
      </StyledContinueText>
    </>
  );
};

export default NextQuestionButton;