import React, { useState } from 'react'
import styled from 'styled-components'
import Select from "react-select";
import { Waveform } from '../../ReferenceMedia/ModalMedia/testAudio';

const WrapMedia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .media-show {
    display: flex;
    flex-direction: column;
    gap: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    video {
      width: 100%;
      height: auto;
    }
    .text-link {
      color: #1570EF;
      font-size: 16px;
      font-weight: 400;
      text-decoration-line: underline;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
`

const HeaderPopup = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default function ResultMedia(props) {
  const { question, setMediaId, mediaId, type } = props;
  const [showMedia, setShowMedia] = useState(1)
  
  const Option = ({ innerProps, label, data, value }) => {
    return (
      <div
        {...innerProps}
        className='option-detail'
        style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <span className="react-select-item">{data?.title}</span>
      </div>
    );
  };

  function getShortMessages(medias) {
    const data = medias?.filter(function(media) {
      return media?.id === mediaId;
    })
    return {
      ...data[0],
      value: data[0]?.id,
      label: data[0]?.title
    }
  }

  const showMediaDetail = (type, dataView) => {
    switch (type) {
      case "Image":
      case "image":
      case "URL":
        return (
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <img className='media-img' src={dataView.url} alt={dataView.title}/>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      case "Video":
        return (
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <video width="420" controls>
              <source src={dataView.url}/>
              Your browser does not support the video tag.
            </video>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      case "Audio":
        return (
          // <audio controls>
          //   <source src={dataView.url} type="audio/mpeg" />
          //   Your browser does not support the audio element.
          // </audio>
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <Waveform {...props} dataView={dataView}/>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      default:
        break;

    }
  }
  
  return (
    <WrapMedia>
      {type !== "mcq" && (
        <HeaderPopup>
          <div className="w-100" onClick={() => setShowMedia(!showMedia)}>
            <Select
              className="react-select-common w-100"
              placeholder="Select Modal"
              value={getShortMessages(question?.media) || []}
              options={question?.media} 
              onChange={val => {
                setMediaId(val.id)
              }}
              classNamePrefix="react-select-custom"
              components={{
                IndicatorSeparator: () => null,
                Option: Option
              }}
            />
          </div>
        </HeaderPopup>
      )}
      <div className="media-show">
        {question?.media?.map((media) => (
          media?.id === mediaId && (
            showMediaDetail(media?.type, media)
          )
        ))}
      </div>
    </WrapMedia>
  )
}
