import React, { useState } from "react";

//components
import BtnAddTimepoint from "./BtnAddTimepoint";
import { ModalAddTimePoint } from "../../storyboard/TimePoint";

const AddTimePoint = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <React.Fragment>
      <BtnAddTimepoint {...props} onClick={toggle} />
      <ModalAddTimePoint
        {...props}
        isOpen={isModalOpen}
        toggle={toggle}
      />
    </React.Fragment>
  );
};

export default AddTimePoint;