import React, { useState } from 'react'
import styled from 'styled-components'
import { Input } from 'reactstrap';
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import { TitleNoteCommon } from "../../../../home/generate";
import { Icon } from '@components/common'
import Calendar from '@images/new_interface/sidebar/calendar.svg'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { actCreatePeriod } from "@actions";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

const WrapInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .wrap-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .set-calendar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 14px 12px;
      border: 1px solid #D0D5DD;
      border-radius: 8px;
      input {
        border: none;
      }
    }
  }
  .wrap-time {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export default function FormCreateSeries(props) {
  const { watch, setValue, handleNext, control, errors } = props;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleCreatePeriod = async () => {
    setIsLoading(true);
    const params = {
      Action: "Create",
      Name: watch('period_name'),
      StartTime: moment(startDate).format(),
      EndTime: moment(endDate).format(),
    };;
    const response = await dispatch(actCreatePeriod(params));
    if (response?.status === 200) {
      const itemWithLargestId = response?.data?.reduce((max, item) => item.PeriodId > max.PeriodId ? item : max, response?.data[0]);
      setValue('period_selected', [itemWithLargestId]);
      await handleNext();
      setIsLoading(false);
    }
  };

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Create a New Period"
          subtitle="Define a timeframe for this assignment by setting a start and end date."
        />
        <WrapInput>
          <div className='wrap-item'>
            <TitleNoteCommon
              dataFor={'periodName'} 
              title="Period Name" 
              content=""
              required
            />
            <Controller
                name="period_name"
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => (
                  <>
                    <Input
                      type='text'
                      name='period_name'
                      placeholder="Name"
                      {...field}
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </>
                )}
              />
          </div>
          <div className='wrap-time'>
            <div className='wrap-item'>
              <TitleNoteCommon
                dataFor={'startTime'} 
                title="Start Time" 
                content=""
                required
              />
              <div className='set-calendar'>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(e) => setStartDate(e)}
                  dateFormat="yyyy/MM/dd"
                />
                <Icon src={Calendar} />
              </div>
            </div>
            <div className='wrap-item'>
              <TitleNoteCommon
                dataFor={'endTime'} 
                title="End Time" 
                content=""
                required
              />
              <div className='set-calendar'>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(e) => setEndDate(e)}
                  dateFormat="yyyy/MM/dd"
                  minDate={startDate}
                />
                <Icon src={Calendar} />
              </div>
            </div>
          </div>
        </WrapInput>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          handleActionBackup={handleCreatePeriod}
          dataPass={!watch('period_name') || isLoading}
          {...props} 
        />
      </div>     
    </WrapCommon>
  )
}
