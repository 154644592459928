import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import styled from "styled-components";

// Free Text Components
import {
  InputFreeText,
  QuestionSelectMany,
  QuestionSelectOne,
} from "../Preceptor/MutipleChoice";

// Voice Components
import {
  SelectManyVoiceGroup,
  SelectOneVoiceGroup,
} from "../Preceptor/MutipleChoiceVoice";

// Styled Components
const ContainerGroupVoice = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 20px 20px 8px 8px;
  background: transparent;
  box-shadow: 0px -5px 9.4px -2px rgba(16, 24, 40, 0.06);
`;

const HeadingVoice = styled.p`
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
`;

// Hàm chọn dữ liệu phù hợp dựa trên Type và SubType
const getCheckOptions = (type, subType, optionsData) => {
  if (type === "multiple_choice") {
    if (subType === "select_many") {
      return optionsData?.SelectMany || [];
    } else if (subType === "select_one") {
      return optionsData?.SelectOne || [];
    }
  }
  return [];
};

// Component chung cho Free Text và Voice
const CommonQuestionGroup = ({
  SubType,
  Type,
  optionsData,
  handleSelectMany,
  handleSelectOne,
  valueOptions,
  handleSubmitQuestion,
  IsloadingSubmitAnswer,
  QuestionResponse,
  isVoice = false,
  DisbledBtnSubmit,
  ...restProps
}) => {
  const CheckOptions = getCheckOptions(Type, SubType, optionsData);  // Lấy dữ liệu tương ứng
  const isSelected = CheckOptions?.some((option) => option?.Selected === true);

  // Render UI cho Voice hoặc Free Text
  return (
    <>
      {isVoice ? (
        <>
          <HeadingVoice>
            {SubType === "select_many" ? 'Select ALL that apply' : 'Select only One answer'}
          </HeadingVoice>
          {SubType === "select_many" && (
            <SelectManyVoiceGroup
              {...restProps}
              handleSelectMany={handleSelectMany}
              QuestionResponse={QuestionResponse}
              AnswerChoices={optionsData?.SelectMany}
            />
          )}
          {SubType === "select_one" && (
            <SelectOneVoiceGroup
              {...restProps}
              handleSelectOne={handleSelectOne}
              QuestionResponse={QuestionResponse}
              AnswerChoices={optionsData?.SelectOne}
            />
          )}
        </>
      ) : (
        <>
          {SubType === "select_many" && (
            <QuestionSelectMany
              {...restProps}
              handleSelectMany={handleSelectMany}
              QuestionResponse={QuestionResponse}
              AnswerChoices={optionsData?.SelectMany}
            />
          )}
          {SubType === "select_one" && (
            <QuestionSelectOne
              {...restProps}
              handleSelectOne={handleSelectOne}
              QuestionResponse={QuestionResponse}
              AnswerChoices={optionsData?.SelectOne}
            />
          )}
          {QuestionResponse && (
            <InputFreeText
              {...restProps}
              handleSubmitQuestion={handleSubmitQuestion}
              IsloadingSubmitAnswer={IsloadingSubmitAnswer}
              valueOptions={valueOptions}
              typeQuestion={Type}
              disabled={Type === "multiple_choice"}
            />
          )}
        </>
      )}
      {isVoice &&
        <Button
          className="button-yellow px-5 d-flex align-items-center justify-content-center"
          size="md"
          onClick={handleSubmitQuestion}
          disabled={DisbledBtnSubmit || !isSelected}
        >
          {IsloadingSubmitAnswer && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Submit
        </Button>
      }
    </>
  );
};

// Component chính, hiển thị group dựa trên InstructorChatMode
const MainQuestionType = (props) => {
  const {
    nextQuestion,
    InstructorChatMode,
    handleSubmitQuestion,
    isMessageLoader,
    isLoading,
    DisabledBtnSubmitMutipleChoice,
    ...restProps
  } = props;

  const questionData = useSelector((state) => state.question || {});
  const QuestionResponse = questionData?.data?.Question;
  const IsloadingSubmitAnswer = isLoading?.submitQuestion;
  return (
    <React.Fragment>
      {InstructorChatMode === "voice" ? (
        <ContainerGroupVoice>
          <CommonQuestionGroup
            {...restProps}
            QuestionResponse={QuestionResponse}
            isVoice={true}
            handleSubmitQuestion={handleSubmitQuestion}
            IsloadingSubmitAnswer={IsloadingSubmitAnswer}
            DisbledBtnSubmit={DisabledBtnSubmitMutipleChoice}
          />
        </ContainerGroupVoice>
      ) : (
        <div className={`questionBottom ${nextQuestion ? "mx-auto" : ""}`}>
          <CommonQuestionGroup
            {...restProps}
            QuestionResponse={QuestionResponse}
            isVoice={false}
            handleSubmitQuestion={handleSubmitQuestion}
            IsloadingSubmitAnswer={IsloadingSubmitAnswer}
            DisbledBtnSubmit={DisabledBtnSubmitMutipleChoice}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default MainQuestionType;