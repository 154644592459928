/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import HeaderCommon from './HeaderCommon'
import { ItemOption } from '@ui-partents/Form/FormChoose';
import ButtonSubmitCommon from './ButtonSubmitCommon';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function ChoiceCommon(props) {
  const { dataForm, setValue, handleSubStep, watch, type } = props;
  const [dataChoice, setDataChoice] = useState()

  const handleChoose = (data) => {
    setDataChoice(data)
    setValue(type, data.value)
  }

  useEffect(() => {
    if (dataForm) {
      dataForm?.items?.forEach((data) => {
        if (data?.value === watch(type)) {
          setDataChoice(data)
        }
      })
    }
  }, [dataForm])

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon isHtml={true} {...props}/>
        <div className="form-area__choice">
          {dataForm?.items?.map((data, index) => (
            <ItemOption 
              key={index}
              title={data?.title}
              handleChoose={() => handleChoose(data)}
              name="option"
              isCheck={watch(type) === data?.value ? true : false}
              subTitle={data?.description}
            />
          ))}
        </div>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon
          handleNext={() => handleSubStep(dataChoice)}
          {...props} 
        />
      </div>     
    </WrapCommon>
  )
}
