import React, { useEffect, useState } from "react";
import { FormModal } from '@ui-partents/Modal';
import styled from "styled-components";
// FormPage.js
import { ButtonComponent } from "@components/patterns/elements/Button";
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';

const WrapContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header-modal {
    h1 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #475467;
    }
  }
  .action-modal {
    padding-top: 24px;
    border-top: 1px solid #D0D5DD;
    display: flex;
    justify-content: flex-end;
  }
  .form-modal {
    display: flex;
    flex-direction: column;
    gap: 6px;
    label {
      font-size: 14px;
      font-weight: 600;
    }
    .mail-error {
      .select-mail__multi-value {
        border: 1px solid #FECDCA !important;
        background:#FFF !important;
        &__label {
          color:#D92D20 !important;
        }
        &__remove {
          path {
            color: #D92D20 !important;
          }
          &:hover {
            background-color: #ee5252 !important;
            color: #D92D20 !important;
          }
        } 
      }
    }
  }
`

// const createOption = (label) => ({
//   label,
//   value: label,
// });


function ModalSendForReview(props) {
  const { isOpen, toggle, dataEdit, setListValue, getListMyQuestionBank } = props && props;
  const [listOptionCohort, setListOptionCohort] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const getListCohort = async () => {
    apiCaller(`/api/teach/cohorts/?type=reviewers`, "GET").then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        console.log('success', data);
        const convertListCohort = data?.map((cohort) => ({
          value: cohort.CohortId,
          label: cohort.Name,
        }))
        setListOptionCohort(convertListCohort)
      } else {
        console.log("error....");
      }
    })
  }

  useEffect(() => {
    getListCohort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const componentsCommon = {
  //   DropdownIndicator: () => null,
  //   MultiValueContainer: (props) => {
  //     const emailError = listEmailError?.map((email) => email?.value)
  //     return (
  //       <div className={`${emailError.includes(props?.data?.value) > 0 ? "mail-error" : ""}`}>
  //         <components.MultiValueContainer {...props} />
  //       </div>
  //     );
  //   }
  // };

  // const handleKeyDown = (event) => {
  //   const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   if (!inputValue) return;
  //   switch (event.key) {
  //     case 'Enter':
  //     case 'Tab':
  //     case ' ':
  //       if (!emailValidationRegex.test(inputValue)) {
  //         setListEmailError((prev) => [...prev, createOption(inputValue)]);
  //       }
  //       setListValue((prev) => [...prev, createOption(inputValue)]);
  //       setInputValue('');
  //       event.preventDefault();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleBlur = () => {
  //   const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   if (inputValue && emailValidationRegex.test(inputValue)) {
  //     setListValue((prev) => [...prev, createOption(inputValue)]);
  //     setInputValue('');
  //   } else if (inputValue && !emailValidationRegex.test(inputValue)) {
  //     setListEmailError((prev) => [...prev, createOption(inputValue)]);
  //     setListValue((prev) => [...prev, createOption(inputValue)]);
  //     setInputValue('');
  //   }
  // };

  // const handleAddEmail = (value) => {
  //   let emailError = []
  //   const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   setListValue(value)
  //   value?.forEach((email) => {
  //     if (!emailValidationRegex.test(email.value)) {
  //       emailError.push(email)
  //     }
  //   })
  //   setListEmailError(emailError)
  // }

  const {
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    setLoadingAdd(true)
    const listCohorts = data?.cohort?.map((cohort) => cohort.value)
    const params = {
      Action: "SendToReviewCohort",
      QuestionItemId: dataEdit?.QuestionItemId,
      CohortIds: listCohorts
    };
    apiCaller(`/feedback/question/items/`, "POST", params).then((res) => {
      if (res?.status === 200) {
        toast.success("Successfully sent for review", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setListValue([])
        getListMyQuestionBank()
        toggle()
        setLoadingAdd(false)
      } else {
        toast.error("Failed to sent for review", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoadingAdd(false)
      }
    });
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={''}
      maxWidth="800px"
      hiddenIcon={true}
      hiddenButtonCancel={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapContentModal>
          <div className="header-modal">
            <h1>Send for Review</h1>
            <p>Assign this question to one or more cohorts for review. Please note that once sent, <br /> you will no longer be able to edit the question’s content.</p>
          </div>
          {/* <div className="form-modal">
            <label>Enter Email Address(es)</label>
            <CreatableSelect
              components={componentsCommon}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => handleAddEmail(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              placeholder="Type an email, then press space to add another"
              value={listValue}
              className={`react-select-common react-select-md w-100`}
              classNamePrefix="react-select-custom"
            />
          </div> */}
          <div className="form-modal mb-3">
            <label>Select Cohort(s)</label>
            <Select
              className={`react-select-common react-select-md w-100`}
              placeholder="Select cohort(s) from the list"
              options={listOptionCohort}
              isMulti
              classNamePrefix="react-select-custom"
              onChange={val => {
                setValue('cohort', val)
              }}
            />
          </div>
          <div className="action-modal">
            <ButtonComponent 
              color={(!watch('cohort')?.length) ? "outline" : "primary"} 
              type="submit"
              // disabled={(!listValue?.length && !watch('cohort')?.length ) || listEmailError?.length}
              disabled={(!watch('cohort')?.length) || loadingAdd}
            >
              Send
            </ButtonComponent>
          </div>
        </WrapContentModal>
      </form>
    </FormModal>
  );
}

export default ModalSendForReview;