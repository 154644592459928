import React, { useContext, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import UserContext from '@context/UserContext';
import { Iconsax } from "@components-common";
import { NavLink } from 'reactstrap';
import { ProfileTopMenu } from "../Header";

//components
import { ModalFeedback } from "@components/teachV2/home/common";

// Redux
import { useDispatch } from "react-redux";

//actions
import { toggleMenuMobile } from "@actions";

//actions
import { switchRole } from '@actions';

const SidebarTitle = styled.div`
  color: #98A2B3;
  font-size: 12px;
  font-weight: 500;
  display: block;
`;

// Styled components
const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.4s ease;
  z-index: 999;
`;

const MenuContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.4s ease-out, opacity 0.4s ease;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  box-shadow: ${({ isOpen }) => (isOpen ? '0 4px 12px rgba(0, 0, 0, 0.2)' : 'none')};
  z-index: 1000;
`;

const NavContainer = styled.nav`
  padding: 24px 16px;
  height: 100%;
  overflow-y: auto;
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 24px;
`;

const NavItem = styled.li`
  margin: 12px 0;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`;

const StyledNavLink = styled(NavLink)`
  color: #475467;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ isActive }) => isActive ? '#F2F4F7' : 'transparent'};
  border-radius: ${({ isActive }) => isActive ? '6px' : '0px'};
  ${({ isActive }) => isActive && `
    color: #0089C2;
  `}
`;

const NavItemText = styled.span`
  margin-left: 12px;
`;

const StyledLogoutLink = styled(NavLink)`
  color: #F63D68;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
`;

// Menu item definitions
const sidebarItems = {
  '/learn': [
    { icon: "sidebar-home", name: 'Home', to: '/learn/home' },
    { icon: "sidebar-book", name: 'Assignments', to: '/learn/assignments' },
    { icon: "sidebar-validate", name: 'Validate', to: '/validate', isHidden: true },
    { icon: "sidebar-manage", name: 'Manage', to: '/adminstrator-tool', isHidden: true },
  ],
  'alpha': [
    { icon: "sidebar-home", name: 'Home', to: '/teach/home' },
    { icon: "inventory", name: 'My Inventory', to: '/teach/inventory' },
    { icon: "reference-library", name: 'Reference Library', to: '/teach/reference-library' },
    { icon: "reference-media", name: 'Media Library', to: '/teach/reference-media' },
    // { icon: "performance", name: 'Performance Report', to: '/teach/performance-report', isBeta: true },
  ],
  'default': [
    { icon: "sidebar-home", name: 'Home', to: '/teach/home' },
    { icon: "inventory", name: 'My Inventory', to: '/teach/inventory' },
    { icon: "reference-library", name: 'Reference Library', to: '/teach/reference-library' },
    { icon: "reference-media", name: 'Media Library', to: '/teach/reference-media' },
  ]
};

const MenuMobileFullScreen = ({ isOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const context = useContext(UserContext);
  const history = useHistory();

  //state
  const [modalOpen, setModalOpen] = useState({ feedback: false });

  //Redux
  // const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
  // const AccountData = AccountInfoData?.Account;
  // const is_instructor = AccountData?.is_instructor === "Yes";

  // Toggle function for modals
  const toggleModal = (type) => {
    setModalOpen((prev) => ({
      ...prev,
      [type]: !prev[type], // Toggle the specific modal type
    }));
  };

  // Handle Role Change
  // const handleRoleChange = useCallback((role) => {
  //   dispatch(switchRole(role))
  //     .then(() => {
  //       // Determine the correct path based on the role
  //       const path = role === 'learner' ? '/learn/home' : '/teach/home';
  //       history.push(path);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to switch role:", error);
  //       // Handle the error if needed
  //     });
  // }, [dispatch, history]);

  // Handle Logout
  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(switchRole(null));
    history.push("/login");
  }, [dispatch, history]);

  // Determine sidebar items based on the current path and user role
  const sidebar = sidebarItems[pathName.startsWith('/learn') ? '/learn' : context?.privilege === 'alpha' ? 'alpha' : 'default'];

  //Role Active
  // const currentRole = useSelector((state) => state?.statusRoleSystem || {});
  // const activeRole = currentRole?.roleActive;

  // Define role switch items with updated logout functionality
  const BottomMenu = [
    { name: 'Log Out', onClick: handleLogout, isLogout: true, isShow: true }
  ];

  const accountItems = [
    { icon: "feedback", name: 'Feedback', onClick: () => toggleModal("feedback") }, // Wrap in a function
  ];
  return (
    <React.Fragment>
      <MenuOverlay isOpen={isOpen} onClick={() => dispatch(toggleMenuMobile(false))}>
        <MenuContent isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
          <ProfileTopMenu toggleMenu={() => dispatch(toggleMenuMobile(false))} />
          <NavContainer className='w-100'>
            {/* Render Menu Items */}
            <NavList>
              <SidebarTitle>MAIN MENU</SidebarTitle>
              {sidebar.map((item, index) => (
                <NavItem key={index} isHidden={item.isHidden}>
                  <StyledNavLink
                    href={item.to}
                    aria-label={item.name}
                    isActive={pathName === item.to}
                  >
                    <Iconsax
                      iconName={item.icon}
                      fill={pathName === item.to ? '#0089C2' : '#697592'}
                    />
                    <NavItemText>{item.name}</NavItemText>
                  </StyledNavLink>
                </NavItem>
              ))}
            </NavList>

            {/* Account Items */}
            <NavList>
              <SidebarTitle>ACCOUNT</SidebarTitle>
              {accountItems.map((item, index) => (
                <React.Fragment key={index}>
                  <NavItem>
                    <StyledNavLink
                      onClick={() => {
                        item.onClick(); // Execute the item's onClick
                        dispatch(toggleMenuMobile(false));
                      }}
                      aria-label={item.name}
                      isActive={pathName === item.to}
                    >
                      <Iconsax
                        iconName={item.icon}
                        fill={pathName === item.to ? '#0089C2' : '#697592'}
                      />
                      <NavItemText>{item.name}</NavItemText>
                    </StyledNavLink>
                  </NavItem>
                  {BottomMenu?.map((item, j) => (
                    <NavItem key={j}>
                      {item?.isShow &&
                        <StyledLogoutLink
                          onClick={() => {
                            item.onClick();
                            dispatch(toggleMenuMobile(false));
                          }}
                        >
                          {item.name}
                        </StyledLogoutLink>
                      }
                    </NavItem>
                  ))}
                </React.Fragment>
              ))}
            </NavList>
          </NavContainer>
        </MenuContent>
      </MenuOverlay>
      <ModalFeedback
        isOpen={modalOpen?.feedback}
        toggle={() => toggleModal("feedback")}
      />
    </React.Fragment>
  );
};

export default MenuMobileFullScreen;