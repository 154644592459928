export const TEACH_CASE_REQUEST = "TEACH_CASE_REQUEST";
export const TEACH_CASE_SUCCESS = "TEACH_CASE_SUCCESS";
export const TEACH_CASE_FAILURE = "TEACH_CASE_FAILURE";

export const UPDATE_TEACH_CASE_REQUEST = "UPDATE_TEACH_CASE_REQUEST";
export const UPDATE_TEACH_CASE_SUCCESS = "UPDATE_TEACH_CASE_SUCCESS";
export const UPDATE_TEACH_CASE_FAILURE = "UPDATE_TEACH_CASE_FAILURE";

export const CREATE_CASE_REQUEST = "CREATE_CASE_REQUEST";
export const CREATE_CASE_SUCCESS = "CREATE_CASE_SUCCESS";
export const CREATE_CASE_FAILURE = "CREATE_CASE_FAILURE";

export const DELETE_CASE_REQUEST = "DELETE_CASE_REQUEST";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAILURE = "DELETE_CASE_FAILURE";

export const LOAD_QUESTION_REQUEST = "LOAD_QUESTION_REQUEST";
export const LOAD_QUESTION_SUCCESS = "LOAD_QUESTION_SUCCESS";
export const LOAD_QUESTION_FAILURE = "LOAD_QUESTION_FAILURE";

export const TEACH_TASK_ACTIVE = "TEACH_TASK_ACTIVE";
export const UPDATE_TASK_ACTIVE = "UPDATE_TASK_ACTIVE";
export const UPDATE_AVAILABLE_TASK = "UPDATE_AVAILABLE_TASK";
export const QUESTION_VIEW_ACTIVE = "QUESTION_VIEW_ACTIVE";

export const teachConstants = {
    TEACH_CASE_REQUEST: 'TEACH_CASE_REQUEST',
    TEACH_CASE_SUCCESS: 'TEACH_CASE_SUCCESS',
    TEACH_CASE_FAILURE: 'TEACH_CASE_FAILURE',

    UPDATE_TEACH_CASE_REQUEST: 'UPDATE_TEACH_CASE_REQUEST',
    UPDATE_TEACH_CASE_SUCCESS: 'UPDATE_TEACH_CASE_SUCCESS',
    UPDATE_TEACH_CASE_FAILURE: 'UPDATE_TEACH_CASE_FAILURE',

    CREATE_CASE_REQUEST: 'CREATE_CASE_REQUEST',
    CREATE_CASE_SUCCESS: 'CREATE_CASE_SUCCESS',
    CREATE_CASE_FAILURE: 'CREATE_CASE_FAILURE',

    DELETE_CASE_REQUEST: 'DELETE_CASE_REQUEST',
    DELETE_CASE_SUCCESS: 'DELETE_CASE_SUCCESS',
    DELETE_CASE_FAILURE: 'DELETE_CASE_FAILURE',

    GET_CASE_LEARNERS_REQUEST: 'GET_CASE_LEARNERS_REQUEST',
    GET_CASE_LEARNERS_SUCCESS: 'GET_CASE_LEARNERS_SUCCESS',
    GET_CASE_LEARNERS_FAILURE: 'GET_CASE_LEARNERS_FAILURE',

    LOAD_QUESTION_REQUEST: 'LOAD_QUESTION_REQUEST',
    LOAD_QUESTION_SUCCESS: 'LOAD_QUESTION_SUCCESS',
    LOAD_QUESTION_FAILURE: 'LOAD_QUESTION_FAILURE',

    LOAD_ALL_QUESTION_REQUEST: 'LOAD_ALL_QUESTION_REQUEST',
    LOAD_ALL_QUESTION_SUCCESS: 'LOAD_ALL_QUESTION_SUCCESS',
    LOAD_ALL_QUESTION_FAILURE: 'LOAD_ALL_QUESTION_FAILURE',

    CREATE_QUESTION_REQUEST: 'CREATE_QUESTION_REQUEST',
    CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
    CREATE_QUESTION_FAILURE: 'CREATE_QUESTION_FAILURE',

    CLONE_QUESTION_REQUEST: 'CLONE_QUESTION_REQUEST',
    CLONE_QUESTION_SUCCESS: 'CLONE_QUESTION_SUCCESS',
    CLONE_QUESTION_FAILURE: 'CLONE_QUESTION_FAILURE',

    UPDATE_QUESTION_REQUEST: 'UPDATE_QUESTION_REQUEST',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    UPDATE_QUESTION_FAILURE: 'UPDATE_QUESTION_FAILURE',

    DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
    DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
    DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',

    ORDER_QUESTION_REQUEST: 'ORDER_QUESTION_REQUEST',
    ORDER_QUESTION_SUCCESS: 'ORDER_QUESTION_SUCCESS',
    ORDER_QUESTION_FAILURE: 'ORDER_QUESTION_FAILURE',

    CREATE_DOMAIN_REQUEST: 'CREATE_DOMAIN_REQUEST',
    CREATE_DOMAIN_FAILURE: 'CREATE_DOMAIN_FAILURE',

    UPDATE_DOMAIN_SUCCESS: 'UPDATE_DOMAIN_SUCCESS',

    UPDATE_DOMAIN_REQUEST: 'UPDATE_DOMAIN_REQUEST',
    UPDATE_DOMAIN_FAILURE: 'UPDATE_DOMAIN_FAILURE',

    DELETE_DOMAIN_REQUEST: 'DELETE_DOMAIN_REQUEST',
    DELETE_DOMAIN_FAILURE: 'DELETE_DOMAIN_FAILURE',

    COPY_DOMAINS_FROM_SOURCE_CASE_REQUEST: 'COPY_DOMAINS_FROM_SOURCE_CASE_REQUEST',
    COPY_DOMAINS_FROM_SOURCE_CASE_SUCCESS: 'COPY_DOMAINS_FROM_SOURCE_CASE_SUCCESS',
    COPY_DOMAINS_FROM_SOURCE_CASE_FAILURE: 'COPY_DOMAINS_FROM_SOURCE_CASE_FAILURE',

    LOAD_CANDIDATES_FOR_LEARNERS_REQUEST: 'LOAD_CANDIDATES_FOR_LEARNERS_REQUEST',
    LOAD_CANDIDATES_FOR_LEARNERS_SUCCESS: 'LOAD_CANDIDATES_FOR_LEARNERS_SUCCESS',
    LOAD_CANDIDATES_FOR_LEARNERS_FAILURE: 'LOAD_CANDIDATES_FOR_LEARNERS_FAILURE',

    PUT_ENROLL_REQUEST: 'PUT_ENROLL_REQUEST',
    PUT_ENROLL_SUCCESS: 'PUT_ENROLL_SUCCESS',
    PUT_ENROLL_FAILURE: 'PUT_ENROLL_FAILURE',

    REMOVE_LEARNERS_REQUEST: 'REMOVE_LEARNERS_REQUEST',
    REMOVE_LEARNERS_SUCCESS: 'REMOVE_LEARNERS_SUCCESS',
    REMOVE_LEARNERS_FAILURE: 'REMOVE_LEARNERS_FAILURE',

    GET_INSTITUTION_TIME_ZONE_REQUEST: 'GET_INSTITUTION_TIME_ZONE_REQUEST',
    GET_INSTITUTION_TIME_ZONE_SUCCESS: 'GET_INSTITUTION_TIME_ZONE_SUCCESS',
    GET_INSTITUTION_TIME_ZONE_FAILURE: 'GET_INSTITUTION_TIME_ZONE_FAILURE',

    SET_INSTITUTION_TIME_ZONE_REQUEST: 'SET_INSTITUTION_TIME_ZONE_REQUEST',
    SET_INSTITUTION_TIME_ZONE_SUCCESS: 'SET_INSTITUTION_TIME_ZONE_SUCCESS',
    SET_INSTITUTION_TIME_ZONE_FAILURE: 'SET_INSTITUTION_TIME_ZONE_FAILURE',

    TEACHER_HOME_REQUEST: 'TEACHER_HOME_REQUEST',
    TEACHER_HOME_SUCCESS: 'TEACHER_HOME_SUCCESS',
    TEACHER_HOME_FAILURE: 'TEACHER_HOME_FAILURE',

    LOAD_QUESTION_FLOW_CONTROL_REQUEST: 'LOAD_QUESTION_FLOW_CONTROL_REQUEST',
    LOAD_QUESTION_FLOW_CONTROL_SUCCESS: 'LOAD_QUESTION_FLOW_CONTROL_SUCCESS',
    LOAD_QUESTION_FLOW_CONTROL_FAILURE: 'LOAD_QUESTION_FLOW_CONTROL_FAILURE',

    LOAD_QUESTIONS_FROM_TEMPLATES_REQUEST: 'LOAD_QUESTIONS_FROM_TEMPLATES_REQUEST',
    LOAD_QUESTIONS_FROM_TEMPLATES_SUCCESS: 'LOAD_QUESTIONS_FROM_TEMPLATES_SUCCESS',
    LOAD_QUESTIONS_FROM_TEMPLATES_FAILURE: 'LOAD_QUESTIONS_FROM_TEMPLATES_FAILURE',

    CREATE_QUESTION_FROM_QUESTION_BANK_REQUEST: 'CREATE_QUESTION_FROM_QUESTION_BANK_REQUEST',
    CREATE_QUESTION_FROM_QUESTION_BANK_SUCCESS: 'CREATE_QUESTION_FROM_QUESTION_BANK_SUCCESS',
    CREATE_QUESTION_FROM_QUESTION_BANK_FAILURE: 'CREATE_QUESTION_FROM_QUESTION_BANK_FAILURE',

    UPDATE_AVATAR_REQUEST: 'UPDATE_AVATAR_REQUEST',
    UPDATE_AVATAR_SUCCESS: 'UPDATE_AVATAR_SUCCESS',
    UPDATE_AVATAR_FAILURE: 'UPDATE_AVATAR_FAILURE',

    GET_LIST_CHARACTER_REQUEST: 'GET_LIST_CHARACTER_REQUEST',
    GET_LIST_CHARACTER_SUCCESS: 'GET_LIST_CHARACTER_SUCCESS',
    GET_LIST_CHARACTER_FAILURE: 'GET_LIST_CHARACTER_FAILURE',

    TEACH_TASK_ACTIVE,
    UPDATE_TASK_ACTIVE,
    UPDATE_AVAILABLE_TASK,
    QUESTION_VIEW_ACTIVE
};