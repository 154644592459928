/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { debounce } from 'lodash';
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";
import TextareaAutosize from "react-textarea-autosize";
import ChatPromptTemp from "./ChatPromptTemp";
import { Label } from "reactstrap";

function InteractionScript(props) {
    const dispatch = useDispatch();
    const { id } = useParams();

    //props
    const { timepointData } = props && props;
    const { ChatSettings } = timepointData || {};
    const { ChatScript, ChatEndingCriteria } = ChatSettings || {};
    const InteractionPromptTemplates = ChatSettings?.InteractionPromptTemplates || [];

    //state
    const [tempContent, setTempContent] = useState(ChatScript || "");
    const [tempContentEnding, setTempContentEnding] = useState(ChatEndingCriteria || "")
 
    useEffect(() => {
        setTempContent(ChatScript || "");
        setTempContentEnding(ChatEndingCriteria || "")
    }, []);

    // Handle Submit
    const handleSubmit = useCallback(debounce((value) => {
        const params = {
            Action: "Update",
            Target: "ChatSettings",
            ChatScript: value,
            TimePointId: timepointData?.Id
        };
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: "error" });
            }
        });
    }, 1000), []);

    // Handle Submit
    const handleSubmitEnding = useCallback(debounce((value) => {
        const params = {
            Action: "Update",
            Target: "ChatSettings",
            ChatEndingCriteria: value,
            TimePointId: timepointData?.Id
        };
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_ENDING_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_ENDING_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_ENDING_FAILURE, error: "error" });
            }
        });
    }, 1000), []);

    // Handle Change
    const handleChange = (e) => {
        const { value } = e.target;
        setTempContent(value);
        handleSubmit(value);
    };

    const handleChangeEnding = (e) => {
        const { value } = e.target;
        setTempContentEnding(value);
        handleSubmitEnding(value);
    };
    
    return (
        <React.Fragment>
            <React.Fragment>
                <Label className="labeForm mt-4">
                    Interaction Script
                </Label>
                <TextareaAutosize
                    name="ChatScript"
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={8}
                    onChange={handleChange}
                    value={tempContent}
                    placeholder="Enter a description..."
                />
            </React.Fragment>
            <React.Fragment>
                <Label className="labeForm mt-4">
                    Ending Criteria
                </Label>
                <TextareaAutosize
                    name="ChatEndingCriteria"
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={8}
                    onChange={handleChangeEnding}
                    value={tempContentEnding}
                    placeholder="Enter a description..."
                />
            </React.Fragment>
            <ChatPromptTemp
                {...props}
                currentTemplate={InteractionPromptTemplates}
                typeChatSettings={"InteractionPromptTemplates"}
            />
        </React.Fragment>
    );
}

export default InteractionScript;