import React from 'react'
import styled from 'styled-components'
import { Input } from 'reactstrap';
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import { TitleNoteCommon } from "../../../../home/generate";
import { Controller } from "react-hook-form";
// import { useDispatch } from 'react-redux';
// import { actCreateSeries } from "@actions";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

const WrapInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .wrap-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export default function FormCreateSeries(props) {
  const { watch, errors, control, handleNext } = props;

  const handleCreateSeries = async () => {
    // setIsLoading(true);
    // const params = {
    //   Action: "Create",
    //   Title: watch('series_name'),
    //   Descriptions: watch('series_description'),
    // };
    // const response = await dispatch(actCreateSeries(params));
    // if (response?.status === 200) {
    //   const itemWithLargestId = response?.data?.reduce((max, item) => item.Id > max.Id ? item : max, response?.data[0]);
    //   setValue('series_selected', [itemWithLargestId]);
    //   await handleNext();
    //   setValue('series_name', '');
    //   setValue('series_description', '');
    //   setIsLoading(false);
    // }
    await handleNext();
  };

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Create a New Series"
          subtitle="Give your series a name and (optionally) provide a brief description to help identify its purpose."
        />
        <WrapInput>
          <div className='wrap-item'>
            <TitleNoteCommon
              dataFor={'seriesName'} 
              title="Series Name" 
              content=""
              required
            />
            <Controller
              name="series_name"
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <>
                  <Input
                    type='text'
                    name='series_name'
                    placeholder="Example: Emergency Medicine Modules – Adult & Pediatric"
                    {...field}
                  />
                  {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                </>
              )}
            />
            {/* <Input
              className='pt-1'
              type='text'
              name="series_name"
              value={watch("series_name")}
              placeholder="Example: Emergency Medicine Modules – Adult & Pediatric"
              onChange={(e) => {
                setValue("series_name", e.target.value);
              }}
            /> */}
          </div>
          <div className='wrap-item'>
            <TitleNoteCommon
              dataFor={'seriesDescription'} 
              title="Series Description" 
              content=""
            />
            <Controller
              name="series_description"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='series_description'
                  placeholder="Example: This series covers emergency scenarios such as sepsis, cardiac arrest, and trauma management."
                  style={{ height: '160px' }}
                  {...field}
                />
              )}
            />
            {/* <Input
              className='pt-1'
              type='textarea'
              name={"series_description"}
              value={watch("series_description")}
              placeholder="Example: This series covers emergency scenarios such as sepsis, cardiac arrest, and trauma management."
              style={{ height: '160px' }}
              onChange={(e) => {
                setValue("series_description", e.target.value);
              }}
            /> */}
          </div>
        </WrapInput>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          handleActionBackup={handleCreateSeries}
          dataPass={!watch('series_name')}
          {...props} 
        />
      </div>     
    </WrapCommon>
  )
}
