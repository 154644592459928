import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const ChartBasicBarCohort = (props) => {
  const { dataCurriculum, activeTab } = props
  const { Domains } = dataCurriculum?.data?.ReportDetails || {};
  
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  // Extract LaunchPlans data for a specific domain, e.g., 'Medical Science Knowledge'
  const launchPlans = Domains?.[activeTab]?.LaunchPlans || [];

  // Extract LaunchPlan names and AverageScores
  // const launchPlanNames = launchPlans?.map(plan => plan.LaunchPlanName);
  const launchPlanNames = launchPlans.map(plan => truncate(plan.LaunchPlanName, 10)); // Truncate names to 10 characters
  const averageScores = launchPlans?.map(plan => plan.AverageScore);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const fullNames = launchPlans.map(plan => plan.LaunchPlanName);
        return `${fullNames[params[0].dataIndex]}<br/>Value: ${params[0].value}`;
      }
    },
    legend: {
      orient: "vertical", // Đặt legend theo chiều dọc
      right: "right", // Căn trái
      top: "20%",
    },
    grid: {
      left: 100,
      right: 200,
      top: 50,
      bottom: 50
    },
    xAxis: {
      type: 'category',
      data: launchPlanNames,
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: averageScores,
        name: activeTab,
        type: 'bar',
        barWidth: "50%", // Độ rộng của cột
        label: {
          show: true,
          position: 'top'
        },
      }
    ],
    color: '#AAF0C6'
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartBasicBarCohort
