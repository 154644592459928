import React, { useEffect, useMemo, useState } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//lodash
import _ from "lodash";

//components
import { TimeLineTop, OptionEdit } from "../CaseTimeline";

//actions
import { actFetchTimeline, actFetchLoadTimePoint, actSaveTaskActive, actActiveViewQuestion } from "@actions";

// Utilities
import { LoaderText } from "@utils";
import { CaseTimelineLeftPanel } from "./CaseTimelineLeft";
import { CaseTimelineRightPanel } from "./CaseTimelineRight";
import styled from "styled-components";
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
import Right from '@images/teachV2/turn-right.svg'

const WrapDataSetting = styled.div`
  padding: 0 20px;  
  display: flex;
  flex-direction: column;
  gap: 20px;
  .tab-details__title {
    font-weight: 500;
    font-size: 14px;
    color: #475467;
    &.active-task {
      color: #0089C2;
      font-weight: 600;
    }
  }
  .case-timelineCt__subtask {
    margin-left: 14px;
  }
`

const HeaderBack = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  span {
    font-weight: 600;
    font-size: 14px;
    color: #101828;
  }
`

const CaseTimelineTabContent = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  
  //store
  const timelineStore = useSelector((state) => state?.timeline || {});
  const timepointStore = useSelector((state) => state?.timepoint || {});
  const timepointData = timepointStore?.data;
  const taskActive = useSelector((state) => state?.statusTaskActive?.currentTask || {});
  const isLoadingTimepoint = timepointStore?.isLoading;
  const timelineData = useMemo(() => timelineStore?.data || [], [timelineStore]);
  const teachCaseData = useSelector((state) => state.teachCase || []);
  const dataResponse = teachCaseData && teachCaseData.data;
  
  //state
  const [dataType, setDataType] = useState()
  const [timepointIdActive, setTimepointIdActive] = useState(null);
  const [isLoadFirst, setIsLoadFirst] = useState(false);
  const [tabActive, setTabActive] = useState()

  // Load Task
  useEffect(() => {
    dispatch(actSaveTaskActive("demographics"));
  }, [id, dispatch]);

  // Load Timeline
  useEffect(() => {
    const fetchData = async () => {
      const paramsTimeline = {
        Action: "Load",
        Target: "Timeline",
      };
      try {
        const data = await dispatch(actFetchTimeline(id, paramsTimeline));
        const initialTimepoint = _.find(data, { Time: 0 });
        if (initialTimepoint?.Id) {
          handleActiveTimePoint(initialTimepoint);
        }
      } catch (error) {
        console.error("Error fetching timeline:", error);
        // Xử lý lỗi nếu cần thiết
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  // Load Timepoint
  useEffect(() => {
    if (timelineData.length > 0 && !isLoadFirst) {
      const initialTimepoint = _.find(timelineData, { Time: 0 });
      const initialTimepointId = initialTimepoint?.Id;
      if (initialTimepointId) {
        fetchTimePoint(initialTimepointId);
        setTimepointIdActive(initialTimepointId);
        setIsLoadFirst(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineData, isLoadFirst]);

  // Handle Api Timepoint
  const fetchTimePoint = async (timePointId) => {
    const params = {
      Action: "Load",
      Target: "TimePoint",
      TimePointId: String(timePointId),
    };
    try {
      await dispatch(actFetchLoadTimePoint(id, params));
    } catch (error) {
      console.error("Error fetching timepoint:", error);
    }
  };

  // Handle Active Timepoint
  const handleActiveTimePoint = (values) => {
    setTimepointIdActive(values?.Id);
    setDataType()
    if (values?.Id !== 9999) {
      fetchTimePoint(values?.Id);
    }
  };

  // Handle Open View Create Question
  const handleOpenViewCreateQuestion = () => {
    dispatch(actActiveViewQuestion("Blank_Question"));
  }

  const handleBack = () => {
    setDataType()
    dispatch(actSaveTaskActive("demographics"));
  }

  return (
    <React.Fragment>
      <div className="card-area bg-white">
        <TimeLineTop
          {...props}
          timepointIdActive={timepointIdActive}
          timelineData={timelineData}
          timepointData={timepointData}
          handleClickTimepoint={handleActiveTimePoint}
          handleActiveTimePointAfterCreate={handleActiveTimePoint}
          id={id}
        />
      </div>
      {isLoadingTimepoint ? (
          <div className="position-relative mt-3">
            <div style={{ height: '200px' }}><LoaderText /></div>
          </div>
        ) : dataType || timepointIdActive === 9999 ? (
          <WrapDataSetting className="tab-details">
            {timepointIdActive !== 9999 && (
              <div className="case-timelineCt m-0">
                <div className="case-timelineCt__left">
                  <HeaderBack onClick={handleBack}>
                    <Icon src={Back} stroke="#101828"/>
                    <span>Back to Personas</span>
                  </HeaderBack>
                </div>
                <div className="case-timelineCt__subtask">
                  <h1 className="tab-details__title">{dataType?.name}</h1>
                  {tabActive && (
                    <>
                      {tabActive?.Parent && (
                        <>
                          <Icon src={Right}/>
                          <h1 className="tab-details__title">{tabActive?.Parent}</h1>
                        </>
                      )}
                      <Icon src={Right}/>
                      <h1 className="tab-details__title active-task">{tabActive?.Label}</h1>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="case-timelineCt position-relative m-0">
              {timepointIdActive !== 9999 && (
                <CaseTimelineLeftPanel
                  {...props}
                  timelineData={timelineData}
                  timepointData={timepointData}
                  isLoadingTimepoint={isLoadingTimepoint}
                  handleActiveTimePoint={handleActiveTimePoint}
                  activeViewClass={taskActive}
                  timepointIdActive={timepointIdActive}
                  handleOpenViewCreateQuestion={handleOpenViewCreateQuestion}
                  dataType={dataType}
                  setTabActive={setTabActive}
                />
              )}
              <CaseTimelineRightPanel
                {...props}
                timelineData={timelineData}
                timepointData={timepointData}
                isLoadingTimepoint={isLoadingTimepoint}
                activeViewClass={taskActive}
                timepointIdActive={timepointIdActive}
                dataType={dataType}
                dataResponse={dataResponse}
                id={id}
              />
            </div>
          </WrapDataSetting>
        ) : (
          <OptionEdit
            setDataType={setDataType} 
            timepointData={timepointData}
            timepointIdActive={timepointIdActive}
            dataResponse={dataResponse}
            id={id}
          />
        )
      }
    </React.Fragment>
  );
};

export default CaseTimelineTabContent;