import { questionConstants } from "@constants";

export function question(state = {}, action) {
    switch (action.type) {
        case questionConstants.QUESTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case questionConstants.QUESTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case questionConstants.QUESTION_SUBMIT:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case questionConstants.QUESTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function chatRecordForQuestion(state = { data: [] }, action) {
    switch (action.type) {
        case questionConstants.CHAT_RECORD_FOR_QUESTION:
            return {
                ...state,
                data: action.payload
            };
        case questionConstants.UPDATE_CHAT_RECORD_FOR_QUESTION:
            return {
                ...state,
                data: [...state.data, action.payload] // Đẩy dữ liệu mới vào mảng hiện tại
            };
        case questionConstants.RESET_CHAT_RECORD_FOR_QUESTION:
            return {
                ...state,
                data: [] // Reset dữ liệu về mảng rỗng
            };
        default:
            return state;
    }
};

export function allQuestionForelement(state = {}, action) {
    switch (action.type) {
        case questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function questionSubmitSuccess(state = { success: false, data: {} }, action) {
    switch (action.type) {
        case questionConstants.QUESTION_SUBMIT:
            return {
                ...state,
                success: true,
                data: action.payload,
            };
        default:
            return state;
    }
};
