import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import apiCaller from "@utils/apiCaller";

const TextToSpeech = ({ contentSpeechAudio, onAudioStart, onAudioEnd, onStatusChange }) => {
    const [audioObject, setAudioObject] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const history = useHistory();
    const audioRef = useRef(null);

    useEffect(() => {
        // Lắng nghe sự kiện điều hướng để tạm dừng âm thanh khi điều hướng
        const unlisten = history.listen(() => {
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect(); // Ngắt kết nối
                audioRef.current = null;
            }
            if (audioContext) {
                audioContext?.close();
                setAudioContext(null);
            }
        });

        return () => {
            unlisten(); // Dọn dẹp listener khi component bị unmount
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect(); // Ngắt kết nối
                audioRef.current = null;
            }
            if (audioContext) {
                audioContext?.close();
                setAudioContext(null);
            }
        };
    }, [history, audioContext]);

    useEffect(() => {
        // Nếu có contentSpeechAudio, phát âm thanh
        if (contentSpeechAudio) {
            handleStreamAudio(contentSpeechAudio);
        }

        // Cleanup khi component bị unmount hoặc khi contentSpeechAudio thay đổi
        return () => {
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect(); // Ngắt kết nối
                audioRef.current = null;
            }
            if (audioContext) {
                audioContext?.close();
                setAudioContext(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSpeechAudio]);

    const handleStreamAudio = async (text) => {
        if (onStatusChange) onStatusChange('streaming'); // Báo cáo trạng thái đang streaming

        try {
            const response = await apiCaller(
                `/api/binh/tts_streaming/`,
                'POST',
                { input: text, gender: 'Female' },
                false, // Không sử dụng `useMakanSafeUrl`
                { responseType: 'arraybuffer' } // Đảm bảo nhận phản hồi dưới dạng ArrayBuffer
            );

            if (response && response.status === 200 && response.data) {
                if (!(response.data instanceof ArrayBuffer)) {
                    throw new Error("Invalid data format");
                }

                const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
                setAudioContext(newAudioContext);

                // Giải mã dữ liệu âm thanh
                newAudioContext.decodeAudioData(response.data, (buffer) => {
                    const source = newAudioContext.createBufferSource();
                    source.buffer = buffer;
                    source.connect(newAudioContext.destination);

                    // Gọi hàm onAudioStart trước khi âm thanh bắt đầu phát
                    if (onAudioStart) onAudioStart();

                    source.start(0);
                    setAudioObject(source);
                    audioRef.current = source; // Lưu trữ audio object trong ref

                    if (onStatusChange) onStatusChange('playing'); // Báo cáo trạng thái đang playing

                    source.onended = () => {
                        handleAudioEnd(); // Xử lý khi âm thanh kết thúc
                        newAudioContext?.close();
                        setAudioContext(null);
                        if (onStatusChange) onStatusChange('end'); 
                    };
                }, (error) => {
                    console.error('Error decoding audio:', error);
                    if (onStatusChange) onStatusChange('error'); // Báo cáo trạng thái lỗi
                });
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            if (onStatusChange) onStatusChange('error'); // Báo cáo trạng thái lỗi
            console.error('Error streaming audio:', error);
        }
    };

    const handleAudioEnd = () => {
        if (onAudioEnd) onAudioEnd(); // Gọi hàm onAudioEnd khi âm thanh kết thúc
        if (onStatusChange) onStatusChange('ended'); // Báo cáo trạng thái đã kết thúc

        if (audioObject) {
            audioObject.stop();
            setAudioObject(null);
        }
    };

    return null; // Trả về null nếu không cần render gì
};

export default TextToSpeech;