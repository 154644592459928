import React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ButtonComponent } from "@components/patterns/elements/Button";

const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    &:hover svg {
        stroke: #17475C;
    }
`;

const DescribeStyle = styled.p`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
`;

const WrapHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
`

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const FormModal = ({
    isOpen,
    toggleModal,
    title,
    children,
    onAction,
    actionText,
    maxWidth,
    isLoading,
    actionDisabled,
    deleteButtonConfig,
    hiddenButtonCancel,
    classCustom,
    describeContent,
    // hiddenIcon,
    // iconConfig,
    // iconTitle,
}) => {
    const showFooter = !hiddenButtonCancel || onAction || (deleteButtonConfig && deleteButtonConfig.show);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            centered
            size='lg'
            className={`${classCustom} modal-custom modal-size-lg`}
            style={{ maxWidth: maxWidth }}
        >
            <ModalHeader>
                <WrapHeader>
                    {/* {!hiddenIcon && iconConfig?.iconName && (
                        <Iconsax iconName={iconConfig.iconName} fill={iconConfig.color || "#17475C"} size={20} />
                    )}
                    {iconTitle && (
                        <Icon src={iconTitle} width={48} height={48} />
                    )} */}
                    {title && <p className='modal-title__text'>{title}</p>}
                    <CloseButton onClick={toggleModal}>
                        <CloseIcon />
                    </CloseButton>
                </WrapHeader>
                {describeContent && <DescribeStyle className="describe-content">{describeContent}</DescribeStyle>}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {showFooter && (
                <ModalFooter>
                    <div className='d-flex w-100'>
                        {deleteButtonConfig?.show && (
                            <div className='w-50'>
                                <Button
                                    color="danger"
                                    className="button-cta button-sm"
                                    onClick={deleteButtonConfig.onAction}
                                    disabled={deleteButtonConfig.disabled || isLoading}
                                >
                                    {deleteButtonConfig.isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                    {deleteButtonConfig.text || "Delete"}
                                </Button>
                            </div>
                        )}
                        <div className={deleteButtonConfig?.show ? 'w-50' : 'w-100'}>
                            <div className="d-flex align-items-center justify-content-end">
                                {!hiddenButtonCancel &&
                                    <ButtonComponent
                                        className="px-3"
                                        color="outline"
                                        onClick={toggleModal}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                }
                                {onAction &&
                                    <ButtonComponent
                                        className="ml-2 px-4"
                                        onClick={onAction}
                                        disabled={actionDisabled || isLoading}
                                    >
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                        {actionText}
                                    </ButtonComponent>
                                }
                            </div>
                        </div>
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default FormModal;