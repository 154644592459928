import React, { useEffect } from "react";

// context
import { useScreenSize } from "@context/ScreenSizeContext";

function QuestionFullFixed(props) {
  const { children, isShowChatFullScreen } = props;

  // Use screen size context
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (isShowChatFullScreen) {
      document.body.style.overflow = "hidden"; // Lock scrolling
    } else {
      document.body.style.overflow = ""; // Unlock scrolling
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [isShowChatFullScreen]);
  return (
    <div
      className={`chatFixed-full ${isMobile ? "is-mobile" : ""} ${
        isShowChatFullScreen ? "show" : "hidden d-none"
      }`}
    >
      <div 
        className={`chatFixed-full__content position-relative`}
      >
        {children}
      </div>
    </div>
  );
}

export default QuestionFullFixed;