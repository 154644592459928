import styled, { css } from "styled-components";
import BgVoiceStreaming from "@images/chatvoice/bg_voice_to_voice.png";

export const FrameChatFullBox = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: url(${BgVoiceStreaming});
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: cover;
`;

export const PatientLabel = styled.div`
 color: #5EB6DA;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 26px;
`;

export const ImageBox = styled.div`
  margin-top: 50px;
  background: #6fa9e9;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

export const ImageLabelWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .custom-tooltip {
    padding: 8px 12px;
    background: #fff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #101828;
    text-align: center;
    &::after {
      background-color: #fff !important;
    }
  }
`;

export const BlinkingDiv = styled.div`
  position: relative;
  ${({ audioStarted }) =>
    audioStarted &&
    css`
      &:before,
      &:after {
        content: "";
        width: 120px;
        height: 120px;
        border: 9px solid rgba(20, 91, 173, 0.8);
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
      &:before {
        animation: pulse 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
      }
      &:after {
        animation: pulse 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        animation-delay: 0.375s;
      }

      @keyframes pulse {
        from {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.8;
        }
        to {
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.4;
        }
      }
    `}
`;

export const ResponseText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  max-width: 70%;
  margin: 20px auto;
  overflow-y: auto;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  @media (max-width: 1024px) {
    max-width: 90%;
  }
`;

export const ContinueText = styled.div`
  color: #0089C2;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 8px 45px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #FDFDFD;
  cursor: pointer;
  margin: 0 auto 20px auto;
  height: 36px;
  max-width: 150px;
  white-space: nowrap;
`;