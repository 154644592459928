import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { MessageEmtyData } from "@ui-partents/Message";
import { paginationOptions } from "@utils";
import { SortCaret } from "@ui-partents/Table";
import { SearchCustomForTable } from "@ui-partents/Search";
import { FormGroup, Input } from 'reactstrap';
import Icon from '@components-common/Icon'
import Back from '@images/teachV2/back.svg'
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import { useDispatch } from 'react-redux';
import { getReportCurriculum } from "@actions";
import { useHistory } from 'react-router-dom';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  max-width: 500px;
`;

const ButtonCancel = styled.div`
  padding: 14px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #D0D5DD;
  span {
    color: #475467;
    font-size: 18px;
    font-weight: 500;
  }
`

export default function SelectMultiple(props) {
  const { curriculum, setShowTypeSelect } = props;
  
  const history = useHistory();
  const dispatch = useDispatch();

  const initialSelectedBooks = [];
  const [filteredData, setFilteredData] = useState(curriculum[0]?.Cohorts || []);
  const selectedBooksRef = useRef(initialSelectedBooks);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  useEffect(() => {
    if (curriculum[0]?.Cohorts) {
      setFilteredData(
        curriculum[0]?.Cohorts.map(item => ({
          ...item,
          selected: selectedBooksRef.current.some(cur => cur.CohortId === item.CohortId),
        }))
      );
    }
  }, [curriculum]);

  const handleClearSearch = () => {
    setFilteredData(
      curriculum[0]?.Cohorts.map(item => ({
        ...item,
        selected: selectedBooksRef.current.some(cur => cur.CohortId === item.CohortId),
      })) || []
    );
  };

  const handlePageChange = () => {
    setFilteredData(filteredData.map(item => ({
      ...item,
      selected: selectedBooksRef.current.some(cur => cur.CohortId === item.CohortId),
    })));
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [...selectedBooksRef.current];

      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.CohortId === row.CohortId);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }

      selectedBooksRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.CohortId === item.CohortId),
      })));
    },
    selected: selectedBooksRef.current.map(book => book.CohortId),
    selectionRenderer: ({ mode, checked, disabled, onChange }) => (
      <FormGroup check className="form-common px-1">
        <Input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name="checkbox"
          className="react-checkbox-common react-checkbox-md"
        />
      </FormGroup>
    ),
  };

  const columns = [
    {
      dataField: "CohortName",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: { width: "96%" },
      formatter: (value, row) => (
        <div className="d-flex justify-content-between item-book">
          <div className="d-flex">
            <p>{value}</p>
          </div>
        </div>
      ),
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <>
      <ToolkitProvider
        keyField="CohortId"
        data={filteredData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
              <SearchCustomForTable
                toolkitprops={toolkitprops}
                paginationProps={paginationProps}
                isResetPagination
                searchMode="auto"
                placeholder="Search"
                onClear={handleClearSearch}
              />
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive list-common"
              data={filteredData || []}
              columns={columns}
              bordered={false}
              noDataIndication={() => (
                <MessageEmtyData
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
              selectRow={selectRow}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </>
  );

  const totalSize = filteredData.length || 0;
  const paginationConfig = paginationOptions({ totalSize });

  const handleToReportMultiple = async () => {
    setLoadingGenerate(true)
    const listIdCohorts = selectedBooksRef?.current?.map(item => item.CohortId)
    const dataReport = await dispatch(getReportCurriculum({
      ReportType: "CurriculumPerformance",
      ReportMode: "MultipleCohorts",
      CurriculumId: curriculum[0]?.CurriculumId,
      CohortIds: listIdCohorts
    }))
    if (dataReport?.data?.status === 200) {
      const listCohorts = JSON.stringify(selectedBooksRef?.current)
      const curriculumActive = JSON.stringify(curriculum[0])
      history.push({
        pathname: '/teach/report-cohort-multiple',
        state: {  // location state
          listCohorts,
          curriculumActive, 
        },
      })
      setLoadingGenerate(false)
    }
  }

  return (
    <>
      <div className="cohort-form cohort-scroll">
        <div className="cohort-title">
          <h1>Select a Cohort</h1>
          <p>Select multiple or all cohorts from the list below.</p>
        </div>
        <WrapCommon>
          <div className="form-area">
            <div className="form-area__select">
              <PaginationProvider pagination={paginationFactory(paginationConfig)}>
                {contentTable}
              </PaginationProvider>
            </div>
          </div>
        </WrapCommon>
      </div>
      <div className='cohort-btn d-flex align-items-center justify-content-end'>
        <ButtonCancel
          onClick={() => setShowTypeSelect(false)} 
          style={{ display: 'flex' }}
        >
          <Icon src={Back}/>
          <span>
            Back
          </span>
        </ButtonCancel>
        <ButtonComponent 
          size="large" 
          style={{ fontSize: '18px', fontWeight: 500 }} 
          onClick={handleToReportMultiple}
          disabled={loadingGenerate || !selectedBooksRef?.current?.length}
        >
          Generate Report
        </ButtonComponent>
      </div> 
    </>
  );
}