import React, { useState } from 'react'
import styled from 'styled-components'
import { ChoiceCommon } from '../../StepCommon'
import FormCreateSeries from './FormCreateSeries'
import SelectMultipleModules from './SelectMultipleModules'
import SelectSingleModules from './SelectSingleModules'

const WrapperModule = styled.div`
  width: 100%;
  height: 100%;
`

const ModuleSeries = (props) => {
  const { setShowOptionSeries, handleNextStep } = props;
  const [subStep, setSubStep] = useState();
  const [stepId, setStepId] = useState(2);

  const handleNext = () => {
    setStepId((prev) => prev + 1);
  };

  const handleBack = () => {
    setStepId((prev) => prev - 1);
  };

  const handleSubStep = (subStep) => {
    setSubStep(subStep)
  }

  const selectTypeFlow = (contents) => {
    switch (contents?.view) {
      case "create_series":
        return (
          <FormCreateSeries
            handleNext={handleNext}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      case "add_module":
        return (
          <SelectMultipleModules
            handleNext={handleNextStep}
            handleBack={handleBack}
            {...props}
          />
        )
      case "select_exist":
        return (
          <SelectSingleModules
            handleNext={handleNextStep}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      default:
        break;
    }
  }

  return (
    <WrapperModule>
      {subStep ? (
        subStep?.SubSteps?.map((step, index) => (
          (index + 2) === stepId && selectTypeFlow(step.Contents)
        ))
      ) : (
        <ChoiceCommon
          {...props}
          handleBack={() => setShowOptionSeries()}
          handleSubStep={handleSubStep}
          type="series"
        />
      )}
    </WrapperModule>
  )
}

export default ModuleSeries
