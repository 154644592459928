import React from "react";
import QuestionNarrativeMain from "./NarrativeSetting/QuestionNarrative/QuestionNarrativeMain";

const AdvanceSetting = (props) => {

  return (
    <QuestionNarrativeMain 
      {...props} 
      explanation="The Learner must answer these questions before advancing to the next timepoint or before completing the module if this is the last timepoint." 
      type="advance"
    /> 
  );
};

export default AdvanceSetting;
