import React from 'react';
import { ButtonTaskItem } from "@components/learner/StandardScreen/CaseTask";
import { useParams } from "react-router-dom";
import { getIconForObject } from "@helpers";
import { useSelector, useDispatch } from "react-redux";
import { toggleExamMobile } from "@actions";
import { useScreenSize } from '@context/ScreenSizeContext';

function MessageButton({ TaskToResume, handleClickBtnNextTimePoint, handleExamMenu, isViewChatFullScreen }) {
    const { id } = useParams();
    const iconPath = getIconForObject(TaskToResume?.Task);
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const CaseTask = InterfaceTemplateDetails?.CaseTask;
    const dispatch = useDispatch();

    // Use screen size context
    const { isMobile } = useScreenSize();

    const handleClick = () => {
        const isTaskResume = TaskToResume?.Task;
        //Chỉ khi ở mobile mới chạy logic if
        if (isMobile && TaskToResume && isTaskResume === "exam") {
            dispatch(toggleExamMobile(true));
            // Mục đích đóng view chat patient và instructor dựa vào hàm handleToogleTaskView
            handleExamMenu(isTaskResume, "closeTask");
        } else if (isTaskResume === "advance") {
            handleClickBtnNextTimePoint(id, "advance");
        } else {
            handleExamMenu(isTaskResume, isViewChatFullScreen);
        }
    };

    // Check Auto Next Timepoint
    const checkAutoNextTimepoint = useSelector((state) => state.checkAutoNextTimepoint || {});
    const autoNextLoading = checkAutoNextTimepoint?.isLoading;

    // Next Timepoint
    const nextTimepointStatusReducer = useSelector((state) => state.checkStatusNextTimePoint || {});
    const loadingBtnNextTimepoint = nextTimepointStatusReducer?.isLoading;
    console.log(nextTimepointStatusReducer, "nextTimepointStatusReducer........");

    const btnNextLoading = loadingBtnNextTimepoint || autoNextLoading;
    return (
        <div className="message-answer__button ml-auto" onClick={handleClick}>
            <div className="tasks-nav">
                {TaskToResume?.Task === "advance" ? (
                    <button className="button-yellow" style={{ minWidth: '200px' }} disabled={btnNextLoading}>
                        {btnNextLoading && (
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {`${CaseTask?.button_next_tp?.Text || "Next"}`}
                    </button>
                ) : (
                    <ButtonTaskItem
                        label={TaskToResume?.Task}
                        attributeTask={TaskToResume?.Task}
                        icon={iconPath}
                        isViewMessage={true}
                    />
                )}
            </div>
        </div>
    );
}

export default MessageButton;