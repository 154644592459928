import React from 'react'
import { ReportDetailCohortMultiple } from '@components/teachV2/reportPage/reportByCohort/ReportMultiple'
import { useSelector } from "react-redux";

const ReportCohortMultiple = () => {
  const dataCurriculum = useSelector((state) => state.getReportCurriculum || {});

  return (
    <div className="wrapper -ReferenceLiblaryPage">
      <div className="h-100">
        <ReportDetailCohortMultiple type="multiplel-cohort" dataCurriculum={dataCurriculum}/>
      </div>
    </div>
  )
}

export default ReportCohortMultiple
