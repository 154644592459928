import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
// import { Rating } from '@ui-partents/Rating';
import { getFinalBank, removeQuestionFinalBank } from "@actions"
import { TagsToolTip } from "@ui-partents/Tags";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";

//Icon
import { Icon } from '@components/common';
import More from '@images/teachV2/more-vertical.svg';

// Import the pagination configuration
import {paginationOptions} from "@utils";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { teachV2Constants } from "@constants";
import { useHistory } from 'react-router-dom';
import { WrapModalMoveToFolder } from '../ModalMoveToFolder';

const TableAllReviews = (props) => {
  const { activeFolder, setListValue, inputValue, setInputValue, listValue } = props;
  const dispatch = useDispatch();
  const history = useHistory()
  const listQuestionBank = useSelector((state) => state.getFinalBank || []);

  const getListFinalBank = () => {
    if (!activeFolder?.FolderId) {
      dispatch(getFinalBank({
        Action: "LoadOrganizationFinalQuestionBank"
      }))
    } else {
      dispatch(getFinalBank({
        Action: "LoadFolder",
        Mode: "FinalQuestionBank",
        FolderId: activeFolder?.FolderId
      }))
    }
  }

  useEffect(() => {
    getListFinalBank()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFolder])

  useEffect(() => {
    if (listQuestionBank?.data) {
      dispatch({ type: teachV2Constants.FILTER_FINAL_BANK_SUCCESS, payload: listQuestionBank?.data, params: listValue });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue])

  const componentsCommon = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    if (value?.length === 0) {
      getListFinalBank()
    }
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const handleViewDetail = (row) => {
    const questionDetail = JSON.stringify(row)
    history.push({
      pathname: `/teach/question-bank/edit`,
      state: {  // location state
        question: questionDetail, 
        type: "final"
      },
    })
  }

  const handleRemove = async (idRemove) => {
    const params = {
      Action: "RemoveFromFinalBank",
      QuestionItemId: idRemove,
      // FolderId: activeFolder,
    }
    const result = await dispatch(removeQuestionFinalBank(params));
    if (result?.data?.status === 200) {
      setListValue([])
      getListFinalBank()
    }
  };

  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "QuestionType",
      text: "Owner",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Owner || "med2lab.com"}
          </div>
        );
      },
    },
    {
      Field: "tags",
      text: "Tag(s)",
      headerClasses: "align-middle text-center",
      formatter: (cell, row) => {
        return (
          <TagsToolTip
            tags={row?.Tags}
            documentId={row?.QuestionItemId}
            color={'blue'}
          />
        );
      },
    },
    // {
    //   dataField: "QuestionType",
    //   text: "Rating",
    //   headerStyle: { width: "190px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <Rating rating={2} />
    //     );
    //   },
    //   filterValue: (cell, row) => row.Contents.question
    // },
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-center",
      headerStyle: { width: "50px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-reference">
            <Icon src={More}/>
            <div className="action-reference__list">
              <p onClick={() => handleViewDetail(row)}>View Details</p>
              <WrapModalMoveToFolder
                dataEdit={row}
                getListOrgBank={getListFinalBank}
                {...props}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Move to Folder</p>
                )}
              </WrapModalMoveToFolder>
              <WrapModalDeleteCommon
                title={`Remove from Final Bank`}
                sub={`Are you sure you want to remove this question from the Final Bank?`}
                handleRemove={handleRemove}
                idRemove={row?.QuestionItemId}
                buttonText={`Remove`}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Remove from Final Bank</p>
                )}
              </WrapModalDeleteCommon>
            </div>
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.dataFilter || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.dataFilter?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
                <div className="email-share__form-input ml-2">
                  <CreatableSelect
                    components={componentsCommon}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      handleAddTag(newValue)
                    }
                    }
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={(e) => {
                      paginationProps.onPageChange(1, 10);
                      handleKeyDown(e)
                    }}
                    onBlur={handleBlur}
                    placeholder="Filter by Tags"
                    value={listValue}
                    classNamePrefix={`filter-tag`}
                  />
                </div>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={listQuestionBank?.dataFilter || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listQuestionBank?.dataFilter?.length || 0;  // Determine the total size dynamically
  // Generate pagination configuration dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.dataFilter?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableAllReviews
