import React from "react";
import { Icon } from "@components/common";
import Empty from "@images/teachV2/empty.svg";

const MessageEmtyData = (props) => {
  const { title, text, ButtonBottomRender, borderDashed, minHeight } = props;

  // Define styles
  const containerStyle = {
    borderRadius: "16px",
    border: borderDashed ? "1px dashed #CDD5DF" : "none",
  };

  const dataEmptyCaseStyle = {
    minHeight: minHeight ? `${minHeight}px` : "500px",
  };

  return (
    <div className="dataEmpty-container">
      <div
        className="dataEmptyCase"
        style={{ ...containerStyle, ...dataEmptyCaseStyle }}
      >
        <div className="dataEmptyCase__img">
          <Icon src={Empty} />
        </div>
        <div className="dataEmptyCase__info">
          <p
            className="dataEmptyCase__info-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="dataEmptyCase__info-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        {/* Render ButtonBottomRender if it exists */}
        {ButtonBottomRender && (
          <div className="dataEmptyCase__button-bottom">
            {ButtonBottomRender}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageEmtyData;