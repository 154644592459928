import React from "react";

//Question
import { AnswerItemRadio } from "../Preceptor/MutipleChoice";

function QuestionSelectOne(props) {
    return (
        <div className="answerList" >
            <AnswerItemRadio
                {...props}
            />
        </div>
    );
}

export default QuestionSelectOne;