const webSocketRef = { current: null };
let isWebSocketActive = false; // Biến này giúp track trạng thái WebSocket

export const initWebSocket = (url, onMessage, onOpen, onClose, onError) => {
  if (
    webSocketRef.current &&
    webSocketRef.current.readyState !== WebSocket.CLOSED
  ) {
    closeWebSocket();
  }

  webSocketRef.current = new WebSocket(url);
  webSocketRef.current.binaryType = "arraybuffer";

  webSocketRef.current.onopen = () => {
    console.log("✅ WebSocket connection opened");
    isWebSocketActive = true; // Đánh dấu WebSocket đang hoạt động
    if (onOpen) onOpen(webSocketRef.current); // Truyền WebSocket vào `onOpen`
  };

  webSocketRef.current.onmessage = (event) => {
    console.log("📩 WebSocket Message Received:", event.data);
    if (onMessage) onMessage(event);
  };

  webSocketRef.current.onclose = (event) => {
    console.warn("⚠️ WebSocket closed:", event);
    isWebSocketActive = false;
    if (onClose) onClose(event);
  };

  webSocketRef.current.onerror = (error) => {
    console.error("❌ WebSocket error:", error);
    isWebSocketActive = false;
    if (onError) onError(error);
  };
};

// Hàm đóng WebSocket
export const closeWebSocket = () => {
  if (webSocketRef.current) {
    if (
      webSocketRef.current.readyState === WebSocket.OPEN ||
      webSocketRef.current.readyState === WebSocket.CONNECTING
    ) {
      console.log("🔴 Closing WebSocket...");
      webSocketRef.current.close();
    }

    webSocketRef.current.onopen = null;
    webSocketRef.current.onmessage = null;
    webSocketRef.current.onclose = null;
    webSocketRef.current.onerror = null;
  }

  isWebSocketActive = false;
  webSocketRef.current = null;
  console.log("✅ WebSocket fully closed and destroyed");
};

// Hàm lấy WebSocket instance
export const getWebSocket = () => webSocketRef.current;

// Custom Hook để sử dụng WebSocket trong component
export const useWebSocketManager = () => {
  return {
    initWebSocket,
    closeWebSocket,
    getWebSocket,
    isWebSocketActive: () => isWebSocketActive,
  };
};
