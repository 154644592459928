import React, { useEffect, useMemo, useState } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//lodash
import _ from "lodash";

// Utilities
import { LoaderText } from "@utils";

//components
import { CaseTimelineLeftPanel } from "../TimeLineLayout";
import { HeaderTimePoint, TimeLineTop, CaseHeader } from "../CaseTimeline";

//actions
import { actFetchTimeline, actFetchLoadTimePoint, actSaveTaskActive, actActiveViewQuestion } from "@actions";
import CaseTimelineRightPanel from "../../settingModule/CaseTimeline/CaseTimelineRight/CaseTimelineRightPanel";

const CaseTimelineTabContent = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  //store
  const timelineStore = useSelector((state) => state?.timeline || {});
  const timepointStore = useSelector((state) => state?.timepoint || {});
  const timepointData = timepointStore?.data;
  const taskActive = useSelector((state) => state?.statusTaskActive?.currentTask || {});
  const isLoadingTimepoint = timepointStore?.isLoading;
  const timelineData = useMemo(() => timelineStore?.data || [], [timelineStore]);

  //state
  const [timepointIdActive, setTimepointIdActive] = useState(null);
  const [isLoadFirst, setIsLoadFirst] = useState(false);

  // Load Task
  useEffect(() => {
    dispatch(actSaveTaskActive("profile_image"));
  }, [id, dispatch]);

  // Load Timeline
  useEffect(() => {
    const fetchData = async () => {
      const paramsTimeline = {
        Action: "Load",
        Target: "Timeline",
      };
      try {
        const data = await dispatch(actFetchTimeline(id, paramsTimeline));
        const initialTimepoint = _.find(data, { Time: 0 });
        if (initialTimepoint?.Id) {
          handleActiveTimePoint(initialTimepoint);
        }
      } catch (error) {
        console.error("Error fetching timeline:", error);
        // Xử lý lỗi nếu cần thiết
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  // Load Timepoint
  useEffect(() => {
    if (timelineData.length > 0 && !isLoadFirst) {
      const initialTimepoint = _.find(timelineData, { Time: 0 });
      const initialTimepointId = initialTimepoint?.Id;
      if (initialTimepointId) {
        fetchTimePoint(initialTimepointId);
        setTimepointIdActive(initialTimepointId);
        setIsLoadFirst(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineData, isLoadFirst]);

  // Handle Api Timepoint
  const fetchTimePoint = async (timePointId) => {
    const params = {
      Action: "Load",
      Target: "TimePoint",
      TimePointId: String(timePointId),
    };
    try {
      await dispatch(actFetchLoadTimePoint(id, params));
    } catch (error) {
      console.error("Error fetching timepoint:", error);
    }
  };

  // Handle Active Timepoint
  const handleActiveTimePoint = (values) => {
    setTimepointIdActive(values?.Id);
    if (values?.Id !== 9999) {
      fetchTimePoint(values?.Id);
    }
  };

  // Handle Active Timepoint  First
  const handleActiveTimepointFirst = () => {
    if (timelineData.length > 0) {
      const initialTimepoint = _.find(timelineData, { Time: 0 });
      const initialTimepointId = initialTimepoint?.Id;
      if (initialTimepointId) {
        fetchTimePoint(initialTimepointId);
        setTimepointIdActive(initialTimepointId);
      }
    }
  };

  // Handle Open View Create Question
  const handleOpenViewCreateQuestion = () => {
    dispatch(actActiveViewQuestion("Blank_Question"));
  }

  return (
    <React.Fragment>
      <div className="card-area bg-white mb-4">
        <CaseHeader
          {...props}
          timelineData={timelineData}
          timepointData={timepointData}
        />
        <TimeLineTop
          {...props}
          timepointIdActive={timepointIdActive}
          timelineData={timelineData}
          timepointData={timepointData}
          handleClickTimepoint={handleActiveTimePoint}
          handleActiveTimePointAfterCreate={handleActiveTimePoint}
        />
      </div>
      <div className="card-area bg-white pd-box-40">
        <div className="tab-details">
          {timepointIdActive !== 9999 && (
            <HeaderTimePoint
              {...props}
              isLoading={isLoadingTimepoint}
              timelineData={timelineData}
              timepointData={timepointData}
              handleActiveTimePoint={handleActiveTimePoint}
              timepointIdActive={timepointIdActive}
              handleActiveTimepointFirst={handleActiveTimepointFirst}
            />
          )}
          <div className="case-timelineCt position-relative">
            {timepointIdActive !== 9999 && (
              <CaseTimelineLeftPanel
                {...props}
                timelineData={timelineData}
                timepointData={timepointData}
                isLoadingTimepoint={isLoadingTimepoint}
                handleActiveTimePoint={handleActiveTimePoint}
                activeViewClass={taskActive}
                timepointIdActive={timepointIdActive}
                handleOpenViewCreateQuestion={handleOpenViewCreateQuestion}
              />
            )}
            <CaseTimelineRightPanel
              {...props}
              timelineData={timelineData}
              timepointData={timepointData}
              isLoadingTimepoint={isLoadingTimepoint}
              activeViewClass={taskActive}
              timepointIdActive={timepointIdActive}
            />
            {isLoadingTimepoint && <div style={{ height: '200px' }}><LoaderText /></div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CaseTimelineTabContent;