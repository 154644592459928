import React, { useEffect, useRef, useState } from "react";

//Get Token
import { authHeader } from "@helpers";

//redux
import { useSelector } from "react-redux";

//axios
import axios from "axios";

//redux
import { useDispatch } from "react-redux";

//actions
import { saveTranscriptRecord, saveDataResponseSocket } from "@actions";

//helps
import { useWebSocketManager } from "@helpers";

//components
import { RecordingButton, NextQuestionButton } from "../WebSocket";

//Loader
import BeatLoader from 'react-spinners/BeatLoader';

//styles
import {
  FrameChatFullBox,
  PatientLabel,
  ImageBox,
  StyledImage,
  ImageLabelWrapper,
  BlinkingDiv,
  ResponseText,
  ControlsContainer,
} from "./WebSocketAudioHandler.styles";

export default function WebSocketAudioHandler(props) {
  const {
    CaseId,
    ImageAvatar,
    NameRole,
    ActionType,
    handleNextQuestion,
    isLoadQuestionNext,
  } = props && props;
  //state
  const [isRecording, setIsRecording] = useState(false);
  const [isLoadingNextQuestion, setQuestionLoadNextQuestion] =
    useState(isLoadQuestionNext);

  //Check Loading Khi CLick Next
  useEffect(() => {
    setQuestionLoadNextQuestion(isLoadQuestionNext);
  }, [isLoadQuestionNext]);

  //ref
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const inputRef = useRef(null);
  const globalStreamRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);

  //Transcript
  const transcriptQueueRef = useRef([]);
  const [currentTranscript, setCurrentTranscript] = useState(null);

  // kiểm soát hiển thị nút Next
  const [hasStartedRecording, setHasStartedRecording] = useState(false);
  const [showBtnNextQuestion, setShowBtnNextQuestion] = useState(false);
  const [volumeLevels, setVolumeLevels] = useState([0, 0, 0, 0, 0]);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [loadingTranscript, setLoadingTranscript] = useState(false);

  //Question Reducer
  let TextQuestion = '';
  const questionData = useSelector((state) => state.question || {});
  let QuestionResponse = questionData?.data?.Question;
  let QuestionId = QuestionResponse?.Id;
  TextQuestion = QuestionResponse?.Prompt || QuestionResponse?.Explanation;

  // Reset the transcript to null when questionData changes
  useEffect(() => {
    setCurrentTranscript(null);
  }, [questionData]);

  //get timepoint to reducer
  const caseData = useSelector((state) => state.cases || []);
  let dataResponse = caseData?.data;
  let CurrentTimePointId = dataResponse?.Case?.Timeline?.CurrentTimePointId;
  const dispatch = useDispatch();

  //Socket
  const { initWebSocket, closeWebSocket, getWebSocket, isWebSocketActive } = useWebSocketManager();

  // Lấy dữ liệu từ Redux state với websocket connection tương ứng
  const instructorDataSocket = useSelector(
    (state) => state.dataConnectSocket?.instructorData || null
  );
  const virtualPatientDataSocket = useSelector(
    (state) => state.dataConnectSocket?.virtualPatientData || null
  );

  useEffect(() => {
    // Cleanup when the component is unmounted or modal is closed
    return () => {
      // Stop the recording and audio playback when the component is no longer rendered
      if (isRecording) {
        stopRecording(false);  // Don't save transcript in this case
      }
  
      // Close WebSocket if it's open
      closeWebSocket();
  
      // Stop all active media streams if needed
      if (globalStreamRef.current) {
        globalStreamRef.current.getTracks().forEach((track) => track.stop());
      }
  
      // Clear audio queue and reset states
      audioQueueRef.current = [];
      isPlayingRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  //Get Token
  const getToken = () => {
    const header = authHeader(); // Gọi hàm authHeader để lấy object
    if (!header || !header.Authorization) {
      console.error("Authorization header is missing");
      return null; // Trả về null nếu không có header
    }

    const parts = header.Authorization.split(" ");
    if (parts.length !== 2 || parts[0] !== "Token") {
      console.error("Authorization header is not in the expected format");
      return null; // Trả về null nếu không đúng định dạng
    }

    return parts[1]; // Trả về token nếu đúng định dạng
  };

  //state
  const [audioStarted, setAudioStarted] = useState(false);
  const [isLoadingWebSocket, setIsLoadingWebSocket] = useState(false);

  //check Domain
  const checkDomain = () => {
    const url = window.location.hostname; // Lấy domain hiện tại
    if (url.includes("app.med2lab.com")) return "pro";
    if (url.includes("app-qa.med2lab.com")) return "qa";
    if (url.includes("dev-us.med2lab.com")) return "dev";
    return "dev"; // Trường hợp không khớp với bất kỳ môi trường nào
  };

  // Khởi tạo WebSocket
  const handleInitWebSocket = async () => {
    // Bắt đầu kết nối WebSocket và cập nhật loading trạng thái
    setIsLoadingWebSocket(true);

    if (isWebSocketActive()) {
      console.log("⚠️ WebSocket is already open, skipping initialization.");
      return true; // WebSocket đang hoạt động, không cần mở lại
    }
    console.warn("⚠️ WebSocket is not active. Reinitializing...");

    try {
      const tokenUser = getToken();
      if (!tokenUser) {
        console.error("Token is missing or invalid");
        return false;
      }

      if (!CaseId) {
        console.error("CaseId is missing");
        return false;
      }

      // Xây dựng URL API với các tham số cần thiết
      let apiURL = `https://voice-chat-socket.med2lab.com/api/init-websocket?token=${tokenUser}&case_id=${CaseId}&timepoint_id=${CurrentTimePointId}&env=${checkDomain()}&action_type=${ActionType}`;
      if (QuestionId) apiURL += `&question_id=${QuestionId}`;

      const response = await axios.get(apiURL);
      if (response.status !== 200 || !response.data) {
        console.error("Invalid response from API");
        return false;
      }
      dispatch(saveDataResponseSocket(response.data, ActionType));

      // Đóng WebSocket hiện tại (nếu có)
      closeWebSocket();

      return new Promise((resolve) => {
        initWebSocket(
          "wss://voice-chat-socket.med2lab.com/chat-stream",
          handleWebSocketMessage,
          async (websocket) => {
            console.log("✅ WebSocket connection opened");

            // Gửi dữ liệu kết nối ngay sau khi WebSocket mở thành công
            const message = JSON.stringify({
              connection_data: response.data,
            });

            console.log("🚀 Sending connection data:", message);
            websocket.send(message);

            // Dừng loading khi WebSocket kết nối thành công
            setTimeout(() => {
              setIsLoadingWebSocket(false);
            }, 2000);

            resolve(true);
          },
          (event) => {
            console.warn("⚠️ WebSocket Closed:", event);
            setIsLoadingWebSocket(false); // Dừng loading khi WebSocket đóng
            resolve(false);
          },
          (error) => {
            console.error("❌ WebSocket Error:", error);
            setIsLoadingWebSocket(false); // Dừng loading khi gặp lỗi
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error("Failed to initialize WebSocket:", error);
      setIsLoadingWebSocket(false);
      return false;
    }
  };

  // Khi nhận được transcript mới từ WebSocket, đặt loadingTranscript = false
  const handleWebSocketMessage = (event) => {
    if (!event.data) {
      console.warn("⚠️ Empty WebSocket message received.");
      return;
    }
    try {
      if (event.data instanceof ArrayBuffer) {
        console.log("🔊 Received audio data (ArrayBuffer)");
        if (
          audioQueueRef.current.length === 0 ||
          audioQueueRef.current[audioQueueRef.current.length - 1] !== event.data
        ) {
          audioQueueRef.current.push(event.data);
        } else {
          console.warn("⚠️ Duplicate audio detected, skipping...");
        }

        if (!isPlayingRef.current) {
          playAudioQueue();
        }
      } else if (typeof event.data === "string") {
        const messageData = JSON.parse(event.data);

        if (messageData.type === "assistant_transcript") {
          transcriptQueueRef.current.push(messageData.text);

          //Delay để đảm bảo có transcript
          setTimeout(() => {
            console.log("📝 Received transcript:", messageData.text);
            setLoadingTranscript(false); // Đặt loadingTranscript thành false khi nhận được transcript mới
          }, 1500);
        }
      } else {
        console.warn("⚠️ Unknown WebSocket message type:", event.data);
      }
    } catch (error) {
      console.error("❌ Error parsing WebSocket message:", error);
    }
  };

  const initAudio = async () => {
    try {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) throw new Error("AudioContext not supported");
  
      audioContextRef.current = new AudioContext();
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: false,
      });
  
      globalStreamRef.current = stream;
      inputRef.current = audioContextRef.current.createMediaStreamSource(stream);
  
      // **Tạo GainNode để tăng cường âm thanh (khuếch đại)**
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 2; // Tăng gấp đôi âm lượng, có thể điều chỉnh giá trị này để tăng hoặc giảm
  
      // Kết nối GainNode với inputRef và analyser
      inputRef.current.connect(gainNode);
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 32;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
  
      gainNode.connect(analyser); // Kết nối gainNode với analyser
  
      const checkVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const volume = dataArray.reduce((a, b) => a + b, 0) / bufferLength;
        
        // **Cập nhật state volumeLevels với 5 giá trị ngẫu nhiên từ volume**
        setVolumeLevels([
          volume * Math.random() * 0.8,
          volume * Math.random(),
          volume * Math.random() * 1.2,
          volume * Math.random(),
          volume * Math.random() * 0.6,
        ]);
  
        requestAnimationFrame(checkVolume); // Lặp liên tục
      };
      checkVolume();
  
      // Tạo bộ xử lý âm thanh để gửi dữ liệu đến WebSocket
      processorRef.current = audioContextRef.current.createScriptProcessor(
        4096,
        1,
        1
      );
      processorRef.current.onaudioprocess = (e) => {
        // Nếu âm thanh từ server đang phát, không gửi dữ liệu microphone
        if (isPlayingRef.current) {
          console.log("⏸️  Đang phát âm thanh từ server, không gửi microphone.");
          return;
        }
      
        const inputData = e.inputBuffer.getChannelData(0);
        const downsampledBuffer = downsampleBuffer(
          inputData,
          audioContextRef.current.sampleRate,
          16000
        );
      
        // Kiểm tra WebSocket trước khi gửi
        const websocket = getWebSocket();
        if (!websocket || websocket.readyState !== WebSocket.OPEN) return;
      
        if (downsampledBuffer) {
          websocket.send(downsampledBuffer);
        }
      };      
  
      gainNode.connect(processorRef.current); // Kết nối GainNode với processorRef
      processorRef.current.connect(audioContextRef.current.destination);
    } catch (error) {
      console.error("Error initializing audio: ", error);
      stopRecording();
    }
  };  

  const downsampleBuffer = (buffer, sampleRate, outSampleRate) => {
    if (outSampleRate >= sampleRate) {
      console.warn("Downsampling rate must be lower than original sample rate");
      return null;
    }
    const sampleRateRatio = sampleRate / outSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;

    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0,
        count = 0;
      for (
        let i = offsetBuffer;
        i < nextOffsetBuffer && i < buffer.length;
        i++
      ) {
        accum += buffer[i];
        count++;
      }
      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }

    return result.buffer;
  };

  // Khi bắt đầu phát audio, đặt loadingTranscript = true
  const playAudioQueue = async () => {
    if (isPlayingRef.current) return; // Ngăn chặn chạy song song
    isPlayingRef.current = true;
    setAudioStarted(true);
    setIsAudioPlaying(true); // Audio đã bắt đầu phát
    setLoadingTranscript(true); // Đặt trạng thái loadingTranscript thành true
    try {
      while (audioQueueRef.current.length > 0) {
        console.log(`🎧 Queue Length Before: ${audioQueueRef.current.length}`);

        // Lấy phần tử đầu tiên trong hàng đợi
        const arrayBuffer = audioQueueRef.current.shift();
        if (!arrayBuffer) continue;

        const audioBuffer = new Int16Array(arrayBuffer);
        const float32Buffer = new Float32Array(audioBuffer.length);

        for (let i = 0; i < audioBuffer.length; i++) {
          float32Buffer[i] = audioBuffer[i] / 0x7fff;
        }

        const buffer = audioContextRef.current.createBuffer(
          1,
          float32Buffer.length,
          24000
        );
        buffer.getChannelData(0).set(float32Buffer);

        const source = audioContextRef.current.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContextRef.current.destination);

        // Hiển thị transcript ngay trước khi phát audio
        if (transcriptQueueRef.current.length > 0) {
          const nextTranscript = transcriptQueueRef.current.shift();
          setCurrentTranscript(nextTranscript);
        }

        await new Promise((resolve) => {
          source.onended = () => {
            console.log("✅ Audio finished, playing next...");
            resolve();
          };
          source.start();
        });

        console.log(`🎧 Queue Length After: ${audioQueueRef.current.length}`);
      }
    } catch (error) {
      console.error("❌ Error during audio playback:", error);
    } finally {
      isPlayingRef.current = false;
      setAudioStarted(false);
      setIsAudioPlaying(false); // Âm thanh đã phát xong
      setLoadingTranscript(false); // Đặt trạng thái loadingTranscript thành false khi audio phát xong
    }
  };

  // Start recording
  const startRecording = async () => {
    if (isRecording) return;
    setIsRecording(true);
    setShowBtnNextQuestion(false);

    // Khi ghi âm lần đầu tiên, hiển thị nút Next
    if (!hasStartedRecording) {
      setHasStartedRecording(true);
    }

    console.log("🔍 Checking WebSocket status...");

    if (!isWebSocketActive()) {
      console.log("⚠️ WebSocket is not active. Reinitializing...");
      const isWebSocketReady = await handleInitWebSocket();

      if (!isWebSocketReady || !isWebSocketActive()) {
        console.error(
          "❌ WebSocket failed to connect. Cannot start audio recording."
        );
        setIsRecording(false);
        return;
      }
    }

    console.log("✅ WebSocket is active. Starting audio...");
    initAudio();
  };

  //HandleSaveTransription
  const handleSaveStranscript = async (completeQuestion = false) => {
    try {
      if (instructorDataSocket || virtualPatientDataSocket) {
        if (ActionType === "virtual_patient" && virtualPatientDataSocket) {
          return await dispatch(
            saveTranscriptRecord(virtualPatientDataSocket, completeQuestion)
          );
        } else if (ActionType === "instructor" && instructorDataSocket) {
          return await dispatch(
            saveTranscriptRecord(instructorDataSocket, completeQuestion)
          );
        } else {
          console.warn("⚠️ Unknown ActionType or missing data:", ActionType);
          return Promise.reject("Unknown ActionType");
        }
      }
      return Promise.reject("No data to save");
    } catch (error) {
      console.error("❌ Error saving transcript:", error);
      throw error; // Đẩy lỗi ra ngoài nếu cần xử lý
    }
  };

  const stopRecording = async (shouldSaveTranscript = true) => {
    if (!isRecording) return;

    console.log("🛑 Stopping recording and closing WebSocket...");

    // Reset Animation
    setIsRecording(false);
    setAudioStarted(false);

    if (processorRef.current) processorRef.current.disconnect();
    if (inputRef.current) inputRef.current.disconnect();
    if (audioContextRef.current) {
      if (audioContextRef.current.state === "running") {
        await audioContextRef.current.suspend();
        await audioContextRef.current.close();
      } else if (audioContextRef.current.state !== "closed") {
        await audioContextRef.current.close();
      }
    }

    // console.log("🛑 Closing WebSocket...");
    // closeWebSocket(); // Đóng WebSocket
    // Nếu âm thanh từ server đang phát, không gửi dữ liệu microphone
    if (isPlayingRef.current) {
      console.log("⏸️  Đang phát âm thanh từ server, không gửi microphone.");
      return;
    }

    if (globalStreamRef.current) {
      globalStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    audioQueueRef.current = [];
    isPlayingRef.current = false;

    // Nếu tham số shouldSaveTranscript là true, thì gọi handleSaveStranscript
    if (shouldSaveTranscript) {
      await handleSaveStranscript();
    }
  };

  //Handle Click Next Question
  const handleNextQuestionClick = async () => {
    setQuestionLoadNextQuestion(true); // Bật trạng thái loading

    try {
      // Gửi transcript với complete_question = true
      const transcriptSaved = await handleSaveStranscript(true);
      console.log(
        "✅ Transcript saved with complete_question=true:",
        transcriptSaved
      );
      
      // Đóng WebSocket
      stopRecording(false);  // Không gọi handleSaveStranscript nua

      // Chuyển sang câu hỏi tiếp theo
      handleNextQuestion();
    } catch (error) {
      console.error("❌ Error in handleNextQuestionClick:", error);
    } finally {
      setQuestionLoadNextQuestion(false); // Tắt trạng thái loading
    }
  };

  //Handle Pause/Resume Stream
  const handlePauseStream = () => {
    // Đóng WebSocket
    stopRecording();
    setShowBtnNextQuestion(true);
  };

  const LabelActionType = ActionType === "virtual_patient" ? "Patient" : "Instructor";
  const AssistantText = currentTranscript || TextQuestion;
  // console.log(streamResponse, "streamResponse");
  return (
    <FrameChatFullBox
      className="frameChat__fullBox"
      data-type="patient-streaming-voice"
    >
      <div className="h-100 w-100 text-center d-flex flex-column justify-content-between">
        <ImageLabelWrapper>
          <BlinkingDiv audioStarted={audioStarted}>
            <ImageBox>
              <StyledImage
                src={ImageAvatar}
                alt={LabelActionType}
                className={`img-fluid`}
              />
            </ImageBox>
          </BlinkingDiv>
          <PatientLabel>{NameRole || LabelActionType}</PatientLabel>
        </ImageLabelWrapper>
        {loadingTranscript ? (
          <BeatLoader loading={true} color={'#fff'} size={15} css="display: block; margin: 0 auto;" />
        ) : (
          AssistantText && <ResponseText
            className="scrollbarStyle-small"
            isInstructor={ActionType === "instructor"}
            dangerouslySetInnerHTML={{ __html: AssistantText }}
          />
        )}
        <div>
          {/* Hiển thị RecordingButton riêng nếu ActionType là "virtual_patient" */}
          {ActionType === "virtual_patient" && (
            <RecordingButton
              isRecording={isRecording}
              startRecording={startRecording}
              volumeLevels={volumeLevels}
              isLoadingWebSocket={isLoadingWebSocket}
              handlePauseStream={handlePauseStream}
              ActionType={ActionType}
              isAudioPlaying={isAudioPlaying}
            />
          )}
          {/* Kiểm tra nếu có QuestionResponse */}
          {QuestionResponse !== null && (
            <ControlsContainer>
              {/* Hiển thị RecordingButton nếu không phải "virtual_patient" */}
              {!questionData?.isLoading && ActionType !== "virtual_patient" && (
                <RecordingButton
                  isRecording={isRecording}
                  startRecording={startRecording}
                  volumeLevels={volumeLevels}
                  isLoadingWebSocket={isLoadingWebSocket}
                  handlePauseStream={handlePauseStream}
                  ActionType={ActionType}
                  isAudioPlaying={isAudioPlaying}
                />
              )}
              {/* Nếu là "instructor" và đã bắt đầu ghi âm */}
              {ActionType === "instructor" && showBtnNextQuestion && !questionData?.isLoading && (
                <NextQuestionButton
                  showNextQuestion={showBtnNextQuestion}
                  isLoadingNextQuestion={isLoadingNextQuestion}
                  handleNextQuestionClick={handleNextQuestionClick}
                />
              )}
            </ControlsContainer>
          )}
        </div>
      </div>
    </FrameChatFullBox>
  );
}
