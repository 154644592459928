import React, { useState } from 'react'
import ListReport from './ListReport'
import ReportDetail from './ReportDetail'

const ReportByLearner = (props) => {
  const [showDetail, setShowDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)

  return (
    <div>
      {!showDetail ? (
        <ListReport setShowDetail={setShowDetail} setDataDetail={setDataDetail}/>
      ) : (
        <ReportDetail dataDetail={dataDetail} setShowDetail={setShowDetail}/>
      )}
    </div>
  )
}

export default ReportByLearner
