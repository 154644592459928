import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";

function ModalViewMedia({ isOpen, toggle, values }) {
  const mediaType = values?.Type?.toLowerCase();
  const mediaUrl = values?.URL;

  const isMatch = (url, types) =>
    new RegExp(`\\.(${types.join("|")})$`, "i").test(url);

  const renderMediaContent = () => {
    if (!mediaUrl) return <p>No media available.</p>;

    // Check for image types
    if (
      mediaType === "image" &&
      isMatch(mediaUrl, ["jpg", "jpeg", "png", "gif", "bmp", "svg"])
    ) {
      return (
        <div
          style={{
            width: "100%",
            height: "500px",
          }}
        >
          <img
            className="img-fluid"
            src={mediaUrl}
            alt="Media Content"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      );
    }

    // Check for audio types
    if (
      mediaType === "audio" &&
      isMatch(mediaUrl, ["mp3", "wav", "ogg", "flac"])
    ) {
      return (
        <div style={{ maxWidth: "500px", margin: "0 auto" }}>
          <ReactPlayer
            url={mediaUrl}
            width="100%"
            height="200px"
            controls
            config={{ file: { forceAudio: true } }}
          />
        </div>
      );
    }

    // Check for video types
    if (mediaType === "video") {
      const isYouTube = /(?:youtube\.com|youtu\.be)\//i.test(mediaUrl); // Check if the URL is a YouTube link

      if (isYouTube) {
        return (
          <ReactPlayer
            url={mediaUrl}
            width="100%"
            height="500px"
            controls
            loop
          />
        );
      }

      // If not YouTube, check for other video formats
      if (isMatch(mediaUrl, ["mp4", "webm", "ogg"])) {
        return (
          <ReactPlayer
            url={mediaUrl}
            width="100%"
            height="300px"
            controls
            loop
          />
        );
      }
    }

    return <p>Unsupported media type.</p>;
  };

  const modalSize = mediaType === "audio" ? "sm" : "lg";
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      zIndex={16000}
      size={modalSize}
      centered
      style={{ maxWidth: mediaType === "audio" ? "50%" : "80%" }}
    >
      <ModalBody>
        <div className="py-4">{renderMediaContent()}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalViewMedia;