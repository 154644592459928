import React, { useState } from "react";
import styled from "styled-components";
import imgUser from "@images/common/placehoder-avatar.svg";
import { SwitchItem } from "@ui-partents/SwitchButton";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { actSaveTaskActive } from "@actions";

const OptionItems = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props?.isActive === "Active" ? "fff" : "#f7f8f9"};
  .item__top {
    width: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border: 1px solid #d0d5dd;
    border-radius: 16px 16px 0 0;
    cursor: ${(props) => props?.isActive === "Active" ? "pointer" : "default"};
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0%;
      color: ${(props) => props?.isActive === "Active" ? "#1D2939" : "#667085"};
    }
    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .item__bottom {
    width: 100%;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d0d5dd;
    border-top: 0;
    border-radius: 0 0 16px 16px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #081b2a;
    }
  }
  &:hover {
    .item__top,
    .item__bottom {
      border-color: ${(props) => props?.isActive === "Active" ? "#0089C2;" : "#d0d5dd"};
    }
  }
`;

export default function VirtualSwitch(props) {
  const { timepointIdActive, id, dataList, setDataList, dataVirtual, setDataType } = props;
  let dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false)

  const handleApiUpdateTaskActive = async (dataSwitch) => {
    setLoading(true);
    const mode = dataSwitch.isActive === "Active" ? "deactivate" : "activate";
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timepointIdActive,
      Flow: {
        Mode: mode,
        Task: "chat"
      },
    };

    try {
      const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
      const data = res?.data;

      if (res?.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_SUCCESS,
          payload: data,
        });
        setLoading(false);
        const itemSwitch = dataList?.map((item) => {
          if (item?.type === dataSwitch?.type) {
            return {
              ...item,
              isActive: res?.data?.TaskElement?.Status,
            };
          }
          return item;
        });
        setDataList(itemSwitch);
      } else {
        dispatch({
          type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_FAILURE,
          error: "API call failed",
        });
        setLoading(false);
        throw new Error("API call failed");
      }
    } catch (error) {
      dispatch({
        type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_FAILURE,
        error: error.message || "An error occurred while calling API",
      });
      setLoading(false);
      throw new Error(error.message || "An error occurred while calling API");
    }
  };

  const handleSelect = (virtual) => {
    if (virtual?.type === "instructor") {
      dispatch(actSaveTaskActive("description"));
    }
    setDataType(virtual)
  }

  return (
    <OptionItems isActive={dataVirtual?.isActive}>
      <div className="item__top" onClick={() => dataVirtual?.isActive === "Active" ? handleSelect(dataVirtual) : null}>
        <div className="item__top-img">
          <img src={dataVirtual?.imgUrl || imgUser} alt="img" />
        </div>
        <p>{dataVirtual?.name}</p>
      </div>
      <div className="item__bottom">
        <p>{loading ? "Processing..." :dataVirtual?.isActive === "Active" ? dataVirtual?.description_active : dataVirtual?.description_inactive}</p>
        {loading ? (
          <ClipLoader color="#0089c3" size={24} />
        ) : (
          dataVirtual?.type === "patient" && (
            <div>
              <SwitchItem
                label={``}
                name={dataVirtual?.name}
                noPadding={true}
                handleChangeSwitch={() => {
                  if (dataVirtual?.type === "patient") {
                    handleApiUpdateTaskActive(dataVirtual)
                  }
                }}
                value={dataVirtual?.isActive === "Active"}
                disabled={loading}
              />
            </div>
          )
        )}
      </div>
    </OptionItems>
  )
}
