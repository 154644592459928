import React from "react";
import { Iconsax } from "@components-common";

function ButtonSend({ handleSubmit, disabled, IsloadingSubmitAnswer }) {
    return (
        <button
            type="button"
            className="btn-send"
            onClick={handleSubmit}
            disabled={disabled}
        >
            <span className="mr-2 text-nowrap">Send</span>
            {IsloadingSubmitAnswer ? (
                <span className="spinner-border spinner-border-sm mr-2"></span>
            ) : (
                <Iconsax iconName="send" fill="#fff" />
            )}

        </button>
    );
}

export default ButtonSend;