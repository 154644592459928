//Components Chat Socket
import { WebSocketAudioHandler } from "../WebSocket"

//react-router-dom
import { useParams } from 'react-router-dom';

//imgPerson
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

// react-redux
import { useSelector } from "react-redux";

const StreamingComponent = (props) => {
    let { id } = useParams();

    // Case Data
    const caseData = useSelector((state) => state?.cases || []);
    const caseResponse = caseData?.data?.Case;

    // Characters
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const InstructorCharacter = Instructor?.Character;
    const preceptorNameText = Instructor?.DisplayName;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    return (
        <WebSocketAudioHandler
            {...props}
            CaseId={id}
            ImageAvatar={PreceptorAvatar}
            NameRole={preceptorNameText}
            ActionType={'instructor'}
        />
    );
};

export default StreamingComponent;
