import React, { useEffect, useState, useRef } from "react";
import { ChatWrapUpContent, ChatWrapUpFullScreen, ChatWrapUpHeader } from "../ChatWrapUp";

//partents
import { ModalAssistantCommon } from '@ui-partents/Modal';

// Responsive
import { useMediaQuery } from "react-responsive";

// Utils
import { apiCaller } from "@utils";

// React-router-dom
import { useParams } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Image
import ImageAssistant from '@images/learnerv2/doctor-2.png';

function ModalChatWrapUp(props) {
    const { isOpen } = props;

    // Get the 'id' parameter from the URL
    let { id } = useParams();

    // State
    const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
    const [feedbackHeight, setFeedbackHeight] = useState(null);
    const chatWrapUpBodyRef = useRef(null);

    // State variables for holding chat wrap-up data and loading state
    const [feedbacksList, setFeedbackList] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // Props
    const { handleContinueCase } = props;

    // Effect hook to fetch chat wrap-up data when 'isOpen' changes
    useEffect(() => {
        // Function to fetch chat wrap-up data
        const fetchData = () => {
            setIsLoading(true);

            // Define API request parameters
            const params = {
                "Action": "GetWrapUpFeedback"
            };
            // Make API call to fetch chat wrap-up data
            apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                // If API call is successful
                if (res?.status === 200) {
                    setIsLoading(false);
                    setFeedbackList(data); // Set chat wrap-up data
                } else {
                    console.log("error...."); // Log error if API call fails
                    setIsLoading(false); // Set loading state to false
                }
            });
        };

        // Only fetch data if component is open
        if (isOpen) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // Handle Expand
    const handleExpandChatWrapUp = () => {
        setIsFullScreenOpen(!isFullScreenOpen);
    };

    // Load height
    useEffect(() => {
        if (chatWrapUpBodyRef.current) {
            const chatWrapUpBodyHeight = chatWrapUpBodyRef.current.offsetHeight;
            const wrapUpFeedbackCtHeight = chatWrapUpBodyHeight - 40;
            setFeedbackHeight(wrapUpFeedbackCtHeight);
        }
    }, []);

    // Detect if the screen is mobile
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    // Define the reusable className variable
    const chatWrapUpClassName = isMobile && isOpen ? 'chat-wrapup-mobile' : '';
    
    // Characters - Case
    // ----------------------------------------------------------------------
    const caseData = useSelector((state) => state.cases || []);
    let caseResponse = caseData?.data;
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const InstructorCharacter = Instructor?.Character;
    const PreceptorAvatar = InstructorCharacter?.StandardPhoto || ImageAssistant;
    return (
        <>
            <ModalAssistantCommon
                {...props}
                chatWrapUpBodyRef={chatWrapUpBodyRef}
                ImageAssistant={PreceptorAvatar}
                className={chatWrapUpClassName}
            >
                <div className={`${chatWrapUpClassName}`}>
                    <ChatWrapUpHeader {...props} />
                    <ChatWrapUpContent
                        {...props}
                        isStatus={isFullScreenOpen}
                        handleExpandChatWrapUp={handleExpandChatWrapUp}
                        feedbackHeight={feedbackHeight}
                        feedbacksList={feedbacksList}
                        isLoading={isLoading}
                    />
                </div>
            </ModalAssistantCommon>
            {isFullScreenOpen &&
                <ChatWrapUpFullScreen
                    {...props}
                    isOpen={isFullScreenOpen}
                    className={chatWrapUpClassName}
                >
                    <ChatWrapUpHeader {...props} />
                    <ChatWrapUpContent
                        {...props}
                        isStatus={isFullScreenOpen}
                        handleExpandChatWrapUp={handleExpandChatWrapUp}
                        feedbackHeight={feedbackHeight}
                        feedbacksList={feedbacksList}
                        isLoading={isLoading}
                        handleContinueCase={() => {
                            handleContinueCase();
                            setIsFullScreenOpen(false);
                        }}
                    />
                </ChatWrapUpFullScreen>
            }
        </>
    );
}

export default ModalChatWrapUp;