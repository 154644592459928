import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PulseButtonAnimate } from '../ChatVoice';
import { useSelector } from "react-redux";
import { MicrophoneMessage } from "@ui-partents/Message";
import imgMicError from "@images/access-mic/error-mic.svg";

//common
import { useWhisperSTT } from "@components-common/Voice";

//EndChatMessage
import { EndChatMessage } from '../ChatVoice';

//redux
import { useDispatch } from "react-redux";

//actions
import { atcSetChatLock } from "@actions";

const WhisperSTTComponent = (props) => {
    const { handleSubmitVoice, statusGenerateAudio, isCurrentTask, handleExamMenu } = props && props;
    const { isRecording, startRecording, stopRecording } = useWhisperSTT();
    const dispatch = useDispatch();

    // Check Lock Elements
    const isChatPending = useSelector((state) => state.isChatPending || {});
    const isLock = isChatPending?.isLock;

    // Check Chat Lock from Reducer
    const statusLock = useSelector((state) => state.isStatusChatLock?.isLock || false);

    //Case
    const caseStore = useSelector((state) => state.cases || []);
    const responseCaseData = caseStore?.data?.Case;
    const CurrentTimePointId = responseCaseData?.Timeline?.CurrentTimePointId;
    const ChatLocked = responseCaseData?.ChatSettings?.ChatLocked;

    const [permissionGranted, setPermissionGranted] = useState(false);
    const [modalState, setModalState] = useState({
        notFound: false
    });
    const [isEndChat, setIsEndChat] = useState(false);
    const [isLoadingTTS, setLoadingTTS] = useState(false);

    //Load Permission
    useEffect(() => {
        requestPermission();
    }, []);

    //Check Logic Show Message for End Chat
    useEffect(() => {
        if ((isEndChat && statusGenerateAudio === 'ended') || ChatLocked) {
            dispatch(atcSetChatLock(true));
        }
    }, [isEndChat, statusGenerateAudio, ChatLocked, dispatch]);

    const mainRef = useRef(null);
    const audioVisualizerRef = useRef(null);

    //Check xem có Mode là EndChat không ?
    const checkEndChat = (response) => {
        const responseDataSubmit = response?.data;
        console.log(responseDataSubmit, "responseDataSubmit");
        const endChatNow = responseDataSubmit?.EndChatNow?.Mode === "EndChat";
        if (endChatNow) {
            setIsEndChat(true);
        }
    };

    // Kết thúc việc submit
    const handleTranscriptionFinish = async (text) => {
        setLoadingTTS(true);
        const params = {
            "Action": "Submit",
            "Message": text,
            "withVoice": true,
            "TimePointId": CurrentTimePointId
        };
        const response = await handleSubmitVoice(params);
        if(response.status === 200) {
            setLoadingTTS(false);
            checkEndChat(response);
        }
    };

    const requestPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop());
            setPermissionGranted(true);
            setModalState({ notFound: false });
        } catch (error) {
            console.error('Error accessing the microphone:', error);
            if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
                setModalState({ notFound: true });
            } else {
                setModalState({ notFound: false });
            }
        }
    };

    const handleButtonClick = async () => {
        if (!permissionGranted) {
            await requestPermission();
        }
        if (permissionGranted) {
            if (isRecording) {
                const transcribedText = await stopRecording();
                handleTranscriptionFinish(transcribedText);
                if (audioVisualizerRef.current) {
                    audioVisualizerRef.current.stop();
                }
            } else {
                startRecording();
                initAudioVisualizer();
            }
        }
    };

    const initAudioVisualizer = () => {
        const visualMainElement = mainRef.current;
        const visualValueCount = 16;
        let visualElements;

        const createDOMElements = () => {
            for (let i = 0; i < visualValueCount; ++i) {
                const elm = document.createElement('div');
                elm.style.background = `linear-gradient(90deg, #00EBFC ${(i / visualValueCount) * 100}%, #003EFF 100%)`;
                visualMainElement.appendChild(elm);
            }
            visualElements = visualMainElement.querySelectorAll('div');
        };

        const init = () => {
            const audioContext = new AudioContext();
            visualMainElement.innerHTML = '';
            createDOMElements();

            const dataMap = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
            const processFrame = (data) => {
                const values = Object.values(data);
                for (let i = 0; i < visualValueCount; ++i) {
                    const value = values[dataMap[i]] / 255;
                    const elmStyles = visualElements[i].style;
                    elmStyles.transform = `scaleY(${value})`;
                    elmStyles.opacity = Math.max(.25, value);
                }
            };

            audioVisualizerRef.current = new AudioVisualizer(audioContext, processFrame);
        };

        init();
    };

    // Close Modal
    const closeModal = (type) => {
        setModalState((prevState) => ({
            ...prevState,
            [type]: false,
        }));
    };

    // Handle Close Chat
    const handleSubmitClose = () => {
        // Prevent clicking if isLock is true
        if (isLock) return;

        //Props được truyền từ component StandardScreenMain
        handleExamMenu(isCurrentTask, ['closeTask', 'chatFullFixed']);
    }

    return (
        <React.Fragment>
            {statusLock ? (
                <EndChatMessage
                    {...props}
                    handleSubmitClose={handleSubmitClose}
                />
            ) : (
                statusGenerateAudio !== "playing" &&
                    <>
                        <MicrophoneMessage
                            isOpen={modalState.notFound}
                            onClose={() => closeModal("notFound")}
                            title="Microphone Access Error"
                            content="It seems there was an issue enabling your microphone. Please check your device settings to ensure your microphone is properly connected and that permissions are granted."
                            icon={imgMicError}
                            buttonGotit={true}
                        />
                        <WrapperDiv>
                            <StyledMain ref={mainRef} className={`${isRecording ? 'd-block' : 'd-none'}`}></StyledMain>
                            <PulseButtonAnimate
                                {...props}
                                onButtonClick={handleButtonClick}
                                disabled={!permissionGranted}
                                isRecording={isRecording}
                                isLoadingTTS={isLoadingTTS}
                            />
                            <Text>{isRecording && 'Listening.... Press to Stop and Send'}</Text>
                        </WrapperDiv>
                    </>
                
            )}
        </React.Fragment>
    );
};

class AudioVisualizer {
    constructor(audioContext, processFrame) {
        this.audioContext = audioContext;
        this.processFrame = processFrame;
        this.connectStream = this.connectStream.bind(this);
        this.rafId = null;
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(this.connectStream)
            .catch((error) => {
                console.error('Microphone access error:', error);
            });
    }

    connectStream(stream) {
        this.analyser = this.audioContext.createAnalyser();
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.analyser.smoothingTimeConstant = 0.5;
        this.analyser.fftSize = 32;
        this.initRenderLoop();
    }

    initRenderLoop() {
        const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        const renderFrame = () => {
            this.analyser.getByteFrequencyData(frequencyData);
            this.processFrame(frequencyData);
            this.rafId = requestAnimationFrame(renderFrame);
        };
        this.rafId = requestAnimationFrame(renderFrame);
    }

    stop() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
    }
}

const StyledMain = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: inline-block;
    width: 3px;
    height: 44px;
    margin: 0 7px;
    transform: scaleY(.5);
    opacity: .25;
  }
`;

const WrapperDiv = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-direction: column;
  margin-bottom: 52px;
  margin-top: 20px;
  @media (max-width: 768px) {
    bottom: 15px;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export default WhisperSTTComponent;