import { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { Iconsax } from "@components-common";
import WaveformIcon from "@images/chatvoice/waveform-playing.svg";
import CheckedSpeed from "@images/chatvoice/check-speed.svg";

const Container = styled.div`
  position: absolute;
  bottom: 18px;
  right: 18px;
  z-index: 1;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  background: #8d8d8d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 75px;
  position: relative;
  height: 32px;
`;

const DropdownMenu = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  width: 239px;
  border-radius: var(--radius-component-radius-button, 8px);
  border: 1px solid #eaecf0;
  background: var(--Background-bg-base, #f9fafb);
  box-shadow: 0px 5px 32px -4px rgba(24, 39, 75, 0.06),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  z-index: 1000;
  padding: 6px;
`;

const DropdownItem = styled.button.attrs(({ selected }) => ({
  "data-selected": selected,
}))`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 8px;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: #f7f8f9;
  }

  &[data-selected="true"] {
    background: #f9fafb;
    border-radius: 8px;
  }
`;

const TooltipStyled = styled(ReactTooltip)`
  transition: opacity 0.2s ease-in-out;
  border-radius: 8px !important;
  background: #121926 !important;
  color: white !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  &:after {
    background-color: #121926 !important;
  }
`;

const InfoBox = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #ebf9ff;
  margin-bottom: 8px;
`;

const InfoText = styled.span`
  color: #101828;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
`;

const InfoIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 31px;
  background: #0089c2;
`;

const speedOptions = [0.5, 0.75, 1, 1.2, 1.5, 2.0];

export default function SpeedSelector({ selectedSpeed, setSelectedSpeed }) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Xử lý đóng dropdown khi click ra ngoài
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container ref={dropdownRef}>
      <DropdownContainer>
        <DropdownToggle
          id="dropdown-toggle"
          data-tip="Voice Speed"
          data-for="voice-speed-tooltip"
          onClick={() => setOpen((prev) => !prev)}
        >
          {selectedSpeed}x
          <Iconsax
            iconName={open ? "chevron-up" : "chevron-down"}
            fill="#fff"
            size={20}
          />
        </DropdownToggle>

        {/* Tooltip hiển thị khi hover */}
        <TooltipStyled
          id="voice-speed-tooltip"
          place="top"
          effect="solid"
        />

        <DropdownMenu open={open}>
          <InfoBox>
            <InfoIcon>
              <img src={WaveformIcon} alt="waveform" />
            </InfoIcon>
            <InfoText>
              The selected speed will apply to the next response.
            </InfoText>
          </InfoBox>
          {speedOptions.map((speed) => (
            <DropdownItem
              key={speed}
              selected={selectedSpeed === speed ? "true" : "false"}
              onClick={() => {
                setSelectedSpeed(speed);
                setOpen(false);
              }}
            >
              {speed}x
              {selectedSpeed === speed && (
                <img src={CheckedSpeed} alt="Checked" />
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </DropdownContainer>
    </Container>
  );
}