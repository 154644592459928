import React from "react";
import Skeleton from 'react-loading-skeleton';
import { ListQuestionForTask } from "../CaseTimeline";

const SelectedTasksList = (props) => {
  const { selectedTasksList = [], handleUpdateTaskActive, isLoading } = props;

  // List of ViewClasses to hide
  const hiddenViewClasses = ["io", "orders", "pn", "documentation", "actions"];

  // If isLoading is true, display skeleton loading
  if (isLoading) {
    return (
      <ul className="selected-tasks-list mt-3">
        {[1, 2, 3].map((_, index) => (
          <li key={index} className="selected-task-item">
            <Skeleton height={50} />
          </li>
        ))}
      </ul>
    );
  }
  
  // Filter out tasks with hidden ViewClasses
  const filteredTasks = [{
    Label: "Profile Image",
    Status: "Active",
    ViewClass: "profile_image"
  }, {
    Label: "Virtual Preceptor",
    Status: "Active",
    ViewClass: "virtual_preceptor"
  }, ...selectedTasksList].filter(task => !hiddenViewClasses.includes(task.ViewClass));

  return (
    <ul className="selected-tasks-list mt-3">
      {filteredTasks.map((selectedTask, index) => (
        <ListQuestionForTask
          {...props}
          key={index} // Ideally, use a unique identifier here if available
          indexItem={index}
          task={selectedTask}
          handleUpdateTaskActive={handleUpdateTaskActive}
        />
      ))}
    </ul>
  );
};

export default SelectedTasksList;