import React from 'react';
import { sortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { actSortDataCase } from "@actions";
import _ from "lodash";
import { useDispatch } from "react-redux";
import imgOlder from '@images/teachV2/img-older.png';
import ReactTooltip from 'react-tooltip';
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { ButtonComponent } from "@components/patterns/elements/Button";
import { Icon } from '@components/common'
import Edit from '@images/teachV2/edit.svg'
import Plus from '@images/common/plus-single.svg'
import { Iconsax } from "@components-common";
import { CloseIcon } from "@components/teachV2/Manage/Cohorts";
import { MessageEmtyData } from "@ui-partents/Message";
import ClipLoader from "react-spinners/ClipLoader";
import { apiCaller } from "@utils";
import { seriesConstants } from "@constants";

const WrapContentCase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .content__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-name {
      color: #081B2A;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
    &-action {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .action-btn {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

const WrapListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 6px
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b7b7b7;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #929292;
  }
`

const WrapItemSort = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;  
  position: relative;
  width: 100%;
  cursor: pointer;
  .drag-question-icon {
    position: absolute;
    left: -12px;    
    visibility: hidden;
  }
  &:hover {
    .drag-question-icon {
      visibility: visible;
    } 
  }
`

const SortableItem = SortableElement(({ data, history, titleSeries, handleRemove }) => (
    <WrapItemSort key={data?.Id}>
      <div className="drag-question-icon">
        <Iconsax iconName={`drag-drop`} fill="#939393" />
      </div>
      <div className="interactiveItem w-100">
        <div className="interactiveItem__info">
          <div className='interactiveItem__info-wrap'>
            <img src={data?.Thumbnail || imgOlder} alt="older" />
            <div className="info-wrap">
              <p className="info-title">{data?.Title} | #{data?.Id}</p>
              <span className="info-status">{data?.Status}</span>
            </div>
          </div>
          <div className="interactiveItem__info-action">
            <div data-tip data-for={`edit-${data?.Id}`} onClick={() => history.push(`/teach/module/storyboard/${data?.Id}`)}>
              <Icon src={Edit} width={20} height={20} stroke="#98A2B3"/>
            </div>
            <ReactTooltip id={`edit-${data?.Id}`} place='top' effect='solid' className={`custom-tooltip`} >
              <span>Edit Module</span>
            </ReactTooltip>
            <WrapModalDeleteCommon
              title={`Remove Module`}
              sub={`Are you sure you want to remove this module from ${titleSeries}. The module will NOT be deleted. However, learners who are assigned this series will no longer have access to this module.`}
              handleRemove={handleRemove}
              idRemove={data?.Id}
            >
              {({onOpen}) => (
                <>
                  <div className="d-flex justify-content-center align-items-center" data-tip data-for={`remove-${data?.Id}`} onClick={onOpen}>
                    <CloseIcon />
                  </div>
                  <ReactTooltip id={`remove-${data?.Id}`} place='top' effect='solid' className={`custom-tooltip`} >
                    <span>Remove Module</span>
                  </ReactTooltip>
                </>
              )}
            </WrapModalDeleteCommon>
          </div>
        </div>
      </div>
    </WrapItemSort>
  ));

const InteractiveSort = (props) => {
  const { dataCase, activeSeries, titleSeries, setCase, toggle, loadingModule } = props && props;
  console.log(loadingModule);
  const dispatch = useDispatch();
  const history = useHistory()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newArrSort = arrayMove(dataCase, oldIndex, newIndex);
    setCase(newArrSort);

    //call api
    const result = _.map(newArrSort, function square(item) {
      return { Id: item?.Id }
    })
    const newParams = {
      Action: "Update-CasesOrder",
      Cases: result,
    };
    dispatch(actSortDataCase(activeSeries, newParams));
  };

  const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const handleRemove = async (caseId) => {
    const params = {
      Action: "RemoveCase",
      CaseId: caseId,
    };
    apiCaller(`/api/teach/series/${activeSeries}/`, 'PUT', params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: seriesConstants.DELETE_CASE_FROM_SERIES_SUCCESS,
          payload: data,
          seriesId: activeSeries
        });

        //update data and close
        setTimeout(() => {
          setCase(data?.Cases);
        }, 2000);
      } else {
        dispatch({
          type: seriesConstants.DELETE_CASE_FROM_SERIES_FAILURE,
          error: "error",
        });
      }
    });
  };

  return (
    <WrapContentCase>
      <div className='content__title'>
        <p className='content__title-name'>{titleSeries}</p>
        <div className='content__title-action'>
          <ButtonComponent size="small"  className="action-btn" color="outline" onClick={() => toggle('edit')}>
            <Icon src={Edit} width={20} height={20} stroke="#667085"/>
            <span>Edit Series</span>
          </ButtonComponent>
          <ButtonComponent size="small" className="action-btn" onClick={() => toggle("addModule", null)}>
            <Icon src={Plus} width={20} height={20}/>
            <span>Add Module(s)</span>
          </ButtonComponent>
        </div>
      </div>
      {dataCase?.length > 0 ? (
        loadingModule?.case ? (
          <div className="w-100 d-flex justify-content-center">
            <ClipLoader color="#0089c3" size={26} />
          </div>
        ) : (
          <SortableContainer
            onSortEnd={onSortEnd}
            lockAxis="y"
            helperClass="SortableTbl"
            useWindowAsScrollContainer={true}
            distance={1}
            handleRemove={handleRemove}
          >
            <WrapListItem>
              {dataCase.map((value, index) => (
                <SortableItem
                  key={`item-${value.Id}`}
                  index={index}
                  data={value}
                  history={history}
                  handleRemove={handleRemove}
                />
              ))}
            </WrapListItem>
          </SortableContainer>
        )
      ) : (
        <MessageEmtyData
          title="No Modules Added"
          text="This series is empty. Click ‘Add Module(s)’ to select and add modules to this cohort."
          ButtonBottomRender={
            <ButtonComponent size="small" className="action-btn" onClick={() => toggle("addModule", null)}>
              <Icon src={Plus} width={20} height={20}/>
              <span>Add Module(s)</span>
            </ButtonComponent>
          }
        />
      )}
    </WrapContentCase>
  );
}

export default InteractiveSort;