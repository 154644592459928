import { chatConstants } from "@constants";

export function ChatRecordFreeText(state = {}, action) {
    switch (action.type) {
        case chatConstants.CHAT_FREE_TEXT_RECORD_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case chatConstants.CHAT_FREE_TEXT_RECORD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case chatConstants.CHAT_FREE_TEXT_RECORD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function dataLoadRecordInstuctor(state = {}, action) {
    switch (action.type) {
        case chatConstants.LOAD_RECORD_INSTRUCTOR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case chatConstants.LOAD_RECORD_INSTRUCTOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case chatConstants.LOAD_RECORD_INSTRUCTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};


export function dataLoadRecordPatient(state = {}, action) {
    switch (action.type) {
        case chatConstants.LOAD_RECORD_PATIENT_REQUEST:
            return {
                ...state,
                isRender: false
            };
        case chatConstants.LOAD_RECORD_PATIENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isRender: true
            };
        case chatConstants.IS_RENDER_RECORD_PATIENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isRender: false
            };
        case chatConstants.LOAD_RECORD_PATIENT_FAILURE:
            return {
                ...state,
                isRender: false,
                error: action.error
            };
        default: return state;
    }
};

//Medical Record - Active tab
export function isChatPending(state = {}, action) {
    switch (action.type) {
      case chatConstants.CHECK_HAS_CHAT_LOCK_ELEMENT:
        // Ensure payload is a boolean
        return {
          ...state,
          isLock: typeof action.payload === 'boolean' ? action.payload : false
        };
      default:
        return state;
    }
}

//Chat Lock - Patient
export function isStatusChatLock(state = {}, action) {
    switch (action.type) {
      case chatConstants.UPDATE_CHAT_LOCK_SUCCESS:
        return {
          ...state,
          isLock: action.payload || false
        };
      default:
        return state;
    }
}

//Save Data Socket Connection
export function dataConnectSocket(state = {}, action) {
    switch (action.type) {
        case chatConstants.SAVE_DATA_SOCKET_INSTRUCTOR:
            return {
                ...state,
                instructorData: action.payload,  // Lưu dữ liệu cho instructor
            };
        case chatConstants.SAVE_DATA_SOCKET_VIRTUAL_PATIENT:
            return {
                ...state,
                virtualPatientData: action.payload,  // Lưu dữ liệu cho virtual_patient
            };
        default:
            return state;
    }
}    