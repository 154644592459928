import React from "react";
import { Row } from "reactstrap";
import { CaseListItem } from "../Home";
import { Link } from "react-router-dom";
import styled from "styled-components"; // Import styled-components

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";

//redux
import { useSelector } from "react-redux";

// Styled component for the Link
const StyledLink = styled(Link)`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
`;

function CaseSection(props) {
  //portfolioData
  const portfolioReducer = useSelector((state) => state?.portfolio || {});
  const InProgressModules = portfolioReducer?.data?.InProgressModules;

  return (
    <section className="case-list-container">
      <div className={`mt-4 position-relative`}>
        <div className="d-flex mb-4">
          <h2 className="headline --size-6">In-Progress</h2>
          <div className="ml-auto">
            <StyledLink to="/learn/assignments">View All</StyledLink>
          </div>
        </div>
        {InProgressModules?.length > 0 ? (
          <div className="card-area bg-white">
            <Row className="case-list">
              {InProgressModules?.map((caseItem) => (
                <React.Fragment key={caseItem?.Id}>
                  <CaseListItem {...caseItem} />
                </React.Fragment>
              ))}
            </Row>
          </div>
        ) : (
          <MessageEmtyData
            title="No Available Assignments Yet"
            text="There are no assignments at the moment. Please check back later for updates!"
            borderDashed={true}
          />
        )}
      </div>
    </section>
  );
}

export default CaseSection;
