/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const WrapForm = styled.div`
  width: 100%;
  max-width: 420px;
`

export default function Demographics(props) {
  const { timepointData, id } = props;
  const chatSettings = timepointData?.ChatSettings || {};
  const dispatch = useDispatch();

  const {
    control,
    watch,
    reset,
    setValue,
    register,
  } = useForm()

  useEffect(() => {
    if (timepointData) {
      reset({
        age: Number(chatSettings.AgeInteractingCharacter),
        gender: chatSettings.GenderInteractingCharacter,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timepointData])

  const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ]

  const handleSubmit = useCallback(
    debounce((param) => {
      const params = {
        "Action": "Update",
        "Target": "ChatSettings",
        "TimePointId": timepointData?.Id,
        ...param,
      }
      dispatch({
        type: storyboardConstants.CHAT_SETTINGS_REQUEST,
        isLoading: true,
      });
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
          toast.success(`Data updated successfully !`, {
            position: "top-right"
          });
        } else {
          dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
        }
      })
    }, 500),
    []
  );

  return (
    <WrapForm>
      <form className="form-platform">
        <Form>
          <FormGroup>
            <Label for="ageInput">Age (years old)</Label>
            <Input 
              id="ageInput" 
              name="age"
              placeholder="Example: 20" 
              type="number" 
              {...register("age")}
              defaultValue={watch("age")}
              min={0}
              onChange={(e) => setValue("age", Number(e.target.value))}
              onBlur={() => handleSubmit({ "AgeInteractingCharacter": watch("age") })}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`modelSelect`}>Gender</Label>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id={`modelSelect`}
                  className={`react-select-common react-select-md w-100`}
                  placeholder="Select gender"
                  options={genders}
                  classNamePrefix="react-select-custom"
                  value={genders?.find((c) => c.value === watch("gender"))}
                  onChange={val => {
                    setValue("gender", val.value)
                  }}
                  onBlur={() => handleSubmit({ "GenderInteractingCharacter": watch("gender") })}
                />
              )}
            />
          </FormGroup>
        </Form>
      </form>
    </WrapForm>
  )
}