import React, { useEffect, useState } from "react";
// import ReportDetailCohort from './ReportDetailCohort'
import { reportConstant } from "@constants";
import SelectCurriculum from "./SelectCurriculum";
import ChoiceCommon from "./ChoiceCommon";
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";

const ReportByCohort = (props) => {
  const [showDetail, setShowDetail] = useState(null);
  const [listCurriculum, setListCurriculum] = useState([]);
  const [curriculum, setCurriculum] = useState(null)
  const dispatch = useDispatch();

  const getListCurriculum = (newData) => {
    apiCaller(`/api/teach/curriculum/`, "GET").then((res) => {
      const dataRes = res?.data;
      if (res?.status === 200) {
        const dataConvert = dataRes?.map((curriculum) => {
          const cohortConvert = curriculum?.Cohorts?.map((cohort) => {
            return {
              ...cohort,
              value: cohort.CohortId,
              label: cohort.CohortName,
            }
          })
          return {
            ...curriculum,
            value: curriculum.CurriculumId,
            label: curriculum.CurriculumName,
            Cohorts: cohortConvert,
          }
        })
        dispatch({ type: reportConstant.GET_CURRICULUM_SUCCESS, payload: dataRes });
        setListCurriculum(dataConvert);
      } else {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    getListCurriculum()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {!showDetail ? (
        <SelectCurriculum
          setShowDetail={setShowDetail}
          showDetail={showDetail}
          listCurriculum={listCurriculum}
          setCurriculum={setCurriculum}
          curriculum={curriculum}
        />
      ) : (
        <ChoiceCommon 
          setShowDetail={setShowDetail} 
          showDetail={showDetail} 
          curriculum={curriculum}
        />
        // <ReportDetailCohort setShowDetail={setShowDetail} showDetail={showDetail} listOptionCohort={listOptionCohort}/>
      )}
    </div>
  );
};

export default ReportByCohort;
