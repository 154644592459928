import React, { useState, useEffect } from "react";
import { InstructorVoiceChat } from ".";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actFetchCheckMissingTask } from "@actions";
import FadeLoader from "react-spinners/FadeLoader";

function InstructorChatSTTMain(props) {
  //props
  const { handleLoadInstructorChatDone } = props && props;
  
  //dispatch
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(actFetchCheckMissingTask(id)).finally(() => {
      setIsLoading(false);
      handleLoadInstructorChatDone(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  // Kiểm tra trạng thái Missing Task từ Redux store
  const missingTaskStatus = useSelector((state) => state?.checkMissingTask || {});
  const dataMissingTask = missingTaskStatus.data;
  const isPendingQuestion = dataMissingTask?.status === "PendingQuestion";

  return (
    <React.Fragment>
      {/* Loading overlay với FadeLoader */}
      {isLoading && (
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
          <FadeLoader color="#0089C3" />
        </div>
      )}

      {/* Nội dung InstructorVoiceChat chỉ hiển thị khi không loading */}
      {!isLoading && (
        <InstructorVoiceChat
          {...props}
          isPendingQuestion={isPendingQuestion}
        />
      )}
    </React.Fragment>
  );
}

export default InstructorChatSTTMain;