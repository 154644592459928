import { teachConstants } from "@constants";
import { apiCaller } from "@utils";

//GET Case
export const actFetchTeachCaseRequest = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.TEACH_CASE_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.TEACH_CASE_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.TEACH_CASE_FAILURE, error: error } }
}

//UPDATE Case
export const actUpdateTeachCaseRequest = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
                return res
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.UPDATE_TEACH_CASE_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.UPDATE_TEACH_CASE_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.UPDATE_TEACH_CASE_FAILURE, error: error } }
}

//Get Case Learners
export const actFetchCaseLearnersRequest = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/learners/case/${id}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.GET_CASE_LEARNERS_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.GET_CASE_LEARNERS_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.GET_CASE_LEARNERS_FAILURE, error: error } }
}

//Case Learners - Load Candidates
export const loadCandidatesOfLearners = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/learners/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_FAILURE, error: error } }
}

//Case - Case Learners - Remove
export const actLearnersRemove = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/learners/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.REMOVE_LEARNERS_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.REMOVE_LEARNERS_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.REMOVE_LEARNERS_FAILURE, error: error } }
}

//Case - Case Learners - Enroll
export const actFetchEnroll = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/learners/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.PUT_ENROLL_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.PUT_ENROLL_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.PUT_ENROLL_FAILURE, error: error } }
}

//Load Question
export const actLoadQuestionRequest = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.LOAD_QUESTION_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.LOAD_QUESTION_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.LOAD_QUESTION_FAILURE, error: error } }
}

//order question
export const actQuestionOrder = (id, params) => {
    // const isLoading = true;
    return dispatch => {
        // dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            // const data = res && res.data;
            if (res && res.status === 200) {
                console.log("success");
            } else {
                console.log("error");
            }
        });
    };
    // function request(isLoading) { return { type: teachConstants.ORDER_QUESTION_REQUEST, isLoading } }
    // function success(data) { return { type: teachConstants.ORDER_QUESTION_SUCCESS, payload: data } }
    // function failure(error) { return { type: teachConstants.ORDER_QUESTION_FAILURE, error: error } }
}

//Create Domains
export const atcCreateDomain = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.CREATE_DOMAIN_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.UPDATE_DOMAIN_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.CREATE_DOMAIN_FAILURE, error: error } }
}

//Update Domains
export const atcEditDomain = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.UPDATE_DOMAIN_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.UPDATE_DOMAIN_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.UPDATE_DOMAIN_FAILURE, error: error } }
}

//Delete Domains
export const atcDeleteDomain = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.DELETE_DOMAIN_FAILURE, isLoading } }
    function success(data) { return { type: teachConstants.UPDATE_DOMAIN_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.DELETE_DOMAIN_FAILURE, error: error } }
}

//Case - Load All Case Questions
export const atcLoadAllQuestion = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.LOAD_ALL_QUESTION_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.LOAD_ALL_QUESTION_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.LOAD_ALL_QUESTION_FAILURE, error: error } }
}

//Get Institution Time Zone
export const getInstitutionTimeZone = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/admin/institution/timezone/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.GET_INSTITUTION_TIME_ZONE_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.GET_INSTITUTION_TIME_ZONE_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.GET_INSTITUTION_TIME_ZONE_FAILURE, error: error } }
}

//Set Institution Time Zone
export const setInstitutionTimeZone = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/admin/institution/timezone/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.SET_INSTITUTION_TIME_ZONE_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.SET_INSTITUTION_TIME_ZONE_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.SET_INSTITUTION_TIME_ZONE_FAILURE, error: error } }
}

export const getTeacherHome = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/home/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.TEACHER_HOME_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.TEACHER_HOME_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.TEACHER_HOME_FAILURE, error: error } }
}

//Load Question for Flow Control
export const atcLoadQuestionByIdFolowControl = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.LOAD_QUESTION_FLOW_CONTROL_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.LOAD_QUESTION_FLOW_CONTROL_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.LOAD_QUESTION_FLOW_CONTROL_FAILURE, error: error } }
}

//Case - Load Questions From Templates (for potentially adding to new case)
export const atcloadQuestionsFromTemplates = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_FAILURE, error: error } }
}

// -------------Status Task-------------//
// ----SAVE Task
export const actSaveTaskActive = (taskActive) => {
    return dispatch => {
        dispatch(success(taskActive));
    };

    function success(data) { return { type: teachConstants.TEACH_TASK_ACTIVE, payload: data } }
}

// ----UPDATE Task
export const actUpdateTaskActive = (taskActive) => {
    return dispatch => {
        dispatch(success(taskActive));
    };

    function success(data) { return { type: teachConstants.UPDATE_TASK_ACTIVE, payload: data } }
}

//Update AvailableTask
export const actUpdateUpdateAvailableTask = (data) => {
    return dispatch => {
        dispatch(success(data));
    };

    function success(data) { return { type: teachConstants.UPDATE_AVAILABLE_TASK, payload: data } }
}

//Check View Question
export const actActiveViewQuestion = (questionActive) => {
    return dispatch => {
        dispatch(success(questionActive));
    };

    function success(data) { return { type: teachConstants.QUESTION_VIEW_ACTIVE, payload: data } }
}

//Editor - GET LIST CHARACTER
export const getListCharacter = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/characters/?role=instructor`, 'GET').then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
                return data
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: teachConstants.GET_LIST_CHARACTER_REQUEST, isLoading } }
    function success(data) { return { type: teachConstants.GET_LIST_CHARACTER_SUCCESS, payload: data } }
    function failure(error) { return { type: teachConstants.GET_LIST_CHARACTER_FAILURE, error: error } }
}
