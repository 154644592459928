import React, { useState } from 'react'
import styled from 'styled-components'
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import ImageUser from '@images/common/no-avatar.jpg';
import { ModalSelectCharacter } from './ComponentCommon';

const WrapCharacter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 78px 0;
  border: 1px solid #D0D5DD;
  border-radius: 10px;
`

const ItemImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .ava__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    color: #081B2A;
    &-img {
      width: 147px;
      height: 147px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    &-gender {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`

export default function Character(props) {
  const { timepointData } = props;
  const characters = timepointData?.Characters || {};
  const instructor = characters.Instructor || {}
  const [modal, setModal] = useState({
    uploadImage: false,
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };
  
  return (
    <WrapCharacter>
      <ItemImage>
        <div className="ava__item">
          <div className="ava__item-img">
            <img src={instructor?.Character?.StandardSquarePhoto || ImageUser} alt="img" />
          </div>
          <p className="ava__item-name">{instructor?.Character?.CharacterName}</p>
          <p className="ava__item-gender">{instructor?.Character?.Demographics?.Age || instructor?.Character?.Demographics?.age} years old / {instructor?.Character?.Demographics?.Gender || instructor?.Character?.Demographics?.gender}</p>
        </div>
        <div>
          <ButtonComponent size="small" color="outline" onClick={() => toggle('uploadImage')}>Switch Character</ButtonComponent>
        </div>
      </ItemImage>
      {modal.uploadImage && (
        <ModalSelectCharacter
          isOpen={modal.uploadImage}
          toggle={() => toggle("uploadImage")}
          actions={modal}
          {...props}
        />
      )}
    </WrapCharacter>
  )
}
