// gradingSchemaOptions.js
const gradingSchemaOptions = [
    {
        value: 'dialog',
        label: 'Guided Dialog'
    },
    {
        value: 'chatgpt',
        label: 'Open AI'
    },
    {
        value: 'statement',
        label: 'Statement'
    }
];

module.exports = gradingSchemaOptions;
