import React, { useState, useEffect } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateVirtualPreceptorSettings } from "@actions";

const Container = styled.div`
  background-color: white;
`;
const Heading = styled.h3`
  color: #081b2a;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #d0d5dd;
  padding-bottom: 8px;
`;
const StyledLabel = styled(Label)`
  color: #344054;
  font-size: 14px;
  font-weight: 500;
`;

const VirtualPreceptorMain = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const timepointReducer = useSelector((state) => state?.timepoint || {});
  const timepointData = timepointReducer?.data;

  const [interactingMethod, setInteractingMethod] = useState(() => {
    return timepointData?.InstructorChatMode || "text";
  });
  const [voiceSpeed, setVoiceSpeed] = useState(() => {
    return (
      Number(localStorage.getItem("voiceSpeed")) ||
      Number(timepointData?.InstructorChatSpeed) ||
      1
    );
  });
  const [timePointId, setTimePointId] = useState(timepointData?.Id || null);

  useEffect(() => {
    if (timepointData?.InstructorChatMode) {
      setInteractingMethod(timepointData.InstructorChatMode);
    }
    if (timepointData?.InstructorChatSpeed) {
      setVoiceSpeed(Number(timepointData.InstructorChatSpeed));
      localStorage.setItem(
        "voiceSpeed",
        Number(timepointData.InstructorChatSpeed)
      );
    }
    setTimePointId(timepointData?.Id || null);
  }, [timepointData]);

  const handleUpdateSettings = (updatedParams) => {
    if (!timePointId) return;
    dispatch(
      updateVirtualPreceptorSettings(id, {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: timePointId,
        ...updatedParams,
      })
    );
  };

  const handleInteractionMethodChange = (mode) => {
    setInteractingMethod(mode);
    handleUpdateSettings({
      InstructorChatMode: mode,
    });
  };

  const handleVoiceSpeedChange = (speed) => {
    setVoiceSpeed(speed);
    localStorage.setItem("voiceSpeed", speed); // Lưu vào localStorage

    if (interactingMethod === "voice") {
      handleUpdateSettings({
        InstructorChatSpeed: speed,
      });
    }
  };

  const voiceSpeedOptions = [
    { label: "0.5", value: 0.5 },
    { label: "0.75", value: 0.75 },
    { label: "Normal", value: 1 },
    { label: "1.2", value: 1.2 },
    { label: "1.5", value: 1.5 },
    { label: "2.0", value: 2.0 },
  ];

  return (
    <Container>
      <Heading>Virtual Preceptor Settings</Heading>
      <FormGroup tag="fieldset" className="mt-4">
        <StyledLabel>Interaction Method</StyledLabel>
        {["voice", "text", "stream"].map((mode) => (
          <FormGroup check key={mode}>
            <StyledLabel check>
              <Input
                type="radio"
                name="interactingMethod"
                value={mode}
                checked={interactingMethod === mode}
                onChange={() => handleInteractionMethodChange(mode)}
              />{" "}
              {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </StyledLabel>
          </FormGroup>
        ))}
      </FormGroup>

      {interactingMethod === "voice" && (
        <FormGroup className="mt-4">
          <StyledLabel>Voice Speed</StyledLabel>
          <Input
            type="select"
            value={voiceSpeed}
            onChange={(e) => handleVoiceSpeedChange(Number(e.target.value))}
          >
            {voiceSpeedOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
    </Container>
  );
};

export default VirtualPreceptorMain;
