import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { ReportByLearner } from '@components/teachV2/reportPage/reportByLearner';
import { ReportByCohort } from '@components/teachV2/reportPage/reportByCohort';

const ReportPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
	const [activeTab, setActiveTab] = useState(pathname === "/teach/report-cohort" ? 1 : 2);

	const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    if (tabId === 1) {
      history.push("/teach/report-cohort")
    } else {
      history.push("/teach/report-learner")
    }
  };

  const tabsOption = [
    { id: 1, key: "report-by-cohort", label: "Report by Cohort" },
    { id: 2, key: "report-by-learner", label: "Report by Learner" },
  ] 

  return (
    <div className="wrapper -ReferenceLiblaryPage">
      <div className='reference-wrap__tabs mb-3'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div className="h-100">
        {activeTab === 1 && (
          <ReportByCohort />
        )}
        {activeTab === 2 && (
          <ReportByLearner />
        )}
      </div>
    </div>
  )
}

export default ReportPage
