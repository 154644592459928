import React, { useState } from 'react'
import styled from 'styled-components'
import { DomainProgress } from '@components/teachV2/reportPage/domainProgress'
import Select from 'react-select';
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import { getReportCurriculum } from "@actions";
import DetailPerformance from './DetailPerformance';
import LoadingCohort from '../LoadingCohort';
import { MessageEmtyData } from "@ui-partents/Message";

const WrapDetailReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .wrap-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__back {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #081B2A;
      }
    }
    &__list {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      border: 1px solid #D0D5DD;
      border-radius: 10px;
      .select-curriculum {
        width: 100%;
        max-width: 260px;
      }
    }
  }
`

const ReportDetailCohortSingle = (props) => {
  const { dataCurriculum } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const listCurriculum = useSelector((state) => state?.getCurriculum || []);
  const [dataListCohort, setDataListCohort] = useState(JSON.parse(location?.state?.listCohorts) || []);
  const [valueCurriculum, setValueCurriculum] = useState(JSON.parse(location?.state?.curriculumActive) || []);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const handleBack = () => {
    history.push('/teach/report-cohort')
  }

  const handleToReportMultiple = async () => {
    setDisableConfirm(true)
    const dataReport = await dispatch(getReportCurriculum({
      ReportType: "CurriculumPerformance",
      ReportMode: "SingleCohort",
      CurriculumId: valueCurriculum?.CurriculumId,
      CohortId: dataListCohort.CohortId
    }))
    if (dataReport?.data?.status === 200) {
      const listCohorts = JSON.stringify(dataListCohort)
      const curriculumActive = JSON.stringify(valueCurriculum)
      history.push({
        pathname: '/teach/report-cohort-single',
        state: {
          listCohorts,
          curriculumActive, 
        },
      })
    }
  }

  return (
    <WrapDetailReport>
      <div className="wrap-select">
        <div className="wrap-select__back" onClick={handleBack}>
          <Icon src={Back} width="20" stroke="#000"/>
          <span>Back</span>
        </div>
        <div className="wrap-select__list">
          <Select
            className={`react-select-common select-curriculum`}
            placeholder="Select item"
            options={listCurriculum?.data}
            classNamePrefix="react-select-custom"
            value={valueCurriculum}
            onChange={(e) => setValueCurriculum(e)}
          />
          <Select
            className={`react-select-common w-100`}
            placeholder="Select item"
            options={valueCurriculum?.Cohorts || []}
            classNamePrefix="react-select-custom"
            value={dataListCohort}
            onChange={(newValue) => {
              setDataListCohort(newValue)
              setDisableConfirm(false)
            }}
          />
          <ButtonComponent disabled={disableConfirm} onClick={() => handleToReportMultiple()}>Confirm</ButtonComponent>
        </div>
      </div>
      {!dataCurriculum?.data ? (
        <MessageEmtyData
          title="No Data Available"
          text="There’s nothing here yet. Please check back later or try adjusting your filters."
        />
      ) : dataCurriculum?.isLoading ? (
        <LoadingCohort />
      ) : (
        <>
          <DomainProgress {...props} />
          <DetailPerformance {...props} />
        </>
      )}
    </WrapDetailReport>
  )
}

export default ReportDetailCohortSingle
