import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const ChartRadarSingle = (props) => {
  const { dataSelect } = props
    
  // Extract LaunchPlan names and AverageScores
  const launchPlanNames = dataSelect?.Domains.map(plan => ({ name: plan.DomainName }));
  const averageScores = dataSelect?.Domains.map(plan => plan.AverageScore);
    
  const dataSingle = [
    {
      value: averageScores,
      name: 'Average Score',
      lineStyle: {
        color: "#0BA5EC", // Màu của đường line
        width: 2, // Độ dày đường line
        type: "solid", // Kiểu nét (solid, dashed, dotted)
      },
      areaStyle: {
        color: "#79DDFC61"
      },
    },
  ]

  const option = {
    legend: {
      orient: "vertical", // Đặt legend theo chiều dọc
      left: "left", // Căn trái
      top: "80%",
    },
    radar: {
      indicator: launchPlanNames,
      axisName: {
        color: '#475467'
      },
    },
    series: [
      {
        type: 'radar',
        data: dataSingle
      }
    ]
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartRadarSingle
