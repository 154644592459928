import React from "react";
import IconButton from "./IconButton";

const RecordingButton = (props) => {
  const { isRecording, startRecording, volumeLevels } = props && props;
  return (
    <IconButton
      {...props}
      isRecording={isRecording}
      onClick={!isRecording ? startRecording : null}
      marginBottom="20px"
      volumeLevels={volumeLevels}
    />
  );
};

export default RecordingButton;