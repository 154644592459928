import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

const Wrapper = styled.div`
  width: 100%;
`

const ChartRadarCohortMultiple = (props) => {
  const { dataCurriculum } = props
  const { Domains } = dataCurriculum?.data?.ReportDetails || {};

  const indicators = Domains && _.map(Domains, (value, key) => ({ name: key }));

  // Handle multiple cohorts
  const cohortIds = Domains &&_.uniq(_.flatMap(Domains, category => category?.Cohorts.map(cohort => cohort?.CohortId)));
  const dataMultiple = Domains && cohortIds.map(cohortId => {
    const values = _.map(Domains, category => {
      const cohort = _.find(category?.Cohorts, { CohortId: cohortId });
      return cohort ? cohort?.AverageScore : 0;
    });

    const cohortName = _.find(_.flatMap(Domains, category => category?.Cohorts), { CohortId: cohortId })?.CohortName;

    return {
      value: values,
      name: cohortName || `Cohort ${cohortId}`,
      areaStyle: {
        color: "#79DDFC61" // Customize as needed
      },
    };
  });

  const option = {
    legend: {
      left: 0,  // Đẩy legend sát lề trái
      orient: "vertical", // Đặt legend dọc
      itemWidth: 10,  // Thu nhỏ biểu tượng
      itemHeight: 10, // Thu nhỏ biểu tượng
      textStyle: {
        fontSize: 12, // Giảm kích thước chữ
      },
      tooltip: {
        show: true,
      }
    },
    grid: {
      top: 200,    // Giữ khoảng trống phía trên
    },
    radar: {
      indicator: indicators,
      axisName: {
        color: '#475467'
      },
      center: ['62%', '50%'],
    },
    series: [
      {
        type: 'radar',
        data: dataMultiple,
      }
    ]
  };

  return (
    <Wrapper>
      <ReactECharts option={option}/>
    </Wrapper>
  )
}

export default ChartRadarCohortMultiple
