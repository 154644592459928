import React from "react";
import imgPerson from "@images/common/no-avatar.jpg";

//styled-components
import styled from "styled-components";

// components
import VoiceChatComponent from "./VoiceChatComponent";

// react-redux
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components Chat Socket
import { WebSocketAudioHandler } from "../WebSocket";

export const FrameChatFullBox = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

// Hàm này có 3 loại voice (API, realtime với gpt4o, Websocket )
function FrameChat(props) {
  const { isShowForSwitch, ChatMode, data } = props;
  let { id } = useParams();

  // Case Data
  const caseData = useSelector((state) => state?.cases || []);
  const caseResponse = caseData?.data?.Case;

  //Characters
  const Characters = caseResponse?.Characters;
  const Patient = Characters?.Patient;
  const PatientCharacter = Patient?.Character;
  const patientNameText = Patient?.DisplayName;
  const photoPatient = PatientCharacter?.StandardSquarePhoto || imgPerson;

  //Chat Voice Condition
  const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
  const chatModeClass =
    ChatMode === "switch_voice_text" ? showSwitchVoiceText : "";
  return (
    <>
      {/* Chức năng stream mới - Sử dụng chung cho instructor và patient */}
      {ChatMode === "streaming" && (
        <WebSocketAudioHandler
          CaseId={id}
          data={data}
          ImageAvatar={photoPatient}
          NameRole={patientNameText}
          ActionType={"virtual_patient"}
        />
      )}

      {/* FrameChatFullBox chỉ bọc cho VoiceChatComponent */}
      <FrameChatFullBox
        className={`position-relative ${chatModeClass}`}
        data-type="patient-voice"
      >
        {ChatMode === "voice" && (
          <VoiceChatComponent
            {...props}
            photoPatient={photoPatient}
            patientNameText={patientNameText}
          />
        )}
        {/* Component test stream vs Open AI 4o - Huy */}
        {/* {ChatMode === "voice" &&
                <SpeechRealTime
                    id={id}
                    data={data}
                    setPatientResponse={setPatientResponse}
                    photoPatient={photoPatient}
                    patientNameText={patientNameText}
                />
            } */}
      </FrameChatFullBox>
    </>
  );
}

export default FrameChat;
