export const questionConstants = {
    QUESTION_REQUEST: 'QUESTION_REQUEST',
    QUESTION_SUCCESS: 'QUESTION_SUCCESS',
    QUESTION_SUBMIT: 'QUESTION_SUBMIT',
    QUESTION_FAILURE: 'QUESTION_FAILURE',

    LOAD_ALL_QUESTION_FOR_ELEMENT_REQUEST: 'LOAD_ALL_QUESTION_FOR_ELEMENT_REQUEST',
    LOAD_ALL_QUESTION_FOR_ELEMENT_SUCCESS: 'LOAD_ALL_QUESTION_FOR_ELEMENT_SUCCESS',
    LOAD_ALL_QUESTION_FOR_ELEMENT_FAILURE: 'LOAD_ALL_QUESTION_FOR_ELEMENT_FAILURE',

    UPDATE_QUESTION_BANK_REQUEST: 'UPDATE_QUESTION_BANK_REQUEST',
    UPDATE_QUESTION_BANK_SUCCESS: 'UPDATE_QUESTION_BANK_SUCCESS',
    UPDATE_QUESTION_BANK_FAILURE: 'UPDATE_QUESTION_BANK_FAILURE',

    CHAT_RECORD_FOR_QUESTION: 'CHAT_RECORD_FOR_QUESTION',
    UPDATE_CHAT_RECORD_FOR_QUESTION: 'UPDATE_CHAT_RECORD_FOR_QUESTION',
    RESET_CHAT_RECORD_FOR_QUESTION: 'RESET_CHAT_RECORD_FOR_QUESTION',
};
