import React, { useState } from 'react';
import { FormModal } from '@ui-partents/Modal';
import editModal from '@images/teachV2/edit-modal.svg'
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { actUpdatePeriod, actCreatePeriod, actLoadAllPeriods } from "@actions";
import { Icon } from '@components/common'
import Calendar from '@images/new_interface/sidebar/calendar.svg'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { TitleNoteCommon } from '../../../home/generate';
import moment from 'moment';

const WrapForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const WrapFormCol = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const FormDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  .set-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    input {
      border: none;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const ModalCreateUpdatePeriod = ({ isOpen, toggle, item }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(item?.StartTime ? new Date(item?.StartTime ): new Date());
  const [endDate, setEndDate] = useState(item?.EndTime ? new Date(item?.EndTime) : new Date());

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: item ? item?.Name : "",
    }
  })

  const onSubmit = async (data) => {
    try {
      if(item?.PeriodId) {
        const params = {
          Action: "Update",
          PeriodId: item?.PeriodId,
          Name: data.name,
          StartTime: moment(startDate).format(),
          EndTime: moment(endDate).format(),
        };
        dispatch(actUpdatePeriod(params));
        dispatch(actLoadAllPeriods({ Action: "Load" }));
        toggle();
      } else {
        const params = {
          Action: "Create",
          Name: data.name,
          StartTime: moment(startDate).format(),
          EndTime: moment(endDate).format(),
        };
        dispatch(actCreatePeriod(params));
        dispatch(actLoadAllPeriods({ Action: "Load" }));
        toggle();
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={item?.PeriodId ? `Edit Period` : `Add Period`}
      actionText={"Save"}
      maxWidth="750px"
      iconTitle={editModal}
      onAction={handleSubmit(onSubmit)}
    >
      <form className="form-platform">
        <WrapForm>
          <WrapFormCol>
            <FormDate className='w-100 mb-0'>
              <TitleNoteCommon
                dataFor={'periodName'} 
                title="Period Name" 
                content=""
                required
              />
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => (
                  <>
                    <Input
                      type='text'
                      name='name'
                      placeholder="Name"
                      {...field}
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </>
                )}
              />
            </FormDate>
          </WrapFormCol>
          <WrapFormCol>
            <FormDate className='w-100 mb-0'>
              <TitleNoteCommon
                dataFor={'startTime'} 
                title="Start Time" 
                content=""
                required
              />
              <div className='set-calendar'>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(e) => setStartDate(e)}
                  dateFormat="yyyy/MM/dd"
                  className="w-100"
                />
                <Icon src={Calendar} width={16} height={16} stroke="#130F26"/>
              </div>
            </FormDate>
            <FormDate className='w-100 mb-0'>
              <TitleNoteCommon
                dataFor={'endTime'} 
                title="End Time" 
                content=""
                required
              />
              <div className='set-calendar'>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(e) => setEndDate(e)}
                  dateFormat="yyyy/MM/dd"
                  minDate={startDate}
                  className="w-100"
                />
                <Icon src={Calendar} width={16} height={16} stroke="#130F26"/>
              </div>
            </FormDate>
          </WrapFormCol>
        </WrapForm>
      </form>
    </FormModal>
  );
};

export default ModalCreateUpdatePeriod;