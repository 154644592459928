import React, { useEffect } from 'react'
import { useState } from 'react';
import { Icon } from '@components/common'
import Right from '@images/teachV2/turn-right.svg'
import AddSeries from '@images/teachV2/add-series.svg'
import { InteractiveDetail, InteractiveSort } from '@components/teachV2/interactiveModule';
import { useDispatch, useSelector } from "react-redux";
import { instructorConstants } from "@constants";
import { apiCaller } from "@utils";
import { actCreateSeries, actDeleteSeries } from "@actions";
import { LoaderText } from "@utils";
import styled from 'styled-components';
import info from '@images/teachV2/info.svg';
import ReactTooltip from 'react-tooltip';
import { ButtonComponent } from "@components/patterns/elements/Button";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import ModalEdit from './ModalEdit';
import ModalAdd from './ModalAdd';
import ModalAddModule from './ModalAddModule';

const WrapCases = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ListCase = styled.ul`
  display: flex;  
  flex-direction: column;
  gap: 4px;
  padding-right: 4px;
  height: calc(100vh - 400px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  .item__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    padding: 10px 8px;
    border-radius: 8px;
    &-name {
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }
    &-number {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        padding: 2px 8px;
        border-radius: 9999px;
        border: 1px solid #EAECF0;
        background: #F9FAFB;
        color: #344054;
        font-size: 12px;
        font-weight: 500;
      }
    }
    &.active {
      background: #F0F9FC;
    }
  }
`

const ItemCase = styled.div`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  gap: 8px;
  .item__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    &-name {
      color: #081B2A;
      font-size: 18px;
      font-weight: 600;
    }
    &-number {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        padding: 2px 8px;
        border-radius: 9999px;
        border: 1px solid #FEDF89;
        background: #FFFAEB;
        color: #F1800F;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  &.active {
    background: #F0F9FC;
  }
`

const ListSeries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .title-name {
    color: #081B2A;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .custom-tooltip {
    width: 340px;
  }
`

const WrapDeleteSeries = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-right: 16px;
  .btn-delete-series {
    border-color: #FECDCA;
    color: #D92D20;
  }
`

const WrapLoader = styled.div`
  height: 200px;
`

const GridMain = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const LeftSection = styled.div`
  flex: 0 0 273px;
`;

const RightSection = styled.div`
  flex: 1;
`;

const SeriesTab = () => {
  const dispatch = useDispatch();
  const instructorData = useSelector((state) => state.instructor || {});
  const [activeSeries, setActiveSeries] = useState("all_case");
  const [seriesData, setSeriesData] = useState(instructorData?.data?.Series || []);
  const [seriesDetail, setSeriesDetail] = useState()
  const [dataCase, setCase] = useState(instructorData?.data?.Cases || []);
  const [loadingModule, setLoadingModule] = useState({
    instructor: false,
    case: false,
  });
  const [modal, setModal] = useState({
    create: false,
    edit: false,
    addModule: false
  });

  const toggle = (type) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  useEffect(() => {
    setSeriesData(instructorData?.data?.Series || []);
  }, [instructorData?.data?.Series]);

  const filterCase = (item) => {
    setSeriesDetail(item);
    setActiveSeries(item?.Id)
    actFetchCaseWithSeriesId(item?.Id);
  };

  //load Data
  useEffect(() => {
    handelLoadAllCase()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actFetchCaseWithSeriesId = (seriesId) => {
    setLoadingModule({
      ...loadingModule,
      case: true
    });
    dispatch({
      type: instructorConstants.FILTER_CASE_REQUEST,
      isLoading: true,
    });
    apiCaller(`/api/teach/series/${seriesId}/`, "GET", null).then((res) => {
      const data = res && res.data;
      console.log(data);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: instructorConstants.FILTER_CASE_SUCCESS,
          payload: data,
          seriesId: seriesId
        });
        console.log(data);
        setCase(data?.Cases);
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      } else {
        dispatch({
          type: instructorConstants.FILTER_CASE_FAILURE,
          error: "error",
        });
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      }
    });
  }

  // //load all case
  const handelLoadAllCase = () => {
    setLoadingModule({
      ...loadingModule,
      case: true
    });
    setActiveSeries('all_case')
    dispatch({ type: instructorConstants.INSTRUCTOR_REQUEST, isLoading: true });
    apiCaller(`/api/teach/portfolio/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: instructorConstants.INSTRUCTOR_SUCCESS, payload: data });
        setCase(data?.Cases);
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      } else {
        dispatch({ type: instructorConstants.INSTRUCTOR_FAILURE, error: "error" });
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      }
    });
  };

  const handleAddSeries = (params) => {
    dispatch(actCreateSeries(params));
    setModal(false);
    handelLoadAllCase()
  };

  const handleEditSeries = async (id, name) => {
    const params = {
      Action: "Update",
      Name: name
    }
    dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_REQUEST, isLoading: true });
    //Call Api
    apiCaller(`/api/teach/series/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_SUCCESS, payload: data });
        setSeriesDetail(data)
        //Close
        toggle('edit');
      } else {
        //close
        toggle('edit');
        dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_FAILURE, error: "error" });
      }
    });
  }

  const handleRemove = (seriesId) => {
    const params = {
      Action: "Delete",
      SeriesId: seriesId,
    };
    dispatch(actDeleteSeries(params));
    setActiveSeries("all_case");
    handelLoadAllCase()
  };

  return (
    <div className="position-relative">
      {instructorData?.isLoading ? (
        <WrapLoader>
          <LoaderText />
        </WrapLoader>
      ) : (
        <GridMain>
          <LeftSection>
            <WrapCases>
              <ItemCase 
                className={`${activeSeries === "all_case" ? 'active' : ''}`}
                onClick={handelLoadAllCase}
              >
                <div className='item__info'>
                  <p className='item__info-name'>All Cases</p>
                  <div className='item__info-number'>
                    <p>{dataCase?.length}</p>
                    <div>
                      <Icon src={Right} width={14} height={14}/>
                    </div>
                  </div>
                </div>
              </ItemCase>
              <ListSeries>
                <WrapNote>
                  <p className="title-name">Series</p>
                  <div data-tip data-for="note-tile">
                    <Icon src={info} width={20} height={20}/>
                  </div>
                  <ReactTooltip id="note-tile" place='top' effect='solid' className={`custom-tooltip`} >
                    <span> A series is a set of interactive modules that can be assigned to learners. Select a series to view and manage its modules.</span>
                  </ReactTooltip>
                </WrapNote>
                <ListCase>
                  {seriesData?.map((series, index) => (
                    <li 
                      className={`item__info ${activeSeries === series?.Id ? 'active' : ''}`} 
                      key={index}
                      onClick={() => filterCase(series)}
                    >
                      <p className='item__info-name'>{series?.Name}</p>
                      <div className='item__info-number'>
                        <p>{series?.NumberOfCases}</p>
                        <div>
                          <Icon src={Right} width={14} height={14}/>
                        </div>
                      </div>
                    </li>
                  ))} 
                </ListCase>
                <div className='interactiveModule__wrap-add' onClick={() => toggle('create', null)}>
                  <Icon src={AddSeries} stroke="#0089C3" width={18} height={18}/>
                  <span>Add Series</span>
                </div>
              </ListSeries>
            </WrapCases>
          </LeftSection>
          <RightSection>
            {activeSeries === "all_case" ? (
              <InteractiveDetail 
                dataCase={dataCase} 
                instructorData={instructorData?.data?.Cases} 
                titleSeries={instructorData?.data?.Title} 
                loadingModule={loadingModule}
                handelLoadAllCase={handelLoadAllCase}
                activeSeries={activeSeries}
              />
            ) : (
              <InteractiveSort 
                dataCase={dataCase} 
                titleSeries={seriesDetail?.Name} 
                seriesDetail={seriesDetail}
                setCase={setCase}
                filterCase={filterCase}
                activeSeries={activeSeries}
                toggle={toggle}
                loadingModule={loadingModule}
              />
            )}
            {activeSeries !== "all_case" && (
              <WrapDeleteSeries>
                <WrapModalDeleteCommon
                  title={`Delete Series`}
                  sub={`Are you sure you want to delete this series? This action cannot be undone.`}
                  handleRemove={handleRemove}
                  idRemove={activeSeries}
                >
                  {({onOpen}) => (
                    <ButtonComponent
                      size="small" 
                      color="outline"
                      className="btn-delete-series"
                      onClick={onOpen}
                    >
                      Delete Series
                    </ButtonComponent>
                  )}
                </WrapModalDeleteCommon>
              </WrapDeleteSeries>
            )}
          </RightSection>
        </GridMain>
      )}
      {modal?.create && (
        <ModalAdd
          isOpen={modal?.create}
          toggle={() => toggle("create", null)}
          handleAddSeries={handleAddSeries}
        />
      )}
      {modal?.edit && (
        <ModalEdit
          isOpen={modal?.edit}
          toggle={() => toggle("edit", null)}
          handleEdit={handleEditSeries}
          seriesDetail={seriesDetail}
        />
      )}
      {modal?.addModule && (
        <ModalAddModule 
          isOpen={modal?.addModule}
          toggle={() => toggle("addModule", null)}
          seriesDetail={seriesDetail}
          actFetchCaseWithSeriesId={actFetchCaseWithSeriesId}
        />
      )}
    </div>
  )
}

export default SeriesTab
