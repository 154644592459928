import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const DropdownCommon = ({ menuData, textDropdown, onItemClick, type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = useCallback(() => {
        if (isOpen) {
            // Start transition to close
            setIsTransitioning(true);
            setTimeout(() => {
                setIsOpen(false);
                setIsTransitioning(false);
            }, 200); // Match this duration with your transition time
        } else {
            setIsOpen(true);
        }
    }, [isOpen]);

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // Start transition to close
            setIsTransitioning(true);
            setTimeout(() => {
                setIsOpen(false);
                setIsTransitioning(false);
            }, 200); // Match this duration with your transition time
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClickOutside]);

    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
        // Close the dropdown after an item is clicked
        toggleDropdown();
    };

    return (
        <Container ref={dropdownRef}>
            <DropdownToggle onClick={toggleDropdown}>
                <span>{textDropdown}</span>
                <DropdownIcon />
            </DropdownToggle>
            {isOpen && (
                <Dropdown isOpen={!isTransitioning}>
                    {type === 'default' ? (
                        menuData?.map((item, index) => (
                            <DropdownItemDefault 
                                key={index} 
                                activeClassName="active"
                                onClick={() => handleItemClick(item)} // Call the custom click handler
                            >
                                <DropdownText>{item.text}</DropdownText>
                            </DropdownItemDefault>
                        ))
                    ) : (
                        menuData?.map((item) => (
                            <DropdownItem 
                                key={item.to} 
                                to={item.to} 
                                activeClassName="active"
                                onClick={() => handleItemClick(item)} // Call the custom click handler
                            >
                                <DropdownText>{item.text}</DropdownText>
                            </DropdownItem>
                        ))
                    )}
                </Dropdown>
            )}
        </Container>
    );
};

const DropdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M4 6L8 10L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const DropdownItem = styled(NavLink)`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    background-color: ${({ isActive }) => (isActive ? '#F2F4F7' : 'transparent')};

    &:hover {
        background-color: #F9FAFB;
        border-radius: 6px;
    }
`;

const DropdownItemDefault = styled.div`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    background-color: ${({ isActive }) => (isActive ? '#F2F4F7' : 'transparent')};

    &:hover {
        background-color: #F9FAFB;
        border-radius: 6px;
    }
`;

const Container = styled.div`
    position: relative;
`;

const DropdownToggle = styled.div`
    display: inline-flex;
    padding: 0 10px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: #0089C2;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    height: 37px;
    cursor: pointer;
    justify-content: flex-end;
    span {
        margin-right: 8px;
    }
`;

const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-top: 6px;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 240px;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    z-index: 1;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

const DropdownText = styled.span`
    color: #101828;
    font-size: 14px;
    font-weight: 500;
`;

DropdownCommon.propTypes = {
    menuData: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,
    textDropdown: PropTypes.string.isRequired,
    onItemClick: PropTypes.func, // New prop for handling item clicks
    type: PropTypes.string,
};

export default DropdownCommon;