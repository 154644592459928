import React from 'react';
import { MessageImage, MessageInfo, MessageButton } from '@components/common/MessageEndChat';

function MessageBoxEndChat(props) {
    const { TaskToResume } = props || {};
    return (
        <div className="message-answer">
            <MessageImage />
            <MessageInfo 
                title={`Next Step`}
                content={TaskToResume?.Statement}
            />
            <MessageButton {...props} />
        </div>
    );
}

export default MessageBoxEndChat;