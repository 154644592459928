import React, { useState, useEffect } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

//Action
import { actUpdateTaskActive, actActiveViewQuestion } from "@actions";
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'

const ListQuestionForTask = ({ indexItem, task, setTabActive }) => {
  let dispatch = useDispatch();

  //Redux store
  let taskActive = useSelector((state) => state?.statusTaskActive?.currentTask || {});

  //state
  const [collapsedViewClass, setCollapsedViewClass] = useState(taskActive);
  const [showChildren, setShowChildren] = useState(true)

  useEffect(() => {
    setCollapsedViewClass(taskActive);
  }, [taskActive]);

  const isActive = collapsedViewClass === task.ViewClass;

  // Handle Active Task
  const handleClickTask = (ViewClass, task) => {
    if (ViewClass) {
      dispatch(actUpdateTaskActive(ViewClass));
      setTabActive(task)
    } else {
      setShowChildren(!showChildren)
    }

    //Reset View
    dispatch(actActiveViewQuestion(null));
  }

  return (
    <div key={indexItem} className={`task-setting ${isActive ? 'active' : ''}`}>
      <div className={`task-setting-head`} is-task={task.ViewClass} onClick={() => handleClickTask(task.ViewClass, task)}>
        <h3 className="task-setting-head__title">{task.Label}</h3>
        {task?.Children?.length > 0 && (
          <div>
            {showChildren ? <Icon stroke="#1D2939" src={Up} width={16}/> : <Icon stroke="#1D2939" src={Down} width={16}/>}
          </div>
        )}
      </div>
      {showChildren && task?.Children?.length > 0 && (
        <div className="task-setting__body">
          <div className="task-setting__body-hr"></div>
          <div className={`task-list-inner`}>
            {task?.Children?.map((item, index) => (
              <div key={index} className={`task-list-inner__item ${collapsedViewClass === item?.ViewClass ? 'active' : ''}`} onClick={() => handleClickTask(item.ViewClass, item)}>
                <span className="status-item"></span>
                <div className="left-item">
                  <h3 className="left-item__title">{item.Label}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ListQuestionForTask;