import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actLoadAllPeriods } from "@actions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { MessageEmtyData } from "@ui-partents/Message";
import { LoaderText } from "@utils";
import { paginationOptions } from "@utils";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import styled from 'styled-components';
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import ReactTooltip from 'react-tooltip';
import editIcon from '@images/common/edit-action.svg'
import trashIcon from '@images/teachV2/trash.svg'
import { ButtonComponent } from "@components/patterns/elements/Button";
import moment from 'moment';
import Plus from '@images/common/plus-single.svg'
import ModalCreateUpdatePeriod from './ModalCreateUpdatePeriod';
import { apiCaller } from "@utils";

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .title-name {
    color: #081B2A;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .custom-tooltip {
    width: 340px;
  }
`

const WrapAction = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .item-action {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

const WrapFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .btn-add-new {
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 4px;
    span {
      white-space: nowrap;
    }
  }
`

const PeriodTab = () => {
  const dispatch = useDispatch();
  const { data: periodsStore, isLoading} = useSelector((state) => state?.loadPeriods || {});
  console.log(periodsStore);
  const [periodDetail, setPeriodDetail] = useState({});
  const [modal, setModal] = useState({
    createAndUpdate: false, 
  });

  const toggle = (type, row) => {
    setPeriodDetail(row);
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  // useEffect(() => {
  //   dispatch(actLoadAllPeriods({ Action: "Load" }));
  // }, [dispatch])

  const columns = [
    {
      dataField: "Name",
      text: "Trial Period",
      headerClasses: "text-nowrap font-weight-500",
    },
    {
      dataField: "StartTime",
      text: "Start Time",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "EndTime",
      text: "End Time",
      headerClasses: "text-nowrap font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "PeriodId",
      text: "Action",
      headerClasses: "text-nowrap font-weight-500 text-center",
      headerStyle: { width: "72px" },
      formatter: (cell, row) => {
        return (
          <WrapAction>
            <div className='item-action' data-tip data-for={`edit-${cell?.topic_id}`} onClick={() => toggle('createAndUpdate', row)}>
              <Icon src={editIcon} width={20} height={20} stroke="#475467"/>
            </div>
            <ReactTooltip id={`edit-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
              <span>Edit Period</span>
            </ReactTooltip>
            <WrapModalDeleteCommon
              title="Delete Period"
              sub="Are you certain that you would like to delete this trial period? Learner’s performances will not be removed. However, all analyzed data regarding this trial period will be removed."
              handleRemove={() => handleRemove(row)}
              idRemove={row?.id}
            >
              {({onOpen}) => (
                <>
                  <div className='item-action' data-tip data-for={`remove-${cell?.topic_id}`} onClick={onOpen}>
                    <Icon src={trashIcon} width={20} height={20} stroke="#475467"/>
                  </div>
                  <ReactTooltip id={`remove-${cell?.topic_id}`} place='top' effect='solid' className={`custom-tooltip`} >
                    <span>Delete Period</span>
                  </ReactTooltip>
                </>
              )}
            </WrapModalDeleteCommon>
          </WrapAction>
        );
      },
    }
  ];

  const handleRemove = (stateItem) => {
    const params = {
      Action: "Delete",
      PeriodId: stateItem?.PeriodId,
    };
    apiCaller(`/api/teach/period/`, "POST", params)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(actLoadAllPeriods({ Action: "Load" }));
        }
      })
      .catch(() => console.log("error"))
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="PeriodId"
        data={periodsStore || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-library__filter d-flex align-items-center justify-content-between">
              <WrapNote>
                <p className="title-name">Periods</p>
                <div data-tip data-for="note-tile">
                  <Icon src={info} width={20} height={20}/>
                </div>
                <ReactTooltip id="note-tile" place='top' effect='solid' className={`custom-tooltip`} >
                  <span>A period defines the timeframe in which the interactive modules will be available to learners.</span>
                </ReactTooltip>
              </WrapNote>
              <WrapFilter>
                <ButtonComponent 
                  size="small"
                  className="w-100 btn-add-new"
                  onClick={() => toggle("createAndUpdate", {})}
                >
                  <Icon src={Plus} stroke="#fff" width={20} height={20}/>
                  Add Period
                </ButtonComponent>
              </WrapFilter>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={periodsStore || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Periods Found"
                  text="You haven’t created any periods yet. Start by clicking ‘Add Period' to create a new one."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = periodsStore?.length || 0; 
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="position-relative">
      {isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
      ) : (
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
          {contentTable}
        </PaginationProvider>
      )}
      {modal?.createAndUpdate && (
        <ModalCreateUpdatePeriod
          isOpen={modal?.createAndUpdate}
          toggle={() => toggle("createAndUpdate", null)}
          item={periodDetail}
        />
      )}
    </div>
  )
}

export default PeriodTab
