import React from 'react';

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//components
import { CheckBoxDialog } from ".";

//format time
import moment from "moment";

//lodash
import _ from "lodash";

//image
import ImageAssistant from "@images/common/assistant.png";

function FrameChat(props) {
    //props
    const { data, showTime, profile_photo_url } = props && props;
    const dataChat = data?.dialog ? data?.dialog : data;

    // Loai bo role la System
    let filterRoleNotSysTem = _.filter(dataChat, function (o) { return o.role !== 'system' });
    // Lọc các phần tử theo is_response
    const users = _.filter(filterRoleNotSysTem, function (o) { return o.is_response === true });
    const listItems = users?.map((item, index) => {
        return (
            <div className="messengerCt__messages" key={index}>
                {item?.role === "user" && (
                    <div className="messengerCt__wrapper">
                        <div className="messengerCt__message messengerCt__message--out">
                            <div className="messengerCt__message-body">
                                <div className="messengerCt__message-content mr-0 --arrow-right">
                                    <div className="messengerCt__message-username">
                                        User:
                                    </div>
                                    <div className="messengerCt__message-text" dangerouslySetInnerHTML={{ __html: item?.content || '' }} />
                                </div>
                            </div>
                            <div className="messengerCt__message-pic">
                                <img src={profile_photo_url} alt="profile" />
                            </div>
                        </div>
                    </div>
                )}
                {item?.role === "assistant" && (
                    <div className={`messengerCt__wrapper`}>
                        <div className="messengerCt__message messengerCt__message--in">
                            <div className="messengerCt__message-pic">
                                <img
                                    src={ImageAssistant}
                                    alt="Assistant"
                                />
                            </div>
                            <div className="messengerCt__message-body">
                                <div className={`messengerCt__message-content --bg-blue --arrow-left`}>
                                    <div className="messengerCt__message-username">
                                        Assistant:
                                    </div>
                                    <div className="messengerCt__message-text">
                                        <div dangerouslySetInnerHTML={{ __html: item?.content || '' }} />
                                    </div>
                                </div>
                                {showTime && (
                                    <div className="timeChat d-flex justify-content-between my-2">
                                        {item?.send_time && (
                                            <div className="timeChat__date text-time">
                                                {moment(item?.send_time).format("LLL")}
                                            </div>
                                        )}
                                        {item?.latency && (
                                            <div className={`timeChat__sec text-time ${parseInt(item?.latency) >= 3 ? "text-red" : ""}`}>
                                                {item?.latency} sec
                                            </div>
                                        )}
                                    </div>
                                )}
                                <CheckBoxDialog {...props} item={item} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    })

    return (
        <div id="interact_panel_chat_models">
            <div className={`FrameChat__fullBox`} data-type="ai-voice">
                <Scrollbar style={{ height: 400 }}>
                    <div className="messengerCt">
                        {listItems}
                    </div>
                </Scrollbar>
            </div>
        </div>
    );
};

export default FrameChat;
