import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Folder from '@images/teachV2/folder.svg'
import { Col, Row } from 'reactstrap'
import ReactTooltip from 'react-tooltip';

const WrapListFolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 12px;
  .folder-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 12px;
  }
`

const WrapFolders = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemFolder = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  background-color: ${({ active }) => active ? '#F2F4F7' : '#FFF'};
  cursor: pointer;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #344054;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .__react_component_tooltip {
    background: #121926;
    color: #FFFFFF;
    opacity: 1;
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px #10182814;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    &::after {
      background-color: #121926 !important;
    }
  }
`

const ButtonShowMore = styled.div`
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #006E9B;
  cursor: pointer;
  width: max-content;
`

const ListFolderItem = (props) => {
  const { activeFolder, setActiveFolder, listFolders } = props;
  const [showFolder, setShowFolder] = useState(true)
  const [isShowMore, setIsShowMore] = useState(false)

  const handleShowData = () => {
    setShowFolder(!showFolder)
  }

  const handleShowMore = () => {
    setIsShowMore(!isShowMore)
  }

  return (
    <WrapListFolder>
      <div className='d-flex align-items-center'>
        <div onClick={handleShowData}>
          {showFolder ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
        </div>
        <p className="folder-title">All Folders</p>
      </div>
      {showFolder && (
        <WrapFolders>
          <Row className='m-0'>
            {listFolders?.map((folder, index) => (
              !isShowMore ? (
                index < 8 && (
                  <Col xs={3} className="px-2 mb-3">
                    <ItemFolder 
                      key={index} 
                      onClick={() => setActiveFolder(folder)}
                      active={activeFolder?.FolderId === folder?.FolderId}
                    >
                      <Icon src={Folder}/>
                      <p
                        data-tip
                        data-for={`folder-${index}`}
                      >
                        {folder?.FolderName}
                      </p>
                      <ReactTooltip id={`folder-${index}`} place='top' effect='solid'>
                        {folder?.FolderName}
                      </ReactTooltip>
                    </ItemFolder>
                  </Col>
                )
              ) : (
                <Col xs={3} className="px-2 mb-3">
                  <ItemFolder 
                    key={index} 
                    onClick={() => setActiveFolder(folder)}
                    active={activeFolder?.FolderId === folder?.FolderId}
                  >
                    <Icon src={Folder}/>
                    <p
                      data-tip
                      data-for={`folder-${index}`}
                    >
                      {folder?.FolderName}
                    </p>
                    <ReactTooltip id={`folder-${index}`} place='top' effect='solid'>
                      {folder?.FolderName}
                    </ReactTooltip>
                  </ItemFolder>
                </Col>
              )
            ))}
          </Row>
          {listFolders?.length > 8 && (
            <ButtonShowMore onClick={handleShowMore} className='mx-2'>
              {isShowMore ? "View less" : "View more"}
            </ButtonShowMore>
          )}
        </WrapFolders>
      )}
    </WrapListFolder>
  )
}

export default ListFolderItem
