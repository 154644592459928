import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormModal } from '@ui-partents/Modal';

import {
  Input,
} from "reactstrap";
import styled from "styled-components";
import { TitleNoteCommon } from "../../../home/generate";

const WrapForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const FormDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const ModalAdd = ({...props}) => {
  const { toggle, isOpen, handleAddSeries, seriesDetail } = props
  console.log(seriesDetail);

  //form
  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      title: seriesDetail?.Name ? seriesDetail?.Name : "",
      descriptions: "",
    }
  });

  const onSubmit = (data) => {
    const params = {
      Action: "Create",
      Title: data.title,
      Descriptions: data.descriptions,
    };
    handleAddSeries(params);
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title="Add Series"
      actionText={"Save"}
      maxWidth="750px"
      onAction={handleSubmit(onSubmit)}
    >
      <form className="form-platform">
        <WrapForm>
          <FormDate className='w-100 mb-0'>
            <TitleNoteCommon
              dataFor={'seriesName'} 
              title="Series Name" 
              content=""
              required
            />
            <Controller
              name="title"
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <>
                  <Input
                    type='text'
                    name='title'
                    placeholder="Example: Emergency Medicine Modules – Adult & Pediatric"
                    {...field}
                  />
                  {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                </>
              )}
            />
          </FormDate>
          <FormDate className='w-100 mb-0'>
            <TitleNoteCommon
              dataFor={'periodName'} 
              title="Period Name" 
              content=""
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='description'
                  placeholder="Example: This series covers emergency scenarios such as sepsis, cardiac arrest, and trauma management."
                  style={{ height: '80px' }}
                  {...field}
                />
              )}
            />
          </FormDate>
        </WrapForm>
      </form>
    </FormModal>
  );
};

export default ModalAdd;