import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
import Icon from '@components-common/Icon'
import Back from '@images/teachV2/back.svg'
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import {paginationOptions} from "@utils";
import { getReportCurriculum } from "@actions";
import { SortCaret } from "@ui-partents/Table";
import { FormGroup, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  max-width: 500px;
`

const ButtonCancel = styled.div`
  padding: 14px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #D0D5DD;
  span {
    color: #475467;
    font-size: 18px;
    font-weight: 500;
  }
`

export default function SelectSingle(props) {
  const { curriculum, setShowTypeSelect } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const initialSelectedBooks = [];
  const [filteredData, setFilteredData] = useState([]);
  const selectedCurRef = useRef(initialSelectedBooks);

  const handleToReportSingle = async () => {
    const listIdCohorts = selectedCurRef?.current?.map(item => item.CohortId)
    const dataReport = await dispatch(getReportCurriculum({
      ReportType: "CurriculumPerformance",
      ReportMode: "SingleCohort",
      CurriculumId: curriculum[0]?.CurriculumId,
      CohortId: listIdCohorts[0]
    }))
    if (dataReport?.data?.status === 200) {
      const listCohorts = JSON.stringify(selectedCurRef?.current[0])
      const curriculumActive = JSON.stringify(curriculum[0])
      history.push({
        pathname: '/teach/report-cohort-single',
        state: {  // location state
          listCohorts,
          curriculumActive, 
        },
      })
    }
  }

  useEffect(() => {
    if (curriculum[0]?.Cohorts) {
      setFilteredData(
        curriculum[0]?.Cohorts.map(item => ({
          ...item,
          selected: selectedCurRef.current.some(cur => cur.CohortId === item.CohortId),
        }))
      );
    }
  }, [curriculum]);

  const columns = [
    {
      dataField: "CohortName",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: { width: "96%" },
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <p>{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [];
      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(cur => cur.CohortId === row.CohortId);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }
      
      selectedCurRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(cur => cur.CohortId === item.CohortId),
      })));
    },
    selected: selectedCurRef.current.map(cur => cur.CohortId),
    selectionRenderer: ({ mode, checked }) => (
      <FormGroup check className="form-common px-1">
        <Input
          {...mode}  // This automatically handles checked state and onChange
          checked={checked} 
          type="radio"
          className="react-radio-common"
        />
      </FormGroup>
    ),
  };

  const handleClearSearch = () => {
    setFilteredData(
      curriculum[0]?.Cohorts.map(item => ({
        ...item,
        selected: selectedCurRef.current.some(cur => cur.CohortId === item.CohortId),
      })) || []
    );
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="CohortId"
          data={filteredData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder="Search"
                  onClear={handleClearSearch}
                />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-common"
                data={filteredData || []}
                bordered={false}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  // Calculate the total size based on documents
  const totalSize = filteredData.length || 0;

  // Retrieve pagination settings dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <>
    <div className="cohort-form cohort-scroll">
      <div className="cohort-title">
        <h1>Select a Cohort</h1>
        <p>Select ONE cohort from the list below.</p>
      </div>
      <WrapCommon>
        <div className="form-area">
          <div className="form-area__select">
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </div>
      </WrapCommon>
    </div>
    <div className='cohort-btn d-flex align-items-center justify-content-end'>
      <ButtonCancel
        onClick={() => setShowTypeSelect(false)} 
        style={{ display: 'flex' }}
      >
        <Icon src={Back}/>
        <span>
          Back
        </span>
      </ButtonCancel>
      <ButtonComponent 
        size="large" 
        style={{ fontSize: '18px', fontWeight: 500 }} 
        onClick={handleToReportSingle}
        disabled={!selectedCurRef?.current?.length}
      >
        Generate Report
      </ButtonComponent>
    </div>      
  </>
  )
}
