import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import DragQuestion from "./DragQuestion";
import { Icon } from '@components/common'
import styled from "styled-components";
import lightbulb from "@images/common/lightbulb.svg";
import { Iconsax } from "@components-common";
import { ButtonComponent } from "@components/patterns/elements/Button";
import ModalAddQuestion from "../../ComponentCommon/ModalAddQuestion";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title-question {
    font-weight: 500;
    font-size: 16px;
    color: #101828;
  }
  .header-explanation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    &__note {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    &__icon {
      min-width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #0089C2;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #475467;
    }
    &__add {
      min-width: fit-content;
    }
  }
`
function QuestionNarrativeMain(props) {
  const { activeViewClass, timepointData, explanation, type } = props;

  const questionViewActive = useSelector(
    (state) => state?.questionViewActive?.questionView || null
  );

  const [itemQuestion, setItemQuestion] = useState(null);
  const [modal, setModal] = useState({
    addQuestion: false,
    editQuestion: false,
  });

  const toggle = (type, item) => {
    setItemQuestion(item);
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  const filterQuestionForTask = (currentTask) => {
    const filterData = _.filter(
      timepointData?.Questions,
      (value) => value.AskedAtTask === currentTask
    );
    return filterData;
  };

  const questionByTask = filterQuestionForTask(activeViewClass === "narrative" ? "history" : activeViewClass === "advance_setting" ? "advance" : activeViewClass);

  return (
    <Wrapper key={questionViewActive}>
      {type === 'advance' && (
        <h1 className="title-question">Questions</h1>
      )}
      <div className="header-explanation">
        <div className="header-explanation__note">
          <div className="header-explanation__icon">
            <Icon src={lightbulb} />
          </div>
          <p className="header-explanation__text">{explanation}</p>
        </div>
        <ButtonComponent onClick={() => toggle("addQuestion")} className="header-explanation__add">
          <Iconsax iconName="plus-square" fill="#fff" size={15} />
          <span className="ml-2">Add Question</span>
        </ButtonComponent>
      </div>
      <div>
        <DragQuestion
          {...props}
          timePointId={timepointData?.Id}
          data={questionByTask}
          disableSort={false}
          task={activeViewClass}
          handleEditQuestion={toggle}
          itemQuestion={itemQuestion}
        />
      </div>
      {modal.addQuestion && (
        <ModalAddQuestion
          isOpen={modal.addQuestion}
          toggle={() => toggle("addQuestion", null)}
          {...props}
        />
      )}
      {modal.editQuestion && (
        <ModalAddQuestion
          isOpen={modal.editQuestion}
          toggle={() => toggle("editQuestion", null)}
          itemQuestion={itemQuestion}
          {...props}
        />
      )}
    </Wrapper>
  );
}

export default QuestionNarrativeMain;
