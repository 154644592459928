import React, { useState, useCallback, useEffect } from "react";
import { RichTextCommon } from "@components/teachV2/storyboard";
import { debounce } from "lodash";
import styled from "styled-components";
import lightbulb from "@images/common/lightbulb.svg";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import { Icon } from '@components/common'
import { toast } from "react-toastify";

const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  .header-explanation {
    display: flex;
    gap: 6px;
    align-items: center;
    &__icon {
      min-width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #0089C2;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #475467;
    }
  }
`

const ContentNarrative = ({
  activeViewClass,
  timepointData,
}) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [valueEditor, setValueEditor] = useState(
    timepointData?.DefaultNarrative
  );

  //Load Value
  useEffect(() => {
    setValueEditor(timepointData?.DefaultNarrative);
  }, [timepointData]);

  //Update narrative
  const handleApiContentNarrative = useCallback(
    (currentContent, currentTimepointId, valueNarrativeId) => {
      let params = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: currentTimepointId,
        Narrative: {
          Id: valueNarrativeId,
          Content: currentContent,
        },
      };

      //Call Api
      dispatch({
        type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_REQUEST,
        isLoading: true,
      });
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        if (res?.status === 200) {
          dispatch({
            type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_SUCCESS,
            payload: params?.Narrative || {},
          });
          toast.success(`Content updated successfully!`, {
            position: "top-right",
          });
        } else {
          dispatch({
            type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_FAILURE,
            error: "Failed to update the narrative content",
          });
        }
      });
    },
    [dispatch, id]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce(
      (currentContent, currentTimepointId, valueNarrativeId) =>
        handleApiContentNarrative(
          currentContent,
          currentTimepointId,
          valueNarrativeId
        ),
      500
    ),
    []
  );

  // debounce content
  const handleUpdateEditor = (currentContent, currentTask) => {
    const currentTimepointId = timepointData?.Id;
    const valueNarrativeId = timepointData?.DefaultNarrative?.Id ?? "";
    if (currentTask === "history" || currentTask === "narrative") {
      debounced(currentContent, currentTimepointId, valueNarrativeId);
      // Update valueEditor to synchronize with DefaultNarrative
      setValueEditor({
        ...timepointData?.DefaultNarrative,
        Content: currentContent,
      });
    }
  };

  return (
    <WrapContent className="content-narrative position-relative">
      <div className="header-explanation">
        <div className="header-explanation__icon">
          <Icon src={lightbulb} />
        </div>
        <p className="header-explanation__text">Enter the introductory information or context for this timepoint in the field below. This will be shown to the Learner at the start of this timepoint.</p>
      </div>
      <RichTextCommon
        handleUpdateEditor={(content) =>
          handleUpdateEditor(content, activeViewClass)
        }
        task={activeViewClass}
        defaultValue={valueEditor?.Content || ""}
        placeholder="Enter the instructions for the learner here..."
      />
    </WrapContent>
  );
};

export default ContentNarrative;
