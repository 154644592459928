import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import { useSelector, useDispatch } from "react-redux";
import { MessageEmtyData } from "@ui-partents/Message";
import { atcLoadAllCohorts } from "@actions";
import { SearchCustomForTable } from "@ui-partents/Search";
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import {paginationOptions} from "@utils";
import { SortCaret } from "@ui-partents/Table";
import { FormGroup, Input } from 'reactstrap';
import ClipLoader from "react-spinners/ClipLoader";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function SelectSingleCohort(props) {
  const { setValue, watch, handleNext, handleBack } = props;
  const dispatch = useDispatch();
  const initialSelectedBooks = watch('cohort_selected') || [];
  const { data: documents, isLoading } = useSelector((state) => state.listCohort) || {};
  const [filteredData, setFilteredData] = useState(documents || []); // dữ liệu gốc
  const selectedImagesRef = useRef(initialSelectedBooks);

  useEffect(() => {
    dispatch(atcLoadAllCohorts());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (documents) {
      // setFilteredData(documents.Combined); // Cập nhật dữ liệu đã lọc khi có thay đổi dữ liệu gốc
      setFilteredData(
        documents.map(item => ({
          ...item,
          selected: selectedImagesRef.current.some(image => image.CohortId === item.CohortId),
        }))
      );
    }
  }, [documents]);

  const handleActionBack = () => {
    setValue('cohort_selected', null)
    selectedImagesRef.current = [];
    setFilteredData([]);
    handleBack();
  }

  const handleNextSelect = () => {
    setValue('cohort_selected', selectedImagesRef.current);
    handleNext();
  };

  const columns = [
    {
      dataField: "Name",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: {
        width: "96%",
      },
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    // hideSelectColumn: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [];
      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.CohortId === row.CohortId);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }
      
      selectedImagesRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.CohortId === item.CohortId),
      })));
      setValue('cohort_selected', updatedSelectedBooks.map(book => book.CohortId));
    },
    selected: selectedImagesRef.current.map(book => book.CohortId),
    selectionRenderer: ({ mode, checked }) => (
      <FormGroup check className="form-common">
        <Input
          {...mode}  // This automatically handles checked state and onChange
          checked={checked} 
          type="radio"
          className="react-radio-common"
        />
      </FormGroup>
    ),
  };

  const handleClearSearch = () => {
    setFilteredData(
      documents.map(item => ({
        ...item,
        selected: selectedImagesRef.current.some(book => book.CohortId === item.CohortId),
      })) || []
    );
    setValue('cohort_selected', selectedImagesRef.current.map(book => book.CohortId));
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="CohortId"
          data={filteredData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder="Search"
                  onClear={handleClearSearch}
                />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-common"
                data={filteredData || []}
                bordered={false}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  // Calculate the total size based on documents
  const totalSize = filteredData.length || 0;

  // Retrieve pagination settings dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Select an Existing Series"
          subtitle="Choose a series from your available list to use for this assignment. To view or make changes to it, please go to Assignments > Assignment Management > Series."
        />
        {!isLoading ? (
          <div className="form-area__select">
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center">
            <ClipLoader color="#0089c3" size={26} />
          </div>
        )}
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          {...props} 
          dataPass={!selectedImagesRef.current.length}
          handleBack={handleActionBack}
          handleActionBackup={handleNextSelect}
        />
      </div>     
    </WrapCommon>
  )
}
