import React, { useEffect } from 'react'
import { useState } from 'react';
// import { Col, Container, Row } from 'reactstrap';
// import { Icon } from '@components/common'
// import Right from '@images/teachV2/turn-right.svg'
// import AddSeries from '@images/teachV2/add-series.svg'
import { InteractiveDetail } from '@components/teachV2/interactiveModule';
import { useDispatch, useSelector } from "react-redux";
import { instructorConstants } from "@constants";
// import ClipLoader from "react-spinners/ClipLoader";
import { apiCaller } from "@utils";
import { ModalSeries } from "@components/instructor/Modal";
import { actCreateSeries } from "@actions";
import { MessageEmtyData } from "@ui-partents/Message";
import { LoaderText } from "@utils";

const InteractiveWrap = () => {
  
  const dispatch = useDispatch();
  const instructorData = useSelector((state) => state.instructor || {});
  const [modal, setModal] = useState(false);
  const [actionsModal, setActionsModal] = useState("");
  const [dataCase, setCase] = useState(instructorData?.data?.Cases || []);
  const [loadingModule, setLoadingModule] = useState({
    instructor: false,
    case: false,
  })

  //load Data
  useEffect(() => {
    handelLoadAllCase()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // //load all case
  const handelLoadAllCase = () => {
    setLoadingModule({
      ...loadingModule,
      case: true
    });
    dispatch({ type: instructorConstants.INSTRUCTOR_REQUEST, isLoading: true });
    apiCaller(`/api/teach/portfolio/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: instructorConstants.INSTRUCTOR_SUCCESS, payload: data });
        setCase(data?.Cases);
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      } else {
        dispatch({ type: instructorConstants.INSTRUCTOR_FAILURE, error: "error" });
        setLoadingModule({
          ...loadingModule,
          case: false
        });
      }
    });
  };

  const toggleAddSeries = () => {
    setModal(!modal);
    setActionsModal("add");
  };

  const handleAddSeries = (params) => {
    dispatch(actCreateSeries(params));
    setModal(false);
  };

  return (
    <div className="position-relative pt-4">
      {instructorData?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
      ) : (
        instructorData?.data?.Cases?.length === 0 ? (
          <MessageEmtyData 
            title="Your Library is Empty"
            text="It looks like you haven't generated any interactive modules yet. Start creating a new one to see it here."
          />
        ) : (
          <InteractiveDetail dataCase={dataCase} instructorData={instructorData?.data?.Cases} loadingModule={loadingModule}/>
        )
      )}
      <ModalSeries
        isOpen={modal}
        toggle={toggleAddSeries}
        handleAddSeries={handleAddSeries}
        actionsModal={actionsModal}
      />
    </div>
  )
}

export default InteractiveWrap
