import React from 'react'
import styled from 'styled-components'
import imgUser from '@images/common/placehoder-avatar.svg';

// UI Components
import { AvatarImage } from '@ui-partents/Avatar';
import ProgressBar from './ProgressBar';

const WrapCotent = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  .wrap-status {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`

const WrapInformation = styled.div`
  display: flex;
  gap: 16px;
  .wrap-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__name {
      display: flex;
      flex-direction: column;
      gap: 2px;
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        color: #121926;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #697586;
      }
    }
    &__status {
      padding: 2px 6px;
      border: 1px solid #ABEFC6;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      white-space: nowrap;
      color: #17B26A;
      border-radius: 120px;
    }
  }
` 

const StatusDetail = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #D0D5DD;
  .status__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color:#4B5565;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      color: #4B5565;
    }
  }
`

const ContentInfomation = () => {
  return (
    <WrapCotent>
      <WrapInformation>
        <AvatarImage
          image={imgUser}
          size="xl"
          isOnline={false}
        />
        <div className="wrap-info">
          <div className="wrap-info__name">
            <h1>Nia Nkosi</h1>
            <p>nia.nkosi@uni.edu</p>
          </div>
          <p className="wrap-info__status">Medical Students Y3</p>
        </div>
      </WrapInformation>
      <div className="wrap-status">
        <StatusDetail>
          <ProgressBar number={14} />
          <div className="status__info">
            <p>Not started</p>
            <h1>7 Cases</h1>
          </div>
        </StatusDetail>
        <StatusDetail>
          <ProgressBar number={14} />
          <div className="status__info">
            <p>In progress</p>
            <h1>7 Cases</h1>
          </div>
        </StatusDetail>
        <StatusDetail>
          <ProgressBar number={14} />
          <div className="status__info">
            <p>Completed</p>
            <h1>7 Cases</h1>
          </div>
        </StatusDetail>
      </div>
    </WrapCotent>
  )
}

export default ContentInfomation
