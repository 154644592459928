import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useSelector } from "react-redux";
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";

//Icon
import {paginationOptions} from "@utils";

const dataInit = [
  {
    "name": "Nia Nkosi",
    "email": "nia.nkosi@uni.edu",
    "cohort": "Medical Students Y3",
    "rate": 64,
    "alert": true,
  },
  {
    "name": "Sofia Rossi",
    "email": "sofia.rossi@uni.edu",
    "cohort": "Clinical Residents 2023",
    "rate": 83,
    "alert": false,
  },
  {
    "name": "Hassan Khan",
    "email": "hassan.khan@uni.edu",
    "cohort": "Medical Students Y3",
    "rate": 74,
    "alert": true,
  },
  {
    "name": "Ayesha Qureshi",
    "email": "ayesha.qureshi@uni.edu",
    "cohort": "Medical Students Y3",
    "rate": 58,
    "alert": false,
  },
  {
    "name": "Leila Farah",
    "email": "leila.farah@uni.edu",
    "cohort": "Medical Students Y4",
    "rate": 79,
    "alert": false,
  },
  {
    "name": "Yuki Nakamura",
    "email": "yuki.nakamura@uni.edu",
    "cohort": "Pharmacy Students Y2",
    "rate": 92,
    "alert": true,
  },
]

const ListReport = (props) => {
  const { setShowDetail, setDataDetail } = props;
  const listQuestionBank = useSelector((state) => state.getQuestionBankOrg || []);

  const columns = [
    {
      dataField: "name",
      text: "Learner",
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <h1>{cell}</h1>
            <p>{row.email}</p>
          </div>
        );
      },
    },
    {
      dataField: "cohort",
      text: "Corhort",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell || ""}
          </div>
        );
      },
    },
    {
      Field: "rate",
      text: "Completion Rate (%)",
      headerClasses: "align-middle text-center",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell || ""}
          </div>
        );
      },
    },
    {
      dataField: "alert",
      text: "Alert",
      formatter: (cell, row) => {
        return (
          cell ? (
            <div className={`d-flex align-items-center question-type type-error`}>
              <p>
                Attention Needed
              </p>
            </div>
          ) : (
            <></>
          )
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-center",
      formatter: (cell, row) => {
        return (
          <div 
            className="d-flex align-items-center justify-content-center action-orgRefer"
            onClick={() => {
              setShowDetail(true);
              setDataDetail(row);
            }}
          >
            <p>
              View Details
            </p>
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="document_id"
        data={dataInit || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Learners: {dataInit?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={dataInit || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = dataInit?.length || 0; // Determine the total size dynamically
  
  // Retrieve dynamic pagination settings
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        dataInit?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default ListReport
