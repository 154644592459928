import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

// redux
import { useSelector } from "react-redux";

//api
import ContentNarrative from "./NarrativeSetting/ContentNarrative";
import QuestionNarrativeMain from "./NarrativeSetting/QuestionNarrative/QuestionNarrativeMain";

const NarrativeSetting = (props) => {
  //state
  const [activeTab, setActiveTab] = useState(1);

  // store
  const questionViewActive = useSelector(
    (state) => state?.questionViewActive?.questionView || null
  );

  const statusOptions = [
    {
      key: 1,
      label: "Narrative Content",
      content: (
        <ContentNarrative {...props}/>
      ),
    },
    { 
      key: 2, 
      label: "Questions", 
      content: (
        <QuestionNarrativeMain
          {...props}
          explanation="These questions appear after the Narrative. The Learner must answer them before proceeding to other tasks." 
          type="history"
        />
      ) 
    },
  ];

  // Handle Tab Change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = statusOptions?.map((statusOption) => ({
    id: statusOption.key,
    title: statusOption.label,
    content: statusOption.content,
  }));

  // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
  useEffect(() => {
    if (questionViewActive === "Blank_Question") {
      setActiveTab(2); // 3 corresponds to the "Questions" tab
    }
  }, [questionViewActive]);

  return (
    <div className="available-tasks-main">
      <TabComponentBorder
        tabs={tabs || []}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default NarrativeSetting;
