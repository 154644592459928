import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useSelector } from "react-redux";
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";

//Icon
import {paginationOptions} from "@utils";
import styled from 'styled-components'

const WrapComparision = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  .comparision-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      width: 9px;
      height: 9px;
      background-color: #17B26A;
      border-radius: 50%;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #101828;
    }
  }
`

const dataInit = [
  {
    "name": "Nia Nkosi",
    "email": "nia.nkosi@uni.edu",
    "disease_knowledge": 88,
    "medical_science_knowledge": 82,
    "reasoning": 75,
    "applied_reasoning": 79,
    "alert": true,
  },
  {
    "name": "Sofia Rossi",
    "email": "sofia.rossi@uni.edu",
    "disease_knowledge": 83,
    "medical_science_knowledge": 89,
    "reasoning": 65,
    "applied_reasoning": 51,
    "alert": false,
  },
  {
    "name": "Hassan Khan",
    "email": "hassan.khan@uni.edu",
    "disease_knowledge": 75,
    "medical_science_knowledge": 62,
    "reasoning": 78,
    "applied_reasoning": 79,
    "alert": true,
  },
  {
    "name": "Ayesha Qureshi",
    "email": "ayesha.qureshi@uni.edu",
    "disease_knowledge": 69,
    "medical_science_knowledge": 52,
    "reasoning": 72,
    "applied_reasoning": 54,
    "alert": false,
  },
  {
    "name": "Leila Farah",
    "email": "leila.farah@uni.edu",
    "disease_knowledge": 86,
    "medical_science_knowledge": 73,
    "reasoning": 92,
    "applied_reasoning": 94,
    "alert": false,
  },
  {
    "name": "Yuki Nakamura",
    "email": "yuki.nakamura@uni.edu",
    "disease_knowledge": 64,
    "medical_science_knowledge": 56,
    "reasoning": 51,
    "applied_reasoning": 67,
    "alert": true,
  },
]

const AverageScoresByLeaner = (props) => {
  const listQuestionBank = useSelector((state) => state.getQuestionBankOrg || []);

  const columns = [
    {
      dataField: "name",
      text: "Learner",
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <h1>{cell}</h1>
            <p>{row.email}</p>
          </div>
        );
      },
    },
    {
      dataField: "disease_knowledge",
      text: "Disease Knowledge",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell}
          </div>
        );
      },
    },
    {
      dataField: "medical_science_knowledge",
      text: "Medical Science Knowledge",
      headerClasses: "align-middle text-center",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell}
          </div>
        );
      },
    },
    {
      dataField: "reasoning",
      text: "Reasoning Principles",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell}
          </div>
        );
      },
    },
    {
      dataField: "applied_reasoning",
      text: "Applied Reasoning",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell}
          </div>
        );
      },
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="document_id"
        data={dataInit || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              {/* <div className='tb-heading-total'>Total Learners: {dataInit?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
              </div> */}
              <WrapComparision>
                <div className="comparision-header">
                  <span></span>
                  <h1>Domain Average Scores By Leaner</h1>
                </div>
                <div className='d-flex align-items-center'>
                  <div className="boxSearch">
                    <SearchCustomForTable
                      toolkitprops={toolkitprops}
                      paginationProps={paginationProps}
                      isResetPagination
                      searchMode="auto"
                      placeholder={`Search`}
                    />
                  </div>
                </div>
              </WrapComparision>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={dataInit || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = dataInit?.length || 0; // Determine the total size dynamically
  
  // Retrieve dynamic pagination settings
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        dataInit?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default AverageScoresByLeaner
