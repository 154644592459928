import React, { useCallback, useEffect, useState } from "react";
import { FormUpload } from "@ui-partents/Form/FormUpload";

//Cropper
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "@utils/cropimage";

//partents
import { FormModal } from "@ui-partents/Modal";
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { teachConstants } from "@constants";

const ModalUploadImage = (props) => {
  const { toggle, isOpen, id } = props && props;
  const dispatch = useDispatch();

  //state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setLoading] = useState(false);

  //Crop compeleted
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  
  // Handle file upload
  const handleFileUpload = async (file) => {
    let imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);
  };

  //Submit Api
  const handleUploadAvatar = useCallback(
    async (formData) => {
      dispatch({ type: teachConstants.UPDATE_AVATAR_REQUEST, isLoading: true });
      //Call Api
      apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
        const data = res && res.data;
        if (res && res.status === 200) {
          dispatch({
            type: teachConstants.UPDATE_AVATAR_SUCCESS,
            payload: data,
          });
          setTimeout(() => {
            setLoading(false);
            toggle();
          }, 2000);
        } else {
          dispatch({
            type: teachConstants.UPDATE_AVATAR_FAILURE,
            error: "error",
          });
          setTimeout(() => {
            setLoading(false);
            toggle();
          }, 2000);
        }
      });
    },
    [id, dispatch, toggle]
  );

  //Save Crop
  const cropImageAndSave = useCallback(async () => {
    try {
      const rotation = 0;
      const blobData = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      const formData = new FormData();
      formData.append("Action", "Update");
      formData.append("Target", "Avatar");
      setLoading(true);
      formData.append("PatientAvatarFile", blobData);
      //Case - Upload Case Patient Avatar
      handleUploadAvatar(formData);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc, handleUploadAvatar]);

  //Read file upload
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  //Load
  useEffect(() => {
    if (isOpen) {
      setImageSrc(null);
    }
  }, [isOpen]);

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Upload Image`}
      hiddenIcon={true}
      actionText={`Upload`}
      maxWidth={`600px`}
      onAction={cropImageAndSave}
      actionDisabled={isLoading || !imageSrc}
    >
      {imageSrc ? (
        <div className="cropImgCt">
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              aspect={1 / 1}
            />
          </div>
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              className="slider"
            />
          </div>
        </div>
      ) : (
        <>
          <FormUpload
            dataFileImported={imageSrc || null}
            setDataFileImported={handleFileUpload}
            typeUpload="JPG, JPEG, PNG. Max: 10 MB."
            typeFile={['image/jpeg', 'image/png']}
            errorFile="Only JPG, JPEG, PNG image files are allowed."
          />
        </>
      )}
    </FormModal>
  );
};

export default ModalUploadImage;
