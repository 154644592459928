import React from 'react';

function TabComponentBorder({ tabs, activeTab, onTabChange, theme, classNames }) {
  return (
    <div className="--new-tabs-custom">
      <ul className={`--new-tabs-border ${classNames && classNames} ${theme}`}>
        {tabs?.map((tab) => (
          <li className="--new-tabs-border__item" key={tab.id}>
            <span
              className={`--new-tabs-border__item--link ${theme ? 'text-size-sm' : ''} ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => onTabChange(tab.id)}
            >
              {tab.title}
            </span>
          </li>
        ))}
      </ul>

      <div className="tab-content mt-3">
        {tabs?.map((tab) => (
          activeTab === tab.id && (
            <div
              className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
              id={tab.id}
              key={tab.id}
            >
              {tab.content}
            </div>
          )
        ))}
      </div>
    </div>
  );
}

export default TabComponentBorder;