import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import { BtnIconEditV2 } from "@ui-partents/Button";
import { Icon } from '@components/common'
import Tick from '@images/common/tick-check.svg'
import Close from '@images/chatvoice/x-close.svg'
import { useDispatch } from "react-redux";
import { actUpdateTeachCaseRequest } from "@actions";

const WrapHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;  

  .header-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #101828;
  }
`

const WrapEdit = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  &:focus-within {
    border-color: #0089C2;
    box-shadow: 0px 0px 0px 4px #006E9B1F;
  }
  .edit__content {
    padding: 8px 0;
    font-weight: 600;
    font-size: 18px;
    color: #101828;
  }
  .edit__action {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 4px;
    border-left: 1px solid #D0D5DD;
    cursor: pointer;
  }
`

const CaseHeader = (props) => {
  const { teachCaseData, id } = props;
  const dispatch = useDispatch();
  const [caseData, setCaseData] = useState(teachCaseData);
  const [isEdit, setIsEdit] = useState(false);
  const refHeader = useRef()

  useEffect(() => {
    setCaseData(teachCaseData);
  }, [teachCaseData]);

  const handleSave = async (e) => {
    const params = {
      Action: "Update",
      Title: refHeader?.current?.innerText || '',
    };
    const dataUpdate = await dispatch(actUpdateTeachCaseRequest(id, params));
    if (dataUpdate.status === 200) {
      setIsEdit(false)
    }
  };

  return (
    <div className="case-header w-100">
      {isEdit ? (
        <WrapEdit>
          <div 
            contentEditable
            ref={refHeader}
            className="edit__content"
          >{caseData?.Title}</div>
          <div className="edit__action">
            <div onClick={() => setIsEdit(false)}>
              <Icon src={Close} width={24} stroke="#1D2939"/>
            </div>
            <div onClick={() => handleSave()}>
              <Icon src={Tick} width={24} height={24} color="#1D2939"/>
            </div>
          </div>
        </WrapEdit>
      ) : (
        <WrapHeaderTitle>
          <p className="header-title">{caseData?.Title}</p>
          <BtnIconEditV2
            handleEditButtonClick={() => setIsEdit(true)}
            size={20}
          />
        </WrapHeaderTitle>
  
      )}
    </div>
  );
};

export default CaseHeader;
