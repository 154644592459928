import { periodsConstant } from "@constants";
import {apiCaller} from "@utils";

//Load All Periods
export const actLoadAllPeriods = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/period/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: periodsConstant.LOAD_ALL_PERIODS_REQUEST, isLoading } }
    function success(data) { return { type: periodsConstant.LOAD_ALL_PERIODS_SUCCESS, payload: data } }
    function failure(error) { return { type: periodsConstant.LOAD_ALL_PERIODS_FAILURE, error: error } }
}

//Create Period
export const actCreatePeriod = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/period/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
                return res
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: periodsConstant.CREATE_PERIODS_REQUEST, isLoading } }
    function success(data) { return { type: periodsConstant.CREATE_PERIODS_SUCCESS, payload: data } }
    function failure(error) { return { type: periodsConstant.CREATE_PERIODS_FAILURE, error: error } }
}

//Update Period
export const actUpdatePeriod = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/period/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: periodsConstant.UPDATE_PERIODS_REQUEST, isLoading } }
    function success(data) { return { type: periodsConstant.UPDATE_PERIODS_SUCCESS, payload: data } }
    function failure(error) { return { type: periodsConstant.UPDATE_PERIODS_FAILURE, error: error } }
}

//Remove Period from Case
export const actRemovePeriodfromCase = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/period/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: periodsConstant.REMOVE_PERIOD_FROM_CASE_REQUEST, isLoading } }
    function success(data) { return { type: periodsConstant.REMOVE_PERIOD_FROM_CASE_SUCCESS, payload: data } }
    function failure(error) { return { type: periodsConstant.REMOVE_PERIOD_FROM_CASE_FAILURE, error: error } }
}

//Delete Period
export const actRemovePeriod = (params, PeriodId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/period/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
                return res
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: periodsConstant.REMOVE_PERIOD_REQUEST, isLoading } }
    function success(data) {
        return { 
            type: periodsConstant.REMOVE_PERIOD_SUCCESS, 
            payload: data,
            PeriodId: PeriodId
        }
    }
    function failure(error) { return { type: periodsConstant.REMOVE_PERIOD_FAILURE, error: error } }
}