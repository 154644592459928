import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VirtualSwitch from "./VirtualSwitch";

const WrapOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 40px 0;
  .option__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 40.8px;
    color: #000000;
  }
  .option__list {
    display: flex;
    align-items: center;
    gap: 40px;
  }
`;

const OptionEdit = (props) => {
  const { timepointData, dataResponse } = props;
  const PatientAvatarUrl = dataResponse?.PatientAvatarUrl;
  const PreceptorAvatarUrl = timepointData?.Characters?.Instructor?.Character?.StandardSquarePhoto;
  
  const [dataList, setDataList] = useState([]);
  
  useEffect(() => {
    const statusInteractive = timepointData?.TaskElements?.filter((el) => el.Label === "Interactive Chat") || [];
    if (timepointData) {
      setDataList([
        {
          name: "Virtual Instructor",
          isActive: "Active",
          imgUrl: PreceptorAvatarUrl,
          type: "instructor",
          description_active: "Always active – Click to edit",
          description_inactive: "Always active – Click to edit",
        },
        {
          name: "Virtual Patient",
          isActive: statusInteractive[0]?.Status,
          imgUrl: PatientAvatarUrl,
          type: "patient",
          description_active: "Active for this timepoint",
          description_inactive: "Inactive for this timepoint",
        },
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timepointData, dataResponse])

  return (
    <WrapOption>
      {dataList?.length > 0 && (
        <WrapContent>
          <h1 className="option__title">
            Click on the Persona that You Would Like to Edit
          </h1>
          <div className="option__list">
            {dataList?.map((data, index) => (
              <VirtualSwitch
                key={index}
                dataVirtual={data}
                dataList={dataList}
                setDataList={setDataList}
                {...props}
              />
            ))}
          </div>
        </WrapContent>
      )}
    </WrapOption>
  );
};

export default OptionEdit;
