import React from "react";
import { CustomInput } from "reactstrap";
import { AnswerItemsWrapper, RadioItem, RadioLabel, RadioText } from "./style";

function SelectOneVoiceGroup(props) {
  const { AnswerChoices, handleSelectOne } = props;

  return (
    <AnswerItemsWrapper>
      {AnswerChoices?.map((item, i) => (
        <RadioItem key={i} className={`${item?.Selected ? "selected" : ""}`}>
          <RadioLabel className="mb-0 font-weight-400">
            <CustomInput
              id={`radio_${i}`}
              type="radio"
              name="Selected"
              onChange={(e) => handleSelectOne(item, e)}
              value={item.Id}
              checked={item?.Selected}
              className="d-none"
            />
            <RadioText className={`${item?.Selected ? 'text-white' : ''}`}>{item.Content}</RadioText>
          </RadioLabel>
        </RadioItem>
      ))}
    </AnswerItemsWrapper>
  );
}

export default SelectOneVoiceGroup;