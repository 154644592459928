import React from 'react'
import styled from 'styled-components'
import { DomainProgress } from '../domainProgress'
import { DetailPerformance } from '../detailPerformance';
import Select from 'react-select';
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
import { useHistory } from 'react-router-dom';

const WrapDetailReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .wrap-select {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__back {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #081B2A;
      }
    }
    &__list {
      display: flex;
      align-items: center;
      gap: 16px;
      .react-select-common {
        width: 200px;
      }
    }
  }
`

const ReportDetailCohort = (props) => {
  const { type } = props;
  const history = useHistory();

  const listCurriculum = [{
    label: "UCR Curriculum",
    value: "UCR"
  }]

  const listOptionCohort = [{
    label: "Medical Students Y3",
    value: "Y3"
  }]

  const handleBack = () => {
    history.push('/teach/report-cohort')
  }

  return (
    <WrapDetailReport>
      <div className="wrap-select">
        <div className="wrap-select__back" onClick={handleBack}>
          <Icon src={Back} width="20" stroke="#000"/>
          <span>Back</span>
        </div>
        <div className="wrap-select__list">
          <Select
            className={`react-select-common`}
            placeholder="Select item"
            options={listCurriculum}
            classNamePrefix="react-select-custom"
            value={listCurriculum[0]}
          />
          <Select
            className={`react-select-common`}
            placeholder="Select item"
            options={listOptionCohort}
            classNamePrefix="react-select-custom"
            value={listOptionCohort[0]}
          />
        </div>
      </div>
      {type === 'single-cohort' && <DomainProgress />}
      <DetailPerformance {...props} />
    </WrapDetailReport>
  )
}

export default ReportDetailCohort
