import React from 'react'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingData = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
  }
`

export default function LoadingCohort() {
  return (
    <LoadingData className="mt-5">
      <ClipLoader size={32} color="#0089C2" />
      <p>Loading...</p>
    </LoadingData>
  )
}
