import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";

//alert
import { Toasts } from "@components/common/Toasts";
import { toast } from "react-toastify";

//actions elements
import { loadLabsForElement } from "@actions";

//fetch story board
export const actFetchTimeline = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
        return data; // Trả về data để có thể sử dụng trong hàm gọi
      } else {
        dispatch(failure("error"));
        return null; // Trả về null trong trường hợp lỗi
      }
    });
  };

  function request(isLoading) {
    return { type: storyboardConstants.TIMLINE_REQUEST, isLoading };
  }
  
  function success(data) {
    return { type: storyboardConstants.TIMLINE_SUCCESS, payload: data };
  }
  
  function failure(error) {
    return { type: storyboardConstants.TIMLINE_FAILURE, error: error };
  }
};

//load Case
export const actFetchLoadTimePoint = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.TIME_POINT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.TIME_POINT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.TIME_POINT_FAILURE, error: error };
  }
};

//Load timePoint
export const actFetchLoadTimePointNotLoading = (id, params) => {
  return (dispatch) => {
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        console.log("success");
      } else {
        console.log("error");
      }
    });
  };
};

//Update Item Timepoint
export const atcUpdateItemTimePoint = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_FAILURE,
      error: error,
    };
  }
};

//Update Timepoint - remove***
export const atcUpdateTimePoint = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      const Alert = data?.Alert;
      const alertSeverity = Alert?.Severity;
      const alertShowDuration = Alert?.ShowDuration;
      if (res?.status === 200) {
        dispatch(success(data));

        //Reload Timepoint
        const paramsTimepoint = {
          Action: "Load",
          Target: "TimePoint",
          TimePointId: String(params.TimePointId),
        };
        dispatch(actFetchLoadTimePoint(id, paramsTimepoint));

        //show toast
        if (alertSeverity && alertSeverity === "success") {
          toast.success(<Toasts data={Alert} />, {
            autoClose: alertShowDuration * 1000,
            toastId: "UPDATE_TIMEPOINT_REQUEST",
          });
        }
      } else {
        dispatch(failure("error"));

        //show toast
        if (alertSeverity && alertSeverity === "warning") {
          toast.warning(<Toasts data={Alert} />, {
            autoClose: alertShowDuration * 1000,
            toastId: "UPDATE_TIMEPOINT_REQUEST",
          });
        }
        if (alertSeverity && alertSeverity === "danger") {
          toast.error(<Toasts data={Alert} />, {
            autoClose: alertShowDuration * 1000,
            toastId: "UPDATE_TIMEPOINT_REQUEST",
          });
        }
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.UPDATE_TIMEPOINT_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.UPDATE_TIMEPOINT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return { type: storyboardConstants.UPDATE_TIMEPOINT_FAILURE, error: error };
  }
};

//Update Exam Point (Drag and Drop)
export const atcUpdateExamPoint = (id, params) => {
  //fix loi bi lag
  return (dispatch) => {
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        console.log("OK");
      } else {
        console.log("error");
      }
    });
  };
};

//Case - Delete Exam Point
export const actDeleteExamPointById = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.DELETE_EXAM_POINT_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.DELETE_EXAM_POINT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.DELETE_EXAM_POINT_FAILURE,
      error: error,
    };
  }
};

export const actCreateExamPoint = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.CREATE_EXAM_POINT_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.CREATE_EXAM_POINT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.CREATE_EXAM_POINT_FAILURE,
      error: error,
    };
  }
};

//Load Labs
export const atcLoadLabs = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.LOAD_LABS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.LOAD_LABS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.LOAD_LABS_FAILURE, error: error };
  }
};

//Update Lab Items Order
export const actSortLabItem = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.SORT_LAB_ITEM_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.SORT_LAB_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.SORT_LAB_ITEM_FAILURE, error: error };
  }
};

//Load AttributesForEffects
export const atcLoadAttributesForEffects = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.ATTRIBUTESF_OR_EFFECTS_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.ATTRIBUTESF_OR_EFFECTS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.ATTRIBUTESF_OR_EFFECTS_FAILURE,
      error: error,
    };
  }
};

//Update Timepoint - Vital Sign Change
export const actVitalSignChange = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.VITAL_SIGN_CHANGE_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.VITAL_SIGN_CHANGE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.VITAL_SIGN_CHANGE_FAILURE,
      error: error,
    };
  }
};

//Create Vital Sign (Case - Create Vital Sign)
export const actCreateVitalSign = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.CREATE_VITAL_SIGN_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.CREATE_VITAL_SIGN_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.CREATE_VITAL_SIGN_FAILURE,
      error: error,
    };
  }
};

//Delete Vital Sign (Case - Delete Vital Sign)
export const actDeleteVitalSign = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.DELETE_VITAL_SIGN_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.DELETE_VITAL_SIGN_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.DELETE_VITAL_SIGN_FAILURE,
      error: error,
    };
  }
};

//Case - Update Lab Change
export const actUpdateLabChange = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.LAB_CHANGE_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.LAB_CHANGE_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.LAB_CHANGE_FAILURE, error: error };
  }
};

//Case - Update Study Change
export const actUpdateStudyChange = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.UDPATE_STUDY_CHANGE_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.UDPATE_STUDY_CHANGE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UDPATE_STUDY_CHANGE_FAILURE,
      error: error,
    };
  }
};

//Case - Update Availability - Studies - Labs
export const atcUpdateOneOnlyCommon = (id, params, idAvailable) => {
  return (dispatch) => {
    // dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 'success') {
        // dispatch(success(data, idAvailable));
        console.log("Update Availability - Studies - Labs - success", idAvailable);
      }
    });
  };
  // function request(isLoading) {
  //   return {
  //     type: storyboardConstants.UPDATE_COMMON_AVAILABILITY_REQUEST,
  //     isLoading
  //   };
  // }
  // function success(data) {
  //   return {
  //     type: storyboardConstants.UPDATE_COMMON_AVAILABILITY_SUCCESS,
  //     payload: data,
  //     idAvailable: idAvailable,
  //     isChecked: params?.LabAvailability?.Available || params?.StudyAvailability?.Available
  //   };
  // }
  // function failure(error) {
  //   return {
  //     type: storyboardConstants.UPDATE_COMMON_AVAILABILITY_FAILURE,
  //     error: error,
  //   };
  // }
};

//Case - Update Lab Panel Availability
export const atcUpdateLabPanelAvailability = (id, params, idAvailable) => {
  return (dispatch) => {
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data?.status === 'success') {
        console.log("Update Lab Panel Availability - success", idAvailable);
      } else {
        console.log("error");
      }
    });
  };
  // function success(data, idAvailable, params) {
  //   return {
  //     type: storyboardConstants.UPDATE_LAB_PANEL_AVAILABILITY_SUCCESS,
  //     payload: data,
  //     idAvailable: idAvailable,
  //     isChecked: params?.LabAvailability?.Available
  //   };
  // }
};

//Case - Update Timepoint - Exam Finding
export const updateTimePointOfExamFinding = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.UPDATE_TIMEPOINT_EXAM_FINDING_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.UPDATE_TIMEPOINT_EXAM_FINDING_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UPDATE_TIMEPOINT_EXAM_FINDING_FAILURE,
      error: error,
    };
  }
};

//Case - Create Timepoint - Exam Finding
export const createTimePointOfExamFinding = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.CREATE_TIMEPOINT_EXAM_FINDING_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.CREATE_TIMEPOINT_EXAM_FINDING_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.CREATE_TIMEPOINT_EXAM_FINDING_FAILURE,
      error: error,
    };
  }
};

//Case - Load Exam Finding
export const actLoadExamFindingById = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.LOAD_EXAM_FINDINGS_ID_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.LOAD_EXAM_FINDINGS_ID_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.LOAD_EXAM_FINDINGS_ID_FAILURE,
      error: error,
    };
  }
};

//Case - Delete Exam Finding
export const actDeleteExamFindingById = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.DELETE_EXAM_FINDINGS_ID_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: storyboardConstants.DELETE_EXAM_FINDINGS_ID_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.DELETE_EXAM_FINDINGS_ID_FAILURE,
      error: error,
    };
  }
};

//Case - Create Lab Panel
export const atcCreateLabPanel = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.CREATE_LAB_PANEL_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.CREATE_LAB_PANEL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return { type: storyboardConstants.CREATE_LAB_PANEL_FAILURE, error: error };
  }
};

//Case - Update Lab Panel
export const atcUpdateLabPanel = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.UPDATE_LAB_PANEL_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.UPDATE_LAB_PANEL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return { type: storyboardConstants.UPDATE_LAB_PANEL_FAILURE, error: error };
  }
};

//Case - Delete Lab Panel
export const atcDeleteLabPanel = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.DELETE_LAB_PANEL_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: storyboardConstants.DELETE_LAB_PANEL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return { type: storyboardConstants.DELETE_LAB_PANEL_FAILURE, error: error };
  }
};

//Case - Update Lab Item
export const atcUpdateLabItem = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));

        //Load labs for elements
        const paramsLoad = {
          Action: "Load",
          Target: "Labs",
        };
        dispatch(loadLabsForElement(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.UPDATE_LAB_ITEM_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.UPDATE_LAB_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.UPDATE_LAB_ITEM_FAILURE, error: error };
  }
};

//Case - Create Lab Item
export const atcCreateLabItem = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.CREATE_LAB_ITEM_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.CREATE_LAB_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.CREATE_LAB_ITEM_FAILURE, error: error };
  }
};

// Case - Delete Lab Item
export const atcDeleteLabItem = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.DELETE_LAB_ITEM_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.DELETE_LAB_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.DELETE_LAB_ITEM_FAILURE, error: error };
  }
};

//Case - Update Attribute Score (Lab)
export const atcUpdateScoreLab = (id, params, attributeId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data, attributeId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_LAB_REQUEST,
      isLoading,
    };
  }
  function success(data, attributeId) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_LAB_SUCCESS,
      payload: data,
      AttributeId: attributeId,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_LAB_FAILURE,
      error: error,
    };
  }
};

//Case - Update Attribute Score (Independent Labs)
export const atcUpdateScoreIndependentLabs = (id, params, attributeId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data, attributeId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_REQUEST,
      isLoading,
    };
  }
  function success(data, attributeId) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_SUCCESS,
      payload: data,
      AttributeId: attributeId,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_FAILURE,
      error: error,
    };
  }
};

//Case - Update Attribute Score (Study)
export const atcUpdateScoreStudy = (id, params, attributeId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data, attributeId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_STUDY_REQUEST,
      isLoading,
    };
  }
  function success(data, attributeId) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_STUDY_SUCCESS,
      payload: data,
      AttributeId: attributeId,
    };
  }
  function failure(error) {
    return {
      type: storyboardConstants.UPDATE_ATTRIBUTE_SCORE_STUDY_FAILURE,
      error: error,
    };
  }
};

//Load Chat
export const atcLoadChat = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.LOAD_CHAT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.LOAD_CHAT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.LOAD_CHAT_FAILURE, error: error };
  }
};

//Delete Chat Topic in Case
export const atcRemoveChat = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.REMOVE_CHAT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.REMOVE_CHAT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.REMOVE_CHAT_FAILURE, error: error };
  }
};

//Case - Update Timepoint - Chat Mode
export const atcUpdateChatModeChat = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: storyboardConstants.UPDATE_CHANGE_MODE_CHAT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: storyboardConstants.UPDATE_CHANGE_MODE_CHAT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: storyboardConstants.UPDATE_CHANGE_MODE_CHAT_FAILURE, error: error };
  }
};

//Virtual Preceptor Settings
export const updateVirtualPreceptorSettings = (id, params) => {
  return dispatch => {
      dispatch(request(true));
      return apiCaller(`/api/teach/case/${id}/`, "PUT", params)
          .then(res => {
              dispatch(success(res.data));
          })
          .catch(err => {
              dispatch(failure(err.message));
          });
  };

  function request(isLoading) { return { type: storyboardConstants.VIRTUAL_PRECEPTOR_SETTINGS_REQUEST, isLoading }; }
  function success(data) { return { type: storyboardConstants.VIRTUAL_PRECEPTOR_SETTINGS_SUCCESS, payload: data }; }
  function failure(error) { return { type: storyboardConstants.VIRTUAL_PRECEPTOR_SETTINGS_FAILURE, error }; }
};