import React, { useState } from 'react'
import { TabComponentBorder } from "@ui-partents/Tabs";
import { AssignmentTab } from '@components/teachV2/AssignmentsPage/AssignmentManager/AssignmentTab';
import { MgmtCohortsPage } from "@pages/teach-v2/Management";
import { SeriesTab } from '@components/teachV2/AssignmentsPage/AssignmentManager/SeriesTab';
import { PeriodTab } from '@components/teachV2/AssignmentsPage/AssignmentManager/PeriodTab';

const AssignmentManagement = () => {
  const [activeTab, setActiveTab] = useState(1);

  const assignmentOptions = [
    {
      key: 1,
      label: "Assignments",
      content: (
        <AssignmentTab />
      ),
    },
    { 
      key: 2, 
      label: "Series", 
      content: (
        <SeriesTab />
      ) 
    },
    { 
      key: 3, 
      label: "Periods", 
      content: (
        <PeriodTab />
      ) 
    },
    { 
      key: 4, 
      label: "Cohorts", 
      content: (
        <MgmtCohortsPage noPadding={true}/>
      ) 
    },
  ];

  // Handle Tab Change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = assignmentOptions?.map((statusOption) => ({
    id: statusOption.key,
    title: statusOption.label,
    content: statusOption.content,
  }));
  
  return (
    <div className="wrapper -assignmentPage">
      <TabComponentBorder
        tabs={tabs || []}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </div>
  )
}

export default AssignmentManagement
