import React, { useEffect } from "react";
import WOW from "wowjs";

function ModalAssistantCommon(props) {
    const { isOpen, children, chatWrapUpBodyRef, ImageAssistant, className } = props;

    useEffect(() => {
        new WOW.WOW({ live: false }).init();
    }, []);

    return (
        <div className={`assistant-modal-common ${isOpen ? "isOpen" : "isClose"} ${className}`}>
            <div className="assistant-feedback">
                <div className={`assistant-feedback__left ${isOpen ? "wow zoomIn animated" : ""}`} style={{ backgroundImage: `url(${ImageAssistant})` }}></div>
                <div className="assistant-feedback__right -show-icon-top wow fadeInUp animated">
                    <div className="assistant-feedback__body" ref={chatWrapUpBodyRef}>
                        {children && (
                            <div className="assistant-modal-ct">
                                {children}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalAssistantCommon;