import React, { useState } from 'react'
import styled from 'styled-components'
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import ImageUser from '@images/common/no-avatar.jpg';
import backgroundPage from "@images/aspet2024/bg-aspet2024.png";
import { ModalUploadImage, ModalUploadTimepointImg } from './ComponentCommon';

const WrapAppearance = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ItemImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #081B2A;
  }
  .ava__item {
    padding: 28px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .timepoint__img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    &-bg {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
`

export default function Appearance(props) {
  const { dataResponse, timepointData } = props;
  const examData = timepointData?.Exam;
  const initialProfileUrl = examData?.Profile?.Url;
  const PatientAvatarUrl = dataResponse?.PatientAvatarUrl;
  const [modal, setModal] = useState({
    uploadAvatar: false,
    uploadTimepointImg: false,
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <WrapAppearance>
      <ItemImage>
        <h1>Chat Avatar</h1>
        <div className='ava__item'>
          <div className="ava__item-img">
            <img src={PatientAvatarUrl || ImageUser} alt="img" />
          </div>
          <div>
            <ButtonComponent size="small" color="outline" onClick={() => toggle('uploadAvatar')}>Change Avatar</ButtonComponent>
          </div>
        </div>
      </ItemImage>
      <ItemImage>
        <h1>Main Timepoint Image</h1>
        <div className='timepoint__img'>
          <div className="timepoint__img-bg">
            <img src={initialProfileUrl || backgroundPage} alt="img" />
          </div>
          <div>
            <ButtonComponent size="small" color="outline" onClick={() => toggle('uploadTimepointImg')}>Change Image</ButtonComponent>
          </div>
        </div>
      </ItemImage>
      <ModalUploadImage
        isOpen={modal.uploadAvatar}
        toggle={() => toggle("uploadAvatar")}
        {...props}
      />
      <ModalUploadTimepointImg
        isOpen={modal.uploadTimepointImg}
        toggle={() => toggle("uploadTimepointImg")}
        {...props}
      />
    </WrapAppearance>
  )
}
