import React from 'react'
import styled from 'styled-components'
import Select from 'react-select';
import {
  ButtonComponent
} from "@components/patterns/elements/Button";

const WrapSelectCohort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 200px) !important;
  width: 100%;
  padding: 16px 0;
  .cohort-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .cohort-title {
    width: 458px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }    
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .cohort-select {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 458px;
    h1 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .cohort-btn {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    width: 458px;
    
    &__next {
      font-size: 18px;
      width: 136px;
      font-weight: 500;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
`

const SelectCurriculum = (props) => {
  const { setShowDetail, listCurriculum, setCurriculum, curriculum } = props

  return (
    <WrapSelectCohort>
      <div className="cohort-form">
        <div className="cohort-title">
          <h1>Select a Curriculum</h1>
          <p>First, please select a curriculum from the list below.</p>
        </div>
        <div className="cohort-select">
          <h1>Curriculum</h1>
          <Select
            className={`react-select-common w-100`}
            placeholder="Select a curriculum"
            options={listCurriculum}
            classNamePrefix="react-select-custom"
            value={curriculum}
            onChange={val => {
              const data = listCurriculum?.filter((cur) => cur?.CurriculumId === val?.value)
              setCurriculum(data)
            }}
          />
        </div>
      </div>
      <div className='cohort-btn d-flex align-items-center justify-content-end'>
        <ButtonComponent disabled={!curriculum} onClick={() => setShowDetail(true)} size="large" className="cohort-btn__next">Next</ButtonComponent>
      </div>
    </WrapSelectCohort>
  )
}

export default SelectCurriculum
