import React, { useState } from 'react'
import styled from 'styled-components'
import { ChoiceCommon } from '../../StepCommon'
import SelectSingleCohort from './SelectSingleCohort'
import FormCreateCohort from './FormCreateCohort'
import SelectUserAddCohort from './SelectUserAddCohort'

const WrapperModule = styled.div`
  width: 100%;
  height: 100%;
`

const LearnerCohort = (props) => {
  const { handleNextStep, handleBackStep } = props;
  const [subStep, setSubStep] = useState();
  const [stepId, setStepId] = useState(2);

  const handleNext = () => {
    setStepId((prev) => prev + 1);
  };

  const handleBack = () => {
    setStepId((prev) => prev - 1);
  };

  const handleSubStep = (subStep) => {
    setSubStep(subStep)
  }

  const selectTypeFlow = (contents) => {
    switch (contents?.view) {
      case "create_cohort":
        return (
          <FormCreateCohort
            handleNext={handleNext}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      case "select_exist_cohort":
        return (
          <SelectSingleCohort
            handleNext={handleNextStep}
            handleBack={() => setSubStep()}
            {...props}
          />
        )
      case "add_learner":
        return (
          <SelectUserAddCohort
            handleNext={handleNextStep}
            handleBack={handleBack}
            {...props}
          />
        )
      default:
        break;
    }
  }

  return (
    <WrapperModule>
      {subStep ? (
        subStep?.SubSteps?.map((step, index) => (
          (index + 2) === stepId && selectTypeFlow(step.Contents)
        ))
      ) : (
        <ChoiceCommon
          {...props}
          handleBack={handleBackStep}
          handleSubStep={handleSubStep}
          type="cohort"
        />
      )}
    </WrapperModule>
  )
}

export default LearnerCohort
