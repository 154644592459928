import React from 'react'
import styled from 'styled-components'
// import { Icon } from '@components/common'
// import Down from '@images/teachV2/chevron-down.svg'
// import Up from '@images/teachV2/chevron-up.svg'

const WrapResultExplan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .wrap-answer {
    border: 1px solid #D0D5DD;
    padding: 12px;
    border-radius: 6px;
    &:hover {
      background: #F2F4F7;
    }
    &:focus,
    &:focus-within {
      box-shadow: 0px 0px 0px 2px #006E9B1F;
      border: 1px solid #0089C2;
    }
    .answer {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #101828;
      &.correct-answer {
        color: #079455;
      }
    }
  }
  .text-explanation {
    padding-left: 20px;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export default function ItemResult(props) {
  const { choice, question, refAnswer, type } = props
  // const [showData, setShowData] = useState(false)
  
  // const handleShowData = () => {
  //   setShowData(!showData)
  // }

  return (
    <WrapResultExplan>
      <div className='wrap-answer d-flex align-items-center justify-content-between'>
        <p className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} answer`}>
          {choice.id}. <span contentEditable={(type === "org" || type === "review")? false : true} ref={refAnswer}>{choice.content}</span>
        </p>
        {/* {choice.id !== question?.best_answer?.id && (
          <div onClick={handleShowData}>
            {showData ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
          </div>
        )} */}
      </div>
      {/* {showData && (
        <div className='text-explanation'>
          <p>Explanation: <span>{question?.explanation?.[choice?.id]?.explanation || question?.best_answer?.explanation}</span></p>
        </div>
      )} */}
    </WrapResultExplan>
  )
}
