import { chatConstants } from "@constants";
import { apiCaller } from "@utils";

//axios
import axios from "axios";

//Chat - Add Record (Instructor Channel)
export const atcAddRecordToChanel = (params) => {
    return async dispatch => {
        dispatch(request(true));
        try {
            const res = await apiCaller(`/api/learn/case/chat_record/`, 'POST', params);
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
                console.error("API call failed");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    function request(isLoading) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_REQUEST, isLoading } }
    function success(data) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_FAILURE, error: error } }
}

// Chat - Load Records (Instructor Channel)
export const loadRecordInstuctor = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/chat_record/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_REQUEST, isLoading } }
    function success(data) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_FAILURE, error: error } }
}

// Chat - Load Records (Instructor Channel)
export const loadRecordPatient = (params) => {
    const isRender = false;
    return dispatch => {
        dispatch(request(isRender));
        return apiCaller(`/api/learn/case/chat_record/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isRender) { return { type: chatConstants.LOAD_RECORD_PATIENT_REQUEST, isRender } }
    function success(data) { return { type: chatConstants.LOAD_RECORD_PATIENT_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.LOAD_RECORD_PATIENT_FAILURE, error: error } }
}

// Check API Pending - Lock elments for Chat
export const atcCallLockElementsChat = (status) => {
    return dispatch => {
      // Directly dispatch the status (true/false)
      dispatch(success(status));
    };
  
    function success(status) { 
      return { type: chatConstants.CHECK_HAS_CHAT_LOCK_ELEMENT, payload: status }
    }
}

// Chat Lock
export const atcSetChatLock = (status) => {
    return dispatch => {
      dispatch(success(status));
    };
  
    function success(data) { return { type: chatConstants.UPDATE_CHAT_LOCK_SUCCESS, payload: data || false } }
}

// Save Transcript Record
export const saveTranscriptRecord = (connectionData, completeQuestion = false) => {
    return async (dispatch) => {
        dispatch(request(true));

        // Đảm bảo payload được format đúng JSON
        const payload = {
            connection_data: connectionData,  // Dữ liệu cần truyền
            complete_question: completeQuestion
        };

        try {
            const response = await axios.post(
                "https://voice-chat-socket.med2lab.com/api/save-transcript",
                JSON.stringify(payload),  // Chuyển đổi sang JSON
                {
                    headers: {
                        "Accept": "application/json",  // Giống với cURL
                        "Content-Type": "application/json"  // Giống với cURL
                    }
                }
            );

            if (response.status >= 200 && response.status < 300) {
                dispatch(success(response.data));
                return response.data;  // Trả về dữ liệu từ API
            } else {
                throw new Error(`API call failed: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error saving transcript:", error);
            const errorMessage = error.response?.data?.message || error.message || "Unknown error";
            dispatch(failure(errorMessage));
            return Promise.reject(errorMessage);
        }
    };

    function request(isLoading) {
        return { type: chatConstants.SAVE_TRANSCRIPT_REQUEST, isLoading };
    }
    function success(data) {
        return { type: chatConstants.SAVE_TRANSCRIPT_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: chatConstants.SAVE_TRANSCRIPT_FAILURE, error };
    }
};

export const saveDataResponseSocket = (data, dataType) => {
    return dispatch => {
        if (dataType === 'instructor') {
            dispatch(saveInstructorData(data));
        } else if (dataType === 'virtual_patient') {
            dispatch(saveVirtualPatientData(data));
        }
    };

    function saveInstructorData(data) {
        return { type: chatConstants.SAVE_DATA_SOCKET_INSTRUCTOR, payload: data || null };
    }

    function saveVirtualPatientData(data) {
        return { type: chatConstants.SAVE_DATA_SOCKET_VIRTUAL_PATIENT, payload: data || null };
    }
}