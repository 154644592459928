import { teachV2Constants } from "@constants";
import { apiCaller } from "@utils";

//GET Case
export const getListCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_LIST_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_LIST_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_LIST_CASE_SCRIPT_FAILURE, error: error } }
}

//GET Case
export const loadCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.LOAD_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.LOAD_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.LOAD_CASE_SCRIPT_FAILURE, error: error } }
}

export const saveTitleScript = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SAVE_TITLE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SAVE_TITLE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SAVE_TITLE_SCRIPT_FAILURE, error: error } }
}

export const saveCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SAVE_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SAVE_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SAVE_CASE_SCRIPT_FAILURE, error: error } }
}

export const removeCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REMOVE_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REMOVE_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.REMOVE_CASE_SCRIPT_FAILURE, error: error } }
}

export const getTemplateCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_FAILURE, error: error } }
}

export const getQuestionBank = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'GET').then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_QUESTION_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_QUESTION_BANK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_QUESTION_BANK_FAILURE, error: error } }
}

export const getQuestionBankByFolder = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_QUESTION_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_QUESTION_BANK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_QUESTION_BANK_FAILURE, error: error } }
}

export const getQuestionBankOrg = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_QUESTION_BANK_ORG_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_QUESTION_BANK_ORG_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_QUESTION_BANK_ORG_FAILURE, error: error } }
}

export const getFinalBank = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_FINAL_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_FINAL_BANK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_FINAL_BANK_FAILURE, error: error } }
}

export const getListPermissions = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/account/permission/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_LIST_PERMISSIONS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_LIST_PERMISSIONS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_LIST_PERMISSIONS_FAILURE, error: error } }
}

export const getListFolders = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        return {
          data: data,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_ALL_FOLDERS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_ALL_FOLDERS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_ALL_FOLDERS_FAILURE, error: error } }
}

export const moveTofolder = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.MOVE_TO_FOLDERS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.MOVE_TO_FOLDERS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.MOVE_TO_FOLDERS_FAILURE, error: error } }
}

export const shareMediaOrg = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SHARE_MEDIA_ORG_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SHARE_MEDIA_ORG_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SHARE_MEDIA_ORG_FAILURE, error: error } }
}

export const sentToFinalBank = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SENT_TO_FINAL_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SENT_TO_FINAL_BANK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SENT_TO_FINAL_BANK_FAILURE, error: error } }
}

export const removeQuestionBank = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REMOVE_QUESTION_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REMOVE_QUESTION_BANK_SUCCESS, payload: data, params } }
  function failure(error) { return { type: teachV2Constants.REMOVE_QUESTION_BANK_FAILURE, error: error } }
}

export const removeQuestionFinalBank = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REMOVE_QUESTION_FINAL_BANK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REMOVE_QUESTION_FINAL_BANK_SUCCESS, payload: data, params } }
  function failure(error) { return { type: teachV2Constants.REMOVE_QUESTION_FINAL_BANK_FAILURE, error: error } }
}

export const getListLearner = (id) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/learners/case/${id}/`, 'GET').then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_LIST_LEARNER_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_LIST_LEARNER_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_LIST_LEARNER_FAILURE, error: error } }
}

export const removeLearner = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/learners/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REMOVE_LEARNER_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REMOVE_LEARNER_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.REMOVE_LEARNER_FAILURE, error: error } }
}

export const enrollEmail = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/learners/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.ENROLL_EMAIL_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.ENROLL_EMAIL_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.ENROLL_EMAIL_FAILURE, error: error } }
}

//Get RAG Document by Account
export const getRAGDocumentByAccount = (params, reload = false) => (dispatch) => {
  const isLoading = !reload;
  return new Promise((resolve, reject) => {
    if (isLoading) {
      dispatch(request(isLoading));
    }
    apiCaller(`/api/medicalcase/functional/`, 'POST', params)
      .then(res => {
        const data = res?.data;
        if (data) {
          dispatch(success(data));
          resolve({ success: true, data });
        } else {
          const error = "error";
          dispatch(failure(error));
          resolve({ success: false, error });
        }
      })
      .catch(err => {
        const errorMessage = err.message || "API call failed";
        dispatch(failure(errorMessage));
        resolve({ success: false, error: errorMessage });
      });
  });

  function request(isLoading) {
    return { type: teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_REQUEST, isLoading };
  }

  function success(data) {
    return { type: teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_FAILURE, error: error };
  }
};

//[Upload] RAG Document
export const uploadRAGDocument = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const isLoading = false;
      dispatch(request(isLoading));

      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        const data = res && res.data;
        if (data) {
          dispatch(success(data));
          resolve(res);
        } else {
          const error = "error";
          dispatch(failure(error));
          reject(error);
        }
      }).catch(error => {
        dispatch(failure(error));
        reject(error);
      });
    });
  };

  function request(isLoading) {
    return { type: teachV2Constants.UPLOAD_RAG_DOCUMENT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: teachV2Constants.UPLOAD_RAG_DOCUMENT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: teachV2Constants.UPLOAD_RAG_DOCUMENT_FAILURE, error: error };
  }
};

export const getMcqUsage = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.MCQ_USAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.MCQ_USAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.MCQ_USAGE_FAILURE, error: error } }
}

export const getDetailedMcqUsage = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.DETAILED_MCQ_USAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.DETAILED_MCQ_USAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.DETAILED_MCQ_USAGE_FAILURE, error: error } }
}

//[Update] Doc Info
export const editDocumentMedicalInfo = (params) => (dispatch) => {
  dispatch(request(true));

  return new Promise((resolve, reject) => {
    apiCaller(`/api/medicalcase/functional/`, 'POST', params)
      .then(res => {
        const data = res?.data;
        if (data) {
          dispatch(success(data));
          resolve({ success: true, data });
        } else {
          const error = "error";
          dispatch(failure(error));
          resolve({ success: false, error });
        }
      })
      .catch(err => {
        const errorMessage = err.message || "API call failed";
        dispatch(failure(errorMessage));
        resolve({ success: false, error: errorMessage });
      });
  });

  function request(isLoading) {
    return { type: 'EDIT_DOCUMENT_MEDICAL_INFO_REQUEST', isLoading };
  }

  function success(data) {
    return { type: 'EDIT_DOCUMENT_MEDICAL_INFO_SUCCESS', payload: data };
  }

  function failure(error) {
    return { type: 'EDIT_DOCUMENT_MEDICAL_INFO_FAILURE', error: error };
  }
};

export const getSlideTemplates = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_TEMPLATE_SCRIPT_FAILURE, error: error } }
}

export const getListSlideDesks = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_SLIDE_DESKS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_SLIDE_DESKS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_SLIDE_DESKS_FAILURE, error: error } }
}

export const removeSlideDesk = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REMOVE_SLIDE_DESK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REMOVE_SLIDE_DESK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.REMOVE_SLIDE_DESK_FAILURE, error: error } }
}

//GET Case
export const loadSlideDesk = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.LOAD_SLIDE_DESK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.LOAD_SLIDE_DESK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.LOAD_SLIDE_DESK_FAILURE, error: error } }
}

export const regenerateCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REGENERATE_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REGENERATE_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.REGENERATE_CASE_SCRIPT_FAILURE, error: error } }
}

export const generateExplanation = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.REGENERATE_EXPLANATION_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.REGENERATE_EXPLANATION_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.REGENERATE_EXPLANATION_FAILURE, error: error } }
}

//Create Engage
export const createEngage = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        return dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.CREATE_ENGAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.CREATE_ENGAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.CREATE_ENGAGE_FAILURE, error: error } }
}

export const editEngage = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        return dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.EDIT_ENGAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.EDIT_ENGAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.EDIT_ENGAGE_FAILURE, error: error } }
}

//Create Engage
export const deleteEngage = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.DELETE_ENGAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.DELETE_ENGAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.DELETE_ENGAGE_FAILURE, error: error } }
}

//Create Engage
export const getAllEngages = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_ALL_ENGAGE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_ALL_ENGAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_ALL_ENGAGE_FAILURE, error: error } }
}

export const getReferenceBook = (params, skipLoading = false) => {
  return (dispatch) => {
    if (!skipLoading) {
      const isLoading = true;
      dispatch(request(isLoading));
    }

    return new Promise((resolve, reject) => {
      apiCaller(`/api/reference/library/`, 'GET', params)
        .then((res) => {
          const data = res && res.data;
          if (data) {
            dispatch(success(data));
            resolve({
              data: res,
            });
          } else {
            const error = "error";
            dispatch(failure(error));
            reject({
              error,
            });
          }
        })
        .catch((error) => {
          dispatch(failure(error.message || "error"));
          reject({
            error: error.message || "error",
          });
        });
    });
  };

  function request(isLoading) {
    return { type: teachV2Constants.GET_REFERENCE_BOOK_REQUEST, isLoading };
  }
  function success(data) {
    return { type: teachV2Constants.GET_REFERENCE_BOOK_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: teachV2Constants.GET_REFERENCE_BOOK_FAILURE, error };
  }
};

export const shareReference = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/reference/item/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SHARE_REFERENCE_BOOK_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SHARE_REFERENCE_BOOK_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SHARE_REFERENCE_BOOK_FAILURE, error: error } }
}

export const uploadFileAnalysis = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/analysis/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.UPLOAD_FILE_ANALYSIS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.UPLOAD_FILE_ANALYSIS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.UPLOAD_FILE_ANALYSIS_FAILURE, error: error } }
}

export const createNewAnalysis = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/analysis/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.CREATE_NEW_ANALYSIS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.CREATE_NEW_ANALYSIS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.CREATE_NEW_ANALYSIS_FAILURE, error: error } }
}

export const getListAnalysesAvailabel = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_FAILURE, error: error } }
}

export const getListQuestionBankToReview = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_LIST_QUESTION_BANK_REVIEW_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_LIST_QUESTION_BANK_REVIEW_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_LIST_QUESTION_BANK_REVIEW_FAILURE, error: error } }
}

export const sendQuestionForReview = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SEND_QUESTION_FOR_REVIEW_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SEND_QUESTION_FOR_REVIEW_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SEND_QUESTION_FOR_REVIEW_FAILURE, error: error } }
}

export const getAnalysesAnswerBatch = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/analysis/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_FAILURE, error: error } }
}

export const getAnalysesAnswerBatchStatistics = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/feedback/question/analysis/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_FAILURE, error: error } }
}

export const getReferenceMedia = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/media/library/`, 'GET', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_REFERENCE_MEDIA_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_REFERENCE_MEDIA_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_REFERENCE_MEDIA_FAILURE, error: error } }
}

export const createNewMedia = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/media/library/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        return {
          data: res,
          dispatch: dispatch(success(data)),
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.CREATE_REFERENCE_MEDIA_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.CREATE_REFERENCE_MEDIA_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.CREATE_REFERENCE_MEDIA_FAILURE, error: error } }
}

export const shareMedia = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/media/library/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.SHARE_REFERENCE_MEDIA_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.SHARE_REFERENCE_MEDIA_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.SHARE_REFERENCE_MEDIA_FAILURE, error: error } }
}

export const editReferenceMedia = (params) => (dispatch) => {
  dispatch(request(true));

  return new Promise((resolve, reject) => {
    apiCaller(`/api/media/library/`, 'POST', params)
      .then(res => {
        const data = res?.data;
        if (data) {
          dispatch(success(data));
          resolve({ success: true, data });
        } else {
          const error = "error";
          dispatch(failure(error));
          resolve({ success: false, error });
        }
      })
      .catch(err => {
        const errorMessage = err.message || "API call failed";
        dispatch(failure(errorMessage));
        resolve({ success: false, error: errorMessage });
      });
  });

  function request(isLoading) {
    return { type: 'EDIT_REFERENCE_MEDIA_REQUEST', isLoading };
  }

  function success(data) {
    return { type: 'EDIT_REFERENCE_MEDIA_SUCCESS', payload: data };
  }

  function failure(error) {
    return { type: 'EDIT_REFERENCE_MEDIA_FAILURE', error: error };
  }
};

export const getGptModels = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/medicalcase/meta-data/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data))
        return {
          data: res,
        }
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: teachV2Constants.GET_GPT_MODELS_REQUEST, isLoading } }
  function success(data) { return { type: teachV2Constants.GET_GPT_MODELS_SUCCESS, payload: data } }
  function failure(error) { return { type: teachV2Constants.GET_GPT_MODELS_FAILURE, error: error } }
}
