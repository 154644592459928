import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

// Question Type
import { QuestionTypeOptions, QuestionPromptTemp, ImmediateFeedbackModeSwitch } from "../CreateQuestion";

// Schema
import { GSFreeText } from "../AnswerList/GradingSchema";

// Answer List
import { TableAnswerList } from "../AnswerList";

function AnswersTab(props) {

  //Truyền như này sẽ ko bị đè props
  const {
    QuestionType,
    itemQuestion,
    questionData,
    handleChangeSchema
  } = props && props;

  // Get GradingSchema from either itemQuestion or questionData, preferring itemQuestion
  const initialGradingSchema = itemQuestion?.GradingSchema || questionData?.GradingSchema;
  const [gradingSchema, setGradingSchema] = useState(initialGradingSchema);

  // Thực hiện side effect khi nhận dữ liệu questionData.GradingSchema
  useEffect(() => {
    if(itemQuestion?.GradingSchema) {
        setGradingSchema(itemQuestion?.GradingSchema);
    }
  }, [itemQuestion?.GradingSchema]);

  // Xử lý khi người dùng thay đổi Grading Schema
  const handleChangeOptionsSchema = (value) => {
    setGradingSchema(value);
    handleChangeSchema(value); // Gọi hàm handleChangeSchema truyền từ props
  };

  // Determine if GradingComponents should be hidden
  const shouldHideGradingComponents = gradingSchema === "statement";

  // Set column size based on QuestionType
  const colSize = QuestionType === "free_text" ? 9 : 12;

  console.log(gradingSchema, "gradingSchema...");
  return (
    <Row className="mb-3">
      <Col md={colSize}>
        <QuestionTypeOptions 
          {...props}
        />  
        {!shouldHideGradingComponents && <QuestionPromptTemp {...props} />}
      </Col>
      {QuestionType === "free_text" && (
        <GSFreeText
          {...props}
          handleChangeOptions={handleChangeOptionsSchema}
          gradingSchema={gradingSchema}
        />
      )}
      <Col md={12}>
        {!shouldHideGradingComponents && <ImmediateFeedbackModeSwitch {...props} />}
      </Col>
      <Col md={12}>
        <TableAnswerList {...props} />
      </Col>
    </Row>
  );
}

export default AnswersTab;