import React from 'react'
import styled from 'styled-components'
import Icon from '@components-common/Icon'
import Back from '@images/teachV2/back.svg'
import { ButtonComponent } from "@components/patterns/elements/Button";

const WrapperButton = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  .btn-back {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
  }
  .btn-next,
  .btn-generate {
    padding: 12px 32px;
    font-size: 18px;
    font-weight: 500;
  }
`

const ButtonSubmitCommon = (props) => {
  const { 
    handleNext, 
    handleBack, 
    handleActionBackup,
    isGenerate, 
    dataPass,
  } = props

  const handleContinue = async (e) => {
    if (handleActionBackup) {
      await handleActionBackup()
    } else {
      await handleNext()
    }
  }

  return (
    <WrapperButton>
      <ButtonComponent 
        size="large" 
        color="outline"
        onClick={handleBack} 
        className="btn-back"
      >
        <Icon src={Back} width={24} height={24}/>
        <span>
          Back
        </span>
      </ButtonComponent>
      {isGenerate ? (
        <ButtonComponent
          type="submit" 
          id="form-assignment"
          size="large" 
          className="btn-generate"
          disabled={dataPass}
        >
          Create Assisgment 
        </ButtonComponent>
      ) : (
          <ButtonComponent 
            className={`btn-next`} 
            size="large" 
            onClick={handleContinue}
            disabled={dataPass}
          >
            Continue
          </ButtonComponent>
        )
      }
    </WrapperButton>
  )
}

export default ButtonSubmitCommon
