import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Row,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
    Label,
    FormFeedback
} from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//alert
import { Toasts } from "@components/common/Toasts";
import { toast } from "react-toastify";

//utils
import { apiCaller } from "@utils";

//redux
import { useDispatch } from "react-redux";

//partents
import { FormModal } from '@ui-partents/Modal';

//actions
import { actFetchLoadTimePoint } from "@actions";

//constants
import { storyboardConstants } from "@constants";

//components
import StatusOptions from "./StatusOptions";

function ModalUpdateTimePoint(props) {
    let { id } = useParams();

    //props
    const { isOpen, toggle, timepointData } = props && props;
    const isFirstTimepoint = timepointData?.Time === 0;
    const formikRef = useRef(null);
    const dispatch = useDispatch();

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        days: isFirstTimepoint ? Yup.number() : Yup.number().integer().required("Days is required"),
        hours: isFirstTimepoint ? Yup.number() : Yup.number().integer().required("Hours is required"),
        mins: isFirstTimepoint ? Yup.number() : Yup.number().integer().required("Minutes is required"),
    });

    //state
    const DefaultActiveStatus = timepointData?.DefaultActiveStatus;
    const [statusOption, setStatusOption] = useState(DefaultActiveStatus || true);
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        title: "",
        days: 0,
        hours: 0,
        mins: 0
    });

    //Load Active/Inactive
    useEffect(() => {
        if (timepointData) {
            const DefaultActiveStatus = timepointData.DefaultActiveStatus;
            setStatusOption(DefaultActiveStatus);

            const timeConvert = (time) => {
                const days = parseInt(time / (60 * 24));
                const hours = parseInt((time - (days * 60 * 24)) / 60);
                const mins = time - (days * 60 * 24) - (hours * 60);
                return { days, hours, mins };
            };

            const timeTotal = timepointData.Time;
            const title = timepointData.DisplayName;
            const { days, hours, mins } = timeConvert(timeTotal);

            // Set formValues state
            setFormValues({
                title: title || "",
                days: days || 0,
                hours: hours || 0,
                mins: mins || 0
            });
        }
    }, [timepointData, isOpen]);

    // Function to handle status option change
    const handleStatusOptionChange = (status) => {
        setStatusOption(status);
    };

    const validateTime = () => {
        toast.warning("Time must be larger than 0 second", {
            autoClose: 3000
        });
    };

    // Handle Submit Form
    const handleSubmit = async (values) => {
        // Kiểm tra nếu isFirstTimepoint thì không cần xử lý thêm
        if (isFirstTimepoint) {
            const params = {
                "Target": "TimePoint",
                "TimeInMinutes": 0,
                "DisplayName": values?.title,
                "DefaultActiveStatus": statusOption
            };
            params[`Action`] = "Update";
            params[`TimePointId`] = timepointData?.Id;
            handleAPIUpdateTimepoint(params);
        } else {
            const dataDays = parseInt(values?.days);
            const dataHours = parseInt(values?.hours);
            const dataMins = parseInt(values?.mins);

            // Kiểm tra xem giá trị đã chuyển đổi có là một số hợp lệ không
            if (isNaN(dataDays) || isNaN(dataHours) || isNaN(dataMins)) {
                validateTime();
                return;
            }

            const logic1 = (dataDays === 0 && dataHours === 0 && dataMins === 0);
            const logic2 = (dataDays < 0 || dataHours < 0 || dataMins < 0);
            if (logic1 || logic2) {
                validateTime();
            } else {
                const totalTimeInMinutes = dataDays * 24 * 60 + dataHours * 60 + dataMins;
                const params = {
                    "Target": "TimePoint",
                    "TimeInMinutes": totalTimeInMinutes,
                    "DisplayName": values?.title,
                    "DefaultActiveStatus": statusOption
                }
                params[`Action`] = "Update";
                params[`TimePointId`] = timepointData?.Id;
                handleAPIUpdateTimepoint(params);
            }
        }

    };

    // Handle Update Timepoint
    const handleAPIUpdateTimepoint = (params) => {
        dispatch({ type: storyboardConstants.UPDATE_TIMEPOINT_REQUEST, isLoading: true });
        setIsLoading(true);
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            const Alert = data?.Alert;
            const alertSeverity = Alert?.Severity;
            const alertShowDuration = Alert?.ShowDuration;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.UPDATE_TIMEPOINT_SUCCESS, payload: data });
                handleReloadTimepoint();

                //show toast
                if (alertSeverity && alertSeverity === "success") {
                    toast.success(<Toasts data={Alert} />, {
                        autoClose: alertShowDuration * 1000,
                        toastId: "UPDATE_TIMEPOINT_REQUEST",
                    });
                }

                // Close Modal
                toggle();
                setIsLoading(false);
            } else {
                dispatch({ type: storyboardConstants.UPDATE_TIMEPOINT_FAILURE, error: 'error' });

                // Close Modal
                toggle();
                setIsLoading(false);
            }
        });
    };

    // Handle Reload Timepoint
    const handleReloadTimepoint = () => {
        // Reload Timepoint
        const paramsTimepoint = {
            Action: "Load",
            Target: "TimePoint",
            TimePointId: String(timepointData.Id),
        };
        dispatch(actFetchLoadTimePoint(id, paramsTimepoint));
    };

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggle}
            title={`Edit Timepoint`}
            actionText={"Save"}
            maxWidth="900px"
            onAction={() => {
                if (formikRef.current) {
                    formikRef.current.submitForm();
                }
            }}
            isLoading={isLoading}
        >
            <Formik
                innerRef={formikRef}
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                {({ values, errors, handleChange, handleBlur, touched }) => (
                    <Form>
                        {!isFirstTimepoint && (
                            <React.Fragment>
                                <Label className="labeForm">Set the new time point at: </Label>
                                <Row>
                                    <FormGroup className="col-md-4">
                                        <InputGroup>
                                            <Field
                                                type="number"
                                                name="days"
                                                as={Input}
                                                value={values.days}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={!!errors.days && touched.days}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>days</InputGroupText>
                                            </InputGroupAddon>
                                            <FormFeedback>{errors.days}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup className="col-md-4">
                                        <InputGroup>
                                            <Field
                                                type="number"
                                                name="hours"
                                                as={Input}
                                                value={values.hours}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={!!errors.hours && touched.hours}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>hours</InputGroupText>
                                            </InputGroupAddon>
                                            <FormFeedback>{errors.hours}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup className="col-md-4">
                                        <InputGroup>
                                            <Field
                                                type="number"
                                                name="mins"
                                                as={Input}
                                                value={values.mins}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={!!errors.mins && touched.mins}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>mins</InputGroupText>
                                            </InputGroupAddon>
                                            <FormFeedback>{errors.mins}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                </Row>
                            </React.Fragment>
                        )}
                        <FormGroup>
                            <Label className="labeForm" for="title">
                                Time Point Name
                            </Label>
                            <Field
                                type="text"
                                name="title"
                                autoComplete="off"
                                as={Input}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.title && touched.title}
                            />
                            <FormFeedback>{errors.title}</FormFeedback>
                            <p className="my-2">
                                Leave blank to use the time above as Name
                            </p>
                        </FormGroup>
                        <StatusOptions
                            {...props}
                            handleChange={handleStatusOptionChange}
                            isChecked={statusOption} //DefaultActiveStatus
                        />
                    </Form>
                )}
            </Formik>
        </FormModal>
    );
}

export default ModalUpdateTimePoint;