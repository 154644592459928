import React, { useState } from 'react'
import styled from 'styled-components'
import Select, { components } from 'react-select';
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { getReportCurriculum } from "@actions";
import { FormGroup, Input } from 'reactstrap';
import Close from '@images/teachV2/close.svg'
import LoadingCohort from '../LoadingCohort';
import DetailPerformance from './DetailPerformance';
import { MessageEmtyData } from "@ui-partents/Message";

const WrapDetailReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .wrap-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__back {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #081B2A;
      }
    }
    &__list {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      border: 1px solid #D0D5DD;
      border-radius: 10px;
      .react-select-custom__value-container {
        gap: 6px;
      }
      .select-curriculum {
        width: 100%;
        max-width: 260px;
      }
      .__react_component_tooltip {
        padding: 8px 12px;
        border-radius: 8px;
      }
    }
  }
`

const TextSelect = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap; 
  color: #475467;
`

const OptionTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 2px 0;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }
  .close-tooltip {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50%;
    background: #475467;
    cursor: pointer;
  }
`

const ReportDetailCohortMultiple = (props) => {
  const { dataCurriculum } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const listCurriculum = useSelector((state) => state?.getCurriculum || []);
  const [dataListCohort, setDataListCohort] = useState(JSON.parse(location?.state?.listCohorts) || []);
  const [valueCurriculum, setValueCurriculum] = useState(JSON.parse(location?.state?.curriculumActive) || []);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const handleBack = () => {
    history.push('/teach/report-cohort')
  }

  const handleToReportMultiple = async () => {
    const listIdCohorts = dataListCohort?.map(item => item.CohortId)
    setDisableConfirm(true)
    const dataReport = await dispatch(getReportCurriculum({
      ReportType: "CurriculumPerformance",
      ReportMode: "MultipleCohorts",
      CurriculumId: valueCurriculum?.CurriculumId,
      CohortIds: listIdCohorts
    }))
    if (dataReport?.data?.status === 200) {
      const listCohorts = JSON.stringify(dataListCohort)
      const curriculumActive = JSON.stringify(valueCurriculum)
      history.push({
        pathname: '/teach/report-cohort-multiple',
        state: {
          listCohorts,
          curriculumActive, 
        },
      })
    }
  }

  // Custom hiển thị nếu có nhiều hơn 3 lựa chọn + Tooltip
  const MultiValueContainer = (props) => {
    const { data, selectProps, children } = props
    const selectedValues = selectProps.value;
    const index = selectedValues.findIndex((item) => item.value === data.value);

    if (index < 4) {
      return (
        <>
          <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
        </>
      )
    }

    return null
  };

  // Ẩn nút xóa của `+X`
  const MultiValueRemove = (props) => {
    const { data, selectProps } = props;
    const { value: selectedValues, onChange } = selectProps;
    const index = selectedValues.findIndex((item) => item.value === data.value);

    // Nếu là +X, khi xóa phải xóa tất cả các item sau 3
    if (index === 3) {
      return (
        <components.MultiValueRemove
          {...props}
          onClick={(e) => {
            e.stopPropagation();
            onChange(selectedValues.slice(0, 3)); // Chỉ giữ lại 3 mục đầu tiên
          }}
        />
      );
    }

    return <components.MultiValueRemove {...props} />;
  };

  const MultiValueLabel = (props) => {
    const { data, selectProps } = props;
    const selectedValues = selectProps.value;
    const index = selectedValues.findIndex((item) => item.value === data.value);
  
    if (index < 3) {
      return (
        <>
          <TextSelect
            data-tip
            data-for={`tooltip-${data.CohortId}`}
          >
            {data.label}
          </TextSelect>
          <ReactTooltip id={`tooltip-${data.CohortId}`} place='top' effect='solid'>
            {data.label}
          </ReactTooltip>
        </>
      );
    }
    if (index === 3) {
      const remainingOptions = selectedValues.slice(3).map((item) => item);
      return (
        <>
          <TextSelect
            data-tip
            data-for="tooltip-remain"
          >
            +{selectedValues.length - 3}
          </TextSelect>
          <ReactTooltip 
            id='tooltip-remain' 
            place='top' 
            effect='solid' 
            className="nav-profile__item-tooltip"
            delayHide={1000} // Giữ tooltip sau khi rời chuột
            clickable={true} // Cho phép click vào tooltip
            openOnClick={true} // Giữ tooltip khi click
          >
            {remainingOptions?.map((option, index) => (
              <OptionTooltip key={index}>
                <p>{option.label}</p>
                <div className="close-tooltip" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const listCohorts = dataListCohort.filter(item => item.CohortId !== option.value)
                  setDataListCohort(listCohorts)
                }}>
                  <Icon src={Close} width="12" stroke="#FFFFFF"/>
                </div>
              </OptionTooltip>
            ))}
          </ReactTooltip>
        </>
      );
    }
  };
  
  const Option = ({ innerProps, isSelected, label, data, value }) => {
    return (
      <div 
        {...innerProps}
        style={{ padding: '10px 16px', cursor: 'pointer', display: "flex", alignItems: "center", gap: "12px" }}
      >
        <FormGroup check className="form-common">
          <Input
            type="checkbox"
            checked={isSelected}
            name="checkbox"
            className="react-checkbox-common react-checkbox-md"
          />
        </FormGroup>
        <span className="react-select-item">{label}</span>
      </div>
    );
  };

  return (
    <WrapDetailReport>
      <div className="wrap-select">
        <div className="wrap-select__back" onClick={handleBack}>
          <Icon src={Back} width="20" stroke="#000"/>
          <span>Back</span>
        </div>
        <div className="wrap-select__list">
          <Select
            className={`react-select-common select-curriculum`}
            placeholder="Select item"
            options={listCurriculum?.data}
            classNamePrefix="react-select-custom"
            value={valueCurriculum}
            onChange={(e) => {
              setValueCurriculum(e)
              setDataListCohort([])
            }}
          />
          <Select
            className={`react-select-common w-100`}
            placeholder="Select item"
            options={valueCurriculum?.Cohorts || []}
            classNamePrefix="react-select-custom"
            isMulti
            value={dataListCohort}
            closeMenuOnSelect={false} // Không đóng menu sau khi chọn
            hideSelectedOptions={false} // Giữ các option đã chọn trong danh sách
            onChange={(newValue) => {
              setDataListCohort(newValue)
              setDisableConfirm(false)
            }}
            components={{ MultiValueContainer, MultiValueRemove, MultiValueLabel, Option }}
          />
          <ButtonComponent disabled={disableConfirm} onClick={() => handleToReportMultiple()}>Confirm</ButtonComponent>
        </div>
      </div>
      {!dataCurriculum?.data ? (
          <MessageEmtyData
            title="No Data Available"
            text="There’s nothing here yet. Please check back later or try adjusting your filters."
          />
        ) : dataCurriculum?.isLoading ? (
          <LoadingCohort />
        ) : (
          <DetailPerformance {...props} />
        )
      }
    </WrapDetailReport>
  )
}

export default ReportDetailCohortMultiple
