import React, { useEffect, useState } from "react";
import { FormModal } from "@ui-partents/Modal";
import { Input } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { getListCharacter } from "@actions";
import { useForm } from "react-hook-form";
import { TitleNoteCommon } from "../../../home/generate";
import { Controller } from 'react-hook-form'
import { ButtonComponent } from "@components/patterns/elements/Button";
import { apiCaller } from "@utils";
import { teachConstants } from "@constants";

const WrapForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const WrapFormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 440px;
  overflow-y: scroll;
`;

const WrapFormTextarea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  textarea {
    padding: 12px;
    font-size: 14px;
    background: #f9fafb;
    border: 1px solid #d6dbe3;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    &:hover {
      cursor: pointer;
      background: #f4f4f4;
    }
    &:focus {
      background: #fff;
      box-shadow: 0px 0px 0px 4px rgb(0 129 255 / 12%);
    }
  }
`;

const WrapAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const ModalAddQuestion = (props) => {
  const { toggle, isOpen, itemQuestion, id, type, timepointData } = props && props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  function convertToPlain(html) {
    // Create a new div element
    let tempDivElement = document.createElement("mark");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    if (tempDivElement.textContent === "undefined" || tempDivElement.innerText === "undefined") {
      return ""
    } else {
      // Retrieve the text property of the element
      return tempDivElement.textContent || tempDivElement.innerText || "";
    }
  }

  const { 
    control, 
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      init: convertToPlain(itemQuestion?.QuestionContent) || "",
      context: convertToPlain(itemQuestion?.AI_Instructions?.Background_Information) || "",
      specific: convertToPlain(itemQuestion?.AI_Instructions?.Specific_Instructions) || "",
      criteria: convertToPlain(itemQuestion?.AI_Instructions?.Ending_Criteria) || "",
    }
  });
  
  const onSubmit = async (data) => {
    try {
      if (itemQuestion?.TimePointId) {
        let params = {
          Target: "Question",
          Action: "Update",
          TimePointId: itemQuestion?.TimePointId,
          NewTimePointId: itemQuestion?.NewTimePointId || itemQuestion?.TimePointId,
          QuestionContent: data?.init,
          QuestionExplanation: itemQuestion?.QuestionExplanation,
          QuestionType: itemQuestion?.QuestionType,
          QuestionId: itemQuestion?.QuestionId,
          Domains: itemQuestion?.Domains,
          FollowUpExplanationRequired: itemQuestion?.FollowUpExplanationRequired,
          GradingSchema: itemQuestion?.GradingSchema,
          AI_Instructions: {
            ...itemQuestion?.AI_Instructions,
            Background_Information: data?.context,
            Specific_Instructions: data?.specific,
            Ending_Criteria: data?.criteria,
          },
          Scoring_Instructions: itemQuestion?.Scoring_Instructions,
          PromptTemplateIds: itemQuestion?.PromptTemplateIds,
          ImmediateFeedbackMode: itemQuestion?.ImmediateFeedbackMode,
          Task: type,
          Answers: itemQuestion?.Answers
        }
        apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
          if (res?.status === 200) {
            const data = res?.data;
            dispatch({ type: teachConstants.UPDATE_QUESTION_SUCCESS, payload: data });
            setLoading(false);
            toggle()
          } else {
            dispatch({ type: teachConstants.UPDATE_QUESTION_FAILURE, isLoading: false });
            setLoading(false);
          }
        });
      } else {
        let params = {
          Target: "Question",
          Action: "Create",
          TimePointId: timepointData?.Id,
          NewTimePointId: null,
          QuestionContent: data?.init,
          QuestionExplanation: "",
          QuestionType: "free_text",
          Domains: [],
          FollowUpExplanationRequired: false,
          GradingSchema: "dialog",
          AI_Instructions: {
            Text: "",
            Background_Information: data?.context,
            Specific_Instructions: data?.specific,
            Ending_Criteria: data?.criteria,
          },
          Scoring_Instructions: {
            Text: ''
          },
          Task: type,
          PromptTemplateIds: [],
          Answers: [],
          ImmediateFeedbackMode: true
        }
        apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
          if (res?.status === 200) {
            const data = res?.data;
            dispatch({ type: teachConstants.CREATE_QUESTION_SUCCESS, payload: data });
            setLoading(false);
            toggle()
          } else {
            dispatch({ type: teachConstants.CREATE_QUESTION_FAILURE, isLoading: false });
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    dispatch(getListCharacter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={itemQuestion?.QuestionId ? `Edit Question #${itemQuestion.QuestionId}` : `Add New Question`}
      hiddenIcon={true}
      maxWidth="1024px"
			hiddenButtonCancel={true}
    >
      <WrapForm>
        <WrapFormItem>
          <WrapFormTextarea>
            <TitleNoteCommon
              dataFor="init"
              required={true}
              title="Initial Statement"
              content="The first statement that the Virtual Instructor says to the Learner"
            />
            <Controller
              name="init"
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <>
                  <Input
                    type='textarea'
                    name='init'
                    placeholder="Example: 
How do you think your conversation with the patient went? "
                    style={{ height: '150px' }}
                    {...field}
                  />
                  {errors.init && <ErrorMessage>{errors.init.message}</ErrorMessage>}
                </>
              )}
            />
          </WrapFormTextarea>
          <WrapFormTextarea>
            <TitleNoteCommon
              dataFor="context"
              title="Context"
              content="Summary of the situation to provide relevant background information for the Virtual Instructor"
            />
            <Controller
              name="context"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='context'
                  placeholder="Example: 
In this case, the learner is approached by a 56-year-old female patient who is experiencing severe abdominal pain…"
                  style={{ height: '150px' }}
                  {...field}
                />
              )}
            />
          </WrapFormTextarea>
          <WrapFormTextarea>
            <TitleNoteCommon
              dataFor="specific"
              title="Specific Instructions for Virtual Instructor"
              content="Detailed step-by-step guide on how the Virtual Instructor should conduct the conversation, including tone instructions if applicable"
            />
            <Controller
              name="specific"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='specific'
                  placeholder="Example:
-Use a supportive, empathetic and encouraging voice throughout the conversation
-Listen patiently and encourage the learner to share all of their thoughts
-Verbally recognize the feelings and challenges shared by the learner
-Guide the learner through the positive aspects and the areas that can be improved
-Give your feedback and follow-up question as brief statements, not longer than 1-2 sentences, in a positive tone."
                  style={{ height: '150px' }}
                  {...field}
                />
              )}
            />
          </WrapFormTextarea>
          <WrapFormTextarea>
            <TitleNoteCommon
              dataFor="criteria"
              title="Ending Criteria"
              content="Condition to end this dialog"
            />
            <Controller
              name="criteria"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='criteria'
                  placeholder="Example:
The learner indicates that they have shared all their perspectives, and you have provided all necessary feedback."
                  style={{ height: '150px' }}
                  {...field}
                />
              )}
            />
          </WrapFormTextarea>
        </WrapFormItem>
        <WrapAction>
          <ButtonComponent color="outline" onClick={toggle}>
            Cancel
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {itemQuestion?.QuestionId ? "Save" : "Add"}
          </ButtonComponent>
        </WrapAction>
      </WrapForm>
    </FormModal>
  );
};

export default ModalAddQuestion;