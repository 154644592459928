import { cohortsConstant } from "@constants";

//lodash
import _ from "lodash";

export function listCohort (state = {}, action) {
    switch (action.type) {
        case cohortsConstant.GET_COHORT_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case cohortsConstant.GET_COHORT_SUCCESS:
            const listCohort = action.payload?.map((temp) => {
                return {
                    ...temp,
                    value: temp?.CohortId,
                    label: temp?.Name,
                }
            })
            return {
                ...state,
                data: listCohort,
                isLoading: false
            };
        case cohortsConstant.CREATE_COHORT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case cohortsConstant.UPDATE_COHORT_SUCCESS:
            let dataClone;
            dataClone = [...state?.data];
            const currentCohortId = action?.payload?.CohortId;
            if(dataClone?.length > 0) {
                const indexCurrent = _.findIndex(dataClone, function(x) { return x.CohortId === currentCohortId; });
                dataClone[indexCurrent] = action.payload;
            }
            return {
                ...state,
                data: dataClone,
                isLoading: false
            };
        case cohortsConstant.DELETE_COHORT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case cohortsConstant.GET_COHORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadCohortsForReview (state = {}, action) {
    switch (action.type) {
        case cohortsConstant.LOAD_COHORTS_FOR_REVIEW_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case cohortsConstant.LOAD_COHORTS_FOR_REVIEW_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case cohortsConstant.LOAD_COHORTS_FOR_REVIEW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function dataLearnersByCohortId (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case cohortsConstant.LOAD_COHORT_BY_ID_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case cohortsConstant.LOAD_COHORT_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.payload && action.payload.Learners,
                isLoading: false
            };
        case cohortsConstant.ADD_LEARNERS_TO_COHORT_SUCCESS:
            newState.data =  action.payload && action.payload.Learners
            return {
                ...newState,
                isLoading: false
            };
        case cohortsConstant.LOAD_COHORT_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};
