import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import WhisperSTTComponent from "./WhisperSTTComponent";
import TextToSpeech from "./TextToSpeech";
import SpeedSelector from "./SpeedSelector";

// Styled components
import {
  StyledImage,
  PatientLabel,
  ImageLabelWrapper,
  DivContentTranScript,
  BlinkingDiv,
  FooterChatVoice,
  FrameChatFullBox,
} from "./InstructorVoiceChat.styles";

//imgPerson
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import { ViewMediaButton } from "../../Chat";

function InstructorVoiceChat(props) {
  const { isShowForSwitch, ChatMode, nextQuestion, handleStatusChangeForVoice } = props && props;

  // Redux data
  const caseData = useSelector((state) => state?.cases || []);
  const caseResponse = caseData?.data?.Case;

  //Question Reducer
  const questionData = useSelector((state) => state.question || {});
  let QuestionResponse = questionData?.data?.Question;
  let TaskToResume = questionData?.data?.TaskToResume || null;
  let questionLoading = questionData?.isLoading;
  let ShowMedia = QuestionResponse?.ShowMedia;

  // State to track previous question data
  const [prevQuestionResponse, setPrevQuestionResponse] = useState(null);

  // Nếu người dùng submit câu trả lời thành công
  const { success: isSubmitted } = useSelector((state) => state.questionSubmitSuccess);

  const [audioFinished, setAudioFinished] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);
  const [statusGenerateAudio, setStatusGenerateAudio] = useState("idle");
  const [textTranscript, setTextTranscript] = useState(null);
  const [textResponseTmp, setTextResponseTmp] = useState("");
  const [selectedSpeed, setSelectedSpeed] = useState(1.0);
  const [showMediaButton, setShowMediaButton] = useState(false);

  // Nếu submit question thành công hoặc mới load component
  useEffect(() => {
    const QuestionResponseLoad = questionData?.data?.Question;
    // Compare content of the QuestionResponse (Prompt/Explanation)
    const newQuestionContent = QuestionResponseLoad?.Explanation || QuestionResponseLoad?.Prompt;
    
    if (QuestionResponseLoad && !questionLoading && newQuestionContent !== prevQuestionResponse) {
      processQuestionResponse(QuestionResponseLoad);
      setPrevQuestionResponse(newQuestionContent);
    }
  }, [questionData, questionLoading, isSubmitted, prevQuestionResponse]);

  // Chat Voice Condition
  const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
  const chatModeClass = ChatMode === "switch_voice_text" ? showSwitchVoiceText : "";

  const handleAudioStart = () => {
    setAudioStarted(true);
    console.log("Audio has started playing");
  };

  const handleAudioEnd = () => {
    setAudioFinished(true);
    setAudioStarted(false);
    setTextResponseTmp(null);
  };

  // Show Media
  const handleMediaDisplay = () => {
    const isReadyToShowMedia = QuestionResponse && ShowMedia && !questionLoading;
    if (isReadyToShowMedia) {
      // Thực hiện delay 2s để hiển thị ViewMediaButton
      setTimeout(() => {
        setShowMediaButton(true);
      }, 2000);
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatusGenerateAudio(newStatus);
    handleStatusChangeForVoice(newStatus);
    // Trường hợp hiển thị button I got it phải chờ api phát audio xong.
    if (newStatus === "playing") {
      setTextTranscript(textResponseTmp);
      
      //Show Media nếu có
      handleMediaDisplay();
    }
  };

  //Xử lý view dựa vào QuestionResponse
  const processQuestionResponse = (QuestionResponse) => {
    if (!QuestionResponse) return;
    let newTextResponse = QuestionResponse.Explanation || QuestionResponse.Prompt || "";
    setTextResponseTmp(newTextResponse);
  };

  // Characters
  const Characters = caseResponse?.Characters;
  const Instructor = Characters?.Instructor;
  const InstructorCharacter = Instructor?.Character;
  const preceptorNameText = Instructor?.DisplayName;
  const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
  const shouldButtonRecord = TaskToResume === null && !nextQuestion;
  const hiddenSpeedUI = (TaskToResume === null && !nextQuestion) && QuestionResponse?.Type !== "multiple_choice";
  return (
    <FrameChatFullBox
      className={`${chatModeClass}`}
      data-type="instructor-voice"
    >
      <ImageLabelWrapper>
        <BlinkingDiv audioStarted={audioStarted}>
          <StyledImage
            src={PreceptorAvatar}
            alt={preceptorNameText}
            className={`img-fluid`}
          />
        </BlinkingDiv>
        <PatientLabel>{preceptorNameText || "Instructor"}</PatientLabel>
        {/* Check Câu hỏi hiện tại có media và showMediaButton là true */}
        {showMediaButton && ShowMedia && (
          <ViewMediaButton values={ShowMedia} />
        )}
      </ImageLabelWrapper>
      {textTranscript && (
        <DivContentTranScript
          className="scrollbarStyle-small"
          dangerouslySetInnerHTML={{ __html: textTranscript }}
        />
      )}
      <FooterChatVoice type="FooterChatVoice">
        {/* Chỉ hiển thị TextToSpeech khi không có nextQuestion hoặc TaskToResume */}
        <WhisperSTTComponent
          {...props}
          audioFinished={audioFinished}
          audioStarted={audioStarted}
          statusGenerateAudio={statusGenerateAudio}
          contentSpeechAudio={textResponseTmp}
          shouldButtonRecord={shouldButtonRecord}
        />

        <TextToSpeech
          {...props}
          onAudioStart={handleAudioStart}
          onAudioEnd={handleAudioEnd}
          onStatusChange={handleStatusChange}
          contentSpeechAudio={textResponseTmp}
          statusGenerateAudio={statusGenerateAudio}
          selectedSpeed={selectedSpeed}
        />
      </FooterChatVoice>
      {hiddenSpeedUI &&
        <SpeedSelector
          {...props}
          setSelectedSpeed={setSelectedSpeed}
          selectedSpeed={selectedSpeed}
        />
      }
    </FrameChatFullBox>
  );
}

export default InstructorVoiceChat;