import React, { useState, useEffect } from "react";

//Components
import SelectedTasksList from "./SelectedTasksList";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { actUpdateTaskActive } from "@actions";

const CaseTimelineLeftPanel = (props) => {
    let dispatch = useDispatch();

    //store
    const timepointData = useSelector((state) => state?.timepoint?.data || []);

    // Props
    const { timelineData: initialTimelineData, timepointIdActive } = props && props;
    
    // State
    const [timelineData, setTimelineData] = useState([]);
    const [selectedTasksList, setSelectedTasksList] = useState([]);


    // Set Task
    useEffect(() => {
        const TaskElements = timepointData?.TaskElements || [];
        const defaultSelectedTasks = TaskElements.filter(task => task.Status === "Active");
        setSelectedTasksList(defaultSelectedTasks);
    }, [timepointData?.TaskElements]);

    // Set Timline
    useEffect(() => {
        if (Array.isArray(initialTimelineData)) {
            const endingPage = {
                Id: 9999,
                DisplayName: "Ending Page",
                Time: 100,
                DefaultActiveStatus: true
            };
            setTimelineData([...initialTimelineData, endingPage]);
        }
    }, [initialTimelineData]);

    // Handle Task active
    const handleUpdateTaskActive = (task) => {
        dispatch(actUpdateTaskActive(task));
    };

    const renderTimePoint = (timePoint) => (
            <div className={`case-setting-vertical__item ${timepointIdActive === timePoint?.Id ? "isOpen" : "d-none"}`}>
                <div className="tp-vertical-inner">
                    <div className="tp-vertical-inner__details p-0">
                        <SelectedTasksList
                            {...props}
                            selectedTasksList={selectedTasksList}
                            handleUpdateTaskActive={handleUpdateTaskActive}
                        />
                    </div>
                </div>
            </div>
    );
    
    return (
        <div className="case-timelineCt__left">
            <div className="case-setting-vertical">
                {timelineData.map(renderTimePoint)}
            </div>
        </div>
    );
};

export default CaseTimelineLeftPanel;