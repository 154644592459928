import React, { useEffect, useState } from 'react';
import { FormModal } from '@ui-partents/Modal';
import taskSquare from '@images/common/task-square.svg'
import { useSelector, useDispatch } from "react-redux";
import { atcLoadPlanDetails } from "@actions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { MessageEmtyData } from "@ui-partents/Message";
import { LoaderText } from "@utils";
import { paginationOptions } from "@utils";
import styled from 'styled-components';

const WrapStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #ABEFC6;
  background: #ECFDF3;
  border-radius: 9999px;
  padding: 2px 6px;
  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #17B26A;
  }
  p {
    color: #067647;
    font-size: 12px;
    font-weight: 500;
  }
  &.type-draft {
    border: 1px solid #F2F4F7 !important;
    background: #F7F8FA !important;
    span {
      background: #667085 !important;
    }
    p {
      color: #344054 !important;
    }
  }
  &.type-expired {
    border: 1px solid #FECDCA !important;
    background: #FEF3F2 !important;
    span {
      background: #F04438 !important;
    }
    p {
      color: #B42318 !important;
    }
  }
`

const ModalAssignmentDetail = ({ isOpen, toggle, item, styleStatus }) => {
  const dispatch = useDispatch();
  const dataStore = useSelector((state) => state.loadPlanDetails);
  const [columns, setColumns] = useState([ 
    {
      dataField: "Email",
      text: "Email",
      headerClasses: "text-nowrap font-weight-500",
    },
    {
      dataField: "Name",
      text: "Learner Name",
      headerClasses: "text-nowrap font-weight-500",
    },
  ]);
  
  useEffect(() => {
    if (item?.LaunchPlanId) {
      const params = {
        Action: "LoadDetails",
        LaunchPlanId: item.LaunchPlanId,
      };
      dispatch(atcLoadPlanDetails(params));
    }
  }, [dispatch, item]);

  useEffect(() => {
    if (dataStore?.data?.Cohort?.LearnersProgress?.length > 0) {
      const columnsFilter = dataStore?.data?.Cohort?.CaseIds?.map((caseId, index) => ({
        dataField: "Case",
        text: `Case #${caseId}`,
        headerClasses: "text-nowrap font-weight-500",
        formatter: (cell, row) => {
          return (
            <WrapStatus className={`d-flex align-items-center question-type ${styleStatus(row?.Progress[index])}`}>
              <span></span>
              <p>
                {row?.Progress[index]}
              </p>
            </WrapStatus>
          );
        },
      }))
      setColumns([...columns, ...columnsFilter]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStore]);
  
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="Email"
        data={dataStore?.data?.Cohort?.LearnersProgress || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-2"
              bordered={false}
              data={(dataStore?.data?.Cohort?.LearnersProgress && dataStore?.data?.Cohort?.LearnersProgress) || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Assignment Details`}
      actionText={"Save"}
      maxWidth="1280px"
      iconTitle={taskSquare}
      hiddenButtonCancel={true}
    >
      <div className="position-relative">
        {dataStore?.isLoading ? (
          <div className="height-loader mt-5">
            <LoaderText />
          </div>
        ) : (
          dataStore?.data?.Cohort?.LearnersProgress?.length === 0 ? (
            <MessageEmtyData
              title="Your Library is Empty"
              text="It looks like you haven't generated any case scenarios yet. Start creating a new one to see it here."
            />
          ) : (
            <PaginationProvider pagination={paginationFactory(paginationOptions(dataStore?.data?.Cohort?.LearnersProgress?.length || 0))}>
              {contentTable}
            </PaginationProvider>
          )
        )}
      </div>
    </FormModal>
  );
};

export default ModalAssignmentDetail;