/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { FormTextareaCommon } from "./FormTextarea";
import { listChatGpt } from "../../../../utils/dataDefault";
import Select from "react-select";
import { TitleNoteCommon } from "../../home/generate";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import TextareaAutosize from "react-textarea-autosize";
import { atcUpdateItemTimePoint } from "@actions";
import { toast } from "react-toastify";

const WrapDialog = styled.div`
  width: 100%;
  .form-dialog {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const WrapFormTextarea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  textarea {
    padding: 12px;
    font-size: 14px;
    background: #f9fafb;
    border: 1px solid #d6dbe3;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    &:hover {
      cursor: pointer;
      background: #f4f4f4;
    }
    &:focus {
      background: #fff;
      box-shadow: 0px 0px 0px 4px rgb(0 129 255 / 12%);
    }
  }
`;

export default function DialogSettings(props) {
  const dispatch = useDispatch();
  //props
  const { timepointData, dataType, id, activeViewClass } = props && props;
  const { ChatSettings } = timepointData || {};
  const { ChatScript } = ChatSettings || {};
  const filteredData = timepointData?.TaskElements?.find(item => item.ViewClass === activeViewClass);
  let TimepointId = timepointData?.Id;

  //state
  const [tempContent, setTempContent] = useState(ChatScript || "");
  const [valueEditor, setValueEditor] = useState(filteredData?.Instructions);

  useEffect(() => {
    setTempContent(ChatScript || "");
  }, [ChatScript]);

  useEffect(() => {
    const filteredData = timepointData?.TaskElements?.find(item => item.ViewClass === activeViewClass);
    setValueEditor(filteredData?.Instructions);
  }, [timepointData, activeViewClass]);

  // Handle Submit
  const handleSubmit = useCallback(
    debounce((value) => {
      const params = {
        Action: "Update",
        Target: "ChatSettings",
        ChatScript: value,
        TimePointId: timepointData?.Id,
      };
      dispatch({
        type: storyboardConstants.CHAT_SETTINGS_REQUEST,
        isLoading: true,
      });
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        if (res?.status === 200) {
          const data = res?.data;
          dispatch({
            type: storyboardConstants.CHAT_SETTINGS_SUCCESS,
            payload: data,
          });
          toast.success(`Content updated successfully!`, {
            position: "top-right",
          });
        } else {
          dispatch({
            type: storyboardConstants.CHAT_SETTINGS_FAILURE,
            error: "error",
          });
        }
      });
    }, 1000),
    []
  );

  const handleSubmitInstruction = useCallback(
    debounce((value) => {
      let currentParams = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: TimepointId,
        Flow: {
          Mode: "edit",
          Task: activeViewClass,
          Instructions: value
        }
      };
      dispatch(atcUpdateItemTimePoint(id, currentParams));
    }, 1000),
    []
  );

  // Handle Change
  const handleChange = (e) => {
    const { value } = e.target;
    setTempContent(value);
    handleSubmit(value);
  };

  const handleChangeInstruction = (e) => {
    const { value } = e.target;
    setValueEditor(value);
    handleSubmitInstruction(value);
  }

  const { setValue, control, watch } = useForm();

  return (
    <WrapDialog>
      <form className="form-dialog">
        {dataType?.type === "patient" ? (
          <>
            <WrapFormTextarea>
              <div className="w-100">
                <TitleNoteCommon
                  dataFor="prompt"
                  title="Interactive Prompt for Virtual Patient"
                  content="The first statement that the Virtual Instructor says to the Learner"
                  {...props}
                />
              </div>
              <div className="w-100">
                <TextareaAutosize
                  name="ChatScript"
                  className="w-100 form-control"
                  maxRows="10"
                  minRows={8}
                  onChange={handleChange}
                  value={tempContent}
                  placeholder="We recommend that you start your prompt with “YOU are …”, in which “YOU” is the AI. Example:
YOU are roleplaying a 56-year-old female patient who presents with fever, shortness of breath…"
                />
              </div>
            </WrapFormTextarea>
            <div className="wrap-option">
              <TitleNoteCommon
                dataFor={"model"}
                title={"AI Model"}
                content=""
              />
              <Controller
                control={control}
                name="gpt_model"
                defaultValue={"gpt-4o"}
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common react-select-md w-100"
                      placeholder="Select model"
                      value={listChatGpt?.find(
                        (c) => c.value === watch("gpt_model")
                      )}
                      options={listChatGpt}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </div>
            <WrapFormTextarea>
              <div className="w-100">
                <TitleNoteCommon
                  dataFor="initital"
                  title="Instructions for Learner"
                  content="Pop-up instructions given to the Learner as they start the dialog"
                  {...props}
                />
              </div>
              <div className="w-100">
                <TextareaAutosize
                  name="ChatScript"
                  className="w-100 form-control"
                  maxRows="10"
                  minRows={8}
                  onChange={handleChangeInstruction}
                  value={valueEditor}
                  placeholder="Example:
Click on the mic button and start speaking to the patient."
                />
              </div>
            </WrapFormTextarea>
          </>
        ) : (
          <>
            <FormTextareaCommon
              setValue={setValue}
              title="Initial Statement"
              textPlaceholder="Example: 
How do you think your conversation with the patient went? "
              contentTooltip="The first statement that the Virtual Instructor says to the Learner"
              dataFor="initital"
            />
            <FormTextareaCommon
              setValue={setValue}
              title="Context"
              textPlaceholder="Example: 
In this case, the learner is approached by a 56-year-old female patient who is experiencing severe abdominal pain…"
              contentTooltip="Summary of the situation to provide relevant background information for the Virtual Instructor"
              dataFor="context"
            />
            <FormTextareaCommon
              setValue={setValue}
              title="Specific Instructions for Virtual Instructor"
              textPlaceholder="Example:
-Use a supportive, empathetic and encouraging voice throughout the conversation
-Listen patiently and encourage the learner to share all of their thoughts
-Verbally recognize the feelings and challenges shared by the learner
-Guide the learner through the positive aspects and the areas that can be improved
-Give your feedback and follow-up question as brief statements, not longer than 1-2 sentences, in a positive tone."
              contentTooltip="Detailed step-by-step guide on how the Virtual Instructor should conduct the conversation, including tone instructions if applicable"
              dataFor="specific"
            />
            <FormTextareaCommon
              setValue={setValue}
              title="Ending Criteria"
              textPlaceholder="Example:
The learner indicates that they have shared all their perspectives, and you have provided all necessary feedback."
              contentTooltip="Condition to end this dialog"
              dataFor="ending"
            />
          </>
        )}
      </form>
    </WrapDialog>
  );
}
