import React, { useState, useRef, createRef, useEffect } from 'react'
import { Icon } from '@components/common'
import Result from '@images/teachV2/book-close.svg'
import { Button, Col, Container, Row } from 'reactstrap'
import GenerateItem from './GenerateItem'
import ModalEditRequirement from './ModalEditRequirement'
import axios from "axios";
import * as Config from "@constants/Config";
import stick from '@images/teachV2/stick-btn.svg'
import stickNoBg from '@images/teachV2/stick-no-bg.svg'
import { listGenerate } from "@utils";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// import Export from '@images/teachV2/export.svg'
import EditIcon from '@images/teachV2/edit.svg'
import ModalConfirmConvert from './ModalConfirmConvert'
import { ModalFeedback } from '../../home/common'
import Select from "react-select";
import ModalUpdateModule from './ModalUpdateModule'
import { loadCaseScript } from "@actions";
import { useDispatch } from 'react-redux'
import ScriptElement from './ScriptElement'
import GenerateItemImage from './GenerateItemImage'
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import ReactTooltip from 'react-tooltip'
import { BetaComponent } from '@ui-partents/Beta';
import { ModalFaq } from './fqaComponent'
import { apiCaller } from "@utils";
import { teachV2Constants } from "@constants";
import { DropdownCommon } from "@ui-partents/Dropdown";

const ResultComponent = (props) => {
  const dispatch = useDispatch();
  const { watch, dataFileImported, dataContentFile, dataLoadCase, idScript } = props
  const [dataCaseScript, setDataCaseScript] = useState(dataLoadCase ? dataLoadCase : null)
  const [loadingGenerateScript, setLoadingGenerateScript] = useState(false)
  const [listChooseGenerate, setListChooseGenerate] = useState([])
  const [dataFeedback, setDataFeedback] = useState()
  const [dataUpdate, setDataUpdate] = useState()
  const [modalOpen, setModalOpen] = useState({
    editRequired: false,
    editVital: false,
    confirmConvert: false,
    modalGiveFeedback: false,
    updateModule: false,
    modalRegenerate: false,
    modalFaq: false,
  });

  const refDefault = useRef()
  const refImage = useRef()
  let lineRefs = useRef([]);
  lineRefs.current = dataCaseScript?.case_sections?.length > 0 && dataCaseScript?.case_sections?.map((_, i) => lineRefs.current[i] ?? createRef());
  useEffect(() => {
    if (dataCaseScript?.case_sections) {
      lineRefs.current = dataCaseScript?.case_sections?.map((_, i) => lineRefs.current[i] ?? createRef());
    }
  }, [dataCaseScript?.case_sections]) 

  const menuData = [
    { type: 'document', text: 'Word Document' },
    { type: 'scope', text: 'Scope' }
  ];

  // Handle Toogle Modal
  const toggleModal = (type) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  useEffect(() => {
    setDataCaseScript(dataLoadCase)
  }, [dataLoadCase])

  const handleChooseGenerate = (data) => {
    if (listChooseGenerate?.includes(data?.value)) {
      setListChooseGenerate(() => {
        return listChooseGenerate.filter(item => item !== data?.value)
      })
    } else {
      const dataAdd = listChooseGenerate.concat([data?.value])
      setListChooseGenerate(dataAdd)
    }
  }

  const handleGenerateScript = () => {
    setLoadingGenerateScript(true)
    const formData = new FormData();
    dataFileImported && formData.append("document_file", dataFileImported);
    formData.append("action_type", 'case_creation_pipeline');
    formData.append("template_id", watch('template_id'));
    formData.append("gpt_model", watch('gpt_model'));
    // formData.append("lang_code", watch('lang_code'));
    formData.append("step", "case_script");
    formData.append("id", dataCaseScript?.id);
    dataCaseScript?.augmented_document_ids && formData.append("document_id", dataCaseScript?.augmented_document_ids);
    formData.append("prompt", dataCaseScript?.requirements);
    formData.append("learning_objectives", dataCaseScript?.learning_objectives);

    axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if(response.status === 200) {
        let params = {
          action_type: "load_case_script",
          id: idScript,
        }
        dispatch(loadCaseScript(params))
        // showNotificationCaseScript("case_script")
      }
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingGenerateScript(false)
    })
  }

  const handleGenerateFinal = async (step, sections) => {
    setLoadingGenerateScript(true)
    const formData = new FormData();
    dataFileImported && formData.append("document_file", dataFileImported);
    formData.append("action_type", 'case_creation_pipeline');
    formData.append("template_id", watch('template_id'));
    formData.append("gpt_model", watch('gpt_model'));
    // formData.append("lang_code", watch('lang_code'));
    formData.append("id", dataCaseScript?.id);
    dataCaseScript?.augmented_document_ids && formData.append("document_id", dataCaseScript?.augmented_document_ids);
    formData.append("prompt", dataCaseScript?.requirements);
    formData.append("learning_objectives", dataCaseScript?.learning_objectives);
    formData.append("step", step);
    formData.append("case_sections", JSON.stringify(sections));

    return await axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response?.data) {
        if(response.status === 200) {
          let params = {
            action_type: "load_case_script",
            id: idScript,
          }
          dispatch(loadCaseScript(params))
          // showNotificationCaseScript(step)
        }
      }
      return response?.data
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingGenerateScript(false)
      setListChooseGenerate([])
    })
  }

  const GenerateStep = async () => {
    // eslint-disable-next-line array-callback-return
    const listFilter = listGenerate?.filter((generate) => {
      if (listChooseGenerate.includes(generate?.value)) {
        return generate?.value
      }
    })
    if (listFilter?.length) {
      const dataTest = await handleGenerateFinal(listFilter[0].value, dataCaseScript.case_sections)
      if (dataTest && listFilter?.length > 1) {
        const dataTest2 = await handleGenerateFinal(listFilter[1].value, dataTest.case_sections)
        if (dataTest2 && listFilter?.length > 2) {
          await handleGenerateFinal(listFilter[2].value, dataTest2.case_sections)
        }
      }
      setListChooseGenerate([])
    }
  }

  const Option = ({ innerProps, label, data, value }) => {
    return (
      <div
        {...innerProps}
        className='option-detail'
        style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <span className="react-select-item">Module #{data}</span>
      </div>
    );
  };

  const handleExport = () => {
    const splitParagraphIntoBullets = (text) => {
      const test = text.replaceAll(/<[^>]*>/g, '<br>')
      return test.split("<br>")
    }
    const arrLearning = []
    const learningPoints = splitParagraphIntoBullets(dataContentFile?.learning_objectives);
    learningPoints.forEach(bulletPoint => {
      bulletPoint.trim().length !== 0 && arrLearning.push(
        new Paragraph({
          text: bulletPoint,
          bullet: {
            level: 0
          }
        }),
      );
    });
    const doc = new Document({
      sections: [{
        children: [
          new Paragraph({
            text: dataCaseScript?.title,
            heading: "Heading1"
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Learning Points`,
                bold: true, // Making the title bold
              }),
              new Paragraph({
                children: [],  // Just newline without text
              }),
              ...arrLearning
            ],
          }),
          ...dataCaseScript?.case_sections.map(item => {
            const arr = []
            const bulletPoints = splitParagraphIntoBullets(
              item.contents
            );
            bulletPoints.forEach(bulletPoint => {
              bulletPoint.trim().length !== 0 && arr.push(new Paragraph({
                  text: bulletPoint,
                  bullet: {
                    level: 0
                  }
                }),
              );
            });
            return (
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item.name}\n`,
                    bold: true, // Making the title bold
                  }),
                  new Paragraph({
                    children: [],  // Just newline without text
                  }),
                  ...arr
                ],
              })
            )
          }),
        ]
      }]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'export.docx');
    });
  }

  const checkListGenerate = () => {
    let list = dataCaseScript?.case_sections?.map(section => section?.name)
    let listGen = listGenerate?.map(gen => gen?.type)
    if (listGen.every(item => list.includes(item))) {
      return false
    } else {
      return true
    }
  } 

  const handleSaveTitle = async (e) => {
    const params = {
      ...dataCaseScript,
      action_type: "save_case_script",
      title: e?.replaceAll('\n', '')
    }
    if (dataCaseScript?.title !== e?.replaceAll('\n', '')) {
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          dispatch({ type: teachV2Constants.SAVE_CASE_SCRIPT_SUCCESS, payload: res?.data });
        }
      });
    }
  }

  const handleDropdownItemClick = (item, e) => {
    if (item?.type === "document") {
      handleExport()
    } else {
      const params = {
        action_type: "export_case_script",
        export_type: "scope",
        id: idScript
      }
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          window.open(res?.data?.ScopeURL, '_blank').focus();
        }
      });
    }
    // Here you can add additional logic for when an item is clicked
  };

  return (
    <>
      <div className="result-top">
        <Row>
          <Col md={9} className='pl-0 pr-3'>
            <div className="resultComponent">
              <div className="resultComponent__header w-100">
                <div 
                  className="resultComponent__header-title" 
                  contentEditable={dataCaseScript?.case_sections?.length > 0 ? true : false}
                  onBlur={(e) => handleSaveTitle(e?.target?.innerText)}
                >
                  <div className="result-icon">
                    <Icon src={Result}/>
                  </div>
                  <ReactTooltip id={`title-${dataCaseScript?.id}`} place='bottom' effect='solid' className="case-tooltip">
                    <span>{dataCaseScript?.title}</span>
                  </ReactTooltip>
                  <p
                    data-tip
                    data-for={`title-${dataCaseScript?.id}`}
                  >
                    {dataCaseScript?.title}
                  </p>
                </div>
                <div className="resultComponent__header-copy">
                  <ReactTooltip id={`export-${dataCaseScript?.id}`} place='bottom' effect='solid' className="case-tooltip">
                    <span>Download your case scenario as a Word document.</span>
                  </ReactTooltip>
                  <div
                    // data-tip
                    // data-for={`export-${dataCaseScript?.id}`}
                    // onClick={(e) => handleExport(e)}
                    className={`${!dataCaseScript?.case_sections?.length ? "disabled-export" : ""}`}
                  >
                    {/* <Icon src={Export} stroke="#0089C2"/>
                    Export */}
                    <DropdownCommon
                      menuData={menuData}
                      textDropdown="Export"
                      type="default"
                      onItemClick={handleDropdownItemClick}
                    />
                  </div>
                </div>
              </div>
              <div className="resultComponent__required w-100">
                <div className="resultComponent__required-header">
                  <p>Requirements</p>
                  <ReactTooltip id={`edit-required-${dataCaseScript?.id}`} place='top' effect='solid' className="case-tooltip">
                    <span>Edit The Requirements</span>
                  </ReactTooltip>
                  <div 
                    data-tip
                    data-for={`edit-required-${dataCaseScript?.id}`}
                    className="btn-icon" 
                    onClick={() => toggleModal("editRequired")}
                  >
                    <Icon src={EditIcon} width={22} height={22}/>
                  </div>
                </div>
                <p className="resultComponent__required-content">{dataLoadCase?.requirements}</p>
              </div>
              <div className="resultComponent__generate w-100">
                <GenerateItem
                  handleGenerateScript={handleGenerateScript}
                  dataCaseScript={dataCaseScript}
                  contentGenerate={dataCaseScript?.learning_objectives} 
                  loadingGenerateScript={loadingGenerateScript}
                  // handleSave={handleSave}
                  dataRef={refDefault}
                  refDefault={refDefault}
                  index={0}
                  toggleModal={toggleModal}
                  setDataFeedback={setDataFeedback}
                  setDataCaseScript={setDataCaseScript}
                  lineRefs={lineRefs}
                  {...props} 
                />
                {dataCaseScript?.case_sections &&
                  <>
                    {dataCaseScript?.case_sections?.map((section, index) => (
                      <GenerateItem
                        dataRef={lineRefs.current[index]}
                        key={index}
                        index={index+1}
                        dataCaseScript={dataCaseScript} 
                        handleGenerateScript={handleGenerateScript}
                        contentGenerate={section?.contents} 
                        titleGenerate={section?.name}
                        // handleSave={handleSave}
                        toggleModal={toggleModal}
                        setDataFeedback={setDataFeedback}
                        modalOpen={modalOpen}
                        setDataCaseScript={setDataCaseScript}
                        refDefault={refDefault}
                        lineRefs={lineRefs}
                        {...props} 
                      />
                    ))}
                    {loadingGenerateScript && 
                      <div className="generateItem w-100">
                        <Skeleton height={20} width={`100%`} />
                        <Skeleton height={20} width={`75%`} />
                        <Skeleton height={20} width={`60%`} />
                        <Skeleton height={20} width={`80%`} />
                        <Skeleton height={20} width={`55%`} />
                      </div>
                    }
                    {checkListGenerate() && (
                      <div className="caseGenerate">
                        <p>Would you like to generate additional elements?</p>
                        <div className="caseGenerate-list">
                          {listGenerate?.map((dataGen, index) => (
                            !dataCaseScript?.case_sections?.some(a => a.name === dataGen.type) && (
                              <span
                                className={`${listChooseGenerate?.includes(dataGen?.value) ? "active-item" : ""} caseGenerate-list__item`}
                                onClick={() => handleChooseGenerate(dataGen)}
                                key={index}
                              >
                                {dataGen.label}
                              </span>
                            )
                          ))}
                        </div>
                        <div className={`${loadingGenerateScript || !listChooseGenerate?.length ? "btn-disable" : ""} caseGenerate-btn`} onClick={GenerateStep}>
                          <Icon src={loadingGenerateScript || !listChooseGenerate?.length ? stickNoBg : stick} width={20} height={20}/>
                          Generate 
                        </div>
                        <p className="resultComponent__generate-other">Otherwise, please continue to the section below.</p>
                      </div>
                    )}
                    <GenerateItemImage dataRef={refImage} dataCaseScript={dataCaseScript}/>
                    <p className="resultComponent__generate-other">Once you are satisfied with all of the above, please proceed with the following:</p>
                  </>
                }
              </div>
              <ModalEditRequirement
                isOpen={modalOpen.editRequired}
                toggle={() => toggleModal("editRequired")}
                dataCaseScript={dataCaseScript}
              />
              <ModalConfirmConvert 
                isOpen={modalOpen.confirmConvert}
                toggle={() => toggleModal("confirmConvert")}
                idScript={idScript || dataCaseScript?.id}
              />
              <ModalUpdateModule 
                isOpen={modalOpen.updateModule}
                toggle={() => toggleModal("updateModule")}
                idScript={idScript || dataCaseScript?.id}
                dataUpdate={dataUpdate}
              />
              <ModalFeedback
                isOpen={modalOpen.modalGiveFeedback}
                toggle={() => toggleModal("modalGiveFeedback")}
                type="case_script"
                dataFeedback={dataFeedback}
                idFeedback={dataCaseScript?.id}
              />
              <ModalFaq
                isOpen={modalOpen.modalFaq}
                toggle={() => toggleModal("modalFaq")}
              />
            </div>
          </Col>
          <Col md={3} className='px-3'>
            {/* <ScriptElement
              dataCaseScript={dataCaseScript}
              lineRefs={lineRefs}
              refDefault={refDefault}
              refImage={refImage}
              {...props}
            /> */}
          </Col>
        </Row>
      </div>
      <div id="result-bottom">
        <Container>
          <Row>
            <Col md={9} className='pl-0 pr-0'>
              <div className="resultComponent__footer">
                {dataCaseScript?.related_module_ids?.length > 0 && (
                  <>
                    <ReactTooltip id={`module-${dataCaseScript?.id}`} place='left' effect='solid' className="case-tooltip">
                      <span>Apply recent changes to current interactive module.</span>
                    </ReactTooltip>
                    <div
                      data-tip
                      data-for={`module-${dataCaseScript?.id}`}
                    >
                      <Select
                        className="react-select-common"
                        placeholder="Update Interactive Module"
                        menuPlacement="top"
                        // value={dataCaseScript?.related_module_ids}
                        options={dataCaseScript?.related_module_ids}
                        onChange={val => {
                          toggleModal("updateModule")
                          setDataUpdate(val)
                        }}
                        classNamePrefix="react-select-case"
                        components={{
                          IndicatorSeparator: () => null,
                          Option: Option
                        }}
                      />
                    </div>
                  </>
                )}
                <ReactTooltip id={`convert-${dataCaseScript?.id}`} place='top' effect='solid' className="case-tooltip">
                  <span>Transform your case scenario into a new interactive learning module.</span>
                </ReactTooltip>
                <Button
                  data-tip
                  data-for={`convert-${dataCaseScript?.id}`}
                  className={`resultComponent__footer-btn ${!dataCaseScript?.case_sections?.length ? "disabled-btn" : ""}`}
                  onClick={() => toggleModal("confirmConvert")}
                >
                  Convert to NEW Interactive Module
                  <BetaComponent />
                </Button>
              </div>
            </Col>
            <Col md={3} className='px-3'>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scenario-element">
        <Container>
          <Row>
            <Col md={9} className='pl-0 pr-3'>
            </Col>
            <Col md={3} className='px-3 h-100'>
              <ScriptElement
                dataCaseScript={dataCaseScript}
                lineRefs={lineRefs}
                refDefault={refDefault}
                refImage={refImage}
                toggleModal={toggleModal}
                {...props}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ResultComponent
