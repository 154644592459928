import React from "react";
import _ from "lodash";
import styled from "styled-components";

const WrapListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #101828;
    &.first-item {
      padding-right: 5px;
      border-right: 1px solid #101828;
    }
  }
`;

const QuestionListItem = ({ item }) => {
  const showNameByType = (type) => {
    let string = "";
    switch (type) {
      case "multiple_choice":
        string = "Multiple Choice";
        break;
      case "multiple_choice_table":
        string = "Multiple Choice Table";
        break;
      case "free_text":
        string = "Free Text";
        break;
      case "drag_n_drop":
        string = "Drag And Drop";
        break;
      case "drop_down":
        string = "Drop Down";
        break;
      case "highlight":
        string = "Highlight";
        break;
      default:
        string = "Drag and drop Cloze";
    }
    return string;
  };

  const gradingSchemaOptions = [
    // Danh sách các tùy chọn
    {
      value: "null",
      label: "None (using regular expression)",
    },
    {
      value: "concept",
      label: "Concept Recognition",
    },
    {
      value: "dialog",
      label: "Guided Dialog",
    },
    // {
    //     value: 'umls',
    //     label: 'UMLS Concept'
    // },
    {
      value: "diagnosis",
      label: "Diagnosis Analysis",
    },
    {
      value: "compliance",
      label: "Risk Adjustment Documentation / Compliance",
    },
    {
      value: "chatgpt",
      label: "Open AI",
    },
    {
      value: "chatgpt_voice",
      label: "Open AI - voice",
    },
  ];

  const showNameBySchema = (type, list) => {
    const filteredData = _.find(list, { value: type });
    return <span>{filteredData?.label}</span>;
  };

  return (
    <WrapListItem>
      <span className="first-item">
        {showNameByType(item?.QuestionType)}
      </span>
      <span>
        {showNameBySchema(item?.GradingSchema, gradingSchemaOptions)}
      </span>
    </WrapListItem>
  );
};

export default QuestionListItem;
