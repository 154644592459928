import React, { useState, useEffect, useRef } from "react";
import { MessageEmtyData } from "@ui-partents/Message";
import { FormModal } from '@ui-partents/Modal';
import { FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { paginationOptions } from "@utils";
import { actFetchAllCase } from "@actions";
import { SortCaret } from "@ui-partents/Table";
import { SearchCustomForTable } from "@ui-partents/Search";
import { apiCaller } from "@utils";

function ModalAddModule(props) {
  const { seriesDetail, toggle, isOpen, actFetchCaseWithSeriesId } = props;
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.loadInstitutionAllCases) || {};
  const initialSelectedBooks = [];
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedBooksRef = useRef(initialSelectedBooks);

  useEffect(() => {
    dispatch(actFetchAllCase());
  }, [dispatch]);

  useEffect(() => {
    if (documents) {
      setFilteredData(
        documents.map(item => ({
          ...item,
          selected: selectedBooksRef.current.some(book => book.Id === item.Id),
        }))
      );
    }
  }, [documents]);

  const handleClearSearch = () => {
    setFilteredData(
      documents.map(item => ({
        ...item,
        selected: selectedBooksRef.current.some(book => book.Id === item.Id),
      })) || []
    );
  };

  const handlePageChange = () => {
    setFilteredData(filteredData.map(item => ({
      ...item,
      selected: selectedBooksRef.current.some(book => book.Id === item.Id),
    })));
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [...selectedBooksRef.current];
      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.Id === row.Id);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }

      selectedBooksRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.Id === item.Id),
      })));
    },
    selected: selectedBooksRef.current.map(book => book.Id),
    selectionRenderer: ({ mode, checked, disabled, onChange }) => (
      <FormGroup check className="form-common">
        <Input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name="checkbox"
          className="react-checkbox-common react-checkbox-md"
        />
      </FormGroup>
    ),
  };

  const columns = [
    {
      dataField: "Title",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: { width: "96%" },
      formatter: (value, row) => (
        <div className="d-flex justify-content-between item-book">
          <div className="d-flex">
            <p className="ml-2">{value}</p>
          </div>
        </div>
      ),
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <>
      <ToolkitProvider
        keyField="Id"
        data={filteredData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
              <SearchCustomForTable
                toolkitprops={toolkitprops}
                paginationProps={paginationProps}
                isResetPagination
                searchMode="auto"
                placeholder="Search"
                onClear={handleClearSearch}
              />
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive list-common"
              data={filteredData || []}
              columns={columns}
              bordered={false}
              noDataIndication={() => (
                <MessageEmtyData
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
              selectRow={selectRow}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </>
  );

  const totalSize = filteredData.length || 0;
  const paginationConfig = paginationOptions({ totalSize });

  const handleAdd = async () => {
    setIsLoading(true);
    const listIds = selectedBooksRef.current.map(book => book.Id)
    const params = {
      "Action": "AddMultipleCases",
      "CaseIds": listIds
    }
    apiCaller(`/api/teach/series/${seriesDetail?.Id}/`, 'PUT', params)
      .then(res => {
        if (res?.status === 200) {
          setIsLoading(false);
          toggle()
          actFetchCaseWithSeriesId(seriesDetail?.Id)
        }
      })
      .catch(error => {
        console.error('Error exporting CSV:', error);
        setIsLoading(false);
      });
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Add Module(s) to ${seriesDetail?.Name}`}
      hiddenIcon={true}
      onAction={handleAdd}
      actionText={`Add`}
      maxWidth={`950px`}
      describeContent={`Select from the list below the module(s) that you would like to add to this series.`}
      isLoading={isLoading}
    >
      <PaginationProvider pagination={paginationFactory(paginationConfig)}>
        {contentTable}
      </PaginationProvider>
    </FormModal>
  );
}

export default ModalAddModule;