import React from "react";
import styled from "styled-components";
import { SearchBasic } from "@ui-partents/Search";
import ImageAssistant from "@images/common/assistant.png";

const WrapRecord = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HeaderRecord = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1d2939;
    padding: 8px 0;
  }
`;

const WrapChatRecord = styled.div`
  border: 1px solid #006E9B;
  border-radius: 12px;
  padding: 20px 4px 20px 20px;
  .messengerCt {
    padding-right: 6px;
    overflow-y: scroll;
    height: 560px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &::-webkit-scrollbar {
      width: 8px;
      right: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: #D6DBE3;
    }
    .messages {
      .messages__wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 16px;
        &.assistant-role {
          align-items: flex-start;
          justify-content: flex-start;
        }
        .message-body {
          padding: 12px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background-color: #F79E1C;
          .message-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
          }
          &__role {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #F1800F;
          }
          &.assistant-body {
            background-color: #F2F4F7;
            .message-text {
              color: #697586;
            }
          }
        }
        .message-pic {
          img {
            background-color: #fff;
            width: 40px;
            height: 40px;
            object-fit: cover;
            object-position: top;
            border-radius: 100%;
            border: 1px solid #3CCB7F;
            margin: 0 !important;
          }
        }
      }
    }
  }
`

const dialog = [
  {
    role: "user",
    content: "The fever could be related to the seizure.",
  },
  {
    role: "assistant",
    content:
      "Yes, fever is often linked with seizures. Can you explain more about how fever impacts the seizure mechanism?",
  },
  {
    role: "user",
    content:
      "I think it might be related to some imbalance in the brain’s chemicals.",
  },
  {
    role: "assistant",
    content:
      "That’s part of it. Specifically, what role do genetic factors and chemical imbalances play in this mechanism?",
  },
  {
    role: "user",
    content:
      "I’m not sure about genetic factors, but I know that neurotransmitters like glutamate might be involved.",
  },
];

const RecordGuidedDialog = () => {

  const listItems = dialog?.map((item, index) => {
    return (
      <div className="messages" key={index}>
        {item?.role === "user" && (
          <div className="messages__wrapper">
            <div className="message-body">
              <div
                className="message-text"
                dangerouslySetInnerHTML={{ __html: item?.content || "" }}
              />
            </div>
            <div className="message-pic">
              <img src={ImageAssistant} alt="profile" />
            </div>
          </div>
        )}
        {item?.role === "assistant" && (
          <div className={`messages__wrapper assistant-role`}>
            <div className="message-pic">
              <img src={ImageAssistant} alt="Assistant" />
            </div>
            <div className="message-body assistant-body">
              <p className="message-body__role">
                Assistant:
              </p>
              <div
                className="message-text"
                dangerouslySetInnerHTML={{ __html: item?.content || "" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <WrapRecord>
      <HeaderRecord>
        <h1>Record of Virtual Preceptor’s Guided Dialog</h1>
        <SearchBasic
          onSearch={(e) => console.log(e)}
          placeholder="Keyword search"
          showButton={false}
        />
      </HeaderRecord>
      <WrapChatRecord>
        <div className="messengerCt">
          {listItems}
        </div>
      </WrapChatRecord>
    </WrapRecord>
  );
};

export default RecordGuidedDialog;
