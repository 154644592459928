import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common';
import FileCase from '@images/teachV2/file-case.svg';
import Error from '@images/teachV2/error-circle.svg';
import Close from '@images/teachV2/close.svg';
import { useSelector, useDispatch } from "react-redux";
import { MessageEmtyData } from "@ui-partents/Message";
import { actFetchAllCase, actCreateSeries } from "@actions";
import { paginationOptions } from "@utils";
import { SortCaret } from "@ui-partents/Table";
import { SearchCustomForTable } from "@ui-partents/Search";
import { FormGroup, Input } from 'reactstrap';
import { apiCaller } from "@utils";
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import ClipLoader from "react-spinners/ClipLoader";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`;

export default function SelectMultipleModules(props) {
  const { setValue, watch, handleNext, handleBack } = props;
  const dispatch = useDispatch();
  const { data: documents, isLoading: loadingData } = useSelector((state) => state.loadInstitutionAllCases) || {};
  const initialSelectedBooks = watch('cases_selected') || [];
  const [filteredData, setFilteredData] = useState([]);
  const [showError, setShowError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const selectedBooksRef = useRef(initialSelectedBooks);

  useEffect(() => {
    dispatch(actFetchAllCase());
  }, [dispatch]);

  useEffect(() => {
    if (documents) {
      setFilteredData(
        documents.map(item => ({
          ...item,
          selected: selectedBooksRef.current.some(book => book.Id === item.Id),
        }))
      );
    }
  }, [documents]);

  const handleActionBack = () => {
    setValue('cases_selected', []);
    selectedBooksRef.current = [];
    setFilteredData([]);
    handleBack();
  };

  const handleNextSelect = async () => {
    setIsLoading(true);
    const paramsSeries = {
      Action: "Create",
      Title: watch('series_name'),
      Descriptions: watch('series_description'),
    };
    const response = await dispatch(actCreateSeries(paramsSeries));
    if (response?.status === 200) {
      const itemWithLargestId = response?.data?.reduce((max, item) => item.Id > max.Id ? item : max, response?.data[0]);
      setValue('series_selected', [itemWithLargestId]);
      setValue('series_name', '');
      setValue('series_description', '');
      const listIds = selectedBooksRef.current.map(book => book.Id)
      const params = {
        "Action": "AddMultipleCases",
        "CaseIds": listIds
      }
      apiCaller(`/api/teach/series/${itemWithLargestId?.Id}/`, 'PUT', params)
        .then(res => {
          if (res?.status === 200) {
            handleNext();
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.error('Error exporting CSV:', error);
        });
    }
  };

  const handleClearSearch = () => {
    setFilteredData(
      documents.map(item => ({
        ...item,
        selected: selectedBooksRef.current.some(book => book.Id === item.Id),
      })) || []
    );
    setValue('cases_selected', selectedBooksRef.current.map(book => book.Id));
  };

  const handlePageChange = () => {
    setFilteredData(filteredData.map(item => ({
      ...item,
      selected: selectedBooksRef.current.some(book => book.Id === item.Id),
    })));
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [...selectedBooksRef.current];
      if (isSelect) {
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.Id === row.Id);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }

      selectedBooksRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.Id === item.Id),
      })));
      setValue('cases_selected', updatedSelectedBooks.map(book => book.Id));
    },
    selected: selectedBooksRef.current.map(book => book.Id),
    selectionRenderer: ({ mode, checked, disabled, onChange }) => (
      <FormGroup check className="form-common">
        <Input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name="checkbox"
          className="react-checkbox-common react-checkbox-md"
        />
      </FormGroup>
    ),
  };

  const columns = [
    {
      dataField: "Title",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: { width: "96%" },
      formatter: (value, row) => (
        <div className="d-flex justify-content-between item-book">
          <div className="d-flex">
            <Icon src={FileCase} />
            <p className="ml-2">{value}</p>
          </div>
        </div>
      ),
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <>
      <ToolkitProvider
        keyField="Id"
        data={filteredData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
              <SearchCustomForTable
                toolkitprops={toolkitprops}
                paginationProps={paginationProps}
                isResetPagination
                searchMode="auto"
                placeholder="Search"
                onClear={handleClearSearch}
              />
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive list-common"
              data={filteredData || []}
              columns={columns}
              bordered={false}
              noDataIndication={() => (
                <MessageEmtyData
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
              selectRow={selectRow}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </ToolkitProvider>
      {selectedBooksRef.current.some(book => book?.status?.content === "Caution") && showError && (
        <div className="note-reference">
          <Icon src={Error} />
          <p>
            This document contains limited readable text, which may affect the generated content quality.
            Please proceed with caution when using it for generation.
          </p>
          <div className="note-reference__close" onClick={() => setShowError(false)}>
            <Icon src={Close} stroke="#DC6803" />
          </div>
        </div>
      )}
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </>
  );

  const totalSize = filteredData.length || 0;
  const paginationConfig = paginationOptions({ totalSize });

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Add Interactive Modules to Your Series"
          subtitle="Select from the available interactive modules to include in this series. Use the search bar to quickly find specific modules."
        />
        {!loadingData ? (
          <div className="form-area__select">
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center">
            <ClipLoader color="#0089c3" size={26} />
          </div>
        )}
      </div>
      <div className="action-mcq">
        <ButtonSubmitCommon
          {...props}
          dataPass={!selectedBooksRef.current.length || isLoading}
          handleNext={handleNextSelect}
          handleBack={handleActionBack}
        />
      </div>
    </WrapCommon>
  );
}