import React, { useState, useEffect } from "react";
import {
  StyledImage,
  PatientLabel,
  ImageLabelWrapper,
  ResponseText,
  BlinkingDiv,
  FooterChatVoice,
} from "./VoiceChatStyled";
import WhisperSTTComponent from "./WhisperSTTComponent";
import TextToSpeech from "./TextToSpeech";

const VoiceChatComponent = (props) => {
  const { photoPatient, patientNameText, responseUserMessage } = props && props;

  const [audioFinished, setAudioFinished] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);
  const [statusGenerateAudio, setStatusGenerateAudio] = useState("idle");
  const [messageCurrent, setMessageCurrent] = useState(null);

  // UseEffect to update messageCurrent when responseUserMessage or statusGenerateAudio changes
  useEffect(() => {
    const textMessage = responseUserMessage?.PatientResponse;
    if (
      statusGenerateAudio === "playing" &&
      textMessage !== messageCurrent
    ) {
      setMessageCurrent(textMessage);
    }
  }, [responseUserMessage, statusGenerateAudio, messageCurrent]);

  const handleAudioStart = () => setAudioStarted(true);
  const handleAudioEnd = () => {
    setAudioFinished(true);
    setAudioStarted(false);
  };
  const handleStatusChange = (newStatus) => setStatusGenerateAudio(newStatus);
  const sendTextGenerateAudio = responseUserMessage?.PatientResponse;
  return (
    <React.Fragment>
      <ImageLabelWrapper>
        <BlinkingDiv audioStarted={audioStarted}>
          <StyledImage
            src={photoPatient}
            alt={patientNameText}
            className="img-fluid"
          />
        </BlinkingDiv>
        <PatientLabel>{patientNameText || "Patient"}</PatientLabel>
      </ImageLabelWrapper>
      {/* Khi audio tạo xong thì mới show transcript để cùng lúc phát audio và show text */}
      {messageCurrent && <ResponseText>{messageCurrent}</ResponseText>}
      <FooterChatVoice>
        <WhisperSTTComponent
          {...props}
          audioFinished={audioFinished}
          audioStarted={audioStarted}
          statusGenerateAudio={statusGenerateAudio}
          //Gửi trước cho generate audio
          contentSpeechAudio={sendTextGenerateAudio}
        />
        <TextToSpeech
          {...props}
          onAudioStart={handleAudioStart}
          onAudioEnd={handleAudioEnd}
          onStatusChange={handleStatusChange}
          //Gửi trước cho generate audio
          contentSpeechAudio={sendTextGenerateAudio}
          statusGenerateAudio={statusGenerateAudio}
        />
      </FooterChatVoice>
    </React.Fragment>
  );
};

export default VoiceChatComponent;
