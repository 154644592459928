/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import styled from 'styled-components'
import { ItemOption } from '@ui-partents/Form/FormChoose';
import {
  ButtonComponent
} from "@components/patterns/elements/Button";
import Icon from '@components-common/Icon'
import Back from '@images/teachV2/back.svg'
import SelectMultiple from './SelectMultiple';
import SelectSingle from './SelectSingle';

const WrapSelectCohort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 200px) !important;
  max-height: calc(100vh - 200px) !important;
  width: 100%;
  gap: 20px;
  padding: 16px 0;
  .cohort-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    &.cohort-scroll {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 4px;
        width: 8px
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #b7b7b7;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }
    }
  }
  .cohort-title {
    width: 458px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }    
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .form-area__choice {
    display: flex;
    justify-content: center;
    gap: 24px;
    max-width: 760px;
    &-item {
      @include rem("padding", 20);
      background: #fff;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      max-width: 300px;
      cursor: pointer;
      &--title {
        @include flexbox();
        @include align-items(center);
        @include rem("gap", 20);
        @include rem("padding-bottom", 4);
      }
      p {
        color:#344054;
        font-size: 14px;
        font-weight: 400;
      }
      &.active {
        border: 1px solid #0089C2;
      }
    }
    @media (max-width: 1200px) {
      @include flex-wrap(wrap);
    }
  }
  .cohort-btn {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    width: 458px;
    &__next {
      font-size: 18px;
      font-weight: 500;
      width: 136px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
`

const ButtonCancel = styled.div`
  padding: 14px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #D0D5DD;
  span {
    color: #475467;
    font-size: 18px;
    font-weight: 500;
  }
`

const dataForm = [
  {
    title: "Single Cohort Longitudinal Report",
    description: "Analyze a single cohort’s performance trends over time.",
    value: 1,
    type: "single",
  },
  {
    title: "Multiple Cohorts Comparison",
    description: "Compare performance data across multiple cohorts.",
    value: 2,
    type: "multiple",
  }
]

export default function ChoiceCommon(props) {
  const { setShowDetail } = props
  const [dataChoice, setDataChoice] = useState(dataForm[0])
  const [showTypeSelect, setShowTypeSelect] = useState(false)

  const handleChoose = (value) => {
    setDataChoice(value)
  }

  return (
    <WrapSelectCohort>
      {showTypeSelect ? (
          dataChoice?.type === "single" ? (
            <SelectSingle setShowTypeSelect={setShowTypeSelect} {...props}/>
          ) : (
            <SelectMultiple setShowTypeSelect={setShowTypeSelect} {...props}/>
          )
      ) : (
        <>
          <div className="cohort-form">
            <div className="cohort-title">
              <h1>Choose Report Type</h1>
              <p>How would you like to view cohort performance data?</p>
            </div>
            <div className="form-area__choice">
              {dataForm?.map((data, index) => (
                <ItemOption 
                  key={index}
                  title={data?.title}
                  handleChoose={() => handleChoose(data)}
                  name="option"
                  isCheck={dataChoice?.value === data?.value ? true : false}
                  subTitle={data?.description}
                />
              ))}
            </div>
          </div>
          <div className='cohort-btn d-flex align-items-center justify-content-end'>
            <ButtonCancel 
              onClick={() => setShowDetail(false)} 
              style={{ display: 'flex' }}
            >
              <Icon src={Back}/>
              <span>
                Back
              </span>
            </ButtonCancel>
            <ButtonComponent size="large" className="cohort-btn__next" onClick={() => setShowTypeSelect(true)}>Next</ButtonComponent>
          </div>  
        </>
      )}
    </WrapSelectCohort>
  )
}