import React from 'react';
import styled from 'styled-components';

// Avatar
import PicEndChatNow from "@images/learnerv2/pic_end_chat_now.png";

const EndChatMessage = ({ handleSubmitClose }) => {
  return (
    <EndChatMessageBox>
      <IconAndText>
        <Icon
          src={PicEndChatNow}
          alt="chat-icon"
        />
        <Text>
          <h2>Nice Work!</h2>
          <p>You’ve reached the end of this conversation. Close this chat to proceed with the next task.</p>
        </Text>
      </IconAndText>
      <CloseChatButton onClick={handleSubmitClose}>Close Chat</CloseChatButton>
    </EndChatMessageBox>
  );
}

const EndChatMessageBox = styled.div`
  display: flex;
  justify-content: space-between; /* Chia phần tử thành hai nhóm */
  align-items: center;
  width: 100%;
  padding: 24px 48px;
  background: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 8px;
`;

const IconAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; /* Khoảng cách giữa hình ảnh và văn bản */
`;

const Icon = styled.img`
  width: 123px;
  height: 123px;
  object-fit: contain;
  object-position: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  h2 {
    color: #121926;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.48px;
  }
  
  p {
    color: #697586;
    font-size: 16px;
    font-weight: 400;
  }
`;

const CloseChatButton = styled.button`
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #0089C2;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  border: none;
  &:hover {
    background: #006C94;
  }
`;

export default EndChatMessage;