import React, { useState, useEffect } from 'react';
import { ItemInteractive, InteractiveModuleSort } from '../interactiveModule';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";

// Import the pagination configuration
import {paginationOptions} from "@utils";

const InteractiveDetail = (props) => {
  const { instructorData, loadingModule, activeSeries } = props && props;

  const [sortOption, setSortOption] = useState(null);
  const [sortedData, setSortedData] = useState(instructorData || []);
  // const [rowRecord, setRowRecord] = useState({});
  // const [modal, setModal] = useState({
  //   remove: false,
  // });
  
  // const toggle = (type, row) => {
  //   setRowRecord(row);
  //   setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  // };

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption);
  };

  useEffect(() => {
    let sortedArray = [...(instructorData || [])];

    if (sortOption) {
      const { value } = sortOption;

      if (value === 'title-asc') {
        sortedArray.sort((a, b) => a.Title.localeCompare(b.Title));
      } else if (value === 'title-desc') {
        sortedArray.sort((a, b) => b.Title.localeCompare(a.Title));
      } else if (value === 'edited-newest') {
        sortedArray.sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate));
      } else if (value === 'edited-oldest') {
        sortedArray.sort((a, b) => new Date(a.CreateDate) - new Date(b.CreateDate));
      }
    }

    setSortedData(sortedArray);
  }, [sortOption, instructorData]);

  const columns = [
    {
      dataField: "Title",
      text: "Title",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div>
            <ItemInteractive caseData={row} {...props}/>
          </div>
        );
      },
      style: {
        width: "100%",
      },
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="Id"
        data={sortedData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-interact__filter d-flex align-items-center justify-content-between">
              {activeSeries === "all_case" && (
                <p>Total Interactive Cases: {sortedData.length}</p>
              )}
              <div className="boxSearch">
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder={`Search`}
                />
                <InteractiveModuleSort onChange={handleSortChange} />
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive mt-4"
              bordered={false}
              data={sortedData}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  // Replace the static options with a call to paginationOptions
  const paginationConfig = paginationOptions({ totalSize: sortedData.length || 0 });
  return (
    <div className={`interactiveModule__content`}>
      <div className={`interactiveModule__content-list`}>
        {loadingModule?.case ? (
          <div className="w-100 d-flex justify-content-center">
            <ClipLoader color="#0089c3" size={26} />
          </div>
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )}
      </div>
    </div>
  );
};

export default InteractiveDetail;