import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const dataSingle = [
  {
    value: [4200, 3000, 20000, 35000, 50000],
    name: 'Average Score',
    lineStyle: {
      color: "#0BA5EC", // Màu của đường line
      width: 2, // Độ dày đường line
      type: "solid", // Kiểu nét (solid, dashed, dotted)
    },
    areaStyle: {
      color: "#79DDFC61"
    },
  },
]

const ChartRadar = (props) => {

  const option = {
    legend: {
      orient: "vertical", // Đặt legend theo chiều dọc
      left: "left", // Căn trái
      top: "80%",
    },
    radar: {
      indicator: [
        { name: 'Disease Knowledge' },
        { name: 'Medical Science Knowledge' },
        { name: 'Reasoning Principles' },
        { name: 'Pattern Recognition' },
        { name: 'Decision-Making Efficiency' },
      ],
      axisName: {
        color: '#475467'
      },
    },
    series: [
      {
        type: 'radar',
        data: dataSingle
      }
    ]
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartRadar
