import React from "react";
import { Iconsax } from "@components-common";

function BtnIconEditV2({ colorIcon, handleEditButtonClick, size = 24 }) {
  return (
    <div className="action-btn-table" onClick={handleEditButtonClick}>
      <Iconsax iconName="edit-2" fill={colorIcon || "#667085"} size={size} />
    </div>
  );
}

export default BtnIconEditV2;