import React from 'react'
import { ReportDetailCohortSingle } from '@components/teachV2/reportPage/reportByCohort/ReportSingle'
import { useSelector } from "react-redux";

const ReportCohortSingle = () => {
  const dataCurriculum = useSelector((state) => state.getReportCurriculum || {});

  return (
    <div className="wrapper -ReferenceLiblaryPage">
      <div className="h-100">
        <ReportDetailCohortSingle type="single-cohort" dataCurriculum={dataCurriculum}/>
      </div>
    </div>
  )
}

export default ReportCohortSingle
