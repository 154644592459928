import React from 'react'
import styled from 'styled-components'
import { Input } from 'reactstrap';
import { HeaderCommon, ButtonSubmitCommon } from '../../StepCommon';
import { TitleNoteCommon } from "../../../../home/generate";
import { Controller } from 'react-hook-form';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

const WrapInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .wrap-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export default function FormCreateCohort(props) {
  const { watch, handleNext, control, errors } = props;

  const handleCreateCohort = async () => {
    // const params = {
    //   "Action": "Create",
    //   "Name": watch("cohort_name")
    // };
    // const response = await dispatch(atcCreateCohort(params));
    // console.log(response);
    // if (response?.status === 200) {
    //   const itemWithLargestId = response?.data?.reduce((max, item) => item.CohortId > max.CohortId ? item : max, response?.data[0]);
    //   setValue('cohort_selected', [itemWithLargestId]);
    //   setIsLoading(false);
    // }
    await handleNext();
  };

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon
          title="Create a New Cohort"
          subtitle="Give your cohort a specific name to easily identify the group of learners."
        />
        <WrapInput>
          <div className='wrap-item'>
            <TitleNoteCommon
              dataFor={'cohortName'} 
              title="Cohort Name" 
              content=""
              required
            />
            <Controller
                name="cohort_name"
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => (
                  <>
                    <Input
                      type='text'
                      name='cohort_name"'
                      placeholder="Name"
                      {...field}
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </>
                )}
              />
          </div>
        </WrapInput>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon 
          handleActionBackup={handleCreateCohort}
          dataPass={!watch('cohort_name')}
          {...props} 
        />
      </div>     
    </WrapCommon>
  )
}
