import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { useParams } from "react-router-dom";
import styled, { keyframes } from 'styled-components';

// Suggestions
import { ReadyButton } from "../CaseTask";

// Pulse animation
const pulseEffect = keyframes`
  0% {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.2), 0 0 9px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.3), 0 0 14px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.1);
  }
`;

const AnimatedButton = styled.div`
  &.show-effect {
    animation: ${pulseEffect} 2s ease-in-out infinite;
  }
`;

function ButtonNextTimepoint({
    dataTour,
    overRideEffect,
    taskNexTour,
    handleClickBtnNextTimePoint
}) {
    const { id } = useParams();

    // Selectors
    const getStore = useSelector(state => state?.checkMissingTask?.data || {});
    const checkAutoNextTimepoint = useSelector(state => state.checkAutoNextTimepoint || {});
    const nextTimepointStatusReducer = useSelector(state => state.checkStatusNextTimePoint || {});

    const isMissingTask = getStore.MissingTask;
    const statusOk = getStore?.status === "OK";
    const autoNextLoading = checkAutoNextTimepoint?.isLoading;
    const loadingBtnNextTimepoint = nextTimepointStatusReducer?.isLoading;
    const btnNextLoading = loadingBtnNextTimepoint || autoNextLoading;

    // Memoized data
    const fileList = useMemo(() => dataTour?.filter(event => event?.selector?.includes('step-4')), [dataTour]);
    const buttonClassNames = useMemo(() => getClassNames(overRideEffect, isMissingTask, statusOk, loadingBtnNextTimepoint), [overRideEffect, isMissingTask, statusOk, loadingBtnNextTimepoint]);

    // Event handler
    const handleClick = () => {
        if (isMissingTask === 'advance' || statusOk) {
            handleClickBtnNextTimePoint(id, "advance");
        }
    };

    return (
        <AnimatedButton className={`${buttonClassNames} ${statusOk ? 'show-effect' : ''}`} onClick={handleClick}>
            <div style={{fontSize: 16}} data-task="advance" data-tour={fileList?.[0]?.selector}>
                {btnNextLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Next
            </div>
            {statusOk && <ReadyButton />}
            <PopperItem
                content={getContent()}
                styleInline={{ right: '220px', top: '-100%', position: 'absolute' }}
                postition="right"
                isOpen={taskNexTour === 'next-timepoint'}
                nextTour="end-tour"
            />
        </AnimatedButton>
    );

    function getClassNames(overRideEffect, isMissingTask, statusOk) {
        return [
            "btn",
            "btn-next-timepoint",
            "w-100",
            overRideEffect && "remove-effect",
            isMissingTask !== 'advance' && !statusOk && "disabled"
        ].filter(Boolean).join(" ");
    }

    function getContent() {
        return (
            <div>
                When you have finished all tasks, click <strong>Next Timepoint</strong> to advance.
            </div>
        );
    }
}

export default ButtonNextTimepoint;