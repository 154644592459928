import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import ImageDoctor from '@images/learnerv2/doctor.png';
// import { useHistory } from "react-router-dom";
import { CaseTitle } from "../../InteractAssistant";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//partents
import { ResetCaseButton } from '@ui-partents/Button';

//FsLightbox
import FsLightbox from "fslightbox-react";

//components
import { CurrentTimepointName } from "../AssistantQuestion";

//reactstrap
import { Button } from "reactstrap";

function InteractAssistantBegin(props) {
    const { handleBeginLearner, isLoadingGotIt, typeQuestion, content, caseData } = props;
    const caseResponse = caseData?.data?.Case;
    const caseTitle = caseResponse?.Title;
    const CurrentTimePointDisplayName = caseResponse?.Timeline?.CurrentTimePointDisplayName || '';
    // let history = useHistory();

    // Use screen size context
    const { isMobile } = useScreenSize();

    // State for lightbox images
    const [images, setImages] = useState([]);
    const [lightboxToggler, setLightboxToggler] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    // Fetch all images in .medical-description__content
    useEffect(() => {
        const container = document.querySelector('.medical-description__content');
        if (container) {
            // Lấy tất cả hình ảnh
            const images = Array.from(container.querySelectorAll('img')).map((img) => img.src);
            setImages(images);
    
            // Gắn sự kiện click vào ảnh
            container.querySelectorAll('img').forEach((img, index) => {
                img.addEventListener('click', () => handleImageClick(index));
            });
        }
    }, [content]);    

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentImageIndex(index); // Cập nhật hình ảnh hiện tại
        setLightboxToggler((prev) => !prev); // Đảm bảo toggler luôn thay đổi
    };    

    // Handle redirection
    // const handleRedirectUrl = () => {
    //     history.push(`/learn/assignments`);
    // };

    // Characters
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const InstructorCharacter = Instructor?.Character;
    const PreceptorAvatar = InstructorCharacter?.StandardPhoto || ImageDoctor;
    return (
        <div id="begin-case-view">
            <div className={`screen-learn-start ${isMobile ? "is-mobile" : ""}`}>
                <div
                    className={`screen-learn-start__left ${typeQuestion === 'begin_question' && "wow zoomIn animated"}`}
                    style={{ backgroundImage: `url(${PreceptorAvatar})` }}
                ></div>
                <div className="screen-learn-start__right -show-icon-top wow fadeInUp animated">
                    <div className="medical-info">
                        <div className="medical-info-inner">
                            <div className="medical-info-top">
                                <CaseTitle {...props} title={caseTitle} />
                                {isMobile && <CurrentTimepointName name={CurrentTimePointDisplayName} />}
                                <div className="medical-description scrollbarStyle">
                                    <div
                                        id="html-wrapper"
                                        className="medical-description__content"
                                        dangerouslySetInnerHTML={{ __html: content }}
                                    />
                                </div>
                            </div>
                            <div className="button-group-footer d-flex justify-content-between">
                                {/* <button
                                    className="button-default mr-2"
                                    onClick={handleRedirectUrl}
                                    disabled={isLoadingGotIt}
                                >
                                    Back to Assignments
                                </button> */}
                                <ResetCaseButton 
                                    {...props}
                                    fullWidth={isMobile}
                                />
                                <Button
                                    className="button-yellow px-5"
                                    size="lg"
                                    onClick={() => handleBeginLearner()}
                                    disabled={isLoadingGotIt}
                                >
                                    {isLoadingGotIt && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    Start
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FsLightbox Integration */}
            <FsLightbox
                toggler={lightboxToggler}
                sources={images}
                sourceIndex={currentImageIndex}
                disableBackgroundClose={true}
            />
        </div>
    );
}

export default InteractAssistantBegin;