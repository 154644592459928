import React from "react";
import { CustomInput } from "reactstrap";

//Styles
import { AnswerItemsWrapper, AnswerItem, StyledLabel } from "./style";

function SelectManyVoiceGroup(props) {
  const { AnswerChoices, handleSelectMany } = props;

  return (
    <AnswerItemsWrapper>
      {AnswerChoices?.map((item, i) => (
        <AnswerItem key={i} className={`${item?.Selected ? "selected" : ""}`}>
          <StyledLabel
            style={{ cursor: "pointer" }}
            className={`mb-0 font-weight-400 ${
              item?.Selected ? "selected" : ""
            }`}
          >
            <CustomInput
              id={`checkbox_${i}`}
              name="Selected"
              type="checkbox"
              data-name={item.Content}
              onChange={(e) => handleSelectMany(item, e)}
              defaultValue={item.Id}
              className="mr-0 d-none"
            />
            {item?.Content}
          </StyledLabel>
        </AnswerItem>
      ))}
    </AnswerItemsWrapper>
  );
}

export default SelectManyVoiceGroup;