import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

// image
import image from "@images/common/assistant.png";

// components
import WhisperSTTComponent from './WhisperSTTComponent';
import TextToSpeech from './TextToSpeech';

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
`;

const ImageLabelWrapper = styled.div`
    text-align: center;
    width: 100%;
`;

const ResponseText = styled.div`
    color: #081B2A;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    max-height: 210px;
    overflow-y: auto;
    margin-top: 50px;
    @media (max-width: 768px) {
        font-size: 16px;
        max-width: 90%;
        max-height: 38%;
    }
`;

const ImageBox = styled.div`
    margin-top: 60px;
    background: #6FA9E9;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const BlinkingDiv = styled.div`
    position: relative;
    ${({ audioStarted }) =>
        audioStarted &&
        css`
            &:before,
            &:after {
                content: "";
                width: 120px;
                height: 120px;
                border: 9px solid rgba(20, 91, 173, 0.8);
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                @media (max-width: 768px) {
                    width: 100px;
                    height: 100px;
                }
            }
            &:before {
                animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
            }
            &:after {
                animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
                animation-delay: 0.375s;
            }

            @keyframes hWnWDR {
                from {
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 0.8;
                }
                to {
                    transform: translate(-50%, -50%) scale(1.2);
                    opacity: 0.4;
                }
            }
        `}
`;

const FrameChatFullBox = styled.div`
    height: calc(100vh - 80px);
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
`;

function EngageChatVoice(props) {
    const { contentSpeechAudio: initialContentSpeechAudio, contentShowFirst } = props;
    const [audioFinished, setAudioFinished] = useState(false);
    const [audioStarted, setAudioStarted] = useState(false);
    const [statusGenerateAudio, setStatusGenerateAudio] = useState(null);
    const [contentSpeechAudio, setContentSpeechAudio] = useState(null);

    useEffect(() => {
        if (statusGenerateAudio === "playing" && initialContentSpeechAudio) {
            setContentSpeechAudio(initialContentSpeechAudio);
        }
    }, [statusGenerateAudio, initialContentSpeechAudio]);

    const handleAudioStart = () => {
        setAudioStarted(true); // Set audioStarted to true when audio starts playing
        console.log("Audio has started playing");
    };

    const handleAudioEnd = () => {
        setAudioFinished(true);
        setAudioStarted(false); // Reset audioStarted to false when audio ends
        console.log("Audio has finished playing");
    };

    const handleStatusChange = (newStatus) => {
        setStatusGenerateAudio(newStatus);
        console.log("Status changed to:", newStatus);
    };

    return (
        <FrameChatFullBox className="frameChat__fullBox" data-type="engage-voice">
            <div className="h-100 w-100 text-center d-flex flex-column justify-content-between">
                <ImageLabelWrapper>
                    <BlinkingDiv audioStarted={audioStarted}>
                        <ImageBox>
                            <StyledImage
                                src={image}
                                alt="Patient"
                                className={`img-fluid`}
                            />
                        </ImageBox>
                    </BlinkingDiv>
                    <ResponseText className="scrollbarStyle-small">
                        {contentSpeechAudio || contentShowFirst}
                    </ResponseText>
                </ImageLabelWrapper>
                <WhisperSTTComponent
                    {...props}
                    audioFinished={audioFinished}
                    audioStarted={audioStarted}
                    statusGenerateAudio={statusGenerateAudio}
                    onStatusChange={handleStatusChange}
                />
                <TextToSpeech
                    {...props}
                    onAudioStart={handleAudioStart}
                    onAudioEnd={handleAudioEnd}
                    onStatusChange={handleStatusChange}
                />
            </div>
        </FrameChatFullBox>
    );
}

export default EngageChatVoice;