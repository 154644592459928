import { periodsConstant } from "@constants";

//lodash
import _ from "lodash";

export function loadPeriods (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case periodsConstant.LOAD_ALL_PERIODS_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case periodsConstant.LOAD_ALL_PERIODS_SUCCESS:
            const listPeriod = action.payload?.map((temp) => {
                return {
                    ...temp,
                    value: temp?.PeriodId,
                    label: temp?.Name,
                }
            })
            return {
                ...state,
                data: listPeriod,
                isLoading: false
            };
        case periodsConstant.CREATE_PERIODS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case periodsConstant.UPDATE_PERIODS_SUCCESS:
            let dataClone;
            dataClone = [...state?.data];
            const currentPeriodId = action?.payload?.PeriodId;
            if(dataClone?.length > 0) {
                const indexCurrent = _.findIndex(dataClone, function(x) { return x.PeriodId === currentPeriodId; });
                dataClone[indexCurrent] = action.payload;
            }
            return {
                data: dataClone,
                isLoading: false
            };
        case periodsConstant.REMOVE_PERIOD_FROM_CASE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case periodsConstant.REMOVE_PERIOD_SUCCESS:
            newState = { ...state };
            const PeriodId = action && action.PeriodId;
            if(action.payload && action.payload.status === "Deleted"){
                const filterData = _.filter((newState && newState.data), function(o) { return o.PeriodId !== PeriodId });
                newState.data = filterData;
            }
            return {
                ...newState,
                isLoading: false
            };
        case periodsConstant.LOAD_ALL_PERIODS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};
