import { reportConstant } from "@constants";

export function listLearnersReport(state = {}, action) {
  switch (action.type) {
    case reportConstant.REPORT_CASE_COMPELETED_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.REPORT_CASE_COMPELETED_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.REPORT_CASE_COMPELETED_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getPerformanceFeedbacks(state = {}, action) {
  switch (action.type) {
    case reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: action.error,
      };
    default:
      return state;
  }
}

export function getQuestionRecord(state = {}, action) {
  switch (action.type) {
    case reportConstant.REPORT_QUESTION_RECORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.REPORT_QUESTION_RECORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.REPORT_QUESTION_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function dataDomainRecord(state = {}, action) {
  switch (action.type) {
    case reportConstant.REPORT_DOMAIN_RECORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.REPORT_DOMAIN_RECORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.REPORT_DOMAIN_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function dataOnboaringReport(state = {}, action) {
  switch (action.type) {
    case reportConstant.ON_BOARDING_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.ON_BOARDING_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.ON_BOARDING_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function updateQuestionGradingFields(state = {}, action) {
  switch (action.type) {
    case reportConstant.UPDATE_QUESTION_GRADING_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.UPDATE_QUESTION_GRADING_FIELD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.UPDATE_QUESTION_GRADING_FIELD_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function loadStatisticsQuestionReport(state = {}, action) {
  switch (action.type) {
    case reportConstant.LOAD_STATISTICS_QUESTION_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.REPORT_QUESTION_RECORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.UPDATE_QUESTION_GRADING_FIELD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.LOAD_STATISTICS_QUESTION_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.LOAD_STATISTICS_QUESTION_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function listChatReport(state = {}, action) {
  switch (action.type) {
    case reportConstant.LOAD_CHAT_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.LOAD_CHAT_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.LOAD_CHAT_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function listTopic(state = {}, action) {
  switch (action.type) {
    case reportConstant.LOAD_TOPIC_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.LOAD_TOPIC_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case reportConstant.LOAD_TOPIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}

export function getCurriculum(state = {}, action) {
  switch (action.type) {
    case reportConstant.GET_CURRICULUM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.GET_CURRICULUM_SUCCESS:
      const dataCurriculum = action.payload?.map((curriculum) => {
        const cohortConvert = curriculum?.Cohorts?.map((cohort) => {
          return {
            ...cohort,
            value: cohort.CohortId,
            label: cohort.CohortName,
          }
        })
        return {
          ...curriculum,
          value: curriculum.CurriculumId,
          label: curriculum.CurriculumName,
          Cohorts: cohortConvert,
        }
      })
      return {
        ...state,
        data: dataCurriculum,
        isLoading: false,
      };
    case reportConstant.GET_CURRICULUM_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}


export function getReportCurriculum(state = {}, action) {
  switch (action.type) {
    case reportConstant.GET_REPORT_CURRICULUM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case reportConstant.GET_REPORT_CURRICULUM_SUCCESS:
      const dataReportCurriculum = action.payload?.ReportDetails?.LaunchPlans?.map((launch) => {
        return {
          ...launch,
          value: launch.LaunchPlanName,
          text: launch.LaunchPlanName,
        }
      })
      return {
        ...state,
        data: {
          ...action.payload,
          ReportDetails: {
            ...action.payload.ReportDetails,
            LaunchPlans: dataReportCurriculum,
          }
        },
        isLoading: false,
      };
    case reportConstant.GET_REPORT_CURRICULUM_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
      };
    default:
      return state;
  }
}
