// VoiceButtonStyles.js
import styled, { css } from "styled-components";
import VoiceCircleBg from "@images/chatvoice/voice-cicrle.svg";

// Background Effect with Smooth Animation
export const BackgroundEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${VoiceCircleBg});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
`;

// Button Wrapper
export const ButtonWrapper = styled.button`
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(12px);
  background: transparent;
  border: 1px solid rgba(20, 91, 173, 0.7);

  /* Box-shadow cho glow động dựa trên volumeLevel */
  ${({ isRecording, volumeLevel }) =>
    isRecording &&
    volumeLevel > 0 &&
    `
      box-shadow: 0 0 ${
        volumeLevel * 40 + 20
      }px rgba(20, 91, 173, 0.6); /* Glow chính xung quanh nút */
    `}

  /* Thêm ::before và ::after cho các lớp glow ngoài vòng tròn */
  ${({ isRecording, volumeLevel }) =>
    isRecording &&
    volumeLevel > 0 &&
    `
      &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${
          volumeLevel * 60 + 40
        }px;  /* Kích thước lớp glow ngoài vòng tròn */
        height: ${volumeLevel * 60 + 40}px;
        background: rgba(20, 91, 173, 0.3);  /* Glow màu chính cho lớp ngoài */
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;  /* Đặt lớp glow phía sau nút */
        animation: pulse 1.5s infinite alternate;  /* Hiệu ứng nhấp nháy cho lớp glow */
      }
      
      &::before {
        /* Glow lớp ngoài lớn hơn, mờ hơn */
        width: ${40 + volumeLevel * 80 + 50}px;
        height: ${40 + volumeLevel * 80 + 50}px;
        background: rgba(20, 91, 173, 0.2);
      }
      
      &::after {
        /* Glow lớp trong, nhỏ hơn */
        width: ${60 + volumeLevel * 50 + 30}px;
        height: ${60 + volumeLevel * 50 + 30}px;
        background: rgba(20, 91, 173, 0.6);
      }
    `}
  
  /* Hiệu ứng nhấp nháy cho các lớp glow */
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.6;
    }
    12.5% {
      transform: translate(-50%, -50%) scale(1.05); /* Phóng to nhẹ */
      opacity: 0.58;
    }
    25% {
      transform: translate(-50%, -50%) scale(1.1); /* Phóng to hơn một chút */
      opacity: 0.56;
    }
    37.5% {
      transform: translate(-50%, -50%) scale(1.12); /* Phóng to thêm một chút nữa */
      opacity: 0.54;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.15); /* Phóng to nhiều hơn */
      opacity: 0.45;
    }
    62.5% {
      transform: translate(-50%, -50%) scale(1.12); /* Giảm kích thước một chút */
      opacity: 0.54;
    }
    75% {
      transform: translate(-50%, -50%) scale(1.1); /* Giảm kích thước thêm một chút */
      opacity: 0.56;
    }
    87.5% {
      transform: translate(-50%, -50%) scale(1.05); /* Phóng to nhẹ lại */
      opacity: 0.58;
    }
    100% {
      transform: translate(-50%, -50%) scale(1); /* Quay lại kích thước ban đầu */
      opacity: 0.6;
    }
  }

  /* Thêm hiệu ứng chuyển động mượt mà cho box-shadow */
  transition: box-shadow 0.3s ease-in-out;
`;

// Mic Stream Icon
export const MicStream = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  opacity: ${({ isRecording }) => (isRecording ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`;

export const MicDisabled = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  width: 100%;
  height: 100%;
`;

export const PauseStream = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
`;

export const Tooltip = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  width: 529px;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-bottom: ${({ marginBottom }) => marginBottom || "0px"};
`;

export const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
`;

// PulseWrapper with multiple layers
export const PulseWrapper = styled.div`
  position: relative;
  cursor: pointer;
  ${({ isRecording, isLoadingWebSocket }) =>
    !isLoadingWebSocket &&
    isRecording &&
    css`
      &:before,
      &:after {
        content: "";
        width: 120px;
        height: 120px;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .pulseLayer {
        width: 160px;
        height: 160px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0.4;
      }
    `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  div {
    position: absolute;
    transform: scale(0.7);
  }
`;
