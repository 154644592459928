import React from 'react';
import { FormGroup, Label, Input, Col } from "reactstrap";
import gradingSchemaOptions from "./GradingSchemaList";

function GSFreeText({ gradingSchema, handleChangeOptions }) {
    return (
        <Col md={3}>
            <FormGroup>
                <Label className="labeForm">Grading Schema:</Label>
                {gradingSchemaOptions?.map((item, index) => (
                    <FormGroup check className="mb-2" key={index}>
                        <Label check>
                            <Input
                                type="radio"
                                onChange={() => handleChangeOptions(item?.value)}
                                checked={gradingSchema === item?.value}
                                name="GradingSchema"
                            />
                            {item?.label}
                        </Label>
                    </FormGroup>
                ))}
            </FormGroup>
        </Col>
    );
}

export default GSFreeText;