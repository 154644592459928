import React from "react";
import { Row } from "reactstrap";
import AssignmentItem from "./AssignmentItem";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";

const AssignmentList = ({ type, launchPlansList, ...props }) => {
    return (
        <>
            {launchPlansList?.length > 0 ? (
                <Row className="assignment-list">
                    {launchPlansList.map((assignment, index) => (
                        <AssignmentItem
                            {...props}
                            key={index}
                            type={type}
                            assignment={assignment}
                            showProgres={true}
                        />
                    ))}
                </Row>
            ) : (
                <MessageEmtyData
                    title="No Available Assignments Yet"
                    text="There are no assignments at the moment. Please check back later for updates!"
                    borderDashed={true}
                />
            )}
        </>
    );
}

export default AssignmentList;